<template>
    <div  class="iframe-form-wrap" :class="[formType + '-form']">
        <f-form
            ref="editForm"
            :model="formData"
            :size="mixSize"
            :label-width="formLabelWidth ? formLabelWidth : mixLabelWidth"
            :labelPosition="mixLabelPosition"
            :disabled="formDisabled"
            :rules="formRules"
            :column="mixColumn"
            :gutter="mixGutter"
            :showInvalidPrompt="false"
        >
            <slot></slot>
        </f-form>
    </div>
</template>

<script>
import { styleMix } from '@/common/comMixin';
    export default {
        mixins: [styleMix],
        name: 'ComIframeForm',
        data() {
            return {
                formDisabled: false,
            }
        },
        props: {
            /**
             * 表单类别
             */
            formType: {
                type: String,
                default: 'add',
                required: true
            },
            formData: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            /**
             * 设置表单项label的宽度
             */
            formLabelWidth: String,
            formRules: {
                type: Object,
                default: () => {
                    return {};
                }
            },
        },
        created () {
            this.init();
        },
        methods: {
            init() {
                switch (this.formType) {
                    case 'add':
                        break;
                    case 'edit':
                        break;
                    case 'view':
                        // 当是查看页面时,对表单disabled处理
                        this.formDisabled = true;
                        break;
                    case 'approval':
                        // 当是审批流页面时,对表单disabled处理
                        this.formDisabled = true;
                        break;
                    default:
                        break;
                }
            },
            getForm() {
                return this.$refs.editForm;
            },
        },
    }
</script>

<style lang="scss" scoped>
.iframe-form-wrap {
    background: #fff;
    margin: 8px;
    .el-form.f-form {
        margin: 0px;
    }
    /deep/ .el-row {
        width: 100%;
    }
    /deep/ .el-form-item {
        margin: 6px 0;
        font-size: 12px;
        .el-form-item__label {
            font-size: 12px;
            color: #303030;
        }
    }
    /deep/ .el-input {
        border-radius: 2px;
    }
    /deep/ .el-input--mini .el-input__inner {
        height: 30px;
        line-height: 30px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
    }
    /deep/ .query-form-wrap .el-input--mini .el-input__inner {
        border: 1px solid #e4e4e4;
        border-radius: 2px;
    }
    /deep/ .el-input.is-disabled .el-input__inner,
    /deep/ .el-textarea.is-disabled .el-textarea__inner {
        background-color: #f7f8f9;
        border-color: #f3f3f3;
        color: #000;
    }
    /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner,
    /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner
    /deep/ .el-radio__input.is-disabled .el-radio__inner,
    /deep/ .el-radio__input.is-disabled.is-checked .el-radio__inner {
        background-color: #f7f7f7;
        border-color: #f3f3f3;
    }
    /deep/ .el-col {
        text-align: left;
    }
}
</style>