import { isEqual, capitalize } from '@iss/vmui-vue/lib/utils/util';
import { isDef } from '@iss/vmui-vue/lib/utils/shared';

let uid = 0;

export default class Node {
  constructor (data, config, parentNode) {
    this.data = data;
    this.config = config;
    this.parent = parentNode || null;
    this.level = !this.parent ? 1 : this.parent.level + 1;
    this.uid = uid++;

    this.initState();
    this.initChildren();
  }

  initState() {
    const { value: valueKey, label: labelKey } = this.config;

    this.value = this.data[valueKey];
    this.label = this.data[labelKey];
    this.pathNodes = this.calculatePathNodes();
    this.path = this.pathNodes.map(node => node.value);
    this.pathLabels = this.pathNodes.map(node => node.label);

    // 动态加载
    this.loading = false;
    this.loaded = false;
  }

  initChildren() {
    const { config } = this;
    const { children: childrenKey } = config;
    const childrenData = this.data[childrenKey];

    this.hasChildren = Array.isArray(childrenData);
    this.children = (childrenData || []).map(child => new Node(child, config, this))
  }

  calculatePathNodes() {
    const nodes = [this];
    let parent = this.parent;
    // 做成一个伪数组
    /**
     * [
     *  0: parent (会找到root 里面)
     *  1: this
     * ]
     */
    while (parent) {
      nodes.unshift(parent);
      parent = parent.parent;
    }

    return nodes;
  }

  getValueByOption() {
    return this.config.emitPath
      ? this.getPath()
      : this.getValue();
  }

  getPath() {
    return this.path;
  }

  getValue() {
    return this.value;
  }

  getText(allLevels, separator) {
    return allLevels ? this.pathLabels.join(separator) : this.label;
  }

  // 是否具有子节点
  get isLeaf() {
    const { data, loaded, hasChildren, children } = this;
    const { lazy, leaf: leafKey } = this.config;
    if (lazy) {
      const isLeaf = isDef(data[leafKey])
        ? data[leafKey]
        : (loaded ? !children.length : false);
      this.hasChildren = !isLeaf;
      return isLeaf;
    }
    return !hasChildren;
  }

}
