/**
 * 下拉公共的mixin
 */
let props = { label: 'orgName', value: 'orgKey' };
let matchKey = {
    orgCode: 'orgCode',
    orgName: 'orgName',
    orgKey: 'orgKey',
    orgType: 'orgType',
    orgPropery: 'orgPropery'
};
let remoteFilterKey = ['orgCode', 'orgName'];
export default {
    data() {
        // eslint-disable-next-line no-unused-vars
        let { change, select, ...listeners } = this.$listeners;
        return {
            listeners,
            keyNames: { currentPage: 'start', total: 'total', pageSize: 'limit', rows: 'rows' },
            scroll: { currentPage: 1, pageSize: 15, height: 200 }
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    /**
     * 1. 开放属性 : props, matchKey, remoteFilterKey
     */
    props: {
        value: [String, Array, Number],
        formModel: Object,
        dataSource: [String, Array],
        extraData: Object,
        props: { type: Object, default: () => props },
        matchKey: { type: Object, default: () => matchKey },
        remoteFilterKey: { type: Array, default: () => remoteFilterKey }
    },
    computed: {
        select: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('change', val);
            }
        },
        exportData: {
            get() {
                return this.formModel;
            },
            set(val) {
                this.$emit('update:formModel', val);
            }
        }
    },
    methods: {
        selectHandle(row) {
            this.$emit('select', row);
        }
    }
};
