<template>
    <div>
        <linkage-select :options="nodes"></linkage-select>
    </div>
</template>

<script>
import LinkageSelect from './linkage-select';
export default {
    name: 'LinkageMenu',
    props: {
        nodes: {
            type: Array,
            required: true
        }
    },
    components: {
        LinkageSelect
    }
};
</script>
