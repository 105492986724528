<template>
    <div class="com-hiddenbtn-setting">
        122233
    </div>
</template>

<script>
    export default {
        name: 'ComHiddenbtnSetting'
    }
</script>

<style lang="scss" scoped>
.com-hiddenbtn-setting {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000000;
}
</style>