<template>
    <div class="multiple-cell">
        <div class="multiple-cell-item" v-for="(item, index) in _multipleConfig" :key="item.label || index">
            <div class="label" :style="{ width:'auto','margin-right': '8px'}">
                {{ typeof item.formatterLabel == 'function' ? item.formatterLabel(rowData) : item.label }}:
            </div>
            <div
                class="value"
                @click="handleValueClick($event, item)"
                :style="{ textAlign: item.valueAlign || 'left' }"
            >
                <!-- 标签类型 -->
                <f-tag v-if="item.type == 'tag'" :type="getTagType(item)" :size="mixSize" effect="dark">
                    {{ getVaule(item) }}
                </f-tag>
                <!-- 超链接类型 -->
                <span
                    v-else-if="item.type == 'link'"
                    :class="getLinkClassName(item)"
                    :title="getVaule(item).toString().length > textMaxLen ? getVaule(item) : ''"
                    >
                    {{ getVaule(item).toString().length > textMaxLen ? getVaule(item).toString().substr(0, textMaxLen) + '...':  getVaule(item) }}
                </span>
                <!-- 带审批流的状态类型 -->
                <span v-else-if="item.type == 'process'" :class="getProcessClassName(item)">
                    <span :class="getVaulecClassName(item)">
                    {{ getVaule(item).toString().length > textMaxLen ? getVaule(item).toString().substr(0, textMaxLen) + '...':  getVaule(item) }}
                    </span>
                    <i class="el-icon-info"></i>
                </span>
                <!-- 其他类型 -->
                <span
                    v-else
                    :class="getVaulecClassName(item)"
                    :title="getVaule(item).toString().length > textMaxLen ? getVaule(item) : ''"
                    >
                    {{ getVaule(item).toString().length > textMaxLen ? getVaule(item).toString().substr(0, textMaxLen) + '...':  getVaule(item)}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { styleMix } from '@/common/comMixin';
import { VXETable, emptyText } from '@/common/plugins/table-formaters';
import XEUtils from 'xe-utils';
/**
 * 提供多行表格的单元格渲染标签
 * 1.可以定义label的宽度
 * 2.可以定义value的对齐方式
 * 3.可以定义value的class名称link-url, link-code, state-success, state-process, state-refuse, state-minor, state-amount等
 * 4.可以定义value的类型, 如link(需要给events.click事件), tag, process流程图(需要给event.click事件) 等类型
 * 5.支持对value的formatter处理
 * 6.支持value的点击事件
 */
export default {
    name: 'MultipleCell',
    mixins: [styleMix],
    data() {
        return {
            textMaxLen: 50
        };
    },
    props: {
        rowData: {
            type: Object,
            defualt: () => {
                return {};
            }
        },
        /**
         * @param multipleConfig[index] 的数据说明如下
         * @param {String} label 要显示的label的值
         * @param {String} valueKey  要显示的值的字段名
         * @param {String} valueAlign  值的对齐方式 有3中类型 'left' | 'center' | 'right' 默认'left'
         * @param {String} type 要显示的值的类型 有3中类型 'tag' | 'process' | 'link'
         *                 'tag' : 会生成el-tag标签
         *                 'process': 会在值的右边生成一个icon
         *                 'link': 值的样式带连接
         * @param {String/Function(rowData)} valueClassName 给显示的值添加class名称, 当为函数时必须return String
         * @param {String/Function(rowData)} tagType 当type=='tag'时,给显示的tag设置类型(具体类型查看el-tag组件的type属性), 当为函数时必须return String
         * @param {String/Funciton(cellValue, rowData)/Array['formatterName',...params]} formatter 对值进行formatter处理, 当为函数时必须有返回值
         * @param {Function(rowData, e)} events.click value上的事件配置, 统一做了e.stopPropagation阻止事件冒泡处理
         */
        multipleConfig: {
            type: [Array, Function],
            default: () => {
                return [];
            }
        },
        /**
         * label的宽度值, 一个单元格用同一种宽度, 默认为90px
         */
        labelWidth: {
            type: String,
            default: '90px'
        }
    },
    computed: {
        _multipleConfig() {
            return typeof this.multipleConfig === 'function' ? this.multipleConfig(this.rowData) : this.multipleConfig;
        }
    },
    created() {},
    methods: {
        getVaule(item) {
            let cellValue = this.rowData[item.valueKey];
            let cellLabel = cellValue;
            // 空数据处理
            let empty = emptyText(cellLabel, item.emptyText);
            if (empty.isEmpty) {
                // 如果cellValue为空, 直接返回emptyText
                return (cellLabel = empty.text);
            }
            let formatter = item.formatter;
            if (formatter) {
                if (XEUtils.isString(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter] || XEUtils[formatter];
                    cellLabel = globalFunc ? globalFunc(cellValue) : cellValue;
                } else if (XEUtils.isArray(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter[0]] || XEUtils[formatter[0]];
                    cellLabel = globalFunc ? globalFunc(...[cellValue].concat(formatter.slice(1))) : cellValue;
                } else {
                    cellLabel = formatter(Object.assign({ cellValue }, this.rowData)) || cellValue;
                }
            }
            return cellLabel;
        },
        // link类型的className
        getLinkClassName(item) {
            let cellValue = this.rowData[item.valueKey];
            // 空数据处理
            let empty = emptyText(cellValue, item.emptyText);
            return empty.isEmpty && !item.emptyClick ? 'link-url is-empty' : 'link-url';
        },
        // process类型的className
        getProcessClassName(item) {
            let cellValue = this.rowData[item.valueKey];
            // 空数据处理
            let empty = emptyText(cellValue, item.emptyText);
            return empty.isEmpty && !item.emptyClick ? 'process-cell is-empty' : 'process-cell';
        },
        getVaulecClassName(item) {
            let className = '';
            if (typeof item.valueClassName == 'function') {
                className = item.valueClassName(this.rowData);
            } else {
                className = item.valueClassName;
            }
            return className;
        },
        getTagType(item) {
            let tagType = '';
            if (typeof item.tagType == 'function') {
                tagType = item.tagType(this.rowData);
            } else {
                tagType = item.tagType || 'info';
            }
            return tagType;
        },
        handleValueClick(e, item) {
            if (
                item.events &&
                item.events.click &&
                (item.emptyClick || !emptyText(this.rowData[item.valueKey]).isEmpty)
            ) {
                e.stopPropagation();
                item.events.click(this.rowData, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.multiple-cell {
    vertical-align: top;
    .multiple-cell-item {
        display: flex;
        font-size: 12px;
        line-height: 18px;
        .label {
            color: #808080;
            flex: none;
            white-space: nowrap;
        }
        .value {
            flex: 1;
            font-size: 12px;
            color: #000;
        }
    }
    .multiple-cell-item + .multiple-cell-item {
        margin-top: 6px;
    }
    .process-cell {
        display: block;
        &.is-empty i {
            display: none;
        }
    }
}
</style>
