<template>
    <f-dialog
        ref="exportDialog"
        :visible.sync="visibleExportDialog"
        :title="$t('i18n.common.exportText')"
        :class="['com-dialog', 'com-table-export-dialog']"
        width="590px"
        :append-to-body="true"
    >
        <div class="com-table-export-wrap">
            <f-form
                ref="form"
                label-width="90px"
                :column="1"
                :inline="false"
                labelPosition="right"
                :model="form"
                :rules="rules"
                :showInvalidPrompt="false"
            >
                <f-form-item :label="$t('i18n.common.components.export.fileName')" prop="fileName">
                    <f-input
                        v-model="form.fileName"
                        clearable
                        :maxlength="50"
                        :placeholder="$t('i18n.common.components.export.placeholderFileName')"
                        @keyup.native="e => (form.fileName = replaceValue(e))"
                    ></f-input>
                </f-form-item>
                <f-form-item :label="$t('i18n.common.components.export.fileType')" prop="fileType">
                    <com-select
                        v-model="form.fileType"
                        :data="mixDictionaries.dictionary['FileType']"
                        :placeholder="$t('i18n.common.components.export.placeholderFileType')"
                        :clearable="false"
                    ></com-select>
                </f-form-item>
                <f-form-item :label="$t('i18n.common.components.export.exportColumn')" prop="exportColumn">
                    <f-checkbox-group v-model="form.exportColumn" style="max-height: 150px;overflow: auto;">
                        <f-checkbox
                            v-for="item in tableColums.filter(
                                item => item.field && !(item.cellRender && item.cellRender.name === 'operationCell')
                            )"
                            :key="item.filed"
                            :label="item"
                        >
                            {{ item.title }}
                        </f-checkbox>
                    </f-checkbox-group>
                </f-form-item>
                <f-form-item :label="$t('i18n.common.components.export.exportData')" prop="exportType">
                    <el-radio-group v-model="form.exportType" v-for="item in mixDictionaries.dictionary['ExportType']">
                        <el-radio :label="item.value">{{item.text}}</el-radio>
                    </el-radio-group>
                </f-form-item>
                <f-form-item>
                    <f-button type="primary" size="mini" @click="exportFun">{{
                        $t('i18n.common.exportText')
                    }}</f-button>
                </f-form-item>
            </f-form>
        </div>
        <!-- <iframe name="exportIframe" style="display:none">
            <form ref="exportForm" :action="exportUrl" method="post" target="exportIframe">
                <input ref="exportParam" name="exportParam" type="hidden" >
            </form>
        </iframe> -->
    </f-dialog>
</template>

<script>
import { dictionaryMix, constantMix } from '@/common/comMixin';
import { rulesValid } from '@/common/comMixin/comUtils.js';
export default {
    name: 'ComTableExport',
    mixins: [dictionaryMix, constantMix],
    data() {
        return {
            visibleExportDialog: false,
            form: {
                fileName: '',
                fileType: '',
                exportColumn: [],
                exportType: '',
                tableUrl: this.tableUrl
            },
            rules: {
                fileName: [
                    {
                        required: true,
                        message: this.$t('i18n.common.components.export.placeholderFileName'),
                        ...rulesValid
                    }
                ],
                fileType: [
                    {
                        required: true,
                        message: this.$t('i18n.common.components.export.placeholderFileType'),
                        ...rulesValid
                    }
                ],
                exportType: [
                    {
                        required: true,
                        message: this.$t('i18n.common.components.export.placeholderExportData'),
                        ...rulesValid
                    }
                ],
                exportColumn: [
                    {
                        required: true,
                        message: this.$t('i18n.common.components.export.placeholderexportColumn'),
                        ...rulesValid
                    }
                ]
            },
            /**
             * 文件类型/导出类型数据字典
             */
            dictionariesConfig: [
                {
                    dictType: 'dictionary',
                    dictKey: ['FileType', 'ExportType']
                }
            ],
            /**
             * 导出类型常量
             */
            constantsConfig: ['ExportType']
        };
    },
    props: {
        tableUrl: String,
        exportUrl: {
            type: String,
            required: true
        },
        /**
         * 数据字典查询地址
         */
        dictionariesSource: {
            type: String,
            required: true
        },
        /**
         * 常量查询地址
         */
        constantsSource: {
            type: String,
            required: true
        },
        tableColums: {
            type: Array,
            default: () => {
                return [];
            }
        },
        tablePage: Object,
        pagination: Boolean,
        keyNames: Object,
        // 导出时额外的参数
        extraData: Object
    },
    async created() {
        // 获取数据字典
        await this.mixGetDictionaries(this.dictionariesSource, this.dictionariesConfig);
        await this.mixGetConstants(this.constantsSource, this.constantsConfig);
        this.exportTypeReady = true;
        this.setExportType();
    },
    methods: {
        /**
         * 表单默认值
         */
        resetForm() {
            this.form.fileName = '';
            // 列默认全选中
            let exportColumn = [];
            this.tableColums.forEach(item => {
                // 剔除单选复选操作列
                if (item.field && !(item.cellRender && item.cellRender.name === 'operationCell')) {
                    exportColumn.push(item);
                }
            });
            this.form.exportColumn = exportColumn;
            //文件类型默认选中第一个
            let fileTypeFirst = this.mixDictionaries.dictionary['FileType'][0];
            this.form.fileType = fileTypeFirst && fileTypeFirst.value ? fileTypeFirst.value : '';

            // 导出数据默认选中第一个
            let exportTypeFirst = this.mixDictionaries.dictionary['ExportType'][0];
            this.form.exportType = exportTypeFirst && exportTypeFirst.value ? exportTypeFirst.value : '';
        },
        open() {
            // 每次打开时将表单重置到默认值
            this.resetForm();
            this.visibleExportDialog = true;
        },
        close() {
            this.visibleExportDialog = false;
        },
        // 设置导出类型
        setExportType() {
            if (this.tablePage.total > 10000) {
                this.mixDictionaries.dictionary['ExportType.obj'][
                    this.mixConstants['ExportType']['ALL']
                ].disabled = true;
            } else {
                this.mixDictionaries.dictionary['ExportType.obj'][
                    this.mixConstants['ExportType']['ALL']
                ].disabled = false;
            }
        },
        // exportFun() {
        //     this.$refs.form.validate(valid => {
        //         if(valid) {
        //             let param = Object.assign({}, this.extraData, this.form);
        //             if (!(this.pagination === false)) {
        //                 param = Object.assign(param, {
        //                     [this.keyNames.currentPage]: this.tablePage.currentPage,
        //                     [this.keyNames.pageSize]: this.tablePage.pageSize
        //                 });
        //             }
        //             this.$refs.exportParam.value = JSON.stringify(param);
        //             this.$refs.exportForm.submit();
        //             this.close();
        //         }
        //     })
        // },
        exportFun() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let param = Object.assign({}, this.extraData, this.form, {
                        pagination: this.pagination
                    });
                    if (!(this.pagination === false)) {
                        param = Object.assign(param, {
                            [this.keyNames.currentPage]: this.tablePage.currentPage,
                            [this.keyNames.pageSize]: this.tablePage.pageSize,
                            [this.keyNames.total]: this.tablePage.total
                        });
                    }
                    let flag = true;
                    if (this.$attrs.beforeExport && typeof this.$attrs.beforeExport == 'function') {
                        flag = this.$attrs.beforeExport();
                    }
                    if (!flag) return;
                    console.log(param);
                    if (param.exportType == '02') {
                        const { total } = param;
                        if (total > 1000000) {
                            // return this.$message.warning('');
                            return this.$message({
                                message: '数据量超过100万，禁止导出',
                                type: 'error'
                            });
                        } else if (total > 10000) {
                            this.$confirm(
                                `数据总计${total}条,超过1万条,数据量过大,是否继续导出？`,
                                this.$t('i18n.common.prompt'),
                                {
                                    confirmButtonText: this.$t('i18n.common.confirmText'),
                                    cancelButtonText: this.$t('i18n.common.cancelText'),
                                    type: 'warning',
                                    callback: action => {
                                        if (action === 'confirm') {
                                            this.$http.post(this.exportUrl, JSON.stringify(param)).then(res => {
                                                if (res.code != 0) {
                                                    // localStorage.setItem('async-export-add', param.fileName);
                                                    this.$message({
                                                        message: `${res.msg}`,
                                                        type: 'error'
                                                    });
                                                } else if (res.code == 0) {
                                                    if (res.data.exportType && res.data.exportType == 2) {
                                                        localStorage.setItem('async-export-add', param.fileName);
                                                        this.$message({
                                                            message: `${param.fileName}${this.$t('i18n.common.components.form.exporting')}`,
                                                            type: 'success'
                                                        });
                                                    }
                                                }
                                                this.close();
                                            });
                                        } else {
                                            this.close();
                                        }
                                    }
                                }
                            );
                        } else {
                            this.$http.export(this.exportUrl, JSON.stringify(param)).then(res => {
                                if (res.data.exportType && res.data.exportType == 2) {
                                    this.$bus.$emit('async-export-add', param.fileName);
                                    this.$message({
                                        message: `${param.fileName}${this.$t('i18n.common.components.form.exporting')}`,
                                        type: 'success'
                                    });
                                }
                                this.close();
                            });
                        }
                    } else {
                        this.$http.export(this.exportUrl, JSON.stringify(param)).then(() => {
                            this.close();
                        });
                    }
                }
            });
        },
        replaceValue(e) {
            return e.target.value.replace(/[\\/:*?"<>|]/g, '');
        }
    },
    watch: {
        tablePage: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (this.exportTypeReady && newValue.total !== oldValue.total) {
                    this.setExportType();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.com-table-export-wrap {
    width: 100%;
    border: 1px solid #d6dfeb;
    .com-table-upload__template {
        text-align: right;
        margin: 10px 36px;
    }
    .com-table-upload__tip {
        color: #c0c4cc;
        font-size: 12px;
        text-align: left;
    }
    .com-table-upload__btn {
        margin-left: 8px;
    }
    .el-form.f-form {
        margin: 50px 80px;
    }
}
</style>
