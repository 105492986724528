import Vue from 'vue';

import { initThemeFile, initNavColor } from '@pages/index/utils/themeChangeFile';
// 引入element-ui相关资源
import 'normalize.css/normalize.css';
import '@/style/global.scss';
import '@/style/element-variables.scss';
/*import ElementUI from '@iss/vmui-vue';
Vue.use(ElementUI);*/

import i18n from './i18n'
import VMUI from '@iss/vmui-vue';
import '@iss/vmui-vue/lib/theme-chalk/index.css';
Vue.use(VMUI,{
    i18n: (key, value) => i18n.t(key, value)
});

// import '@iss/frontend-ui/dist/theme-chalk/index.css';

// 引入frontend-ui
import FrontendUI from '@iss/frontend-ui'; // 正式版启用


Vue.use(FrontendUI, {
    showInvalidPrompt: false,
    showFieldsToolTip: true, // 表单组件是否启用 长度过长时使用tooltip
    dialogCloseWarning: false, // 放大镜关闭时是否给出提示
    i18n: (key, value) => i18n.t(key, value)
});


import Sortable from 'sortablejs';
Vue.prototype.Sortable = Sortable;

import 'xe-utils';
import 'vxe-table/lib/style.css';
import VXETable from 'vxe-table';
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx';
VXETable.use(VXETablePluginExportXLSX);
import './plugins/table-formaters';

VXETable.setup({
    zIndex: 3000, // 这里设置表格tooltip的层级比el-Drawer初始层级高, 解决抽屉中的tooltip被遮挡的问题
    tooltipConfig: {
        enterable: true
    },
    i18n: (key, args) => i18n.t(key, args)
});
Vue.use(VXETable);

// 引入项目公共组件
import ComComponents from '@/common/components';
Vue.use(ComComponents);

// 引入业务公共组件
import BusinessComponents from '@/common/components/businessComponents';
Vue.use(BusinessComponents);

import Print from 'vue-print-nb';
Vue.use(Print); //注册

import { quillEditor } from 'vue-quill-editor'; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.component('QuillEditor', quillEditor);

// 引入项目公共指令
import directives from '@/common/directives';
Object.keys(directives).forEach(key => Vue.directive(key, directives[key]));

//引入项目公共的filter
import filters from '@/common/filters';
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

window.$bus = Vue.prototype.$bus = new Vue();

// 引入http请求,挂载到Vue上
import http from '@/common/http';
Vue.prototype.$http = http;

// 引入api请求地址,挂载到$api上
import url from '@/common/http/urls';
Vue.prototype.$api = url;

import { math } from './unit.js';

Vue.prototype.math = math;

Vue.config.productionTip = false;

/**
 * 初始化主题色
 */

initThemeFile();
/**
 * 初始化导航色
 */
initNavColor();

// 全局注册流程图  待考虑中

// 拦截路由重复报错问题 https://www.cnblogs.com/xiaofenguo/p/11570835.html
// import Router from 'vue-router';
// const originalPush = Router.prototype.push;
// const originalReplace = Router.prototype.replace;
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err);
// };
// Router.prototype.replace = function replace(location) {
//     return originalReplace.call(this, location).catch(err => err);
// };
