<template>
    <div style="display: contents">
        <iframe v-if="this.fileForm.attachmentOuterUrl" width="100%" frameborder="0" :src="fileForm.attachmentOuterUrl"></iframe>

        <div v-else :class="['upload-template', status && 'upload-template--view']">
            <!-- 上传部分 -->
            <div class="upload-template__upload" v-if="!status">
                <com-upload
                    ref="upload"
                    v-bind="_uploadConfig"
                    v-model="upload"
                    :action="urlCf.upload"
                    :data="_uploadExtraData"
                >
                    <!-- 此处暂时这样设计 -->
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        {{ $t('i18n.common.components.uploadTemplate.uploadDragText') }}
                        <em>{{ $t('i18n.common.components.uploadTemplate.uploadClickText') }}</em>
                    </div>
                    <div class="el-upload__tip" slot="tip" v-if="uploadTip">{{ uploadTip }}</div>
                </com-upload>
            </div>
            <!-- 表格部分 -->
            <div class="upload-template__table">
                <f-form style="width: 100%;">
                    <com-edit-table
                        ref="table"
                        :height="220"
                        v-bind="tableConfig"
                        :dataSource="tableData"
                        :pagination="false"
                        :custom="false"
                        :scrollY="{enabled:true}"
                        :extraData="queryExtraData"
                        :editConfig="{trigger: 'click', mode: 'cell',activeMethod:()=>!status}"
                        :buttons="buttons"
                        @buttonClick="tableToolbarClick"
                        :tableColumnsDefault="tableColumnsDefault"
                        :tableFixed="false"
                    ></com-edit-table>
                </f-form>
            </div>
            <!-- 弹窗部分 -->
            <f-dialog
                ref="dialog"
                :visible.sync="visible"
                :title="dialog.title"
                class="f-magnifier-dialog"
                width="842px"
                :append-to-body="true"
            >
                <div v-if="dialog.src" style="text-align: center;height: 600px">
                    <com-pdf :src="dialog.src" v-if="visible" width="100%" height="600px"
                             :fileType="dialog.fileType" :options="{id:dialog.id}"></com-pdf>
                </div>
            </f-dialog>
        </div>
    </div>
</template>
<script>
let dialogCf = {
        titleKey: 'filename', // 标题键名
        srcKey: 'fileurl' // src 简明
    },
    downloadCf = self => ({
        visible: () => true,
        disabled: () => false
    }),
    previewCf = self => ({
        visible: () => true,
        disabled: row => {
            let view = /jpeg|jpg|jpe|bmp|png|gif|ico|apng|webp|svg|tif|pcx|tga|exif|fpx|psd|cdr|pcd|pdf|txt|doc|docx|xlsx|xls|ppt|pptx/;
            if(view.test(row.fileExtName.toLowerCase()) ){
                return false;
            }else{
                return true;
            }
        }
    }),
    deleteCf = self => ({
        visible: row => {
            if (!self.status) {
                if (row['deleteFlag']) {
                    return row['deleteFlag'] === 1;
                } else {
                    return true;
                }
            }
            return false;
        },
        disabled: () => false
    });

const queue = arr => {
    return arr.reduce((t, v) => {
        return t.then(() => {
            return v;
        });
    }, Promise.resolve());
};

import { comMix } from '@/common/comMixin';
/**
 * 1. 上传附件
 * 2. 删除附件
 * 3. 下载附件
 * 4. 查看附件
 * 5. 批量下载
 * 6. 批量删除
 */
export default {
    name: 'UploadTemplate',
    mixins: [comMix],
    model: {
        prop: 'value',
        event: 'change'
    },
    inject: {
        ComEditForm: {
            default: ''
        }
    },
    data() {
        let { comUpload } = this.$api.common;
        return {
            visible: false,
            dialog: { title: '', src: '' },
            filelist: [],
            tableColumnsDefault: [],
            buttons: [],
            comUrl: {
                upload: comUpload.upload,
                delete: comUpload.delete,
                download: comUpload.download,
                query: comUpload.query,
                bulkDownload: comUpload.bulkDownload,
                initFile:comUpload.initFile,
                updateFile:comUpload.updateFile
            },
            tableData: [],
            privateUpload: '',
            fileForm:{
                attachmentFlag:1,//1内部，2外部
                attachmentOuterUrl:'',// 2 外部地址
                isSelfSet:0, //是否自定义类型
                defaultIsRequire:0, // 默认是否必填
                isLimit:0,//是否限制大小
                attachmentSize:0.0, // 附件大小
                attachmentTypeList:[],//附件类型
            },
            defaultType:'upload-none-defalut',
            fileTypeList:[{text:this.$t('i18n.common.components.uploadTemplate.defaultType'),value:'upload-none-defalut'}],
            fileTypeListObj:{'upload-none-defalut':{text:this.$t('i18n.common.components.uploadTemplate.defaultType'),value:'upload-none-defalut'}},
            timer:null
        };
    },
    props: {
        formType: String, // 当前类型， add, view
        value: [Object, Boolean, String, Number],
        uploadConfig: Object,
        dialogConfig: Object,
        tableConfig: Object,
        previewConfig: Object,
        downloadConfig: Object,
        deleteConfig: Object,
        uploadTip: String,
        //地址
        urlConfig: Object,
        // 传入数据
        queryExtraData: Object,
        uploadExtraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },
    created() {
        this.buttons = this.getTableButtonsDefaults();
        this.tableColumnsDefault = this.getTableColumnsDefault();
    },
    mounted() {
        this.initFileConfig();
    },
    computed: {
        upload: {
            get() {
                return typeof this.value === 'boolean' ? this.privateUpload : this.value;
            },
            set(val) {
                this.value ? this.$emit('change', val) : (this.privateUpload = val);
            }
        },
        _uploadExtraData(){
            return {fileBusinessType:this.defaultType, ...this.uploadExtraData}
        },
        dialogCf() {
            return { ...dialogCf, ...this.dialogConfig };
        },
        downloadCf() {
            return { ...downloadCf(this), ...this.downloadConfig };
        },
        previewCf() {
            return { ...previewCf(this), ...this.previewConfig };
        },
        deleteCf() {
            return { ...deleteCf(this), ...this.deleteConfig };
        },

        // 地址配置项目
        urlCf() {
            return { ...this.comUrl, ...this.urlConfig };
        },

        // 当前状态
        status() {
            let status = '';
            // 如果view 不存在 则采用父级的页面状态
            if (this.ComEditForm) {
                let { formType } = this.ComEditForm;
                status = this.formType || formType;
            } else {
                status = this.formType;
            }
            return status === 'view' || status === 'approval';
        },
        _uploadConfig() {
            let config =  { ...this.comUploadConfig(this.uploadConfig)};
            return config;
        },

    },
    methods: {
        initFileConfig(){
            this.$http.post(this.comUrl.initFile, this.queryExtraData).then(res => {
                if(res && res.data){
                    this.fileForm.attachmentTypeList = [];
                    Object.assign(this.fileForm,res.data);
                    this.fileTypeList = [...this.$options.data.call(this).fileTypeList];
                    if(this.fileForm.attachmentTypeList.length > 0){
                        let list = this.fileForm.attachmentTypeList.map(item =>{
                            return {label:item.attachmentTypeName,value:item.attachmentTypeName};
                        })
                        this.fileTypeList.push(...list);
                    }
                    if(this.fileForm.defaultIsRequire == 1){
                        this.fileForm.attachmentTypeList.push({attachmentTypeName:'upload-none-defalut',isRequire:1});
                    }
                    if(this.fileForm.attachmentOuterUrl){
                        this.fileForm.attachmentOuterUrl = this.fileForm.attachmentOuterUrl;
                        let separator = "&";
                        if(this.fileForm.attachmentOuterUrl.indexOf("?") == -1){
                            separator = "?";
                        }
                        let getParrm = this.stringify(this.queryExtraData)
                        if(getParrm && getParrm != ''){
                            this.fileForm.attachmentOuterUrl = this.fileForm.attachmentOuterUrl + separator+ getParrm;
                        }else{
                            this.fileForm.attachmentOuterUrl = '';
                        }
                    }
                }else{
                    this.fileTypeList = [...this.$options.data.call(this).fileTypeList];
                }
            });
        },
        stringify(jsonMap){
            let retStr ="";
            Object.keys(jsonMap).forEach((key) =>{
                if(jsonMap[key]){
                    retStr += `${key}=${jsonMap[key]}&`;
                }
            });
            if(retStr)
                retStr = retStr.substr(0,retStr.lastIndexOf('&'));
            return retStr;
        },
        valid(){//校验必填
           let validFlag = true;
           let { tableData } =  this.$refs.table.getTable().getTableData();
            if(this.fileForm.attachmentOuterUrl){
                return validFlag;
            }
            if(this.fileForm.defaultIsRequire == 1 && tableData.length == 0){
                validFlag = false;
            }else{

                let table = tableData.map(table =>{
                    return table.fileBusinessType;
                })
                if(this.fileForm.attachmentTypeList.length >= 0){

                    let valid = this.fileForm.attachmentTypeList.filter(item =>{
                        return item.isRequire == 1;
                    }).map(item => {
                        return item.attachmentTypeName;
                    })
                    if(valid && valid.length > 0){
                        table = [...new Set(table)];
                        valid = [...new Set(valid)];
                        let hasRequire = valid.filter(item => table.includes(item));
                        validFlag = hasRequire.length == valid.length;
                    }
                }
            }
            if(!validFlag){
                this.$error(this.$t('i18n.common.components.uploadTemplate.uploadRequire',{'type':''}));
            }
            return validFlag;

        },
        getUpload() {
            return this.$refs.upload;
        },
        getDialog() {
            return this.$refs.dialog;
        },
        getTable() {
            return this.$refs.table;
        },
        getVisible() {
            return this.visible;
        },
        setVisible(val) {
            this.visible = val;
        },
        // 获取表格列初始化
        getTableColumnsDefault() {
            let { mixTableColConfig: mtg } = this;

            return [
                {
                    ...mtg.checkbox
                },
                { ...mtg.seq, width: '50' },
                {
                    field: 'fileBusinessType',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.fileType'),
                    formatter: ['matchDictionary',this.fileTypeListObj],
                    slots:{
                        edit:({row,column})=>{
                            return [<com-select  v-model={row[column.property]} data={this.fileTypeList} v-on:change={(val) =>{
                            row.fileBusinessType = '';
                            val = val || this.defaultType
                            this.$http.post(this.urlCf.updateFile, { id: row.id,fileBusinessType: val}).then(() => {
                                row.fileBusinessType = val;
                                this.$refs.table.getTable().clearActived();
                            });
                        }}></com-select>]
                        }
                    },
                    editRender:{
                        enabled:true,
                        immediate:true,
                    },
                    ...mtg.default
                },
                {
                    field: 'originalFileName',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.originalFileName'),
                    align: 'left',
                    sortable: true,
                    ...mtg.default,
                    minWidth:'220px',
                },
                /*{
                    field: 'fileBytesSize',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.fileBytesSize'),
                    align: 'right',
                    sortable: true,
                    ...mtg.default
                },*/
                {
                    field: 'fileMBSize',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.fileMBSize'),
                    align: 'right',
                    ...mtg.default,
                    width: '100px',
                },
                {
                    field: 'fileExtName',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.fileExtName'),
                    align: 'left',
                    ...mtg.default,
                    width: '77px',
                },
                {
                    field: 'createTime',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.createTime'),
                    align: 'center',
                    ...mtg.default,
                    width: '150px',
                },
                {
                    field: 'createByName',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.createByName'),
                    align: 'center',
                    ...mtg.default,
                    width: '150px',
                },

                {
                    field: 'id',
                    title: this.$t('i18n.common.components.uploadTemplate.columns.id'),
                    align: 'center',
                    cellRender: {
                        name: 'operationCell',
                        btns: [
                            {
                                name: this.$t('i18n.common.components.uploadTemplate.btns.download'),
                                events: { click: this.download },
                                props: { ...this.downloadCf },
                                className: 'downloadCf'
                            },
                            {
                                name: this.$t('i18n.common.components.uploadTemplate.btns.preview'),
                                events: { click: this.preview },
                                props: { ...this.previewCf }
                            },
                            {
                                name: this.$t('i18n.common.components.uploadTemplate.btns.delete'),
                                events: { click: this.delete },
                                className: 'danger',
                                props: { ...this.deleteCf }
                            }
                        ]
                    },
                    ...mtg.operation,
                    width: '140px'
                }
            ];
        },
        // 获取表格工具按钮
        getTableButtonsDefaults() {
            let buttons = [
                {
                    code: 'download',
                    name: this.$t('i18n.common.components.uploadTemplate.btns.downloadAll'),
                    type: 'primary',
                    icon: 'proicon icon-download'
                }
            ];
            if (!this.status) {
                buttons.push({
                    code: 'delete',
                    name: this.$t('i18n.common.components.uploadTemplate.btns.deleteAll'),
                    icon: 'proicon icon-delete'
                });
            }
            return buttons;
        },
        // 获取表格工具按钮点击操作
        tableToolbarClick(btn) {
            switch (btn.code) {
                case 'download':
                    this.downloadsHandle(btn);
                    break;
                case 'delete':
                    this.deletesHandle(btn);
                    break;
            }
        },
        // 批量下载动作 触发的方法
        downloadsHandle() {
            let rows = this.$refs.table.getTable().getCheckboxRecords();
            if (rows.length < 1) {
                this.$error(this.$t('i18n.common.components.uploadTemplate.toast.empty'));
                return false;
            }
            let idList = rows.map(item => item.id);
            /* [fix] 各个版本浏览器同时下载数量不同， 只能使用后端批量下载 */
            this.$http.export(this.comUrl.bulkDownload, { idList });
            // queue(rows.map(row => this.download(row)));
        },
        // 批量删除动作， 触发方法
        deletesHandle() {
            let rows = this.$refs.table.getTable().getCheckboxRecords();
            if (rows.length < 1) {
                this.$error(this.$t('i18n.common.components.uploadTemplate.toast.empty'));
                return false;
            }
            let dels = rows.filter(row => this.deleteCf.visible(row));
            if (dels.length < 1) {
                this.$error(this.$t('i18n.common.components.uploadTemplate.toast.emptyDelete'));
                return false;
            }
            this.$toast
                .confirm(this.$t('i18n.common.components.uploadTemplate.toast.batchDelete'), {
                    confirmButtonText: this.$t('i18n.common.confirmText'),
                    cancelButtonText: this.$t('i18n.common.cancelText'),
                    center: true
                })
                .then(() => {
                    Promise.all(dels.map(row => this.$http.post(this.urlCf.delete, { id: row.id }))).then(() => {
                        this.reload();
                    });
                });
        },
        // 行 -> 下载按钮
        download(row) {
            /** 内部实现 */
            let { id } = row;
            this.$http.export(this.urlCf.download, { id });
        },
        // 行 -> 浏览按钮
        preview(row) {
            if (!row) return false;
            let { dialogCf: dCf } = this;
            this.visible = true;
            this.dialog.title = row.originalFileName;
            this.dialog.src = this.previewUrl(this.urlCf.download,row);
            this.dialog.id = row.id;
            this.dialog.fileType = row.fileExtName.toLowerCase();
        },

        // 行 -> 删除按钮
        delete(row) {
            let { id, originalFileName } = row;
            this.$toast
                .confirm(`${this.$t('i18n.common.components.uploadTemplate.toast.content')}${originalFileName}`, {
                    confirmButtonText: this.$t('i18n.common.confirmText'),
                    cancelButtonText: this.$t('i18n.common.cancelText'),
                    center: true
                })
                .then(() => {
                    this.$http
                        .post(this.urlCf.delete, { id })
                        .then(() => {
                            this.reload();
                            this.$success(this.$t('i18n.common.success'));
                        })
                        .catch(err => {
                            this.$errorToast(
                                this.$t('i18n.common.components.uploadTemplate.toast.errorTitle'),
                                err.msg || this.$t('i18n.common.components.uploadTemplate.toast.errorContent')
                            );
                        });
                });
        },

        // hleper
        isImg(url) {
            let reg = /(.*)\.(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)$/;
            return reg.test(url);
        },
        previewUrl(url,row) {
            // <!-- doc， docx， xls， xlsx, ppt 需使用https://view.officeapps.live.com/op/view.aspx?src= -->
            let reg = /doc|docx|xlsx|xls|ppt|pptx/;
            if (reg.test(row.fileExtName)){
                return `/api/fsmc/components/upload/file-online-preview/${row.id}`;
            }
            return url;
        },
        /**上传成功回调 */
        uploadSuccess(res, file, fileList) {
            this.reload();
        },
        /**上传失败回调 */
        uploadError(err, file, fileList) {
            console.log('err,file,fileList :>> ', err, file, fileList);
        },
        /**上传中回到 */
        uploadProgress(event, file, fileList) {
            console.log('event,file,fileList :>> ', event, file, fileList);
        },
        /**
         * 获取表格数据
         */
        async getTableData() {
            // 当dataSource配置为字符串时,发送请求
            let param = Object.assign({}, this.queryExtraData);
            await this.$http.post(this.urlCf.query, param).then(res => {
                this.tableData = res.data;
            });
            let doms = document.getElementsByClassName('operation-btn-downloadCf');
            Object.keys(doms).forEach(i => {
                doms[i].removeAttribute('disabled');
                doms[i].classList.remove('is-disabled');
            });
        },
        /** 刷新表格 */
        reload() {
            this.getTableData();
        },
        comUploadConfig(config) {
            let upConfig = {
                drag: true,
                multiple: true,
                onSuccess: this.uploadSuccess,
                ...config
            };
            if(this.fileForm.isLimit === 1) {
                upConfig.max = this.fileForm.attachmentSize;
            }
            ;
            return {...upConfig,beforeUpload:this.beforeUpload} ;
        },
        beforeUpload(file){
            if(this.uploadConfig && typeof this.uploadConfig.beforeUpload === 'function' ){
                let fg = this.uploadConfig.beforeUpload(file);
                fg === undefined ? true : fg;
                return fg;
            }else{
                return true;
            }
        },
        debounce(fun, wait = 100) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                fun.apply(this);
            }, wait);
        },
    },
    watch: {
        visible(val) {
            this.$emit('change-visible', val);
        },
        queryExtraData:{
            handler: function(v) {
                this.debounce(this.initFileConfig,500)
            },
            deep:true
        }
    }
};
</script>
<style scoped lang="scss">
.upload-template {
    position: relative;
    width: 100%;
    &__upload {
        position: absolute;
        top: 20px;
        left: 0;
    }
    &__table {
        width: 100%;
        padding-left: 360px;
        box-sizing: border-box;
    }
    &--view {
        .upload-template__table {
            padding-left: 0;
        }
    }
    /deep/ .el-upload-list {
        display: none;
        position: absolute;
        top: 0;
        right: -70%;
    }
    .el-upload__tip {
        margin-top: 0;
        text-indent: 20px;
        text-align: left;
        color: #999;
    }

    /deep/ .vxe-table--body-wrapper {
        overflow-y: scroll;
    }
}
</style>
