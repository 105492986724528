import certJ from './cf.min.chrome';

// eslint-disable-next-line no-unused-vars
let certsAll = [],
    certDataList = [],
    certSelectedCertSN = [],
    myCert = null;

export default {
    //设置所有的证书对象信息
    setCertsAll(certsAllParam){
        certsAll = certsAllParam;
    },
    //获取所有的证书对象信息
    getCertsAll(){
        return certsAll;
    },
    //设置页面展示的证书数据信息
    setCertDataList(certDataListParam){
        certDataList = certDataListParam;
    },
    //获取页面展示的证书数据信息
    getCertDataList(){
        return certDataList;
    },
    //设置证书请求后端唯一值
    setCertSelectedCertSN(certSelectedCertSNParam){
        certSelectedCertSN = certSelectedCertSNParam;
    },
    getCertSelectedCertSN(){
        return certSelectedCertSN;
    },
    //设置请求证书对象信息
    setMyCert(mycertParam){
        myCert = mycertParam;
    },
    getMyCert(){
        return myCert;
    },
    //刷新证书列表
    refreshCertList(license, filter) {
        this.setCertDataList([]);
        return new Promise( (resolve, reject) => {
            this.initCertList(license, filter).then(
                res => resolve(res)
            ).catch(
                res => reject()
            );
        });
    },
    //传入CertSet, 将certs中的证书显示在CertList中
    initCertList(license, filter) {
        return new Promise( (resolve, reject) => {
            certJ.selectCertificate(license, filter).then(
                response => {
                    this.setCertsAll(response);
                    this.addOption(response);
                    if (this.getCertDataList().length == 0) {
                        throw '没有找到符合License的证书';
                    }
                    resolve(this.getCertDataList());
                }
            ).catch(
                e =>{
                    reject(e);
                }
            );
        });
    },
    addOption(oValue) {
        let this_certDataList = [];
        var data = this.replaceEnter(oValue);
        const data_arr = data.split('@');
        let dataValue = {};
        dataValue.text = data_arr[1] + '-' + data_arr[data_arr.length - 1];
        dataValue.value = 'CN=' + oValue;
        this_certDataList.push(dataValue);
        this.setCertDataList(this_certDataList);
        this.setCertSelectedCertSN(this_certDataList);
    },
    //加签
    sign(signedData) {
        return certJ.sign(signedData);
    },
    refreshCertListOnClick(license, filter) {
        return new Promise( (resolve, reject) => {
            this.refreshCertList(license, filter).then(
                res => {
                    resolve(res)
                }
            ).catch(
                e => {
                    reject(e)
                }
            )
        });
    },
    certChange(certData) {
        try {
            let cert = this.getCertsAll();
            this.setMyCert(cert[0]);
            this.setCertSelectedCertSN(certData);
        } catch (e) {
            throw e;
        }
    },
    //获取加密签
    getSingData(data) {
        return this.sign(data);
    },
    replaceEnter(str) {
        return str.replace(/[ ]/g, ''); //去除字符算中的空格
    }
};
