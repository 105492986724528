/**
 * 模块请求地址统一管理
 * 注意: 导出对象中的key对应的value值只能是字符串, 不能再嵌套对象
 */
import certset from "./certset";
import business from "./business";
import sysareaset from "./sysareaset";
import counterparty from "./counterparty";
import system from "./system";
import orginfo from "./orginfo";
import workbench from "./workbench";
import contactorg from "./contactorg";
import functiontree from "./functiontree";
import orgstruct from "./orgstruct";
import orgstructmodule from "./orgstructmodule";
import expandfield from "./expandfield";
import remindset from "./remindset";
import interestrate from "./interestrate";
import basicconfig from "./basicconfig";
import basedata from "./basedata";
import sensitive from "./sensitive";
import dataauth from "./dataauth";
import processFlow from "./process";

export default {
    // 请求地址示例
    // list: 'api/list.json'

    common:{
        exportUrl:'/api/fsmc/common/export/do-export',//导出
        dictionaries: '/api/fsmc/common/query/dictionary', // 公共数据字典数据
        constants: '/api/fsmc/common/query/constant/class/value', // 公共的常量判断固定值
        getToken: '/api/fsmc/common/token/getToken', // 公共获取token
        formItemOrder: '/api/fsmc/common/query-condition/query/find-query-index', // 公共的查询表单--查询表单项顺序
        formItemOrderSave: '/api/fsmc/common/query-condition/insert/insert-query-index', // 公共的查询表单--表单项顺序设置保存
        queryFormList: '/api/fsmc/common/query-condition/query/find-query-info-list', // 公共的查询表单--查询已保存查询条件列表
        queryFormSave: '/api/fsmc/common/query-condition/insert/insert-query-info', // 公共的查询表单--查询条件保存
        queryFormDel: '/api/fsmc/common/query-condition/delete/delete-query-info', // 公共的查询表单--查询条件删除
        tableColumnsSave: '/api/fsmc/common/query-condition/insert/insert-query-index', // 公共的查询列表--列表列配置保存
        tableColumns: '/api/fsmc/common/query-condition/query/find-query-index', // 公共的查询列表--列表列配置查询
        collapseItemOrder: '/api/fsmc/common/query-condition/query/find-query-index', // 公共的编辑页面--折叠面板顺序查询
        collapseItemOrderSave: '/api/fsmc/common/query-condition/insert/insert-query-index', // 公共的编辑页面--折叠面板顺序保存
        tableExpandParamUrl:'/api/fsmc/common/expand-elements/table-column-config',//获取列表展示的列
        queryExpandElementsByTemplateCodeUrl:'/api/fsmc/common/expand-elements/query-expand-elements-by-template-code',//查询扩展字段模板信息
        queryExpandElementsDataUrl:'/api/fsmc/common/expand-elements/query-expand-elements-data',//查询扩展字段模板及值信息
    },
    business:business,
    businesstypeprivilege:business,
    sysareaset:sysareaset,
    counterparty:counterparty,
    system:system,
    orginfo:orginfo,
    workbench:workbench,
    contactorg:contactorg,
    functiontree:functiontree,
    certSet:certset,
    orgstruct:orgstruct,
    orgstructmodule:orgstructmodule,
    expandfield:expandfield,
    remindset:remindset,
    interestrate:interestrate,
    basicconfig:basicconfig,
    basedata:basedata,
    sensitive:sensitive,
    dataauth:dataauth,
    processFlow: processFlow

};
