<script>
/**
 * 重新封装vxe-table
 * 1.集成了分页, 分页与不分页
 * 2.集成了tableToorbar, vxe中的button样式太少, buttons修改成了f-button,因此btns的配置与f-button中的使用方式一样
 * 3.提供dataSource数据源配置,
 *      可以是请求的url,
 *      也可以直接是表格数据, 当是表格数据是要区分有分页和无分页的情况, 无分页时为Array类型, 有分页时为Object类型
 * 4.提供extraData配置表格查询参数
 * 5.提供表格reload刷新方法
 * 6.提供多选框的初始配置,同时继承了vxe-table的checkboxConfig属性
 * 7.提供序号的初始配置,主要针对有分页的情况做了处理, 未继承vxe-table的seqConfig属性
 * 8.前后端交互的字段名可以在keyNames中进行配置
 */

import tableMix from '@/common/comMixin/tableMix.js';
import TableTemplateMixin from './table-template-mixin';
export default {
    name: 'ComQueryTable',
    mixins: [TableTemplateMixin, tableMix],
    data() {
        return {
            tableType: 'query',
            showOverflow:false
        };
    }
};
</script>
