/**
 * 整个项目中的请求地址管理
 * import 模块文件夹名称 from '@httpUrl:模块文件夹名称';
 * 后续如果只需要部分模块,对此文件稍做修改即可实现
 * 使用时通过以下方式挂载到Vue原型上
 * import url from '@/common/http/urls';
 * Vue.prototype.$api = url;
 * 组件中通过this.$api.demo.xxx可以拿到账户模块的xxx请求url
 */
import {common} from '@/common/http/common-url.js';

const files = require.context('@/pages', true, /\/http\/index\.js$/);
let pageIndex = {};
files.keys().forEach(item => {
    pageIndex[item.replace('/http/index.js','').replace('./','')]=files(item).default
})

export default Object.assign({},{common: common},pageIndex);

