<template>
    <!-- 单位放大镜--多选 -->
    <f-magnifier-multi
        ref="magnifier"
        :title="$t('i18n.common.components.orgMaginifierMulti.title')"
        :placeholder="placeholder || $t('i18n.common.components.orgMaginifierMulti.placeholder')"
        valueKey="orgCode"
        labelKey="orgName"
        filterKey="orgName"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        size="mini"
        :tableData="tableData"
        v-bind="$attrs"
        @open="handleOpen"
        @change="handlerChange"
        @query="handleQuery"
        @pageChange="handlePageChange"
        @clear="handleClear"
        @reset="handleReset"
        v-on="listeners"
        :autoSelect="autoSelect"
        type="primary"
    >
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.orgMaginifierMulti.orgCode')" prop="orgCode">
                <f-input v-model="searchForm.orgCode"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.orgMaginifierMulti.orgName')" prop="orgName">
                <f-input v-model="searchForm.orgName"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 单位编号 -->
            <f-magnifier-column
                prop="orgCode"
                :label="$t('i18n.common.components.orgMaginifierMulti.orgCode')"
            ></f-magnifier-column>
            <!-- 单位名称 -->
            <f-magnifier-column
                prop="orgName"
                :label="$t('i18n.common.components.orgMaginifierMulti.orgName')"
            ></f-magnifier-column>
            <!-- 单位类型 -->
            <f-magnifier-column prop="orgType" :label="$t('i18n.common.components.orgMaginifierMulti.orgType')">
                <template v-slot="$scope">
                    {{ mixMatchDictionary($scope.row.orgType, mixDictionaries.constant['OrgType.getList.obj']) }}
                </template>
            </f-magnifier-column>
            <!-- 单位性质 -->
            <f-magnifier-column prop="orgPropery" :label="$t('i18n.common.components.orgMaginifierMulti.orgPropery')">
                <template v-slot="$scope">
                    {{ mixMatchDictionary($scope.row.orgPropery, mixDictionaries.dictionary['orgProperty.obj']) }}
                </template>
            </f-magnifier-column>
            <!-- 是否结算中心 -->
            <f-magnifier-column prop="settCenter" :label="$t('i18n.common.components.orgMaginifierMulti.settCenter')">
                <template v-slot="$scope">
                    {{ mixMatchDictionary($scope.row.settCenter, mixDictionaries.constant['YesOrNo.getList.obj']) }}
                </template>
            </f-magnifier-column>
        </template>
    </f-magnifier-multi>
</template>

<script>
    import { dictionaryMix } from '@/common/comMixin';
    import magnifierMultiMix from '@/common/comMixin/magnifierMultiMix.js';
    /**
     * 单位放大镜-单选
     */
    export default {
        name: 'OrgMagnifierMulti',
        mixins: [magnifierMultiMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    orgCode: '', // 单位编号
                    orgName: '' // 单位名称
                },
                /**
                 * 单位类型/单位性质/是否结算中心
                 */
                dictionariesConfig: [
                    {
                        dictType: 'dictionary',
                        dictKey: ['orgProperty']
                    },
                    {
                        dictType: 'constant',
                        dictKey: ['OrgType.getList', 'YesOrNo.getList']
                    }
                ]
            };
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        orgCode: 'orgCode'
                    };
                }
            },
            /**
             * 数据字典URL地址
             **/
            dictionariesSource: {
                type: String
            }
        },
        async created() {
            // 获取数据字典
            await this.mixGetDictionaries(this.dictionariesSource, this.dictionariesConfig);
        }
    };
</script>

<style lang="scss" scoped></style>
