<template>
    <f-dialog
        :visible.sync="visibleDialog"
        title="隐藏字段设置"
        :class="['com-magnifier-dialog']"
        width="1000px"
        :append-to-body="true"
    >
        <div style="max-height: 400px;overflow:auto;">
             <f-tag
                v-show="showInfo"
                closable
                class="hidden-param-info"
                @close="showInfo = false"
                :disable-transitions="false">
                <div>
                    <p>1、本操作设置该页面字段是否显示/隐藏</p>
                    <p>2、当设置为隐藏时，可针对此属性或相关联的隐藏属性进行默认值设定，默认值填写为严格的JSON数据格式，如下：</p>
                    <p style="margin-left: 12px;">a、单个字段隐藏带有多个隐藏值设定默认值：</p>
                    <p style="margin-left: 24px;">例：单位放大镜（字段名称为：orgKey） 设定为隐藏，默认值:｛"orgkey":"2000010","orgName":"集团","orgCode":"20032"｝</p>
                    <p style="margin-left: 24px;">例：数组格式（字段名称为：acctUseCodes） 设定为隐藏，默认值:｛"acctUseCodes":["0001,0002"]｝</p>
                    <p style="margin-left: 12px;">b、单个字段仅当前字段默认值：</p>
                    <p style="margin-left: 24px;">例：金额(字段名称为:amount) 设定为隐藏，默认值为：{"amount":100}</p>
                    <p style="margin-left: 12px;">c、变量值：$now 默认当前日期 </p>
                    <p style="margin-left: 24px;">例：{"date":"$now"},实际显示：date:2021-09-01</p>
                </div>
            </f-tag>
            <div v-for="(vulue, key) in paramObj" :key="key">
                <f-form
                    v-for="(item, index) in vulue"
                    :model="item"
                    :key="item.prop ? item.prop : index"
                    :ref="'editForm' + key + index"
                    :size="mixSize"
                    :gutter="mixGutter"
                    :labelPosition="mixLabelPosition"
                    :showInvalidPrompt="false"
                >
                    <f-form-item
                        :label="(item.label ? item.label : '') + '(' + item.prop + ')'"
                        labelWidth="200px">
                        <f-form-item  style="display: inline-block">
                            <f-radio-group v-model="item.showOrHide">
                                <f-radio :label="true">显示</f-radio>
                                <f-radio :label="false">隐藏</f-radio>
                            </f-radio-group>
                        </f-form-item>
                        <f-form-item labelWidth="80px" label="默认值" :rules="defaultValRules" prop="defaultVal" style="display: inline-block;margin-left: 20px;">
                            <el-input v-model="item.defaultVal" placeholder="默认值" type="text" />
                        </f-form-item>
                        <f-form-item labelWidth="80px" label="校验规则" prop="required" style="display: inline-block;margin-left: 20px;">
                            <f-radio-group v-model="item.required">
                                <f-radio :label="1">默认</f-radio>
                                <f-radio :label="2">必填</f-radio>
                                <f-radio :label="3">非必填</f-radio>
                            </f-radio-group>
                        </f-form-item>
                    </f-form-item>
                </f-form>
            </div>
        </div>
        <template v-slot:footer>
            <f-button :size="mixSize" type="primary" @click="confirm">{{$t('i18n.common.confirmText')}}</f-button>
            <f-button :size="mixSize" @click="cancel">{{$t('i18n.common.cancelText')}}</f-button>
        </template>
    </f-dialog>
</template>

<script>
import { styleMix } from '@/common/comMixin';
    export default {
        name: 'HiddenParam',
        mixins: [styleMix],
        data() {
            return {
                visibleDialog: false,
                defaultValRules: [
                    { validator: this.defaultValValidate, trigger: 'blur' }
                ],
                specialCharactor: '\",\',;,:',
                showInfo: true
            }
        },
        props: {
            paramObj: {
                type: Object,
                defualt: () => {
                    return {}
                }
            },
            code: {
                type: String,
                default: ''
            }
        },
        methods: {
            open() {
                this.visibleDialog = true;
            },
            close() {
                this.visibleDialog = false;
            },
            defaultValValidate(rule, value, callback) {
                if (value) {
                    let isJSON = false;
                    try {
                        let obj = JSON.parse(value);
                        if (typeof obj == 'object' && obj) {
                            isJSON = true;
                        } else {
                            isJSON = false;
                        }
                    } catch (error) {}

                    isJSON ? callback() : callback(new Error('请输入正确的JSON格式'))
                } else {
                    callback();
                }
            },
            confirm() {
                let flags = [];
                for (let key in this.$refs) {
                    if(key.indexOf('editForm') > -1) {
                        this.$refs[key][0].validate((valid) => {
                           flags.push(valid)
                        })
                    }
                }
                if (flags.includes(false) || !this.paramObj || Object.keys(this.paramObj).length == 0 ) return;
                this.$http.post(this.$api.common.hiddenParamSave, JSON.stringify({
                        code: this.code,
                        hiddenParams: [this.paramObj]
                    })).then((res) => {
                    if(res.code == 0) {
                        this.$success('操作成功!');
                        this.$emit('success');
                        this.close();
                    } else {
                        this.$error('操作失败!');
                    }
                });
            },
            cancel() {
                this.close();
            }
        },
    }
</script>

<style lang="scss" scoped>
.hidden-param-info{
    position: relative;
    height: auto;
    width: 100%;
    /deep/ .el-icon-close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
