export default {
    queryRuleCode: '/api/fsmc/sys-area-set/get/rule-code', // 获取规则编码
    querySysAreaSetListUrl: '/api/fsmc/sys-area-set/query/sys-area-set/page', // 系统区块配置分页列表
    getSysAreaSetByIdUrl: '/api/fsmc/sys-area-set/query/sys-area-set/-by-/', // 根据id查询,
    deleteSysAreaSetUrl: '/api/fsmc/sys-area-set/delete/sys-area-set-delete', // 系统区块配置删除
    addSysAreaSetUrl: '/api/fsmc/sys-area-set/insert/sys-area-set-add', // 系统区块配置新增
    editSysAreaSetUrl: '/api/fsmc/sys-area-set/update/sys-area-set-update', // 系统区块配置修改
    startSysAreaSetUrl: '/api/fsmc/sys-area-set/update/sys-area-set-start', // 系统区块配置启用
    stopSysAreaSetUrl: '/api/fsmc/sys-area-set/update/sys-area-set-stop', // 系统区块配置停用
    //查询注册的模块信息
    queryModuleRegisterListUrl:'/api/fsmc/system/module-register/get-module-combo-list',//查询注册模块信息


    //系统字段映射配置
    getModuleNameComboListUrl:'/api/fsmc/system/module-register/query-module-name-combo-list',//查询模块名称下拉框信息
    queryBusinessTypelistUrl:'/api/fsmc/workbench/sys-field-map-setting/query-business-type-combo-list',//查询业务类型下拉框信息
    querySysFieldMapSettingPageListUrl:'/api/fsmc/workbench/sys-field-map-setting/query-sys-field-map-setting-by-page',//分页查询系统字段映射
    addSysFieldMapSettingUrl:'/api/fsmc/workbench/sys-field-map-setting/sys-field-map-setting-add',//新增系统字段映射
    validateSysFieldMapSettingUrl:'/api/fsmc/workbench/sys-field-map-setting/validate-sys-field-map-setting',//验证系统字段映射是否重复添加
    querySysFieldMapSettingListUrl:'/api/fsmc/workbench/sys-field-map-setting/query-sys-field-map-setting-list',//查询系统字段映射
    updateSysFieldMapSettingUrl:'/api/fsmc/workbench/sys-field-map-setting/sys-field-map-setting-update',//修改系统字段映射
    deleteSysFieldMapSettingUrl:'/api/fsmc/workbench/sys-field-map-setting/sys-field-map-setting-delete',//删除系统字段映射
    querySysNodeSettingUrl:'/api/fsmc/workbench/sys-node-setting/query-sys-node-setting',//查询系统节点配置信息
    querySysFieldESUrl:'/api/fsmc/workbench/sys-field-map-setting/query-sys-field-es/-by-/',//查询es数据获取字段信息

}
