export default {

    //数据权限设置列表页面查询
    queryDataAuthListByPageUrl:'/api/fsmc/data-auth-main/query-data-auth-main-by-page',

    //获取用户列表信息
    queryDataAuthUserInfoListUrl:'/api/fsmc/data-auth-main/combo/dataauth/query-user-list/-by-/',

    //获取用户分页列表信息
    queryDataAuthUserInfoPageListUrl:'/api/fsmc/data-auth-main/combo/dataauth/query-user-list-by-page',

    //获取数据权限模块数据信息
    queryDataAuthModuleInfoListUrl:'/api/fsmc/data-auth-main/query-data-auth-module-info',

    //获取组织维度数据url
    queryDataAuthOrgStructListUrl:'/api/fsmc/data-auth-main/query-all-org-struct-list/-by-/',

    //获取账户维度数据url
    queryDataAuthAccountListUrl:'/api/fsmc/data-auth-main/query-account-info-by-orgIds',

    //获取币种维度数据url
    queryDataAuthCurrencyListUrl:'/api/fsmc/data-auth-main/query-currency-info',

    //获取业务类型维度数据url
    queryDataAuthBusinessTypeListUrl:'/api/fsmc/data-auth-main/query-business-type-info/-by-/',

    //获取收付类型维度数据url
    queryDataAuthPaymentListUrl:'/api/fsmc/data-auth-main/query-payment-type-info/-by-/',

    //保存数据权限
    saveDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-add',

    //提交未保存数据权限
    submitUnsaveDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-submit-with-unsave',

    //修改数据权限
    updateDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-update',

    //根据id查询数据权限
    queryDataAuthByIdUrl:'/api/fsmc/data-auth-main/query-data-auth-main/-by-/',

    //删除数据权限
    deleteDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-delete',

    //提交数据权限
    submitDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-submit',

    //废弃数据权限
    cancelDataAuthUrl:'/api/fsmc/data-auth-main/data-auth-main-nullify',



    //数据权限设置复核列表页面查询
    queryDataAuthCheckListByPageUrl:'/api/fsmc/data-auth-main-check/query-data-auth-check-by-page',

    //批量复核通过数据权限
    approvalDataAuthCheckUrl:'/api/fsmc/data-auth-main-check/data-auth-main-batch-check-success',

    //批量复核拒绝数据权限
    refuseDataAuthCheckUrl:'/api/fsmc/data-auth-main-check/data-auth-main-batch-check-refuse',

    //单笔复核通过数据权限
    approvalSingleDataAuthCheckUrl:'/api/fsmc/data-auth-main-check/data-auth-main-check-success',

    //单笔复核拒绝数据权限
    refuseSingleDataAuthCheckUrl:'/api/fsmc/data-auth-main-check/data-auth-main-check-refuse',



    //复制数据权限
    copyDataAuthUrl:'/api/fsmc/data-auth-main-copy/data-auth-main-copy',

    //校验被复制用户是否存在数据权限信息（包含 已保存的 和已生效的）
    validateDataAuthMainCopyHasUrl:'/api/fsmc/data-auth-main-copy/validate-data-auth-main-copy-has',

    validateDataAuthMainHasUrl:'/api/fsmc/data-auth-main-copy/validate-data-auth-main-has',


    //模块数据权限设置
    //列表页面查询
    queryDataAuthModuleRelByPageUrl: '/api/fsmc/data-auth-module-rel/query-data-auth-module-rel-by-page',

    //查询注册的模块信息
    queryModuleRegisterListUrl:'/api/fsmc/system/module-register/get-module-combo-list',//查询注册模块信息

    //保存模块数据权限设置
    saveDataAuthModuleRelUrl:'/api/fsmc/data-auth-module-rel/data-auth-module-rel-add',

    //修改模块数据权限设置
    updateDataAuthModuleRelUrl:'/api/fsmc/data-auth-module-rel/data-auth-module-rel-update',

    //删除模块数据权限设置
    deleteDataAuthModuleRelUrl:'/api/fsmc/data-auth-module-rel/data-auth-module-rel-delete',

    //根据模块编号查询模块数据权限设置
    queryDataAuthModuleRelByModuleCodeUrl:'/api/fsmc/data-auth-module-rel/query-data-auth-module-rel/-by-/',
}
