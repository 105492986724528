<template>
    <div>
        <f-autocomplete-scroll :fetch-suggestions="loadData" :noMore="noMore" v-bind="$attrs" v-on="$listeners">
            <template slot="prepend" v-if="$slots.prepend">
                <slot name="prepend"></slot>
            </template>
            <template slot="append" v-if="$slots.append">
                <slot name="append"></slot>
            </template>
            <template slot="prefix" v-if="$slots.prefix">
                <slot name="prefix"></slot>
            </template>
            <template slot="suffix" v-if="$slots.suffix">
                <slot name="suffix"></slot>
            </template>
            <template slot-scope="{ item }">
                <slot :item="item"></slot>
            </template>
        </f-autocomplete-scroll>
    </div>
</template>

<script>
export default {
    name: 'ComAutocompleteScroll',
    data() {
        return {
            /**
             * 前后端交互的字段名配置,
             * 当后端字段名有变动时,改这里的配置即可
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * table分页配置
             */
            scrollPage: {
                total: 0,
                currentPage: 0,
                pageSize: 10
            },
            resultData: [] // 存放请求回来的数据结果集
        };
    },
    computed: {
        noMore() {
            return this.scrollPage.currentPage * this.scrollPage.pageSize >= this.scrollPage.total && this.scrollPage.currentPage > 0;
        }
    },
    props: {
        dataSource: {
            type: String,
            required: true,
            default: ''
        },
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        queryStringName: {
            type: String,
            default: 'queryString'
        },
        beforeOpen: Function
    },

    methods: {
        loadData({ queryString, type }, cb) {
            if (!this.dataSource) {
                console.log('您为设置请求地址, 请设置相应的请求地址');
                return;
            }
            const $loadData = () => {
                let currentPage;
                if (type !== 'scroll') {
                    this.scrollPage.currentPage = 0
                }
                currentPage = this.scrollPage.currentPage + 1;
                this.$http
                    .post(
                        this.dataSource,
                        {
                            [this.keyNames.currentPage]: currentPage,
                            [this.keyNames.pageSize]: this.scrollPage.pageSize,
                            [this.queryStringName]: queryString,
                            ...this.extraData
                        },
                        {
                            loading: false
                        }
                    )
                    .then(res => {
                        this.scrollPage.currentPage = currentPage;
                        if (res.code == 0) {
                            let data = res.data[this.keyNames.rows];
                            this.scrollPage.total = res.data[this.keyNames.total];
                            if (type === 'scroll') {
                                this.resultData.push(...data);
                            } else {
                                this.resultData = data;
                            }
                            cb(this.resultData);
                        } else {
                            if (type === 'scroll') {
                                cb(this.resultData);
                            } else {
                                cb([]);
                            }
                        }
                    })
                    .catch(error => {
                        this.scrollPage.currentPage = currentPage;
                        this.$error(error);
                        if (type === 'scroll') {
                            cb(this.resultData);
                        } else {
                            cb([]);
                        }
                    });
            };
            if (!this.beforeOpen) {
                $loadData();
            } else if (typeof this.beforeOpen === 'function') {
                const before = this.beforeOpen();
                // promise 异步处理
                if (before && before.then) {
                    before.then(() => {
                        $loadData();
                    });
                    // js 同步处理
                } else if (before !== false) {
                    $loadData();
                }
                cb([]);
                // 其他情况可以不用打开
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
