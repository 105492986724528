/**
 * 所有模块公共的请求地址
 * 如公共放大镜的请求地址,公共组件内部的请求地址
 */
const common = {
    dictionaries: 'api/dictionaries.json', // 公共数据字典数据
    constants: 'api/constants.json', // 公共的常量判断固定值
    formItemOrder: 'api/formItemOrder.json', // 公共的查询表单--查询表单项顺序
    formItemOrderSave: 'api/formItemOrderSave.json', // 公共的查询表单--表单项顺序设置保存
    queryFormList: 'api/common/queryFormList.json', // 公共的查询表单--查询已保存查询条件列表
    queryFormSave: 'api/common/queryFormSave.json', // 公共的查询表单--查询条件保存
    queryFormDel: 'api/common/queryFormDel.json', // 公共的查询表单--查询条件删除
    query: {
        queryFormExpandParam: '/api/fsmc/fsmc-api/expand/query-expand-elements-condition-by-template-code' // 公共的查询表单--查询条件扩展字段查询
    },
    tableColumnsSave: 'api/common/tableColumnsSave.json', // 公共的查询列表--列表列配置保存
    tableColumns: 'api/common/tableColumns.json', // 公共的查询列表--列表列配置查询
    tableExpandParamUrl: 'api/common/tableExpandParamUrl.json', // 公共的查询列表--扩展字段查询
    getToken: 'api/common/token/getToken', // 公共获取token
    collapseItemOrder: 'api/common/collapseItemOrder.json', // 公共的编辑页面--折叠面板顺序查询
    collapseItemOrderSave: 'api/common/collapseItemOrderSave.json', // 公共的编辑页面--折叠面板顺序保存
    hiddenParamSave: '/api/fsmc/common/query-condition/insert/insert-query-index', // 公共的编辑页面隐藏字段保存
    formIframeInfoUrl: '/api/fsmc/common/page-iframe/query/iframe-info/-by-/', // 公共的编辑页面--需要嵌入的iframe的信息
    fieldConfigUrl:'/api/wkfl/workdesk/task-form/get-task-match-config',//录入式审批配置字段
    process: 'api/common/process.json', // 公共的审批流信息
    flow: 'api/common/flow.json', // 公共的流程跟踪
    breadCrumb: 'api/common/breadCrumb',
    orgMaginifier: 'api/comm.json', // 单位放大镜列表
    acctMaginifier: 'api/common/zoom/query-acct-struct-by-auth-and-page', //账户放大镜
    bankMaginifier: 'api/common/zoom/query-bank-struct-by-auth-and-page', //开户支行放大镜
    finaMaginifier: 'api/common/zoom/query-fina-struct-by-auth-and-page', //所属金融机构放大镜
    merMaginifier: 'api/common/zoom/query-mer-struct-by-auth-and-page', //客商信息放大镜
    payaccountMaginifier: 'api/common/zoom/query-payaccount-struct-by-auth-and-page', //付款账户放大镜
    userMaginifier: 'api/common/zoom/query-user-struct-by-auth-and-page', //用户信息放大镜
    statementMaginifier: '/api/acct/common/zoom/query-cooperative-financ-net-by-page', //金融机构业务组件
    cooperativeUrl: '/api/acct/common/zoom/query-cooperative-institution-by-page', //合作金融机构下拉测试URL
    province: '/api/fsmc/data/province/query-province-by-code-and-name', // 省查询
    city: '/api/fsmc/data/city/query-city-by-code-name-provinceCode', // 市查询
    country:'/api/fsmc/data/country/query-country-by-page',
    acctSelect: '/api/common/zoom/query-acct-type-with-data-auth', //账户搜素下拉框
    officeSelect: '/api/common/zoom/query-fina-type-with-data-auth', // 所属金融机构（大行）搜索下拉框
    openSelect: '/api/common/zoom/query-open-type-with-data-auth', // 开户区域国家搜索下拉框
    userSelect: '/api/common/zoom/query-user-type-with-data-auth', // 用户信息搜索下拉框

    orgInteSelect: '/api/common/zoom1/query-business-type-with-data-auth',
    budgetMaginifier: '/api/common/bugdgetMaginifier', // 预选项目放大镜
    exportUrl: '/api/common/export', // 预选项目放大镜
    comUpload: {
        query: '/api/fsmc/components/upload/query-upload-file-list', // 查询
        delete: '/api/fsmc/components/upload/delete-file', // 删除
        download: '/api/fsmc/components/upload/download-file', // 下载
        upload: '/api/fsmc/components/upload/upload-file', // 上传
        bulkDownload: 'api/fsmc/components/upload/download-file-zip', // 批量下载
        viewFile:'/api/fsmc/common/download-file/-by-/',
        initFile:'/api/fsmc/attach-type-set/query-attach-type-set-info',//初始化附件上传配置
        updateFile:'/api/fsmc/components/upload/update-upload-file-comment'
    },
    comForm: {
        queryInfo: '/cms-base/base/api/expand/query-expand-page-info', //查看，修改状态 请求模板与数据
        queryCode: '/cms-base/base/api/expand/query-expand-element-by-code', //新增 需提供新的busId(不重复)，修改和查看必须传递相同的busId
        saveInfo: '/cms-base/base/api/expand/save-expand-element-info', // 保存元素数据
        updateInfo: '/cms-base/base/api/expand/update-expand-element-info' //修改元素数据
    },
    portalMain: {
        queryAllAreaInfo: '/api/fsmc/index-area/query-all-area-info', //获取全部区块
        queryUserAreaInfo: '/api/fsmc/index-area/query-user-area-info', // 获取用户自定义区块
        addUserAreaInfo: '/api/fsmc/index-area/add-user-area-info', //添加区块
        updateUserAreaInfo: '/api/fsmc/index-area/update-user-area-info', // 修改区块
        deleteUserAreaInfo: '/api/fsmc/index-area/delete-user-area-info', // 删除区块
        changeUserAreaOrderInfo: '/api/fsmc/index-area/change-user-area-order-info', // 顺序更改
        resetUserAreaInfo: '/api/fsmc/index-area/user-reset-area-info' // 恢复默认区块
    },
    cert: {
        queryFactoryAndLicense: '/api/fsmc/common/ca-cert/query/cert-factory-liencese',
        validateCert: '/api/fsmc/common/ca-cert/validate/validate-cert'
    },
    hideBtn: '/api/fsmc/auth/userMenu/query-user-menu-button', // 获取隐藏按钮的数据
    getLogoInfo: '/api/fsmc/common/query-upload-file-list', // 获取系统logo图标数据地址
    getOperationGuide: '/api/fsmc/sys-help-set/query-sys-help-set/-by-/', // 获取页面操作指引
    saveOperationGuide: '/api/fsmc/sys-help-set/sys-help-set-modify' // 保存页面操作指引
};

export {common}
