<template>
    <div class="com-table-upload-wrap">
        <div class="com-table-upload__template">
            <slot name="template"></slot>
        </div>

        <f-form
            ref="form"
            label-width="90px"
            :column="1"
            :inline="false"
            labelPosition="right"
            :model="form"
            :rules="rules"
            :showInvalidPrompt="false"
        >
            <f-form-item :label="$t('i18n.common.components.import.templateName')" class="item-center" :column="1" prop="templateType">
                <com-select 
                    v-model="form.templateType" 
                    :data="templateTypeList"
                    :props="{
                        key: 'id',
                        label:'name',
                        value: 'id'
                    }"
                    :placeholder="$t('i18n.common.components.import.placeholderTemplateName')"></com-select>
            </f-form-item>
            <f-form-item v-if="isImportType" :label="$t('i18n.common.components.import.importType')" class="item-center" :column="1" prop="importType">
                <com-select 
                    v-model="form.importType" 
                    :data="importTypeList"
                    :placeholder="$t('i18n.common.components.import.placeholderTemplateName')"></com-select>
            </f-form-item>
            <f-form-item v-if="isImportType && form.importType == importTypeConstants['COVER']" :label="$t('i18n.common.components.import.importTypeDate')" class="item-center" :column="1" prop="importTypeDate">
                <f-date-picker
                    v-model="form.importTypeDate"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('i18n.common.components.import.rangeSeparator')"
                    :start-placeholder="$t('i18n.common.components.import.startDate')"
                    :end-placeholder="$t('i18n.common.components.import.endDate')">
                </f-date-picker>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.import.importTitle')" class="item-center">
                <f-form-item prop="fileNames" :column="1">
                    <f-upload
                        ref="upload"
                        v-bind="$attrs"
                        v-on="listeners"
                        :fileList="fileList"
                        :auto-upload="false"
                        :show-file-list="true"
                        :on-remove="handleRemove"
                        :on-change="handleChange"
                        :http-request="httpRequest"
                        :data="uploadData"
                        :limit="multiple ? 0 : 1"
                    >
                        <f-input slot="trigger" readonly v-model="form.fileNames" size="mini" :placeholder="$t('i18n.common.components.import.placeholderImport')" />
                        <f-button class="com-table-upload__btn" size="mini" type="primary" @click="submitUpload"
                            >{{$t('i18n.common.importText')}}</f-button
                        >
                    </f-upload>
                </f-form-item>

                <f-form-item :column="1">
                    <div class="com-table-upload__tip">
                        <slot name="tip">
                            <div>{{$t('i18n.common.components.import.importTip1')}}</div>
                            <div>{{$t('i18n.common.components.import.importTip2')}}</div>
                        </slot>
                    </div>
                </f-form-item>
            </f-form-item>
        </f-form>
    </div>
</template>

<script>
export default {
    name: 'ComTableUpload',
    data() {
        let { ...listeners } = this.$listeners;
        return {
            form: {
                templateType: '', // 模板类别
                fileNames: '',
                importType: '', // 导入方式
                importTypeDate: ''// 时间
            },
            listeners: listeners,
            uploadAble: false,
            rules: {
                templateType: [{ required: true, message: this.$t('i18n.common.components.import.placeholderTemplateName') }],
                fileNames: [{ required: true, message: this.$t('i18n.common.components.import.placeholderImport') }],
                importType:[],
                importTypeDate: []
            },
            importTypeRules: [
                { required: true, message: this.$t('i18n.common.components.import.placeholderImportType') }
            ],
            importTypeDateRules: [
                {required: true, message: this.$t('i18n.common.components.import.placeholderImportTypeDate')}
            ]

        };
    },
    props: {
        fileList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        /**
         * 模板类别下拉框数据
         */
        templateTypeList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        // 导入方式下拉框数据
        importTypeList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        importTypeConstants: {
            type: Object,
            defualt: ()=> {
                return {}
            }
        },
        //是否显示导入方式
        isImportType: {
            type: Boolean,
            default: false
        },
        /**
         * 上传时额外的参数
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 时候可选择多个文件
         */
        multiple: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        uploadData() {
            return Object.assign({}, this.form, this.extraData);
        }
    },
    created() {
        if(this.isImportType) {
            // 添加导入方式的非空验证
            this.rules.importType = this.importTypeRules;
        }
    },
    watch: {
        'form.importType': {
            handler(newValue, oldValue) {
                if (newValue == this.importTypeConstants['COVER']) {
                    this.rules.importTypeDate = this.importTypeDateRules;
                } else {
                    this.rules.importTypeDate = [];
                }
            }
        }
    },
    methods: {
        submitUpload() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$refs.upload.submit();
                }
            });
        },
        handleRemove(file, fileList) {
            this.getFileNames(fileList);
        },
        handlePreview(file) {
            console.log('2', file);
        },
        handleChange(file, fileList) {
            if (file.status == 'ready') {
                
                // 添加文件时
                this.getFileNames(fileList);
            }
            this.$emit('onChange', file, fileList);
        },
        getFileNames(fileList) {
            let fileNames = fileList.map(item => {
                return item.name;
            });
            this.form.fileNames = fileNames.join(',');
        },
        // 上传文件httpRequest
        httpRequest(options) {
            console.log(options);
            this.$http.upload(options);
        },
        resetForm() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.com-table-upload-wrap {
    width: 100%;
    border: 1px solid #d6dfeb;
    .com-table-upload__template {
        text-align: right;
        margin: 10px 36px;
    }
    .com-table-upload__tip {
        color: #c0c4cc;
        font-size: 12px;
        text-align: left;
    }
    .com-table-upload__btn {
        margin-left: 8px;
    }
    .el-form.f-form {
        margin: 70px 80px;
    }
}
</style>
