/**
 * 业务相关的公共组件放置区域
 */
import OrgMagnifierSingle from './src/org-magnifier-single'; // 单位放大镜--单选
import BankMagnifierSingle from './src/bank-magnifier-single'; // 开户支行放大镜
import OfficeMagnifierSingle from './src/office-magnifier-single'; // 所属金融机构（大行）放大镜
import CustomerMagnifierSingle from './src/customer-magnifier-single'; // 客商信息放大镜
import OfficeSelectSingle from './src/office-select-single'; //所属金融机构（大行）搜索下拉框
import OrgSelectSingle from './src/org-select-single.vue';  //单位搜索下拉框
import CountrySelectSingle from './src/country-select-single.vue'; //开户区域国家搜索下拉框
import PayaccountMagnifierSingle from './src/payaccount-magnifier-single.vue'; // 付款账号放大镜--单选(按钮)
import OrgMagnifierMulti from './src/org-magnifier-multi.vue'; // 单位放大镜--多选
import AcctMagnifierSingle from './src/acct-magnifier-single.vue'; // 账户放大镜单选
import AcctSelectSingle from './src/acct-select-single.vue'; // 账户搜索下拉框
import UserMagnifierSingle from './src/user-magnifier-single.vue'; // 用户信息放大镜
import UserSelectSingle from './src/user-select-single.vue'; // 用户信息放大镜
import ProvinceAndCity from './src/province-and-city.vue'; // 省市联动--单选
import BudgetMagnifierMulti from './src/budget-magnifier-multi.vue'; // 预算项目放大镜--多选
import FormTemplate from './src/form-template.vue'; // 模板表单
import UploadTemplate from './src/upload-template.vue'; // 上传模板
import StatementMagnifierSingle from './src/statement-magnifier-single.vue';   // 模板表单

OrgMagnifierSingle.install = function(Vue) {
    Vue.component(OrgMagnifierSingle.name, OrgMagnifierSingle);
};

BankMagnifierSingle.install = function(Vue) {
    Vue.component(BankMagnifierSingle.name, BankMagnifierSingle);
};

OfficeSelectSingle.install = function(Vue) {
    Vue.component(OfficeSelectSingle.name, OfficeSelectSingle);
};

OfficeMagnifierSingle.install = function(Vue) {
    Vue.component(OfficeMagnifierSingle.name, OfficeMagnifierSingle);
};

CustomerMagnifierSingle.install = function(Vue) {
    Vue.component(CustomerMagnifierSingle.name, CustomerMagnifierSingle);
};

OrgSelectSingle.install = function (Vue) {
    Vue.component(OrgSelectSingle.name, OrgSelectSingle);
};

CountrySelectSingle.install = function (Vue) {
    Vue.component(CountrySelectSingle.name, CountrySelectSingle);
};

PayaccountMagnifierSingle.install = function (Vue) {
    Vue.component(PayaccountMagnifierSingle.name, PayaccountMagnifierSingle);
};

OrgMagnifierMulti.install = function (Vue) {
    Vue.component(OrgMagnifierMulti.name, OrgMagnifierMulti);
};

AcctMagnifierSingle.install = function (Vue) {
    Vue.component(AcctMagnifierSingle.name, AcctMagnifierSingle);
};

AcctSelectSingle.install = function (Vue) {
    Vue.component(AcctSelectSingle.name, AcctSelectSingle);
};

UserMagnifierSingle.install = function (Vue) {
    Vue.component(UserMagnifierSingle.name, UserMagnifierSingle);
};

UserSelectSingle.install = function (Vue) {
    Vue.component(UserSelectSingle.name,UserSelectSingle)
};

ProvinceAndCity.install = function(Vue) {
    Vue.component(ProvinceAndCity.name, ProvinceAndCity);
};
BudgetMagnifierMulti.install = function(Vue) {
    Vue.component(BudgetMagnifierMulti.name, BudgetMagnifierMulti);
};

FormTemplate.install = function(Vue) {
    Vue.component(FormTemplate.name, FormTemplate);
};

StatementMagnifierSingle.install = function (Vue) {
    Vue.component(StatementMagnifierSingle.name, StatementMagnifierSingle);
}

UploadTemplate.install = Vue => {
    Vue.component(UploadTemplate.name, UploadTemplate);
};

const install = function(Vue) {
    OrgMagnifierSingle.install(Vue);
    OrgSelectSingle.install(Vue);
    BankMagnifierSingle.install(Vue);
    OfficeMagnifierSingle.install(Vue);
    OfficeSelectSingle.install(Vue);
    CustomerMagnifierSingle.install(Vue);
    CountrySelectSingle.install(Vue);
    PayaccountMagnifierSingle.install(Vue);
    OrgMagnifierMulti.install(Vue);
    AcctMagnifierSingle.install(Vue);
    AcctSelectSingle.install(Vue);
    UserMagnifierSingle.install(Vue);
    UserSelectSingle.install(Vue);
    ProvinceAndCity.install(Vue);
    BudgetMagnifierMulti.install(Vue);
    FormTemplate.install(Vue);
    UploadTemplate.install(Vue);
    StatementMagnifierSingle.install(Vue);
};

// prettier-ignore
export {
    install,
    OrgMagnifierSingle,
    OrgSelectSingle,
    BankMagnifierSingle,
    OfficeMagnifierSingle,
    OfficeSelectSingle,
    CustomerMagnifierSingle,
    CountrySelectSingle,
    PayaccountMagnifierSingle,
    AcctMagnifierSingle,
    AcctSelectSingle,
    OrgMagnifierMulti,
    UserMagnifierSingle,
    UserSelectSingle,
    ProvinceAndCity,
    BudgetMagnifierMulti,
    FormTemplate,
    StatementMagnifierSingle,
    UploadTemplate
};

export default {
    install
};
