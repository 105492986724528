export default {

    //提醒设置模块
    queryRemindConfigByPageUrl:'/api/fsmc/remind-config/query-remind-config-by-page', // 提醒配置 - 分页列表查询
    queryRemindConfigByIdUrl:'/api/fsmc/remind-config/query-remind-config/-by-/', // 提醒配置 - 根据id查询单个对象 (没有id占位符)
    addRemindConfigUrl:'/api/fsmc/remind-config/remind-config-add', // 提醒配置 - 新增
    updateRemindConfigUrl:'/api/fsmc/remind-config/remind-config-update', // 提醒配置 - 更新
    deleteRemindConfigUrl:'/api/fsmc/remind-config/remind-config-delete', // 提醒配置 - 删除
    enableRemindConfigUrl:'/api/fsmc/remind-config/remind-config-enable', // 提醒配置 - 启用
    disableRemindConfigUrl:'/api/fsmc/remind-config/remind-config-disable', // 提醒配置 - 停用
    validateOrgInfoCodeRepeatUrl:'/api/fsmc/remind-config/validate-config-code-repeat', // 提醒配置 - 校验提醒设置编号是否已经做过设置
    queryRemindTargetBOListUrl:'/api/fsmc/remind-target/query-remind-template-list', // 提醒配置 - 编辑查询提醒对象列表
    queryRemindTemplateListUrl:'/api/fsmc/remind-template/query-remind-template-list',// 提醒配置 - 查询提醒模板列表


    //提醒内容参数配置
    queryRemindParamByPageUrl:'/api/fsmc/remind-param/query-remind-param-by-page', //提醒内容参数配置 - 分页列表查询
    queryRemindParamByIdUrl:'/api/fsmc/remind-param/query-remind-param/-by-/', //提醒内容参数配置 - 根据id查询单个对象
    addRemindParamUrl:'/api/fsmc/remind-param/remind-param-add', //提醒内容参数配置 - 新增
    updateRemindParamUrl:'/api/fsmc/remind-param/remind-param-update', //提醒内容参数配置 - 更新
    deleteRemindParamUrl:'/api/fsmc/remind-param/remind-param-delete', //提醒内容参数配置 - 删除
    getParameterValueListUrl:'/api/fsmc/remind-param/query-remind-param-by-type-list', //提醒内容参数配置 - 根据参数类型查询模板参数
}
