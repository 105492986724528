<template>
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :tableData="tableData"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        size="mini"
        v-bind="$attrs"
        :placeholder="placeholder"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        @reset="handleReset"
        v-on="listeners"
        :autoSelect="autoSelect"
    >
        <template v-slot:search>
            <slot name="search"></slot>
        </template>
        <slot></slot>
    </f-magnifier-single>
</template>

<script>
import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
/**
 * 放大镜-单选
 */
export default {
    name: 'ComMagnifierSingle',
    mixins: [magnifierSingleMix]
};
</script>

<style lang="scss" scoped></style>
