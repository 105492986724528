<template>
    <div class="edit-linkage">
        <div v-for="(menu, index) in menus" :key="index">
            <linkage-menu :nodes="menu"></linkage-menu>
        </div>
    </div>
</template>

<script>
import merge from '@iss/vmui-vue/lib/utils/merge';
import { noop, isEqual, isEmpty, valueEquals } from '@iss/vmui-vue/lib/utils/util';
import LinkageMenu from './src/linkage-menu';
import Store from './src/store';
// 专属行编辑的多级联动
/**
 * 暂不支持功能:
 * 1. 多选
 * 2. 无限加载
 * 3. 自动请求 传入url即可
 * 4. 一次性生成多级联动  options 配置
 * 5. 可配置下拉列数
 * 支持的功能:
 * 1. 请求后端方式多级联动， 根据leaf（可配置）判断； leaf 为 false  可以根据   lazyLoad回调中请求后台，然后处理数据 例如:  leaf: level >= 1 表示二级联动
 */

/**
 * 属性说明:
 * value/v-model  Any 双向绑定数据
 * options  Array<{label: String, value: Any}> 适合一次性加载的方式
 * separator String '/'  label 分割符
 * showAllLevels Boolean  全路径
 * props Object  基础配置
 *    multiple  Boolean false  单选or多选  暂不支持
 *    emitPath  Boolean  true  获得完成路径  例如: 为true返回 ['xx', 'xxx'], 为false返回 'xxx'
 *    lazy   Boolean   true  是否远程加载数据
 *    lazyLoad Function () => {}  远程加载数据方法
 *    value  String 'value' 配置每列数据props值  例如: 数据格式是 {val: 'xx', text: 'xx', leaf: false}， 你就可以将value设置为'val', 将 label 设置为 'text'
 *    label String 'label'
 *    children String 'children'
 *    leaf  String 'leaf'  是否有下一级； 如果有为false， 没有为true； 该属性配置对象的属性名
 */

const defsProps = {
    // expandTrigger: 'click',  // 打开下一级方式
    multiple: false, // 单选多选
    emitPath: true,
    lazy: false,
    lazyLoad: noop,
    value: 'value',
    label: 'label',
    children: 'children',
    leaf: 'leaf',
    disabled: 'disabled'
};

let keyNamesDef = {
    rows: 'rows'
};

export default {
    name: 'EditLinkage',
    provide() {
        return {
            linkage: this
        };
    },

    data() {
        return {
            checkedValue: null,
            checkedNodePaths: [],
            store: [],
            menus: [],
            activePath: [],
            loadCount: 0
        };
    },

    props: {
        value: {}, //
        options: Array, //默认参数；适合一次性加载的方式
        props: Object,
        renderLabel: Function, // 自定义label值,只适用文字
        separator: {
            type: String,
            default: ' / '
        },
        showAllLevels: {
            type: Boolean,
            default: true
        },
        /**
         * 源数据
         */
        dataSource: [Array, String],
        /**
         * 请求发送到后台的数据
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 前后端交互的字段名
         */
        keyNames: Object
    },

    components: {
        LinkageMenu
    },

    mounted() {
        if (!isEmpty(this.value)) {
            this.syncCheckedValue();
        }
    },

    computed: {
        config() {
            return merge({ ...defsProps }, this.props || {});
        },
        multiple() {
            return this.config.multiple;
        },
        renderLabelFn() {
            return this.renderLabel || this.$scopedSlots.default;
        },
        keyNamesOption() {
            return { ...keyNamesDef, ...this.keyNames };
        }
    },

    watch: {
        options: {
            handler: function() {
                this.initStore();
            },
            immediate: true,
            deep: true
        },
        value() {
            this.syncCheckedValue();
        },
        checkedValue(val) {
            if (!isEqual(val, this.value)) {
                this.$emit('input', val);
                this.$emit('change', val);
            }
        }
    },

    methods: {
        initStore() {
            const { config, options } = this;
            if (config.lazy && isEmpty(options)) {
                this.lazyLoad();
            } else {
                this.store = new Store(options, config);
                this.menus = [this.store.getNodes()];
                this.syncMenuState();
            }
        },
        lazyLoad(node, onFullfiled) {
            const { config } = this;
            if (!node) {
                node = node || { root: true, level: 0 };
                this.store = new Store([], config);
                this.menus = [this.store.getNodes()];
            }

            node.loading = true;

            const resolve = dataList => {
                const parent = node.root ? null : node;
                dataList && dataList.length && this.store.appendNodes(dataList, parent);
                node.loading = false;
                node.loaded = true;

                if (Array.isArray(this.checkedValue)) {
                    const nodeValue = this.checkedValue[this.loadCount++];
                    const valueKey = this.config.value;
                    const leafKey = this.config.leaf;

                    if (Array.isArray(dataList) && dataList.filter(item => item[valueKey] === nodeValue).length > 0) {
                        const checkedNode = this.store.getNodeByValue(nodeValue);

                        if (!checkedNode.data[leafKey]) {
                            this.lazyLoad(checkedNode, () => {
                                this.handleExpand(checkedNode);
                            });
                        }

                        if (this.loadCount === this.checkedValue.length) {
                            // this.$parent.computePresentText();
                        }
                    }
                }

                onFullfiled && onFullfiled(dataList);
            };
            config.lazyLoad(node, resolve);
        },

        handleCheckChange(value) {
            this.checkedValue = value;
        },

        handleLabelChange(node) {
            this.$emit('label', node ? node.getText(this.showAllLevels, this.separator) : '');
        },

        handleExpand(node, silent) {
            const { activePath } = this;
            const { level } = node;
            const path = activePath.slice(0, level - 1);
            const menus = this.menus.slice(0, level);

            if (!node.isLeaf) {
                path.push(node);
                menus.push(node.children);
            }

            this.activePath = path;
            this.menus = menus;
            if (!silent) {
                const pathValues = path.map(node => node.getValue());
                const activePathValues = activePath.map(node => node.getValue());
                if (!valueEquals(pathValues, activePathValues)) {
                    this.$emit('active-item-change', pathValues); // Deprecated
                    this.$emit('expand-change', pathValues);
                }
            }
        },
        syncCheckedValue() {
            const { value, checkedValue } = this;
            if (!isEqual(value, checkedValue)) {
                this.checkedValue = value;
                if (isEmpty(this.checkedValue)) {
                    // 重置联动框
                    this.initStore();
                    // 重置label
                    this.handleLabelChange();
                }
                this.syncMenuState();
            }
        },
        syncMenuState() {
            this.syncActivePath();
        },
        syncActivePath() {
            const { store, activePath, multiple, checkedValue } = this;
            if (!isEmpty(activePath)) {
                const nodes = activePath.map(node => this.getNodeByValue(node.getValue()));
                this.expandNodes(nodes);
            } else if (!isEmpty(checkedValue)) {
                const value = multiple ? checkedValue[0] : checkedValue;
                const checkedNode = this.getNodeByValue(value) || {};
                const nodes = (checkedNode.pathNodes || []).slice(0, -1);
                this.expandNodes(nodes);
            } else {
                this.activePath = [];
                this.menus = [store.getNodes()];
            }
        },
        expandNodes(nodes) {
            nodes.forEach(node => node && this.handleExpand(node, true /* silent */)); // 表单输入框
        },
        getNodeByValue(val) {
            return this.store.getNodeByValue(val);
        },
        computePresentContent() {
            this.$nextTick(() => {
                this.computePresentText();
            });
        },
        computePresentText() {
            const { checkedValue } = this;
            if (!isEmpty(checkedValue)) {
                const node = this.getNodeByValue(checkedValue);
                if (node && node.isLeaf) {
                    this.presentText = node.getText(this.showAllLevels, this.separator);
                    return;
                }
            }
            this.presentText = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.edit-linkage {
    display: flex;
}
</style>
