<template>
    <f-upload
        ref="upload"
        v-bind="{ ...$attrs, httpRequest: httpRequest, beforeUpload: beforeUpload }"
        :accept="accept.join(',')"
    >
        <template slot="default">
            <slot name="default"></slot>
        </template>
        <template slot="trigger">
            <slot name="trigger"></slot>
        </template>
        <template slot="tip">
            <slot name="tip">
                <div class="el-upload__tip">{{ tips }}</div>
            </slot>
        </template>
    </f-upload>
</template>
<script>
export default {
    name: 'ComUpload',
    inheritAttrs: false,
    props: {
        max: {
            type: Number,
            default: 500
        },
        accept:{
            type: [Array, String, Object],
            default:() => {
                return SYS_CONFIG['ACCEPT'] || ['*']
            }
        },
        errorText: [String, Function]
    },
    computed: {
        // 转成MB
        singleSize() {
            return this.max * 1024 * 1024;
        },
        tips() {
            return `${this.$t('i18n.common.components.uploadTemplate.uploadTipText')}${this.fileSize(this.singleSize)}`;
        }
    },
    methods: {
        // 获取内部 上传 组件对象
        getUpload() {
            return this.$refs.upload;
        },
        /**
         * 此处是f-upload的三个方法: clearFiles, abort, submit
         */
        clearFiles() {
            this.getUpload().clearFiles();
        },
        abort() {
            this.getUpload().abort();
        },
        submit() {
            this.getUpload().submit();
        },

        /** 此处换成符合业务的方法 */
        httpRequest(options) {
            this.$http.upload(options);
        },
        /** 此处换成符合业务的方法 */
        beforeUpload(file) {
            let { beforeUpload } = this.$attrs;
            // fix: 当beforeUpload为不写时用默认上传方法配置
            if (typeof beforeUpload !== 'function') return this._beforeUpload(file);
            let res = beforeUpload(file);
            if (res && res.then && typeof res.then === 'function') {
                return res.then(
                    () => {
                        return this._beforeUpload(file);
                    },
                    () => {
                        return Promise.reject();
                    }
                );
            } else {
                return res && this._beforeUpload(file);
            }
        },
        _beforeUpload(file) {
            let errorText = this.errorText || this.$t('i18n.common.components.comUpload.errorText');
            let result = '';
            if (errorText instanceof Function) {
                result = errorText(file, this.singleSize);
            } else {
                result = `${errorText}${this.fileSize(this.singleSize)}`;
            }
            if (this.isValidSize(file.size)) {
                return Promise.resolve();
            }
            this.$error(result);
            return Promise.reject();
        },
        fileSize(num, units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y', 'B', 'N', 'D']) {
            let i = 0,
                len = units.length,
                kb = 1024;
            while (i < len) {
                let calc = num / Math.pow(kb, i + 1);
                if (calc >= kb) {
                    i++;
                } else {
                    return `${calc.toFixed(0)}${units[i]}B`;
                }
            }
            return -1;
        },
        isValidSize(size) {
            if (isNaN(parseInt(size))) {
                return false;
            }
            return this.singleSize > size;
        },
        notSupportFile(file) {
            let flag = false;
            // 暂时使用 file.type 为空表示文件夹
            if (file.type === '') {
                flag = true;
            }
            return flag;
        }
    }
};
</script>
<style scoped lang="scss">
.el-upload__tip {
    margin-top: 0;
    text-indent: 20px;
    text-align: left;
    color: #999;
}
</style>
