import '@/common/components/messageBox.js';
import ComQueryForm from '@/common/components/comQueryForm';
import ComQueryTable from '@/common/components/comQueryTable';
import ComEditForm from '@/common/components/comEditForm';
import ComSelect from '@/common/components/comSelect';
import comTableProcess from '@/common/components/comTableProcess';
import ComFormProcess from '@/common/components/comFormProcess';
import ComFormFlow from '@/common/components/comFormFlow';
import ComMagnifierSingle from '@/common/components/comMagnifierSingle';
//import ComBreadCrumb from '@/common/components/comBreadCrumb';
import ComEditTable from '@/common/components/comEditTable';
import ComEditCascader from '@/common/components/comEditCascader';
import ComBtnmagnifierSingle from '@/common/components/comBtnmagnifierSingle';
import ComBtnmagnifierMutil from '@/common/components/comBtnmagnifierMutil';
import ComMagnifierMulti from '@/common/components/comMagnifierMulti';
import ComUpload from '@/common/components/comUpload';
import ComCert from '@/common/components/comCert';
import ComDateRange from '@/common/components/comDateRange';
import ComAutocompleteScroll from '@/common/components/comAutocompleteScroll';
import ComHiddenbtnSetting from '@/common/components/comHiddenbtnSetting';
import ComPage from '@/common/components/comPage';
import ComIframeForm from '@/common/components/comIframeForm';
import ComRange from '@/common/components/comRange';
import ComDrawer from '@/common/components/comDrawer';
import ComOperationGuide from '@/common/components/comOperationGuide';
import ComPdf from '@/common/components/comPdf'

const install = function(Vue) {
    ComQueryForm.install(Vue);
    ComQueryTable.install(Vue);
    ComEditForm.install(Vue);
    ComSelect.install(Vue);
    comTableProcess.install(Vue);
    ComFormProcess.install(Vue);
    ComFormFlow.install(Vue);
    ComMagnifierSingle.install(Vue);
    //ComBreadCrumb.install(Vue);
    ComEditTable.install(Vue);
    ComEditCascader.install(Vue);
    ComBtnmagnifierSingle.install(Vue);
    ComBtnmagnifierMutil.install(Vue);
    ComMagnifierMulti.install(Vue);
    ComUpload.install(Vue);
    ComDateRange.install(Vue);
    ComCert.install(Vue);
    ComAutocompleteScroll.install(Vue);
    ComHiddenbtnSetting.install(Vue);
    ComPage.install(Vue);
    ComIframeForm.install(Vue);
    ComRange.install(Vue);
    ComDrawer.install(Vue);
    ComOperationGuide.install(Vue);
    ComPdf.install(Vue);
};

// prettier-ignore
export {
    install,
    ComQueryForm,
    ComQueryTable,
    ComEditForm,
    ComSelect,
    comTableProcess,
    ComFormProcess,
    ComFormFlow,
    ComMagnifierSingle,
    //ComBreadCrumb,
    ComEditTable,
    ComEditCascader,
    ComBtnmagnifierSingle,
    ComBtnmagnifierMutil,
    ComMagnifierMulti,
    ComUpload,
    ComDateRange,
    ComCert,
    ComAutocompleteScroll,
    ComHiddenbtnSetting,
    ComPage,
    ComIframeForm,
    // ComRange,
    ComDrawer,
    ComOperationGuide,
    ComPdf
};

export default {
    install
};
