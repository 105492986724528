<template>
    <div>
        <f-input v-if="item.type === 'text'" type="text" v-model="form[item.name]" :placeholder="item.placeholder" />
        <!-- 文本域独占一行 -->
        <f-textarea
            v-if="item.type === 'textarea'"
            v-model="form[item.name]"
            :placeholder="item.placeholder"
        ></f-textarea>
        <f-number
            v-if="item.type === 'number'"
            v-model.number="form[item.name]"
            :placeholder="item.placeholder"
            :min="item.min"
            :max="item.max"
        ></f-number>
        <f-amount
            v-if="item.type === 'amount'"
            v-model.number="form[item.name]"
            :placeholder="item.placeholder"
            :min="item.min"
            :max="item.max"
        ></f-amount>
        <f-date-picker
            v-if="item.type === 'date'"
            v-model="form[item.name]"
            :placeholder="item.placeholder"
            :format="item.format"
            :value-format="item.valueFormat || 'yyyy-MM-dd'"
        ></f-date-picker>
        <f-time-picker
            v-if="item.type === 'dateTime'"
            v-model="form[item.name]"
            :placeholder="item.placeholder"
            :format="item.format"
            :value-format="item.valueFormat || 'HH:mm:ss'"
        ></f-time-picker>
        <com-select-scroll
            v-if="item.type === 'remoteselect'"
            v-model="form[item.name]"
            :dataSource="item.data"
            :formModel.sync="form"
            :matchKey="item.hideData | matchKey"
            :placeholder="item.placeholder"
            :props="item.props"
            :filterable="item.dropDownListIsSearch && item.dropDownListIsSearch == 1"
            :remote="item.dropDownListRemoteSearch && item.dropDownListRemoteSearch == 1"
            :remoteFilterKey="item.remoteFilterKey"
            :multiple="item.ismuilte === undefined ? false : item.ismuilte"
        >
            <template v-slot:default="{ item: slotItem }">
                <slot :name="item.name" v-bind:item="slotItem"></slot>
            </template>
        </com-select-scroll>
        <com-select
            v-if="item.type === 'select' && item.dataType === 'static'"
            v-model="form[item.name]"
            :placeholder="item.placeholder"
            :filterable="item.dropDownListIsSearch && item.dropDownListIsSearch == 1"
            :data="item.data | decodeStr | jsonParse"
            :multiple="item.ismuilte === undefined ? false : item.ismuilte"
        ></com-select>
        <com-select
            v-if="item.type === 'select' && dicType.indexOf(item.dataType) > -1"
            v-model="form[item.name]"
            :props="Object.assign({}, item.props, { label: 'text', value: 'value' })"
            :placeholder="item.placeholder"
            :data="mixDictionaries[item.dataType][item.data]"
            :filterable="item.dropDownListIsSearch && item.dropDownListIsSearch == 1"
            :formModel.sync="form"
            :matchKey="item.hideData | matchKey"
            :multiple="item.ismuilte === undefined ? false : item.ismuilte"
        ></com-select>
        <f-radio-group v-if="item.type === 'radio' && item.dataType === 'static'" v-model="form[item.name]">
            <f-radio v-for="(rad, i) in filterArray(item['data'])" :key="rad.value + '_' + i" :label="rad.value">{{
                rad.text
            }}</f-radio>
        </f-radio-group>
        <f-radio-group v-if="item.type === 'radio' && dicType.indexOf(item.dataType) > -1" v-model="form[item.name]">
            <template v-if="item.props">
                <f-radio
                    v-for="(rad, i) in mixDictionaries[item.dataType][item.data]"
                    :key="item.dataType + '_' + rad[item.props.value || 'value'] + '_' + i"
                    :label="rad[item.props.value || 'value']"
                >
                    {{ rad[item.props.text || 'text'] }}
                </f-radio>
            </template>
            <template v-else>
                <f-radio
                    v-for="(rad, i) in mixDictionaries[item.dataType][item.data]"
                    :key="item.dataType + '_' + rad.value + '_' + i"
                    :label="rad.value"
                >
                    {{ rad.text }}
                </f-radio>
            </template>
        </f-radio-group>
        <f-checkbox-group v-if="item.type === 'checkbox' && item.dataType === 'static'" v-model="form[item.name]">
            <f-checkbox
                v-for="(check, i) in filterArray(item['data'])"
                :key="check.value + '_' + i"
                :label="check.value"
                >{{ check.text }}</f-checkbox
            >
        </f-checkbox-group>
        <f-checkbox-group
            v-if="item.type === 'checkbox' && dicType.indexOf(item.dataType) > -1"
            v-model="form[item.name]"
        >
            <template v-if="item.props">
                <f-checkbox
                    v-for="(check, i) in mixDictionaries[item.dataType][item.data]"
                    :key="item.dataType + '_' + check[item.props.value || 'value'] + '_' + i"
                    :label="check[item.props.value || 'value']"
                    >{{ check[item.props.label || 'text'] }}</f-checkbox
                >
            </template>
            <template v-else>
                <f-checkbox
                    v-for="(check, i) in mixDictionaries[item.dataType][item.data]"
                    :key="item.dataType + '_' + check.value + '_' + i"
                    :label="check.value"
                    >{{ check.text }}</f-checkbox
                >
            </template>
        </f-checkbox-group>
        <template v-if="item.type === 'magnifier'">
            <form-magnifier-multi
                v-if="item.ismuilte"
                v-model="form[item.name]"
                :dataSource="item.data"
                :matchKey="item.hideData | matchKey"
                :formModel="form"
                :valueKey="item.magnifierValueKey"
                :labelKey="item.magnifierLabelKey"
                :filterKey="item.magnifierFilterKey"
                :queryData="filterArray(item.queryData)"
                :showData="filterArray(item.showData)"
            >
            </form-magnifier-multi>
            <form-magnifier-single
                v-else
                v-model="form[item.name]"
                :dataSource="item.data"
                :matchKey="item.hideData | matchKey"
                :formModel="form"
                :valueKey="item.magnifierValueKey"
                :labelKey="item.magnifierLabelKey"
                :filterKey="item.magnifierFilterKey"
                :queryData="filterArray(item.queryData)"
                :showData="filterArray(item.showData)"
            >
            </form-magnifier-single>
        </template>
        <upload-template
            v-if="item.type === 'file' && fileConfig"
            ref="files"
            v-model="form[item.name]"
            :uploadExtraData="fileConfig['uploadExtraData'] || {}"
            :queryExtraData="fileConfig['queryExtraData'] || {}"
        ></upload-template>
    </div>
</template>

<script>
function decodeStr(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/(&quot;)/g, '"');
}

function jsonParse(str) {
    let result;
    if (typeof str !== 'string') return str;
    try {
        result = JSON.parse(str);
    } catch (error) {
        result = [];
    }
    return result;
}
import { styleMix } from '@/common/comMixin';
import FormMagnifierSingle from './form-magnifier-single.vue';
import FormMagnifierMulti from './form-magnifier-multi.vue';
export default {
    name: 'SubFormTemplate',
    mixins: [styleMix],
    data() {
        return {
            haveDic: ['select', 'radio', 'checkbox'],
            dicType: ['constant', 'dictionary', 'custom']
        };
    },
    components: {
        [FormMagnifierSingle.name]: FormMagnifierSingle,
        [FormMagnifierMulti.name]: FormMagnifierMulti
    },
    filters: {
        decodeStr,
        jsonParse,
        matchKey(arr) {
            if (!Array.isArray(arr)) return arr;
            let result = {};
            for (let item of arr) {
                for (let key in item) {
                    result[key] = item[key];
                }
            }
            return result;
        }
    },
    props: {
        config: Object,
        formModel: Object, //父级传递过来的参数
        fileConfig: Object, // 上传组件配置
        mixDictionaries: Object // 数据常量
    },
    computed: {
        form: {
            get() {
                return this.formModel;
            },
            set(val) {
                this.$emit('update:formModel', val);
            }
        },
        item() {
            return this.config;
        }
    },
    methods: {
        filterArray(str) {
            return jsonParse(decodeStr(str));
        }
    }
};
</script>

<style lang="scss" scoped></style>
