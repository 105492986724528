export default {
    //组织信息维护
    deleteOrgInfoUrl: '/api/fsmc/org-info/org-info-delete', // 删除组织信息
    enableOrgInfoUrl: '/api/fsmc/org-info/org-info-enable', // 启用组织信息
    disableOrgInfoUrl: '/api/fsmc/org-info/org-info-disable', // 停用组织信息
    orgInfoListUrl: '/api/fsmc/org-info/query-org-info-with-ettr-by-page', // 查询组织列表信息
    queryOrgInfoByIdUrl: '/api/fsmc/org-info/query-org-info/-by-/', // 查询组织列表信息
    queryOrgInfoEttrListUrl: '/api/fsmc/org-info/query-org-info-ettr-list', // 查询组织列表信息
    queryProvinceUrl: '/api/fsmc/org-info/combo/province/query-province-code-list', // 查询省信息
    queryCityUrl: '/api/fsmc/org-info/combo/city/query-city-code-list', //查询市信息
    queryFinancialPageListUrl: '/api/fsmc/data/line-numbers/query-line-numbers-by-page', //查询合作金融机构放大镜
    insertOrgInfoUrl:'/api/fsmc/org-info/org-info-add',
    updateOrgInfoUrl:'/api/fsmc/org-info/org-info-update',
    validateOrgCodeRepeatUrl: '/api/fsmc/org-info/validate-org-info-code-repeat/-by-/',
    validateOrgInfoUsedUrl: '/api/fsmc/org-info/validate-org-info-used-by-org-struct/-by-/',
    queryExpandFieldListInfoUrl: '/api/fsmc/org-info-ettr/query-expand-field-list',

    //组织拓展字段
    queryOrgInfoEttrListByPageUrl:'/api/fsmc/org-info-ettr/query-org-info-ettr-by-page',
    saveOrgInfoEttr:'/api/fsmc/org-info-ettr/org-info-ettr-add',
    updateOrgInfoEttr:'/api/fsmc/org-info-ettr/org-info-ettr-update',
    deleteOrgInfoEttr:'/api/fsmc/org-info-ettr/org-info-ettr-delete',
    queryOrgInfoEttrByIdUrl:'/api/fsmc/org-info-ettr/query-org-info-ettr/-by-/',
    enableOrgInfoEttr:'/api/fsmc/org-info-ettr/org-info-ettr-enable',
    disableOrgInfoEttr:'/api/fsmc/org-info-ettr/org-info-ettr-disable',

    //部门信息
    insertDepartmentInfoUrl:'/api/fsmc/org-department-relation/org-department-relation-add', // 组织部门关联信息新增
    queryDepartmentInfoPageListUrl:'/api/fsmc/department-info/query-department-info-by-page', // 部门分页列表查询
    queryDepartmentInfoListUrl: '/api/fsmc/department-info/query-department-info-list', // 部门列表查询
    deleteOrgDepartmentUrl:'/api/fsmc/department-info/department-info-delete', // 删除组织下的所有部门
    updateDepartmentInfoUrl:'/api/fsmc/org-department-relation/org-department-relation-update', // 组织部门关联信息更新
}
