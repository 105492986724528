<template>
    <!-- 开户支行放大镜 -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="bankName"
        filterKey="code"
        :title="$t('i18n.common.components.BankMagnifierSingle.title')"
        :placeholder="placeholder || $t('i18n.common.components.BankMagnifierSingle.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        v-on="listeners"
        :autoSelect="autoSelect">
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.BankMagnifierSingle.name')" prop="name">
                <f-input v-model="searchForm.name"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.BankMagnifierSingle.code')" prop="code">
                <f-input v-model="searchForm.code"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 银行名称 -->
            <f-magnifier-column prop="bankName" :label="$t('i18n.common.components.BankMagnifierSingle.bankName')"></f-magnifier-column>
            <!-- 直连编号 -->
            <f-magnifier-column prop="directCode" :label="$t('i18n.common.components.BankMagnifierSingle.directCode')"></f-magnifier-column>
            <!-- 支行号 -->
            <f-magnifier-column prop="cnapsCode" :label="$t('i18n.common.components.BankMagnifierSingle.cnapsCode')"></f-magnifier-column>
        </template>
    </f-magnifier-single>
</template>

<script>
import { dictionaryMix } from '@/common/comMixin';
import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
/**
 * 单位放大镜-单选
 */
    export default {
        name: 'BankMagnifierSingle',
        mixins: [magnifierSingleMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    name: '', // 银行名称
                    code: '' // 银行编号
                }
            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        name: 'name',
                        code: 'code',
                        bankName: 'bankName',
                        cnapsCode: 'cnapsCode',
                        directCode: 'directCode'
                    }
                }
            },

        },
        async created() {
        }
    }
</script>

<style lang="scss" scoped>

</style>
