import { styleMix } from '@/common/comMixin';

export default {
    mixins: [styleMix],
    data() {
        return {
            /**
             * 前后端交互的字段名
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * 放大镜默认隐藏
             */
            visible: false,
            /**
             * 放大镜查询条件
             */
            searchForm: {},
            /**
             * 存放表格数据
             */
            tableData: {
                data: [],
                total: 0
            },
            /**
             * 每页显示条数可配
             */
            pageSizes: [10, 20, 50, 100],
            /**
             * 分页信息可配
             */
            page: {
                currentPage: 1,
                pageSize: 10
            },
            selectedTableHeight: '334px',
            selection: [] // 右侧选中数据绑定值
        };
    },
    props: {
        /**
         * 放大镜弹窗title
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * 表格数据的查询地址
         */
        dataSource: {
            type: String
        },
        /**
         * 额外的查询参数配置
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        pagination: {
            type: Boolean,
            default: true
        },
        /**
         * 放大镜用来指定数据的key
         */
        valueKey: {
            type: String,
            required: true
        },
        /**
         * 放大镜用来指定数据的显示文本
         */
        labelKey: {
            type: String,
            required: true
        },
        /**
         * 是否在弹窗关闭的时候重置查询表单中的内容
         */
        isCloseResetSearchForm: {
            type: Boolean,
            default: true
        },
        searchRules:{
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        getTableData() {
            if (!this.dataSource) {
                console.log('缺少请求的url,请配置dataSource属性!');
                return;
            }
            let param = Object.assign({}, this.extraData, this.searchForm);
            if (!(this.pagination === false)) {
                param = Object.assign(param, {
                    [this.keyNames.currentPage]: this.page.currentPage,
                    [this.keyNames.pageSize]: this.page.pageSize
                });
            }
            //console.log(param);
            this.$http.post(this.dataSource, param).then(res => {
                if (this.pagination === false) {
                    this.tableData.data = res.data;
                } else {
                    this.tableData.data = res.data[this.keyNames.rows];
                    this.tableData.total = res.data[this.keyNames.total];
                }

            });
        },
        handleQuery() {
            // 加载表格数据
            this.page.currentPage = 1;
            this.getTableData();
        },
        handlePageChange() {
            // 加载表格数据
            this.getTableData();
        },
        /**
         * 单击行事件
         */
        handleRowClick(row, column, event) {
            this.$emit('row-click', row, column, event);
        },
        /**
         * 表格行双击事件
         */
        handleRowDblclick(row) {
            this.toggleRowSelection(row);
        },
        /**
         * 当前高亮行状态enter事件
         */
        currentRowEnter(row) {
            this.toggleRowSelection(row);
        },
        handleSelect(selection, row) {
            this.$emit('select', selection, row);
        },
        handleSelectAll(selection) {
            this.$emit('select-all', selection);
        },
        toggleRowSelection(row, selected) {
            this.$refs['table'].$refs['table'].toggleRowSelection(row, selected);
        },
        toggleRowsSelection(rows, selected) {
            rows.forEach(row => {
                this.toggleRowSelection(row, selected);
            });
        },
        handleSelectionChange(rows) {
            this.selection = rows;
        },
        handleRemoveSelection({ row, $index }) {
            this.selection.splice($index, 1);
            let storeSelection = this.$refs['table'].$refs['table'].store.states.selection;
            for (let i = 0; i < storeSelection.length; i++) {
                if (storeSelection[i][this.valueKey] === row[this.valueKey]) {
                    this.$refs['table'].$refs['table'].store.states.selection.splice(i, 1);
                    break;
                }
            }
        },
        handleClearSelection() {
            this.$refs.table.clearSelection();
        },
        clearSelection() {
            if (this.$refs.table) {
                this.$refs.table.clearSelection();
            } else {
                this.$nextTick(() => {
                    this.$refs.table && this.$refs.table.clearSelection();
                });
            }
        },
        confirm() {
            this.$emit('confirm', JSON.parse(JSON.stringify(this.selection)));
        },
        cancel() {
            this.visible = false;
        },
        open() {
            this.page.currentPage = 1;
            this.page.pageSize = 10;
            this.visible = true;
            this.$nextTick(() => {
                this.$emit('beforeGetData')
                this.getTableData();
            });
            this.$emit('open');
        },
        close() {
            this.visible = false;
        },
        handleClose() {
            if (this.isCloseResetSearchForm && this.$refs.searchForm) {
                this.$refs.searchForm.reset()
            }
        }
    }
};
