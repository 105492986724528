/**
 * 将有层级关系的list数组转化成树结构的json
 * data 要转换的list数据
 * key: {string}本级唯一id名 parentkey: {string} 父级id名
 */
function listToTreeJson(data, key, parentKey) {
    var id = key || 'id';
    var parentId = parentKey || 'parentId';
    var _arr = [];
    var _obj = {};
    var _noParent = [];
    data.forEach(function(item, i) {
        // 给每item都加上children数组,如果已经有childern了则继承原来的children
        item['children'] = item['children'] || [];

        _obj[item[id]] = item; // 将原数组存入有key的对象

        if (!item[parentId] || item[parentId] == 0) {
            // 当parentId不存在或者为空时,则此级为一级
            _arr.push(item);
        } else if (_obj[item[parentId]]) {
            // 当存在parentId即此级为非一级, 且此级的父级已经存入_obj中时, 则向_obj中的该级的父级childern中加入此项
            _obj[item[parentId]].children.push(item);
        } else {
            // 当存在parentId即此级为非一级, 且在_obj中未找到其父级是, 则将其存入_noParent数组中
            _noParent.push(item);
        }
    });
    if (_noParent.length > 0) {
        _noParent.forEach(function(item, i) {
            if (_obj[item[parentId]]) {
                _obj[item[parentId]].children.push(item);
            } else {
                _arr.push(item);
            }
        });
    }
    return _arr;
}
function allMenuTreeData(data) {
    let searchMenuData = [];
    let allMenuTreeData = [];
    data.forEach(item => {
        let children = [];
        item.children.sort((a,b)=>{
            if(a.funcOrder == b.funcOrder || !a.funcOrder || !b.funcOrder){
                return a.id - b.id
            }else{
                return a.funcOrder-b.funcOrder
            }
        })
        item.children.forEach(item1 => {
            item1.children.sort((a,b)=> {
                if(a.funcOrder == b.funcOrder || !a.funcOrder || !b.funcOrder){
                    return a.id - b.id
                }else{
                    return a.funcOrder-b.funcOrder
                }
            })
            item1.children.forEach(item2 => {
                children.push(item2);
                item2.firstFatherFunctionId = item.id;
                item2.firstFatherFunctionName = item.functionName;
                searchMenuData.push(item2);
            });

        });
        item = JSON.parse(JSON.stringify(item));
        item.children = children;
        allMenuTreeData.push(item);

    });
    return {
        allMenuTreeData: allMenuTreeData,
        searchMenuData: searchMenuData
    };
}

function getBreadCrumb(menuData, id) {
    let result = [];
    let currentItem = menuData.find(item => item.id === id),
        currentFatherId = (currentItem || {}).fatherFunctionId;
    currentItem && result.unshift(currentItem);
    while (currentFatherId && currentItem) {
        currentItem = menuData.find(item => item.id === currentFatherId);
        currentItem && result.unshift(currentItem);
        currentFatherId = (currentItem || {}).fatherFunctionId;
    }
    return result;
}
const  pattern = /((http(s)?:\/\/)|(www.))([^\/]+)\//;

export default {
    namespaced: true,
    state: {
        menuData: [], // 左侧所有菜单
        menuTreeData: [], // 左侧所有菜单树型结构
        allMenuTreeData: [], // 头部所有菜单(一级菜单与三级菜单的树型结构)
        oftenMenuData: [],
        historySearch: [], // 历史检索菜单数据
        searchMenuData: [], // 所有菜单搜索下拉数据
        currentTabs: [],
        userInfo:{},
        navColor: localStorage.getItem("nav_color") ? localStorage.getItem("nav_color") : 'light',
        activeFirstMenuId: "",
    },
    getters: {
        getMenuData: state => state.menuData || [], // 菜单数据
        getOftenMenuData: state => state.oftenMenuData || [],
        getHistorySearch: state => {
            let array = [];
            if(state.historySearch){
                state.historySearch.forEach(value => {
                    state.allMenuTreeData.forEach(item =>{
                        item.children.forEach(child => {
                            if(child.id == value){
                                array.push(child);
                            }
                        })
                    })
                })
            }
            return array;
        },
        getMenuTreeData: state => state.menuTreeData || [],
        getAllMenuTreeData: state => state.allMenuTreeData || [],
        getSearchMenuData: state => state.searchMenuData || [],
        getBreadCrumb: state => id => getBreadCrumb(state.menuData, id),
        getCurrentBreadCrumnb: state => state.currentTabs,
        getUserInfo: state => state.userInfo,
        getNavColor: state => state.navColor,
        getActiveFirstMenuId: state => state.activeFirstMenuId,
    },
    mutations: {
        setMenuData(state, value) {
            value.forEach(item =>{
                let url = item.funcUrl;
                if(url)item.funcUrl = url.replace(pattern,'');
            })
            state.menuData = value;
            this.commit('user/setMenuTreeData', value);
        },
        setMenuTreeData(state, value) {
            let treeData = listToTreeJson(value, 'id', 'fatherFunctionId');
            state.menuTreeData = treeData;
            this.commit('user/setAllMenuTreeData', treeData);
        },
        /**
         * 左侧3级菜单的常用菜单toggle处理
         */
        toggleMenuOfen(state, obj) {
            state.menuTreeData[obj.firstLevelIndex].children[obj.secondLevelIndex].children[
                obj.lastLevelIndex
            ].ofenMenu = !obj.item.ofenMenu;
        },
        setAllMenuTreeData(state, value) {
            let treeData = allMenuTreeData(value);
            state.allMenuTreeData = treeData.allMenuTreeData;
            this.commit('user/setSearchMenuData', treeData.searchMenuData);
        },
        /**
         * 全部菜单总的常用菜单toggle处理
         */
        toggleAllMenuOfen(state, obj) {
            state.allMenuTreeData[obj.parentIndex].children[obj.index].ofenMenu = !obj.item.ofenMenu;
        },
        /**
         * 移除左侧菜单 + 全部菜单中的某个常用菜单
         */
        removeAllMenuOfen(state, item) {
            state.menuData.some((items, index) => {
                if (items.id === item.id) {
                    items.ofenMenu = false;
                    return true;
                }
            });
        },
        setSearchMenuData(state, value) {
            state.searchMenuData = value;
        },
        setOftenMenuData(state, value) {
            state.oftenMenuData = value || [];
        },
        /**
         * 常用菜单--添加常用菜单
         */
        addOfenMenu(state, item) {
            if (item) {
                state.oftenMenuData.push(item);
            }
        },
        /**
         * 常用菜单--移除常用菜单
         */
        removeOfenMenu(state, item) {
            let i = null;
            state.oftenMenuData.some((items, index) => {
                if (items.id === item.id) {
                    i = index;
                    return true;
                }
            });
            if (i !== null) {
                state.oftenMenuData.splice(i, 1);
            }
        },
        /**
         * 常用菜单--清空常用菜单+全部菜单中的常用菜单+左侧菜单中的常用菜单
         */
        clearOfenMenu(state) {
            state.oftenMenuData = [];
            state.menuData.forEach(item => {
                item.ofenMenu = false;
            });
        },
        setHistorySearch(state, items) {
            state.historySearch = items;
        },
        addHistorySearch(state, item) {
            //将历史搜索添加到第一个
            state.historySearch.unshift(item.id);
            let length = state.historySearch.length;
            if (length > 6) {
                //仅存6条历史记录
                //将历史搜索删除最后一条
                delete state.historySearch[state.historySearch.length - 1];
            }
            localStorage.setItem('historySearch', state.historySearch.toString());
        },
        setCurrentBreadCrumnb(state, payload) {
            state.currentTabs = [...getBreadCrumb(state.menuData, payload.value)];
        },
        setUserInfo(state, value){
            state.userInfo = value;
        },
        setNavColor(state, value){
            state.navColor = value;
        },
        setActiveFirstMenuId(state, value) {
            state.activeFirstMenuId = value;
        }

    }
};
