export default {

    //系统语言
    queryLangByPageUrl:'/api/fsmc/system/lang/query-lang-by-page', // 系统语言 - 分页列表
    queryLangByIdUrl:'/api/fsmc/system/lang/query-lang/-by-/', // 系统语言 - 查询单个对象
    addLangUrl:'/api/fsmc/system/lang/add-lang', // 系统语言 - 新增
    updateLangUrl:'/api/fsmc/system/lang/update-lang', // 系统语言 - 更新
    queryLongNameValueListUrl:'/api/fsmc/system/lang/query-lang-name-list', // 系统语言 - 标准语种--下拉框常量列表

    // 模块注册
    queryModuleRegByPageUrl:'/api/fsmc/system/module-register/query-module-register-by-page', // 模块注册 - 分页列表
    deleteModuleRegisterByIdUrl:'/api/fsmc/system/module-register/delete-module-register', // 模块注册 - 删除模块注册信息
    addModuleRegisterUrl:'/api/fsmc/system/module-register/add-module-register', // 模块注册 - 新增模块注册信息
    queryModuleRegisterByIdUrl:'/api/fsmc/system/module-register/query-module-register/-by-/', // 模块注册 - 查询单个对象
    updateModuleRegUrl:'/api/fsmc/system/module-register/update-module-register', // 模块注册 - 更新
    getModuleComboListUrl:'/api/fsmc/system/module-register/get-module-combo-list', // 模块注册 - 模块下拉框列表
    updateRealmUrl:'/api/fsmc/system/module-register/batch-update-module-register', // 模块注册 - 模块下拉框列表

    // 密码规则设置
    passwordRulesUrl:'/api/fsmc/auth/password-rule/query-password-rule', // 密码规则设置
    updatePasswordRuleUrl:'/api/fsmc/auth/password-rule/update-password-rule', // 密码规则设置-更新密码规则

    // 数据字典
    queryDictionaryByPageUrl:'/api/fsmc/system/dictionary/query-dictionary-by-page', // 数据字典 - 分页列表
    queryDictionaryByIdUrl:'/api/fsmc/system/dictionary/query-dictionary/-by-/', // 数据字典 - 根据id查询单个对象
    addDictionaryUrl:'/api/fsmc/system/dictionary/add-dictionary', // 数据字典 - 新增
    updateDictionaryUrl:'/api/fsmc/system/dictionary/update-dictionary', // 数据字典 - 更新
    deleteDictionaryUrl:'/api/fsmc/system/dictionary/delete-dictionary', // 数据字典 - 删除
    queryDictionaryValueByIdUrl:'/api/fsmc/system/dictionary/query-dictionary-value/-by-/', // 数据字典 - 查询单个子单对象
    queryDictionaryValueByPageUrl:'/api/fsmc/system/dictionary/query-dictionary-value-by-page', // 数据字典 - 子单列表分页查询
    addDictionaryValueUrl:'/api/fsmc/system/dictionary/add-dictionary-value', // 数据字典 - 新增子单
    updateDictionaryValueUrl:'/api/fsmc/system/dictionary/update-dictionary-value', // 数据字典 - 更新子单
    deleteDictionaryValueUrl:'/api/fsmc/system/dictionary/delete-dictionary-value', // 数据字典 - 删除子单

    // 业务类型设置
    queryBusinessTypeSetByPageUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/query-business-type-set-by-page', // 业务类型设置 - 分页列表
    queryBusinessTypeSetByIdUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/query-business-type-set/-by-/', // 业务类型设置 - 查询单个对象
    addBusinessTypeSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/business-type-set-add', // 业务类型设置 - 新增
    updateBusinessTypeSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/business-type-set-update', // 业务类型设置 - 更新
    deleteBusinessTypeSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/business-type-set-delete', // 业务类型设置 - 删除
    enableBusinessTypeSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/business-type-set-enable', // 业务类型设置 - 启用
    disableBusinessTypeSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/business-type-set-disable', // 业务类型设置 - 停用
    queryPaymentTypePageSetUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/query-payment-type-by-page', // 业务类型设置 - 查询明细列表
    getRuleCodeUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/get/rule-code', // 业务类型设置 - 获取编码


    // 工作日历
    queryWorkdaySetByPageUrl:'/api/fsmc/workday/workday-set/query-workday-set-by-page', // 工作日历 - 分页列表
    addWorkdaySetUrl:'/api/fsmc/workday/workday-set/workday-set-add', // 工作日历 - 新增
    queryWorkdaySetUrl:'/api/fsmc/workday/workday-set/query-workday-set', // 工作日历 - 查询节假日信息
    addOrUpdateWorkdaySetUrl:'/api/fsmc/workday/workday-set/add_update_workday_set', // 工作日历 - 设置节假日信息

    // 附件类型设置
    queryAttachmentTypeSetByPageUrl:'/api/fsmc/attach-type-set/query-attach-type-set-by-page', // 附件类型设置-列表分页查询
    queryAttachmentTypeSetByIdUrl:'/api/fsmc/attach-type-set/query-attach-type-set/-by-/', // 附件类型设置-查询单个对象
    addAttachmentTypeSetUrl:'/api/fsmc/attach-type-set/attach-type-set-add', // 附件类型设置-新增
    updateAttachmentTypeSetUrl:'/api/fsmc/attach-type-set/attach-type-set-update', // 附件类型设置-修改.
    deleteAttachmentTypeSetUrl:'/api/fsmc/attach-type-set/attach-type-set-delete', // 附件类型设置-删除
    enableAttachmentTypeSetUrl:'/api/fsmc/attach-type-set/attach-type-set-start', // 附件类型设置-启用
    disableAttachmentTypeSetUrl:'/api/fsmc/attach-type-set/attach-type-set-stop', // 附件类型设置-停用
    queryBankListUrl:'', // 附件类型设置-查询银行大类列表
    queryAttachmentTypeListUrl:'', // 附件类型设置-查询附件类型列表
    attachmentTypeSetGetCodeUrl:'/api/fsmc/attach-type-set/get/rule-code', // 附件类型设置-获取编码
    queryBusinessTypeUrl:'/api/fsmc/attach-type-set/query-business-type', // 附件类型设置-业务类型下拉框
    queryAttachBusinessTypeUrl:'/api/fsmc/attach-type-set/query-attach-business-type', // 附件类型设置-业务类型下拉框
    queryAttachModuleCodeUrl:'/api/fsmc/attach-type-set/query-attach-module-code', // 附件类型设置-模块名称下拉框

    queryEnterFinanceOrgByPageUrl:'/api/fsmc/finance-org/query-enter-finance-org-by-page', //

    //系统参数设置
    querySysParamSettingList:'/api/fsmc/sys-param-setting/query-sys-param-setting-list', // 系统参数设置-列表
    updateSysParamSettingList:'/api/fsmc/sys-param-setting/update-sys-param-setting-list', // 系统参数设置-修改


}
