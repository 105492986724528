<template>
    <f-dialog
      :title="$t('i18n.common.components.comEditForm.operateTip')"
      :visible.sync="visible"
      :fullscreen="fullscreen"
      :close-on-click-modal="!isEdit"
      custom-class="com-operation-guide"
    >
        <span slot="title">
            <span>{{ $t('i18n.common.components.comEditForm.operateTip') }}</span>
            <button type="button" class="el-dialog__headerbtn el-dialog__fullscreen" @click="fullscreen = !fullscreen">
                <i class="el-icon el-icon-full-screen"></i>
            </button>
        </span>
        <div :class="['body-content', fullscreen ? 'full-screen' : '']" ref="bodyContent">
            <div class="content" v-if="!isEdit" v-html="form.descriptionContent"></div>
            <f-upload
                style="display: none"
                class="quillEditerClass"
                :action="action"
                :data="data"
                :on-success="handleSuccess"
                :http-request="httpRequest"
                list-type="picture-card"
                ref="upload"
            >
                <i class="el-icon-plus"></i>
            </f-upload>
            <quill-editor
                v-if="isEdit"
                v-model="form.descriptionContent"
                :options="editorOption"
                ref="myQuillEditor"
                class="com-operation-editor"
                :style="{height: editorHeight}">
            </quill-editor>

        </div>
        <span slot="footer">
            <div class="my-upload">
                <f-upload multiple ref="upload" :action="action"
                          :data="fileData"
                          :on-success="handleFileSuccess"
                          :http-request="httpRequest"
                          :on-preview="handlePreview"
                          :on-error="testError"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          :file-list="fileList">
                    <el-button size="small" type="primary" style="display: none">{{$t('i18n.common.components.uploadTemplate.uploadClickText')}}</el-button>
                </f-upload>
            </div>
            <f-button plain :size="mixSize" v-show="isEdit" @click="cancel">{{$t('i18n.common.cancelText')}}</f-button>
            <f-button type="primary" :size="mixSize" v-show="isEdit" @click="saveData">{{$t('i18n.common.saveText')}}</f-button>
            <f-button type="primary" :size="mixSize" v-show="userInfo && userInfo.userType == 1 && !isEdit" @click="edit">{{$t('i18n.common.editText')}}</f-button>
        </span>
    </f-dialog>
</template>

<script>
import { styleMix } from '@/common/comMixin';
import { addResizeListener, removeResizeListener } from '@iss/vmui-vue/lib/utils/resize-event';
import storageUtils from '@/common/utils/storageUtils';

    export default {
        name: 'ComOperationGuide',
        mixins: [styleMix],
        data() {
            return {
                visible: false,
                editorHeight: '0px',
                form: {
                    id: '',
                    funcId: '',
                    descriptionContent: ''
                },
                isEdit: false,
                fullscreen: false, // 是否全屏显示
                fileList:[],
                data:{
                    orderNo:'',
                    systemCode:11
                },
                fileData:{
                    orderNo:'',
                    systemCode:11
                },
                action:this.$api.common.comUpload.upload,
                editorOption: {
                    placeholder: this.$t('i18n.common.placeholder'),
                    modules: {
                        toolbar: {
                            container:  [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['clean'],
                                ['link', 'image', 'video']
                            ],
                            handlers: {
                                image:  (value) => {
                                    if (value) {
                                        document
                                            .querySelector(".quillEditerClass .el-icon-plus")
                                            .click();

                                    } else {
                                        this.quill.format("image", false);
                                    }
                                },
                            },
                        },
                    },
                },
            }
        },
        watch: {
            visible: {
                handler(newValue, oldValue) {
                    if(newValue && !this.isResizeListener) {
                        this.$nextTick(() => {
                            addResizeListener(this.$refs.bodyContent, this.resizeEditorHeight);
                            this.isResizeListener = true
                        });
                    }
                }
            }
        },
        computed: {
            userInfo: {
                get() {
                    return JSON.parse(storageUtils.getItem('userInfo')).loginUserInfo;
                }
            },
        },
        created () {
            this.$bus.$on('operationGuideOpen', this.operationGuideOpen);
        },
        destroyed() {
            this.$bus.$off('operationGuideOpen');
            removeResizeListener(this.$refs.bodyContent)
        },
        methods: {
            resizeEditorHeight() {
                let bodyContentHeight = this.$refs.bodyContent.clientHeight;
                let editorHeight = this.fullscreen ? `${bodyContentHeight-60}px` : `${bodyContentHeight-80}px`
                this.editorHeight = editorHeight;
            },
            operationGuideOpen(param) {
                this.form.id = '';
                this.form.funcId = param;
                this.data.orderNo = param;
                this.fileData.orderNo = 'FILE'+param;
                this.form.descriptionContent = '';
                this.isEdit = false;
                this.visible = true;
                this.$nextTick(() => {
                    this.getData(param);
                })
            },
            /**
             * 打开放大镜的时候去查询操作指引的值
             * @param funcId {string} 页面编号
             */
            getData(funcId = '') {
                this.isEdit = false;
                this.$http.get(this.$api.common.getOperationGuide + funcId).then(res => {
                    if(res.code == 0 && res.data) {
                        Object.assign(this.form, res.data)
                    }
                })
                this.$http.post(this.$api.common.comUpload.query, this.fileData).then(res => {
                    console.log('res,datatasdasdada',res)
                    if(res.code === 0 && res.data.length >= 1){
                        this.fileList = res.data.map(ret =>{
                            return {name:ret.originalFileName,id:ret.id}
                        });
                        console.log('kussss',list);
                    }

                });
            },
            edit() {
                this.isEdit = true;
                this.$nextTick(()=>{
                    let quill = this.$refs.myQuillEditor.quill;
                    //粘贴图片
                    quill.root.addEventListener('paste', evt => {

                        if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {

                            evt.preventDefault();
                            [].forEach.call(evt.clipboardData.files, file => {

                                if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                                    return
                                }
                                this.$http.upload({data:this.data,action:this.action,filename:'file',file:file,onSuccess:(res)=>{
                                       this.handleSuccess(res);
                                    },onError:(res)=>{
                                        this.$error(res.msg);
                                    },onProgress(){}})

                            })
                        }
                    }, false)

                    //附件上传
                    const toolbar = document.querySelector('.ql-toolbar');
                    toolbar.title = '上传附件';
                    let formats = document.createElement('span');
                    formats.classList.add('ql-formats');
                    toolbar.appendChild(formats);
                    //设置inputContainer节点与fileInput节点的style是为了隐藏input节点，实现点击图标上传文件
                    let inputContainer = document.createElement('span');
                    inputContainer.style = 'position: relative;display: inline-block;overflow: hidden;top: 4px;font-size: 16px;left:-8px;cursor:pointer';
                    //创建i节点，添加class,设置图标
                    let fileIcon = document.createElement('i');
                    fileIcon.classList.add('el-icon-paperclip');
                    //创建input节点并设置相关属性

                    fileIcon.addEventListener('click', async () => {
                        document
                            .querySelector(".my-upload button")
                            .click();
                    }, false);
                    formats.appendChild(inputContainer);
                    inputContainer.appendChild(fileIcon);
                })

            },
            saveData() {
                this.$http.update(this.$api.common.saveOperationGuide, this.form).then(res => {
                    if(res.code == 0) {
                        this.isEdit = false;
                        this.$success(this.$t('i18n.common.success'))
                    } else {
                        this.$error(this.$t('i18n.common.error'))
                    }
                })
            },
            cancel() {
                this.isEdit = false;
            },
            httpRequest(options) {
                this.$http.upload(options);
            },
            handleSuccess(response,file){
                if(response.code === 0){
                    let quill = this.$refs.myQuillEditor.quill;
                    // 获取光标所在位置
                    let length = quill.getSelection().index;
                    let url = `${this.$api.common.comUpload.viewFile}${response.data.id}`
                    // 插入图片，res为服务器返回的图片链接地址
                    quill.insertEmbed(
                        length,
                        "image",
                        `${url}`
                    );
                    // 调整光标到最后
                    quill.setSelection(length + 1);
                }
            },
            handleFileSuccess(response,file){
                console.log('handleFileSuccess',response)
                if(response.code === 0){
                    let quill = this.$refs.myQuillEditor.quill;
                    // 获取光标所在位置
                    let length = quill.getSelection().index;
                    let url = `${this.$api.common.comUpload.viewFile}${response.data.id}`
                    // 插入图片，res为服务器返回的图片链接地址
                    quill.insertEmbed(
                        length,
                        "image",
                        `${url}`
                    );
                    // 调整光标到最后
                    quill.setSelection(length + 1);
                }
            },

            handleRemove (file, fileList) {
                if(file.response && file.response.code === 0){
                    this.$http.post(this.$api.common.comUpload.delete, { id: file.response.data.id })
                }
                if(file.id){
                    this.$http.post(this.$api.common.comUpload.delete, { id: file.id })
                }
            },
            beforeRemove(file, fileList){
                if(!this.isEdit){
                    return false;
                }
            },
            handlePreview(file){
                if(file.id && !this.isEdit){
                    this.$http.export(this.$api.common.comUpload.download, {  id: file.id });
                }
            },
            testError(ee){
                console.log(ee);
            }
        },
    }
</script>

<style lang="scss" scoped>
/deep/ .com-operation-guide{
    .el-dialog__fullscreen{
        right: 40px;
    }
    .el-dialog__body{
        padding: 0 0 1px 0;
    }
    .body-content{
        padding: 0 20px;
        margin-bottom: 80px;
        height: 300px;
        overflow: auto;
        &.full-screen{
            margin-bottom: 0px;
            position: absolute;
            top: 54px;
            left: 0;
            right: 0;
            bottom: 80px;
            height: auto;
            overflow: auto;
        }
    }
    .el-dialog__footer{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.my-upload{
    bottom: 0px;
    position: absolute;
    width: 78%;
    /deep/ ul li{
        width: 150px;
        float: left;
        margin: 9px;
        height: 33px;
    }
}

</style>
