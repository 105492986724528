export default {
    queryCapitalInAndOutflowListUrl: '/api/acct/workbench/query/capital-in-and-outflow/list', // 资金流入流出列表
    queryBalanceListByBankUrl: '/api/acct/workbench/query/balance-by-bank/list', // 实时余额
    //queryBalanceListByBankUrl: '/api/acct/workbench/query/balance-by-bank/list', // 根据银行统计查询余额
    queryRateByPageUrl: '/api/fsmc/api/fsmcRate/rate/query-rate-by-page', // 利率分页列表
    queryExchangeRateByPageUrl: '/api/fsmc/data/exchangeRate/query-current-exchange-rate-by-page', // 汇率率分页列表
    queryBigDealByPageUrl: '/api/acct/workbench/query/big-deal/page', // 大额交易分页列表
    queryRemindInfoByPageUrl: '/api/fsmc/workbench/query/remind-info/page', // 提醒信息列表
    deleteRemindInfoUrl: '/api/fsmc/workbench/cancel/remind-info', // 提醒数据删除
    payProcessTracking: '/api/fsmc/workbench/pay/process-tracking', // 付款流程跟踪
    monthBalanceSummary: '/api/fsmc/workbench/month/balance-summary', // 月度余额汇总
    bankAccountOpening: '/api/acct/query/acct/query/account-groupby-bank/list', // 银行账户开户情况
    capitalStockTable: '/api/fsmc/workbench/capital/stock-table', // 资金存量表
    earlyWarningSummary: '/api/fsmc/workbench/early/warning-summary', // 预警监控汇总
    collectionAndPayment: '/api/fsmc/workbench/amount/collention-payment', // 收付款
    exampleTable: '/api/fsmc/workbench/example/table', // 例子 ->表格
    acctChartOverview: '/api/acct/query/acct/query/month-total-balance/list', //账户总览
    settTurnoverChartUrl: '/api/fsmc/xxxxxxxxxxxx', //收付款交易
    cashFlowTable: '/api/fsmc/workbench/cash/flow-table', //资金流入流出表
    accountCurrencyStatistics: '/api/fsmc/workbench/account/currency-statics', // 账户余额币种统计
    accountOverview: '/api/fsmc/workbench/account/over-view', // 实时账户概况
    basePending: '/api/wkfl/workdesk/task-form/query-wd-to-do-list',
    basePendingCheck: '/api/wkfl/workdesk/task-form/check/ext-biz',
    comUsedFunction: '/api/fsmc/oftenMenu/query-user-often-menu', //常用功能
    balanceByBank: '/cms-bnkp/bnkp/query/statistics-query/query-statistics-balance.json', //实时余额
    acct: {
        dictionaries: '/api/acct/common/query/dictionary'
    },
    waitCommitBench: '/api/fsmc/workbench/wait-commit/query-wait-commit-bench',
    queryWaitCommitListByPageUrl:'/api/fsmc/workbench/wait-commit/query-wait-commit-by-page',
    querytableExpandParamUrl:'/api/fsmc/workbench/wait-commit/query-table-column-config',
    queryModuleRegisterByModuleCodeUrl:'/api/fsmc/system/module-register/query-module-register-by-modeleCode-/',

    //任务驱动提醒
    taskRemindBench: '/api/fsmc/workbench/task-remind/query-task-remind-beach'
};
