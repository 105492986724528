/**
 * 模块请求地址统一管理
 * 注意: 导出对象中的key对应的value值只能是字符串, 不能再嵌套对象
 */
export default {
    common:{
        //表单公共部分
        formItemOrder: 'api/formItemOrder.json', // 公共的查询表单--查询表单项顺序
        formItemOrderSave: 'api/formItemOrderSave.json', // 公共的查询表单--表单项顺序设置保存
        queryFormList: 'api/common/queryFormList.json', // 公共的查询表单--查询已保存查询条件列表
        queryFormSave: 'api/common/queryFormSave.json', // 公共的查询表单--查询条件保存
        queryFormDel: 'api/common/queryFormDel.json', // 公共的查询表单--查询条件删除

        //当前位置公共请求
        breadCrumb:'api/common/breadCrumb',//当前位置公共请求
        //表格公共请求
        tableColumnsSave: 'api/common/tableColumnsSave.json', // 公共的查询列表--列表列配置保存
        tableColumns: 'api/common/tableColumns.json', // 公共的查询列表--列表列配置查询
    },
    tableListUrl:'api/md/tableListUrl.json', // 列表数据
    tableExportUrl:'api/md/tableExportUrl.json', // 列表数据导出地址

    componentsSelectSingle:'api/doc/md/componentsSelectSingle',  //账户搜索下拉框
    orgSelectSingle:'api/doc/md/orgSelectSingle',  //单位搜索下拉框
    officeSelectSingle:'api/doc/md/officeSelectSingle',  //所属金融机构（大行）下拉框
    countrySelectSingle:'api/doc/md/countrySelectSingle',  //开户区域国家搜索下拉框
    userSelectSingle:'api/doc/md/userSelectSingle',  //开户区域国家搜索下拉框

    orgMagnifierSingle: 'api/doc/md/orgMagnifierSingle',   //单位放大镜
    acctMagnifierSingle: 'api/doc/md/acctMagnifierSingle',  //账户放大镜
    bankMagnifierSingle: 'api/doc/md/bankMagnifierSingle',  //开户支行放大镜
    officeMagnifierSingle: 'api/doc/md/officeMagnifierSingle',  //所属金融机构（大行）放大镜
    customerMagnifierSingle: 'api/doc/md/officeMagnifierSingle',  //客商信息放大镜
    userMagnifierSingle: 'api/doc/md/userMagnifierSingle',  //用户信息放大镜
    statementMagnifierSingle: 'api/doc/md/statementMagnifierSingle',  //金融机构业务组件
    cooperativeSelect: 'api/doc/md/cooperativeSelect',  //机构类型下拉组件

    comSelectScrollOne: 'api/doc/md/comSelectScrollOne',
    comSelectScrollTwo: 'api/doc/md/comSelectScrollTwo',
    magnifier: 'api/doc/md/magnifier',
    queryTable: 'api/doc/md/queryTable',



};
