import ComEditTable from './src/com-edit-table';
import EditLinkage from './src/edit-linkage/edit-linkage';
import EditDialog from './src/edit-dialog/edit-dialog.vue';
/* istanbul ignore next */
ComEditTable.install = function(Vue) {
     Vue.component(EditLinkage.name, EditLinkage)
     Vue.component(EditDialog.name, EditDialog);
};

export default ComEditTable;
