<template>
    <f-date-picker
        ref="fpick"
        type="daterange"
        :range-separator="$t('i18n.common.separator')"
        :start-placeholder="$t('i18n.common.startDate')"
        :end-placeholder="$t('i18n.common.endDate')"
        value-format="yyyy-MM-dd"
        :shortcuts="shortcuts"
        :popperClass="_popperClass"
        unlink-panels
        :onPick="onPick" v-bind="$attrs" v-on="$listeners"
        @fieldReset="handleFieldReset">
    </f-date-picker>
</template>

<script>
export default {
    name: 'ComDateRange',
    data() {
        Date.prototype.format = function (fmt) { //author: meizz
            var o = {
                "M+": this.getMonth() + 1, //月份
                "d+": this.getDate(), //日
                "h+": this.getHours(), //小时
                "m+": this.getMinutes(), //分
                "s+": this.getSeconds(), //秒
                "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                "S": this.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        };
        let superThis = this;
        return {
            initialValue: {},
            pickName:'',
            _popperClass:'',
            shortcuts: [
                {
                    text: this.$t('i18n.common.pickToday'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$today'));
                    }
                },
                {
                    text: this.$t('i18n.common.pickWeek'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$week'));
                    }
                },
                {
                    text: this.$t('i18n.common.pickOneMonth'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$one_month'));
                    }
                },
                {
                    text: this.$t('i18n.common.pickThreeMonth'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$three_month'));
                    }
                },
                {
                    text: this.$t('i18n.common.pickHalfAYear'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$half_a_month'));
                    }
                },
                {
                    text: this.$t('i18n.common.pickOneYear'),
                    onClick(picker) {
                        picker.$emit('pick', superThis.pickerDate('$one_year'));
                    }
                }
            ]
        };
    },
    props: {
        formModel: Object,
        shortKey: String
    },
    created() {
        if('zh-CN' != this.$i18n.locale){
            this._popperClass = 'el-shortcuts';
        }
        this.init();
    },
    methods: {
        init() {
            // 保存初始化的shorKey的值
            this.initialValue[this.shortKey] = this.formModel[this.shortKey];
        },
        /**
         * 日历重置时同时重置shortKey的值
         */
        handleFieldReset() {
            this.formModel[this.shortKey] = this.initialValue[this.shortKey];
        },
        onPick(max,min){
            this.formModel[this.shortKey] = '';
        },

        pickerDate(code) {
            this.formModel[this.shortKey] = code;
            switch (code) {
                case '$today': //今天
                    return today();
                case '$week': //近一周
                    return week();
                case '$one_month': //一个月
                    return month(1);
                case '$three_month': //三个月
                    return month(3);
                case '$half_a_month': //半年
                    return month(6);
                case '$one_year': //一年
                    return month(12);
                default:
                    break;
            }
            function today(){
                const end = new Date();
                const start = new Date();
                return [start.format('yyyy-MM-dd'), end.format('yyyy-MM-dd')];
            }
            function week() {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start.format('yyyy-MM-dd'), end.format('yyyy-MM-dd')];
            }
            function month(num) {
                let day = 30 * num;
                const end = new Date();
                const start = new Date();
                start.setMonth(start.getMonth() - num);
                return [start.format('yyyy-MM-dd'), end.format('yyyy-MM-dd')];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
     /deep/ .el-range__icon {
         display: none;
     }
    /deep/ .el-icon-date{
        display: none;
    }
</style>
