<template>
    <f-select
        ref="select"
        v-model="select"
        v-bind="{ size: 'mini', ...$attrs }"
        v-on="listeners"
        :props="props"
        :data="optionsData"
        :infinite-scroll="infiniteScroll"
        :scroll="scrollOption"
        :placeholder="placeholder"
        :loading="loading"
        :multiple="multiple"
        size="mini"
        @change="handleChange"
        @loadMore="loadMore"
        @reset="resetFormModel"
        @visible-change="visibleChange"
        @clear="handleClear"
        :clearable="clearable"
        :remote-method="remoteFilter"
        :keyNames="keyNamesOption"
        :auto-select="autoSelect"
        reserveKeyword
    >
        <template v-slot:optionSlot="slotProps">
            <slot :item="slotProps.item"></slot>
        </template>
        <slot v-if="!dataUrl"></slot>
    </f-select>
</template>

<script>
/**
 * 计划新增 label-text 属性
 * 1. label-text 会自动新增一条数据
 */

let scrDef = {
    currentPage: 1,
    pageSize: 10,
    height: 200
};

let keyNamesDef = {
    currentPage: 'start',
    pageSize: 'limit',
    total: 'total',
    rows: 'rows'
};

let scrollDef = (option, defs = scrDef) => {
    let keys = Object.keys(option),
        obj = {};
    keys.forEach(key => {
        if (defs[key] != null) {
            obj[option[key]] = defs[key];
        }
    });
    return obj;
};
import { isEmpty } from '@iss/vmui-vue/lib/utils/util';

function duplicate(arr, key) {
    return Object.values(
        (arr || []).reduce((t, v) => {
            return (t[v[key]] = v), t;
        }, [])
    );
}

export default {
    inheritAttrs: false,
    name: 'ComSelectScroll',
    data() {
        // eslint-disable-next-line no-unused-vars
        let { change, loadMore, clear, reset, ...listeners } = this.$listeners;
        return {
            cache: new Map(), // 防止单选，多选滚动下拉无法设置matchKey,  多选单次搜索会出现问题
            scrollOption: {},
            optionsData: null,
            loading: false,
            prevQuery: '',
            query: '',
            listeners: listeners
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value: {
            handler(newValue, oldValue) {
                // 当前只处理单选情况
                if (
                    !this.multiple &&
                    newValue !== '' &&
                    newValue !== undefined &&
                    newValue !== null &&
                    this.labelText !== '' &&
                    this.labelText !== undefined &&
                    this.labelText !== null
                ) {
                    // 当为js改变value的值的, 并且optionsData中没有与之匹配的数据时,需要手动给optionsdata添加一条数据,保证下拉框回显正确的值
                    if (
                        this.optionsData == null ||
                        !this.optionsData.some(item => item[this.props.value] === newValue)
                    ) {
                        this.optionsData = [{ [this.props.label]: this.labelText, [this.props.value]: newValue }];
                    }
                }
            }
        }
    },
    computed: {
        select: {
            get() {
                return this.value;
            },
            set(val) {
                if (this.optionsData && this.optionsData.length > 0) {
                    let obj = this.optionsData.find(item => {
                        let o = item[this.props.value] === val;
                        return o;
                    });
                    if (obj) {
                        this.$emit('update:labelText', obj[this.props.label]);
                    }
                }
                this.$emit('change', val);
            }
        },
        keyNamesOption() {
            return { ...keyNamesDef, ...this.keyNames };
        },
        scrollOp() {
            return { ...scrDef, ...this.scroll };
        },
        copyScrollPro() {
            return { ...scrollDef(this.keyNamesOption, this.scrollOp) };
        },
        dataUrl() {
            return this.dataSource;
        }
    },
    //  可以传入内容
    props: {
        /**
         * 双向绑定值
         */
        value: [String, Number, Array], // v-model
        props: {
            type: Object,
            default: () => {
                return {
                    key: 'value',
                    label: 'text',
                    value: 'value'
                };
            }
        },
        dataSource: String,
        /**
         * 请求发送到后台的数据
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        remoteFilterKey: [Array, String],
        infiniteScroll: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * 前后端交互的字段名
         */
        keyNames: Object,
        scroll: Object,
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        multiple: Boolean,
        formModel: Object,
        matchKey: Object,
        placeholder: String,
        openLoading: {
            type: Boolean,
            default: true
        },
        beforeOpen: Function, // 打开之前的方法 可返回 Promise, Boolean
        labelText: [String, Number, Array],
        autoSelect: {
            type: Boolean,
            default: false
        },
        parseDataFn: Function
    },
    mounted() {
        if (this.labelText) {
            // 单选  multiple
            if (this.multiple) {
                this.optionsData = this.labelText.map((item, index) => {
                    return { [this.props.label]: item, [this.props.value]: this.value[index] };
                });
            } else {
                this.optionsData = [{ [this.props.label]: this.labelText, [this.props.value]: this.value }];
            }
        }
        this.initialData(); // 初始化数据
        this.initialValue();
        !this.openLoading && this.beforeVisible();
    },
    methods: {
        initialValue() {
            Object.defineProperty(this, 'initValue', {
                value: this.formModel ? Object.assign({}, this.formModel) : this.formModel
            });
        },
        // 初始化数据， 当一开始没有数据时，便初始化数据
        // 1. 初始化 v-model 值
        // 2. 初始化 matchKey 值
        initialData() {
            let { value, props, multiple, matchKey, formModel } = this;
            let result = [];
            // 不为空
            if (value == null) return result;

            let valueList = multiple ? value : [value];
            if(matchKey) {
                let matchKeyArr = Object.keys(matchKey);
                matchKeyArr.forEach(key => {
                    let val = matchKey[key];
                    for (let i = 0; i < valueList.length; i++) {
                        result.push({
                            [props.value]: valueList[i],
                            [val]: Array.isArray(formModel[key]) ? formModel[key][i] : formModel[key]
                        });
                    }
                });
            }
            this.optionsData = result;
        },
        init() {
            let { hideInfiniteLoading } = this.$refs.select,
                { currentPage } = this.keyNamesOption;

            this.scrollOption = !Object.keys(this.scrollOption).length
                ? Object.assign(this.scrollOption, { ...this.copyScrollPro })
                : this.scrollOption;
            /** 此处打开就是清空数据 start*/
            this.prevQuery = this.query = '';
            this.scrollOption[currentPage] = 1;
            this.optionsData = [];
            /** end */
            this.infiniteScroll ? this.getSelectData(this.query, hideInfiniteLoading) : this.getSelectData(this.query);
        },
        getSelectData(query, resolve) {
            console.log(this.optionsData, 333);
            let { dataUrl, remoteFilterKey, infiniteScroll } = this,
                isEqual = false,
                { currentPage, total, pageSize, rows } = this.keyNamesOption;

            const isArrayKey = arr => {
                return Array.isArray(arr) && arr.length;
            };
            const isStringKey = str => {
                return typeof str === 'string' && str;
            };
            /**
             * 1. dataSource 为 String 类型 请求数据
             * 2. dataSource 为 Array  类型 显示数据
             */
            if (typeof dataUrl === 'string') {
                this.loading = true;
                let param = Object.assign({}, this.extraData);
                /**
                 * 需要考虑一种情况， 在可以搜索的情况下无限滚动
                 * 判断与上一次值是否相等
                 */
                if (query) {
                    if (isArrayKey(remoteFilterKey)) {
                        isEqual = !!remoteFilterKey.filter(key => param[key] === query).length;
                        remoteFilterKey.forEach(key => {
                            param[key] = query;
                        });
                    }
                    if (isStringKey(remoteFilterKey)) {
                        isEqual = param[remoteFilterKey] === query;
                        param[remoteFilterKey] = query;
                    }

                    if (!isEqual && this.query !== this.prevQuery) {
                        this.scrollOption[currentPage] = 1;
                    }
                }

                //判断是不是无限滚动加载的情况
                if (this.infiniteScroll) {
                    param = Object.assign(param, this.scrollOption);
                }

                delete param[total];

                console.log(param);
                this.$http.post(dataUrl, param, { loading: false }).then(res => {
                    let resData = null,
                        { data } = res;
                    this.loading = false;

                    if (infiniteScroll) {
                        this.scrollOption = {
                            [currentPage]: this.scrollOption[currentPage],
                            [total]: Math.ceil(data[total] / this.scrollOption.limit),
                            [pageSize]: this.scrollOption[pageSize]
                        };
                        resData = (this.scrollOption[currentPage] === 1 ? [] : this.optionsData).concat(
                            rows ? data[rows] : data
                        );
                    } else {
                        /**
                         * 如果返回数组， 直接返回； 如果是对象且如果 rows 有值 就取data[rows] 没有直接返回
                         */
                        resData = Array.isArray(data) ? data : Object.keys(data).length && rows ? data[rows] : data;
                    }
                    this.prevQuery = this.query;
                    // 添加处理回调方法
                    this.optionsData = this.parseDataFn ? this.parseDataFn(resData) : resData;
                    console.log(this.optionsData, 444);
                    this.$nextTick(() => {
                        resolve && resolve();
                    });
                });
            } else if (typeof dataUrl !== 'string') {
                this.optionsData = dataUrl;
            }
        },
        reload() {
            let { multiple } = this;
            if (this.dataUrl) {
                this.scrollOption = this.copyScrollPro;
                this.optionsData = [];
            }
            this.handleChange(multiple ? [] : '');
            this.getSelectData();
        },
        /**
         * 远程筛选的方法
         */
        remoteFilter(query) {
            // [fix] 当远程输入后， 下拉滚动会清空输入值
            // [fix]
            // let queryStr = this.multiple ? `${query ? this.value.concat(query) : this.value}` : query;
            // this.query = queryStr;
            this.query = query;
            if (typeof this.dataUrl === 'string') {
                setTimeout(() => {
                    this.infiniteScroll
                        ? this.getSelectData(this.query, this.$refs.select.hideInfiniteLoading)
                        : this.getSelectData(this.query);
                }, 200);
            }
        },
        loadMore(option, resolve) {
            let { currentPage } = this.keyNamesOption;
            if (this.infiniteScroll && typeof this.dataUrl === 'string') {
                this.$nextTick(() => {
                    this.scrollOption = Object.assign(this.scrollOption, {
                        ...this.copyScrollPro,
                        [currentPage]: typeof option[currentPage] === 'number' ? ++option[currentPage] : 1
                    });
                    this.getSelectData(this.query, resolve);
                });
            } else {
                this.$emit('loadMore', option, resolve);
            }
        },
        handleChange(val) {
            // 考虑分页情况， 当this.optionData 中没有时，页面无法设置matchKey
            // 单选 select 会变成 obj val 不为空 但是selects 为空;
            // 多选 select 会变成 arr val 不为空 但是selects 为空数组或长度不够
            // 还要考虑 重置，删除等操作
            // 1. 设置缓存，缓存matchKey还是缓存数据
            // 缓存数据比较合理;
            let selects = this.getSelect(val);
            this.$emit('select', selects);
            this.$emit('update:formModel', { ...this.formModel, ...this.updateFormModel(selects) });
        },
        getSelect(val) {
            let { multiple } = this;
            return multiple ? this.getSelectRows(val) : this.getSelectRow(val);
        },
        getSelectRow(val) {
            let data = duplicate(
                this.cache.get(val) ? this.optionsData.concat(this.cache.get(val)) : this.optionsData,
                this.props.value
            );
            let result = data.find(item => item[this.props.value] === val);
            result && this.cache.set(val, result);
            return result;
        },
        getSelectRows(vals) {
            if (!Array.isArray(vals)) return [];
            return vals.map(val => this.getSelectRow(val)).filter(item => item);
        },
        updateFormModel(val) {
            let obj = {};
            if (val == null) return obj;
            /**
             * val 可能是对象， 可能是数组, null, undefined
             * 如果是对象 { axx: 'xxx'}
             * 如果使数组 { axx: ['xxx']}
             */
            for (let key in this.matchKey) {
                let value = this.matchKey[key];
                obj[key] = Array.isArray(val)
                    ? val.map(item => item[value])
                    : typeof val === 'object'
                    ? val[value]
                    : '';
            }
            return obj;
        },
        resetFormModel(val) {
            let result = { [this.props.value]: val };
            this.$emit('update:formModel', { ...this.initValue, ...result });
            this.$emit('reset', val);
        },
        clearFormModel() {
            /**
             * 清空需要做的事情有;
             * 1. 合并 初始值，formModel， value 值， 然后删除matchKey 里面的属性
             */
            const value = this.multiple ? [] : '';
            this.select = value;
            this.$emit('update:formModel', this.deleteMatchKey({ ...this.initValue, ...this.formModel }));
            this.$emit('clear', this.select);
        },
        deleteMatchKey(option) {
            let { multiple } = this;
            for (let okey in option) {
                for (let mkey in this.matchKey) {
                    if (okey === mkey) {
                        option[okey] = multiple ? [] : '';
                    }
                }
            }
            return option;
        },
        visibleChange(visible) {
            visible && this.beforeVisible();
        },
        beforeVisible() {
            if (!this.beforeOpen) {
                this.init();
            } else if (typeof this.beforeOpen === 'function') {
                const before = this.beforeOpen();
                if (before && before.then) {
                    before.then(
                        () => {
                            this.init();
                        },
                        () => {
                            this.blur();
                        }
                    );
                } else if (before !== false) {
                    this.init();
                } else {
                    this.blur();
                }
            }
        },
        blur() {
            this.$refs.select.blur();
        },
        /**
         * 清空方法
         */
        handleClear() {
            this.clearFormModel();
        },
        /**
         * 外部提供的方法 清空方法
         */
        clear() {
            /**
             * 使用nextTick让formModel更新一轮，再来支持清空
             */
            this.$nextTick(() => {
                this.handleClear();
            });
        }
    }
};
</script>

<style lang="scss">
.scroll-divider {
    /deep/ .el-divider__text {
        padding: 0 5px;
        font-size: 10px !important;
    }
}
</style>
