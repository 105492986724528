<template>
    <f-magnifier-multi
        ref="magnifier"
        size="mini"
        :tableData="tableData"
        v-bind="$attrs"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :placeholder="placeholder"
        @open="handleOpen"
        @query="handleQuery"
        @pageChange="handlePageChange"
        @change="handlerChange"
        @clear="handleClear"
        @fieldReset="handleReset"
        v-on="listeners"
        :autoSelect="autoSelect"
        :reInitialized="reInitialized"
        type="primary"
    >
        <template v-slot:search>
            <slot name="search"></slot>
        </template>
        <slot></slot>
        <template v-slot:rightColumns>
            <slot name="rightColumns"></slot>
        </template>
    </f-magnifier-multi>
</template>

<script>
import magnifierMultiMix from '@/common/comMixin/magnifierMultiMix.js';
/**
 * 放大镜-多选
 */
export default {
    name: 'ComMagnifierMulti',
    mixins: [magnifierMultiMix]
};
</script>

<style lang="scss" scoped></style>
