<template>
    <f-dialog
        :visible.sync="visibleDialog"
        :title="dialogConfig.title"
        class="f-magnifier-dialog"
        :append-to-body="true"
    >
        <f-form
            ref="dialogForm"
            :model="dialogConfig.form.model"
            :size="mixSize"
            :label-width="mixLabelWidth"
            :column="mixColumn"
            :gutter="mixGutter"
            :rules="config.form.rules"
        >
            <slot v-for="item in config.formItems" :name="prefix + item.name"></slot>
            <!-- <f-form-item
        v-for="item in config.formItems"
        :key="item.name"
        :column="mixColumn / (item.propsConfig.column || 1)"
        v-bind="item.propsConfig"
      >
        <slot :name="prefix + item.name"></slot>
      </f-form-item> -->
            <slot v-if="!config.formItems.length" :name="prefix"></slot>
        </f-form>
        <template v-slot:footer>
            <f-button
                v-for="(btn, index) in btnsCfg"
                :key="btn.name + index"
                v-bind="btn.props"
                @click="dialogBtn(btn)"
                >{{ btn.name }}</f-button
            >
            <!-- <f-button size="mini" type="primary" @click="dialogConfirm">确定</f-button>
      <f-button size="mini" @click="dialogCancel">取消</f-button> -->
        </template>
    </f-dialog>
</template>

<script>
const dialogConfig = {
    title: '',
    form: {
        model: {},
        rules: {}
    },
    formItems: [],
    btns: []
};

import { styleMix } from '@/common/comMixin';
export default {
    name: 'editDialog',
    mixins: [styleMix],
    data() {
        return {
            model: this.dialogConfig.form.model
        };
    },
    props: {
        // 前缀  <template v-slot:dialogxxx></template>
        prefix: {
            type: String,
            default: 'dialog'
        },
        // 显示与隐藏
        visible: Boolean,
        // 配置
        dialogConfig: {
            type: Object,
            default: () => dialogConfig
        },
        type: Object
    },
    computed: {
        visibleDialog: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('update:visible', val);
            }
        },
        config() {
            return Object.assign(dialogConfig, this.dialogConfig);
        },
        formCfg() {
            return this.config.form;
        },
        formItemCfg() {
            return this.config.formItems;
        },
        btnsCfg() {
            return this.config.btns.map(btn => {
                btn.props = { ...btn.props, size: 'mini' };
                return btn;
            });
        },
        isValid() {
            return !!Object.keys(this.formCfg.rules).length;
        }
    },
    methods: {
        dialogBtn(btn) {
            btn = { ...btn, ...this.type };

            const resetFields = this.$refs.dialogForm.resetFields;

            let events = () => {},
                { isValid } = this;

            const resolve = (isVisible, isReset) => {
                isReset ? resetFields() : null;
                this.$emit('update:visible', isVisible);
            };

            if (btn.events && typeof btn.events.click === 'function') {
                events = btn.events.click;
                switch (btn.tag) {
                    case 'reset':
                    case 'cancel':
                        events(btn, resolve);
                        break;
                    case 'save':
                    default:
                        isValid
                            ? this.$refs.dialogForm.validate(valid => {
                                  if (valid) {
                                      events(btn, this.config, resolve);
                                  } else {
                                      return false;
                                  }
                              })
                            : events(btn, this.config, resolve);
                        break;
                }
            }
        },
        dialogConfirm() {
            this.$emit('update:visible', false);
        },
        dialogCancel() {
            this.$emit('update:visible', false);
        }
    }
};
</script>

<style lang="scss" scoped></style>
