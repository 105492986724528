<template>
    <div class="com-table-info">
        <slot name="default"></slot>
        <!-- <f-popover
            v-if="tableInfoData.length > 1"
            placement="top"
            width="520"
            trigger="click">
            <slot name="default">
                <f-table :data="tableInfoData" max-height="300px">
                    <f-table-column property="name" label="列名"></f-table-column>
                    <f-table-column property="info" label="汇总信息"></f-table-column>
                    <f-table-column property="otherInfo" label="其他信息"></f-table-column>
                </f-table>
            </slot>
            <p slot="reference" class="first-info">
                <slot name="firstInfo">
                    <span class="name">
                        {{tableInfoData[0].name}} : 
                    </span>
                    <span class="info">
                        {{tableInfoData[0].info}}
                    </span>
                </slot>
            </p>
            
        </f-popover>
        <p v-if="tableInfoData.length == 1" class="first-info">
            <slot name="firstInfo">
                <span class="name">
                    {{tableInfoData[0].name}}
                </span>
                <span class="info">
                    {{tableInfoData[0].info}}
                </span>
            </slot>
        </p> -->
    </div>
</template>

<script>
    export default {
        name: 'ComTableInfo',
        data() {
            return {
                // key: ''
            }
        },
        props: {
            // tableInfoData: {
            //     type: Array,
            //     required: true,
            //     default: () => {
            //         return []
            //     }
            // },
        },
    }
</script>

<style lang="scss" scoped>
.com-table-info{
    padding-left: 8px;
    font-size: 12px;
    max-width: 600px;
    overflow: hidden;
}
.table-info-item{
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 24px;
    .label{
        color: #666;
        margin-right: 8px;
    }
    .amount {
        color: #ff3315;
    }
}
</style>