import {error} from "three";

export default {
    name: 'RenderFormItem',
    props: {
        hiddenParams: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hiddenParamsVal: {
            type: Object,
            default: () => {
                return {};
            }
        },
        verticalForm: {
            type: [Object, Boolean],
            defualt: null
        }, // 表单是否为垂直居中显示 verticalForm.width [string] 可以配置表单的宽度
        renderModel:{
            type: Array,
            default: () => {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        curStyle() {
            let style = {
                display: 'flex',
                flexWrap: 'wrap'
            }
            if (this.verticalForm !== null && this.verticalForm) {
                style = {
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: this.verticalForm.width || '800px',
                    margin: '0 auto'
                }
            }
            return style;
        }
    },
    render(h) {
        let slotVnode = this.$slots.default;
        let showSlotVnode = this.filterVnode(slotVnode);
        return h('div', { style: this.curStyle }, showSlotVnode);
    },
    methods: {
        filterVnode(slotVnode) {
            let showSlotVnode = [];
            let that = this;
            let paramsArr = []
            //console.log('hiddenParams',slotVnode)
            function recursionArr(vnodes,prop) {
                let showSlotVnode = [];
                if (vnodes) {
                    vnodes.map(item => {
                        if (item.componentOptions && item.componentOptions.tag === 'f-form-item' &&  item.componentOptions.propsData && item.componentOptions.propsData.prop) {
                            paramsArr.push({
                                label: item.componentOptions.propsData.label,
                                prop: item.componentOptions.propsData.prop,
                                showOrHide: that.hiddenParams.includes(item.componentOptions.propsData.prop) ? false : true,
                                defaultVal: that.hiddenParamsVal[item.componentOptions.propsData.prop] ? that.hiddenParamsVal[item.componentOptions.propsData.prop] : ''
                            });

                        }
                        let isHiddenItem =
                            item.componentOptions &&
                            item.componentOptions.tag === 'f-form-item' &&
                            item.componentOptions.propsData &&
                            item.componentOptions.propsData.prop &&
                            that.hiddenParams.includes(item.componentOptions.propsData.prop);



                        if (!isHiddenItem) {

                            if(item.componentOptions && item.componentOptions.propsData && prop){

                                if(that.renderModel && that.renderModel.length > 0){
                                    let renderDisabled = true;
                                    let _value='',_disabled=false,_isValidValue = true;

                                    that.renderModel.some(item =>{
                                        if(prop === item.prop){
                                            renderDisabled = false;
                                            _value = item.value ? item.value : '';
                                            _disabled =item.disabled?item.disabled : false;
                                            _isValidValue = item.isValidValue !== undefined ?item.isValidValue:true;
                                            return;
                                        }
                                    })
                                    //console.log('renderModel',renderDisabled)
                                    try{
                                        if(renderDisabled){
                                            item.componentOptions.propsData.disabled = true;
                                            item.data.attrs.disabled=true;
                                        }else{
                                            item.componentOptions.propsData.disabled = _disabled;
                                            item.data.attrs.disabled=_disabled;
                                            if(_isValidValue)item.componentOptions.propsData.value=_value;
                                        }
                                    }catch (e) {

                                    }
                                    //console.log(renderDisabled,prop)
                                }

                            }else{
                               // console.error(item,(item.componentOptions && item.componentOptions.propsData),'2121',(prop))
                            }


                            if (item.componentOptions && item.componentOptions.children &&item.componentOptions.children.length > 0) {

                                item.componentOptions.children = recursionArr(item.componentOptions.children,item.componentOptions.propsData && item.componentOptions.propsData.prop);
                            }else{
                                if(item.children && item.children.length > 0){
                                    recursionArr(item.children)
                                }else if(item.componentOptions && item.componentOptions.tag == 'com-edit-table'){
                                    if(that.renderModel && that.renderModel.length > 0){
                                        let renderDisabled = true;
                                        let _disabled=false
                                        that.renderModel.some(model =>{
                                            if(item.data.ref === model.prop){
                                                renderDisabled = false;
                                                _disabled =model.disabled?model.disabled : false;
                                                return;
                                            }
                                        })
                                        try{
                                            if(renderDisabled){
                                                item.componentOptions.propsData.disabled = true;
                                                item.data.attrs.disabled=true;
                                            }else{
                                                item.componentOptions.propsData.disabled = _disabled;
                                                item.data.attrs.disabled=_disabled;
                                            }
                                        }catch (e) {

                                        }
                                    }else{
                                        item.componentOptions.propsData.disabled = that.disabled;
                                        item.data.attrs.disabled = that.disabled;
                                    }
                                }
                            }
                            showSlotVnode.push(item);
                        }
                    });
                }
                return showSlotVnode;
            }
            showSlotVnode = recursionArr(slotVnode);
            this.$emit('getParamsArr', paramsArr);
            return showSlotVnode;
        }
    }
};
