<template>
    <!-- 省市联动下拉框 -->
    <div class="province-city-wrap">
        <f-form-item :column="2" :prop="keyConfig.provinceNameKey">
            <com-select-scroll
                class="province"
                v-model="currentFormModel[keyConfig.provinceNameKey]"
                :dataSource="provinceUrl"
                :formModel.sync="currentFormModel"
                infiniteScroll
                filterable
                remote
                remoteFilterKey="name"
                :props="provinceProps"
                @select="handleProvinceSelect"
                @clear="handleProvinceClear"
                :matchKey="provinceMatchKey"
                :placeholder="$t('i18n.common.components.provinceAndCity.provincePlaceholder')"
                :disabled="disabled"
            >
            </com-select-scroll>
        </f-form-item>
        <f-form-item :column="2" :prop="keyConfig.cityNameKey">
            <com-select-scroll
                class="city"
                ref="city"
                v-model="currentFormModel[keyConfig.cityNameKey]"
                :dataSource="cityUrl"
                :formModel.sync="currentFormModel"
                :extraData="cityExtraData"
                infiniteScroll
                filterable
                remote
                remoteFilterKey="name"
                :props="cityProps"
                :beforeOpen="handleBeforeOpen"
                @select="handleCitySelect"
                :matchKey="cityMatchKey"
                :placeholder="$t('i18n.common.components.provinceAndCity.cityPlaceholder')"
                :disabled="disabled"
            >
            </com-select-scroll>
        </f-form-item>
    </div>
</template>

<script>
export default {
    name: 'ProvinceAndCity',
    data() {
        return {
            provinceProps: {
                // 省字段名称配置
                key: 'code',
                value: 'name',
                label: 'name'
            },
            cityProps: {
                // 市字段名称配置
                key: 'code',
                value: 'name',
                label: 'name'
            },
            /**
             * 市额外的查询条件
             */
            cityExtraData: {
                provinceCode: '' // 当前选中的省的code值
            }
        };
    },
    computed: {
        currentFormModel: {
            get() {
                return this.formModel;
            },
            set(v) {
                this.$emit('update:formModel', v);
            }
        }
    },
    props: {
        /**
         * 绑定的表单
         */
        formModel: {
            type: Object,
            required: true
        },
        /**
         * 省数据请求地址
         */
        provinceUrl: {
            type: String
        },
        /**
         * 市数据请求地址
         */
        cityUrl: {
            type: String
        },
        keyConfig: {
            type: Object,
            default: () => {
                return {
                    provinceKey: 'provinceCode', // 省在formModel中的value字段名称
                    provinceNameKey: 'provinceName', // 省在formModel中的label字段名称
                    cityKey: 'cityCode', // 市在formModel中的value字段名称
                    cityNameKey: 'cityName' // 市省在formModel中的label字段名称
                };
            }
        },
        provinceMatchKey: Object,
        cityMatchKey: Object,
        provinceRules: {
            type: Array,
            default: () => {
                return [];
            }
        },
        cityRules: {
            type: Array,
            default: () => {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleBeforeOpen() {
            if (!this.currentFormModel[this.keyConfig.provinceNameKey]) {
                return false;
            } else {
                return true;
            }
        },
        handleProvinceClear() {
            this.provinceClear();
            this.clearCity();
        },
        handleCityClear() {
            this.currentFormModel[this.keyConfig.cityKey] = ''; // 清除city在formModel中的value的值
            this.currentFormModel[this.keyConfig.cityNameKey] = ''; //  清除city在formModel中的label的值
            this.$refs['city'].clear(); // 清除city的matchKey对应的值
        },
        handleProvinceSelect(row) {
            // 联动formModel中的provinceCode值
            this.currentFormModel[this.keyConfig.provinceKey] = row && row['code'] ? row['code'] : '';
            this.currentFormModel[this.keyConfig.provinceNameKey] = row && row['name'] ? row['name'] : '';
            this.cityExtraData.provinceCode = row && row['code'] ? row['code'] : '';

            this.clearCity();
            this.$emit('provinceSelect', row);
        },
        handleCitySelect(row) {
            // 联动formModel中的cityCode值
            this.currentFormModel[this.keyConfig.cityKey] = row && row['code'] ? row['code'] : '';
            this.currentFormModel[this.keyConfig.cityNameKey] = row && row['name'] ? row['name'] : '';
            this.$emit('citySelect', row);
        },
        provinceClear() {
            this.currentFormModel[this.keyConfig.provinceKey] = ''; // 清除province在formModel中的value值
            this.currentFormModel[this.keyConfig.provinceNameKey] = ''; // 清除province在formModel中的label值
            this.cityExtraData.provinceCode = ''; // 清除city的查询条件的provinceCode的值
        },
        /**
         * js清除city的数据
         */
        clearCity() {
            this.currentFormModel[this.keyConfig.cityKey] = ''; // 清除city在formModel中的value的值
            this.currentFormModel[this.keyConfig.cityNameKey] = ''; //  清除city在formModel中的label的值
            this.$refs['city'].clear(); // 清除city的matchKey对应的值
        }
    }
};
</script>

<style lang="scss" scoped>
.province-city-wrap {
    display: flex;
    justify-content: space-between;
    .el-form-item {
        margin: 0;
    }
}
.province {
    flex: 1;
    margin-right: 4px;
}
.city {
    flex: 1;
    margin-left: 4px;
}
</style>
