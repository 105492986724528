<template>
    <div :id="container" style="width: 100%;"></div>
</template>

<script>
import { registerShapeApproval, parseFlow } from '@/common/utils';
import { Chart, Util } from '@antv/g2';
registerShapeApproval();
const ID = 'flow_chart' + parseInt((Math.random() + 1) * 10000);
export default {
    name: 'comFlowChartForm',
    data() {
        return {
            container: ID,
            data: [],
            chart: null,
            tooltip: {}
        };
    },
    props: {
        dataSource: [String, Array], // 数据源
        extraData: Object, // 额外参数
        props: Object, //渲染节点的值
        height: { type: Number, default: 300 }, // 高度，默认300; 暂不考虑根据数据数量进行动态设置高度
        column: { type: Number, default: 6 }, // 一行6列，考虑是否可支持修改
        parseDataFn: { type: Function, default: parseFlow } // 格式化数据用于格式化列点位
    },
    async created() {
        let { data } = await this.getChartData();
        this.data = this.parseDataFn(data, this.column);
    },
    mounted() {
        this.$nextTick(() => {
            this.renderChart(this.data);
        });
    },
    methods: {
        async getChartData() {
            console.log(this);
            return typeof this.dataSource === 'string'
                ? await this.$http.post(this.dataSource, this.extraData)
                : { data: this.dataSource };
        },
        renderChart1() {
            const data = [
                { type: '一线城市', value: 0.19 },
                { type: '二线城市', value: 0.21 },
                { type: '三线城市', value: 0.27 },
                { type: '四线及以下', value: 0.33 }
            ];
            const chart = new Chart({
                container: ID,
                autoFit: true,
                height: 500
            });
            chart.data(data);

            chart.coordinate('theta', {
                radius: 0.75
            });
            chart.tooltip({
                showMarkers: false
            });

            const interval = chart
                .interval()
                .adjust('stack')
                .position('value')
                .color('type', ['#063d8a', '#1770d6', '#47abfc', '#38c060'])
                .style({ opacity: 0.4 })
                .state({
                    active: {
                        style: element => {
                            const shape = element.shape;
                            return {
                                matrix: Util.zoom(shape, 1.1)
                            };
                        }
                    }
                })
                .label('type', val => {
                    const opacity = val === '四线及以下' ? 1 : 0.5;
                    return {
                        offset: -30,
                        style: {
                            opacity,
                            fill: 'white',
                            fontSize: 12,
                            shadowBlur: 2,
                            shadowColor: 'rgba(0, 0, 0, .45)'
                        },
                        content: obj => {
                            return obj.type + '\n' + obj.value + '%';
                        }
                    };
                });

            chart.interaction('element-single-selected');

            chart.render();
        },

        renderChart(data) {
            let _this = this;
            let Y = data.length <= 6 ? '1' : 'Y';
            this.chart =
                this.chart ||
                new Chart({
                    container: this.container,
                    autoFit: true,
                    height: this.height,
                    padding: [60, 100, 77]
                });

            let chart = this.chart;
            chart.clear();
            chart.data(data);
            chart.axis(false);
            chart.scale({
                X: { nice: true },
                Y: { nice: true }
            });
            chart.tooltip({
                triggerOn: 'none',
                showMarkers: false,
                showTitle: false,
                position: 'right',
                follow: true,
                domStyles: {
                    'g2-tooltip': {
                        padding: '0px'
                    },
                    'g2-tooltip-list-item': {
                        padding: '0px 12px'
                    }
                }
            });
            chart
                .path()
                .animate({
                    appear: {
                        animation: 'path-in',
                        duration: 1000,
                        easing: 'easeLinear'
                    }
                })
                .position(`X*${Y}`)
                .tooltip(false)
                .color('#E0E0E0');
            chart
                .point()
                .animate({
                    appear: {
                        appear: 'fade-in',
                        duration: 200,
                        delay: obj => {
                            const index = _this.data.findIndex(item => item.label === obj.label);
                            return index * (1000 / _this.data.length);
                        },
                        easing: 'easeLinear'
                    }
                })
                .position(`X*${Y}`)
                .shape('approval');

            chart.render();

            chart.on('tooltip:show', function(ev) {
                // targetOn 无效， 导致
                let { items } = ev;
                if (!items) return false;
                let origin = items[0]; // 将一条数据改成多条数据
                if (!origin) return false;
                let data = origin.data;
                if (!data) return false;
                items.splice(0);
                if (['start', 'end'].includes(data.type)) {
                    return false;
                }
                // 清空
                (data.list || []).forEach(item => {
                    items.push({
                        ...origin,
                        ...{ name: item.text, value: item.value, marker: true }
                    });
                });
            });

            chart.on('point:click', ev => {
                const data = ev.data;
                console.log(data);
                this.$emit('click', data);
            });

            chart.on('point:mouseenter', ev => {
                const data = ev.data;
                const point = {
                    x: data.x,
                    y: data.y
                };
                this.tooltip = point;
                chart.showTooltip(point);
            });
            chart.on('point:mouseleave', () => {
                chart.hideTooltip();
            });
        }
    }
};
</script>

<style></style>
