<template>
    <!-- 付款账号放大镜 -->
    <f-dialog
      :visible.sync="visible"
      :title="$t('i18n.common.components.account.title')"
      :class="['com-magnifier-dialog']"
      :width="mixDialogWidth"
      :append-to-body="true"
      @close="handleClose"
    >
      <f-magnifier-search
        ref="searchForm"
        :formSize="mixSize"
        :model="searchForm"
        @query="handleQuery"
      >
        <template>
          <f-form-item :label="$t('i18n.common.components.account.acctNo')" prop="acctNo">
                <f-input v-model="searchForm.acctNo"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.account.acctName')" prop="acctName">
                <f-input v-model="searchForm.acctName"></f-input>
            </f-form-item>
<!--            <f-form-item label="账户名称" prop="orgKey">
                <f-input v-model="searchForm.orgKey"></f-input>
            </f-form-item>-->
        </template>
      </f-magnifier-search>
      <f-magnifier-table
        ref="table"
        :tableSize="mixSize"
        :tableData="tableData"
        :pageSizes="pageSizes"
        :page="page"
        :pagination="pagination"
        @row-dblclick="handleRowDblclick"
        @currentRowEnter="currentRowEnter"
        @pageChange="handlePageChange"
      >
          <!-- 账户名 -->
          <f-magnifier-column prop="acctName" :label="$t('i18n.common.components.PayaccountMagnifier.acctName')"></f-magnifier-column>
          <!-- 账户号 -->
          <f-magnifier-column prop="acctNo" :label="$t('i18n.common.components.PayaccountMagnifier.acctNo')"></f-magnifier-column>
          <!-- 单位名称 -->
          <f-magnifier-column prop="orgName" :label="$t('i18n.common.components.PayaccountMagnifier.orgName')"></f-magnifier-column>
          <!-- 机构名称 -->
          <f-magnifier-column prop="officeName" :label="$t('i18n.common.components.PayaccountMagnifier.officeName')"></f-magnifier-column>
          <!-- 币种 -->
          <f-magnifier-column prop="currencyName" :label="$t('i18n.common.components.PayaccountMagnifier.currencyName')"></f-magnifier-column>
          <!-- 开户行 -->
          <f-magnifier-column prop="bankName" :label="$t('i18n.common.components.PayaccountMagnifier.bankName')"></f-magnifier-column>

      </f-magnifier-table>
      <div class="warming">
         {{$t('i18n.common.components.magnifier.warming')}}
      </div>
    </f-dialog>
</template>

<script>
import btnMagnifierSingleMix from '@/common/comMixin/btnMagnifierSingleMix.js';
/**
 * 付款账号-单选
 */
export default {
    name: 'PayaccountMagnifierSingle',
    mixins: [btnMagnifierSingleMix],
    data() {
        return {
            /**
             * 单位放大镜查询条件
             */
            searchForm: {
                acctName: '', // 账户名称
                acctNo: '' // 账户编号
            }
        }
    }
}
</script>
