export default {
    // 业务类型映射
    queryBusinessTypeMappingListUrl: '/api/fsmc/business-type-mapping/query/business-type-mapping/page', // 业务类型映射维护分页列表
    queryBusinessTypeListUrl: '/api/fsmc/business-type-mapping/query/business-type/page', // 资金业务类型分页列表
    queryExtBusinessTypeListUrl: '/api/fsmc/business-type-mapping/query/ext-business-type/list', // 外部业务类型列表
    getBusinessTypeMappingByIdUrl: '/api/fsmc/business-type-mapping/query/business-type-mapping/-by-/', // 根据id查询,
    deleteBusinessTypeMappingUrl: '/api/fsmc/business-type-mapping/delete/business-type-mapping', // 业务类型映射维护删除
    addBusinessTypeMappingUrl: '/api/fsmc/business-type-mapping/insert/business-type-mapping', // 业务类型映射维护新增
    editBusinessTypeMappingUrl: '/api/fsmc/business-type-mapping/update/business-type-mapping', // 业务类型映射维护修改

    //业务类型权限设置
    queryOrgInfoUrl: '/api/fsmc/settset/business-type-power/query-org-info-list',
    queryBusinessTypeListWithPrivilegeUrl: '/api/fsmc/settset/business-type-power/query-business-type-set-list',
    saveBusinessTypePrivilegeUrl: '/api/fsmc/settset/business-type-power/business-type-power-batch-add',
    tyBusinessTypePrivilegeUrl: '/api/fsmc/settset/business-type-power/business-type-power-ty'
};
