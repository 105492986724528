import { setAxiosHeaders, removeAxiosHeader } from '@/common/http/axios';
import { rootNav } from '@/common/utils';
import axios from 'axios';

let dictionaryMix = {
    data() {
        return {
            /**
             * 数据字典查询结果
             */
            mixDictionaries: {
                dictionary: {},
                constant: {},
                custom: {}
            }
        };
    },
    methods: {
        /**
         * 获取字典数据
         * @param {String} url [required] 数据字典请求地址
         * @param {Array} dictionariesConfig 数据字典配置,要传入后台的数据
         */
        async mixGetDictionaries(url, dictionariesConfig = []) {
            if (!url) {
                console.log('数据字典的请求地址不存在!');
                return;
            }
            await this.$http.post(url, dictionariesConfig, { headers: { __expires__: 'true' } }).then(res => {
                if(res){
                    this.mixDictionaries = Object.assign(
                        {
                            dictionary: {},
                            constant: {},
                            custom: {}
                        },
                        res.data
                    );
                    Object.keys(this.mixDictionaries).forEach(item => {
                        Object.keys(this.mixDictionaries[item]).forEach(item1 => {
                            let dictionaryObj = {};
                            this.mixDictionaries[item][item1].forEach(item2 => {
                                dictionaryObj[item2.value] = item2;
                            });
                            this.mixDictionaries[item][item1 + '.obj'] = dictionaryObj;
                        });
                    });
                }
            });
        },
        /**
         * 根据数据字典的value值匹配相应的text值
         * @param {*} value 当前值
         * @param {*} dictionariesObj 要从哪个数据字典对象进行匹配
         */
        mixMatchDictionary(value, dictionariesObj) {
            if (value == undefined) {
                return '';
            }
            if (dictionariesObj && dictionariesObj[value] && dictionariesObj[value].text) {
                return dictionariesObj[value].text;
            } else {
                return value;
            }
        }
    }
};

let constantMix = {
    data() {
        return {
            /**
             * 常量判断数据结果集
             */
            mixConstants: {}
        };
    },
    methods: {
        /**
         * 获取常量判断数据
         * @param {String} url 常量判断数据请求地址
         * @param {Array} constantsConfig 常量判断数据配置,要传入后台的数据
         */
        async mixGetConstants(url, constantsConfig = []) {
            if (!url) {
                console.log('常量的请求地址不存在!');
                return;
            }
            await this.$http.post(url, constantsConfig, { headers: { __expires__: 'true' } }).then(res => {
                if(res){
                    this.mixConstants = res.data;
                }
            });
        },
        /**
         * 常量值判断--匹配常量值
         * value要判断的值
         * constantObj常量对象
         * 每个常量值需要返回的结果值配置
         * matchConfig = {
                ESTABLISH: 'state-process',
                NORMAL: 'state-success',
                VERDRAW: 'state-process',
                DORMANCY: 'state-success',
                LOGOUT: 'state-refuse'
            }
         */
        mixMatchConstant(value, constantObj, matchConfig) {
            let isMatch = false;
            for (let key in constantObj) {
                if (value === constantObj[key]) {
                    isMatch = true;
                    // 返回matchConfig中配置的值
                    return matchConfig[key] || false;
                }
            }
            if (!isMatch) {
                // 当未匹配到任何值时返回false
                return false;
            }
        }
    }
};

let comMix = {
    data() {
        return {
            /**
             * 表格列的公共属性配置
             */
            mixTableColConfig: {
                // 常规列配置
                default: {
                    width: '140px',
                    visible: true,
                    sortable: true,
                    align: 'left'
                },
                // 序号列配置
                seq: {
                    type: 'seq',
                    title: this.$t('i18n.common.components.seqText'),
                    width: '50',
                    fixed: 'left',
                    align: 'center',
                    visible: true
                },
                // 复选框列配置
                checkbox: {
                    type: 'checkbox',
                    width: '35',
                    fixed: 'left',
                    align: 'center',
                    visible: true
                },
                // 单选框列配置
                radio: {
                    type: 'radio',
                    width: '35',
                    fixed: 'left',
                    align: 'center',
                    visible: true
                },
                // 超链接列配置,可点击的带下划线
                linkUrl: {
                    width: '180px',
                    fixed: 'left',
                    visible: true,
                    sortable: true
                },
                // 高亮列配置, 不可点击不带下划线
                linkCode: {
                    className: 'link-code',
                    width: '180px',
                    visible: true,
                    sortable: true
                },
                // 次要数据列配置
                minor: {
                    className: 'state-minor',
                    width: '140px',
                    visible: true,
                    sortable: true
                },
                // 金额列配置
                amount: {
                    headerAlign: 'center',
                    align: 'right',
                    width: '180px',
                    className: 'state-amount',
                    visible: true,
                    sortable: true
                },
                // 状态列配置
                state: {
                    headerAlign: 'center',
                    align: 'center',
                    width: '180px',
                    visible: true,
                    sortable: true
                },
                // 操作列配置
                operation: {
                    fixed: 'right',
                    align: 'left',
                    width: '180px',
                    className: 'operation-cell',
                    visible: true
                },
                // 多行列
                multiple: {
                    headerAlign: 'center',
                    width: '210px',
                    className: 'multiple-cell',
                    visible: true,
                    sortable: true
                },
                mucheckbox: {
                    headerAlign: 'center',
                    width: '210px',
                    className: 'multiple-cell',
                    visible: true
                }
            }
        };
    },
    methods: {
        /**
         * 根据选择的行数据, 返回需要的字段的值
         * @param {Array} rows 源数据
         * @param {String} key 需要的字段名
         */
        mixGetCheckedValue(rows, key) {
            let values = [];
            rows.forEach(item => {
                if (item[key]) {
                    values.push(item[key]);
                }
            });
            return values.join(',');
        }
    }
};

function getToken(url) {
    return axios.get(url, {}, { headers: { __expires__: 'true' } }).then(res => {
        let { data } = res.data;
        return Promise.resolve(data.token);
    });
}

const tokenMix = {
    data() {
        return {
            _token: null,
            _tokenUrl: null
        };
    },
    methods: {
        async initToken() {
            let url = this.$store.getters['common/getTokenUrl'];
            let requestToken = await getToken(url);
            removeAxiosHeader('requestToken');
            // 设置headers
            setAxiosHeaders({ requestToken });
        }
    }
};

/**
 * navigate 根导航
 */
const rootNavMix = {
    provide() {
        return {
            rootNav: this
        };
    },
    data() {
        return {
            drawers: {},
            routers: {},
            configs: {},
            params: {},
            routerViews: rootNav.getRouterView(this.$route.matched, this.$route)
        };
    },
    filters: {
        widthDirection: rootNav.widthDirection,
        closeDirection: rootNav.closeDirection,
        contentDirection: rootNav.contentDirection
    },
    methods: {
        isIframeType(type) {
            const iframeReg = /^(iframe):.+/;
            return iframeReg.test(type);
        },
        getIndexView(){
            return this.$refs.routerview;
        },
        setRootNav(type, params, options) {
            let defs = {
                params: {},
                config: {
                    size: '86.1%',
                    direction: 'rtl',
                    height: document.body.offsetHeight + 'px'
                }
            };
            /**
             * (type: String | Object, params?: Object, options?:Object)
             */
            if (typeof type !== 'string') {
                options = params;
                params = type;
                type = 'view';
            }

            let { model, ...config } = options || {};

            if (!this.routerViews.includes(type) && this.isIframeType(type)) {
                this.routerViews = this.routerViews.concat(type);
            }
            // 如果抽屉打开了， 就直接切换内容就可以了
            if (!this.drawers[type]) {
                this.$set(this.drawers, type, true);
                this.$set(this.routers, type);
            } else {
                model === 'overflow' && (this.drawers[type] = false);
                this.routers[type] = false;
            }

            this.configs[type] = Object.assign(defs.config, config);
            this.params[type] = Object.assign(defs.params, params);
            this.$router.replace(Object.assign(defs.params, params)).catch(err => {
                err;
            });
            // 打开当前路由视窗
            this.$nextTick(() => {
                model === 'overflow' && (this.drawers[type] = true);
                this.routers[type] = true;
            });
        },
        closeRootNav(nav) {
            let parent = nav.$parent;
            while (parent && !parent.closeDrawer && parent.$parent) {
                parent = parent.$parent;
            }
            if (parent.closeDrawer) {
                parent.closeDrawer();
            } else if (self.frameElement && self.frameElement.tagName == 'IFRAME') {
                self.parent.$bus.$emit('iframe:closeRootNav');
            }
        },
        drawerclosed(type) {
            delete this.params[type];
            let currentType = Object.keys(this.params).slice(-1)[0];
            this.$router.replace(this.params[currentType] || {}).catch(err => {
                err;
            });
        }
    },
    watch: {
        $route(to, form) {
            if (!(to.name === form.name && to.path === form.path)) {
                this.routerViews = rootNav.getRouterView(this.$route.matched, this.$route);
                for (let dra in this.drawers) {
                    this.drawers[dra] = false;
                }
                for (let rou in this.routers) {
                    this.routers[rou] = false;
                }
            }
        }
    }
};
/**
 * navigate 子导航
 */
const childNavMix = {
    inject: {
        rootNav: {
            default: {}
        }
    }
};

// 获取子form数据 mix
const subFormMix = {
    methods: {
        // 获取子form数据
        getSubFormData(url, params) {
            if (typeof url !== 'string') {
                let tmp = this.$api.common.comForm.queryCode;
                params = url;
                url = tmp;
            }
            return this.$http.post(url, params).then(({ data, code }) => {
                if (code == 0) {
                    try {
                        let temp = JSON.parse(data);
                        let result = temp.template.map(item => {
                            item['propsConfig'] = { label: item.text, prop: item.name };
                            return result;
                        });
                        return Promise.resolve(result);
                    } catch (e) {
                        return Promise.reject(e);
                    }
                } else {
                    return Promise.reject(data);
                }
            });
        }
    }
};

const formUtilsMix ={
    created() {
        if (window.parent && window.parent.$bus) {
            let eventName = 'iframeMountedValidComponent';
            window.parent.$bus.$emit(eventName, this);
        }
    },
    methods:{
        mountedValidDataForm(){
            if(this.customApprovalValid && typeof this.customApprovalValid === 'function'){
                return this.customApprovalValid()
            }else{
                for(let k in this.templateFormData){
                    if(this.formData[k] != this.templateFormData[k]){
                        return false;
                    }
                }
                return true;
            }

        }
    },
}

// 全局的样式配置比如说form的列数, 控件的size等等
// 不同语言的不同的样式配置
const langStyleConfig = {
    'zh-CN': {
        column: 3,
        width: '130px'
    },
    'en-US': {
        column: 2,
        width: '350px'
    }
};
let styleMix = {
    data() {
        return {
            // 控件尺寸
            mixSize: 'mini',
            // 表单列数
            mixColumn:
                langStyleConfig[this.$i18n.locale] && langStyleConfig[this.$i18n.locale].column
                    ? langStyleConfig[this.$i18n.locale].column
                    : 3,
            // 表单前面的label宽度
            mixLabelWidth: '120px',
            // 表单前面的label的对齐方式
            mixLabelPosition: 'right',
            // 表单item项的间隔距离
            mixGutter: '5px',
            mixDialogWidth: '800px'
        };
    },
    mounted() {
        let newVal = this.$i18n.locale;
        console.log('newVal',newVal,langStyleConfig[newVal].column)
        if (langStyleConfig[newVal] && langStyleConfig[newVal].column) {
            this.mixColumn = langStyleConfig[newVal].column;
            this.mixLabelWidth = langStyleConfig[newVal].width;

        } else {
            this.mixColumn = 3;
        }
    },

    methods: {
        /**
         * 根据选择的行数据, 返回需要的字段的值
         * @param {Number} require form表单列数
         */
        mixSetColumn(num) {
            if (num) {
                console.log('mixSetColumn',num,this.mixLabelWidth)
                this.mixColumn = num;
                this.$bus.$emit('changeColumn', num);
            }
        }
    }
};

let tableProcessMix = {
    data() {
        return {
            mixProcessPopVisible: false,
            mixProcessPopReference: null
        };
    },
    watch: {
        mixProcessPopVisible(newVal) {
            if (!newVal) {
                this.mixProcessPopVisible = null;
            }
        }
    },
    mounted() {
        document.addEventListener('mousedown', this.handleWindowClick);
    },
    beforeDestroy() {
        if (this.handleWindowClick) {
            document.removeEventListener('mousedown', this.handleWindowClick);
        }
    },
    methods: {
        /**
         * 点击表格单元格显示流程图的方法
         * 仅针对表格中的流程图
         * @param {Event} e 单击的事件对象
         */
        mixHandlerProcessClick(e) {
            let target = e.currentTarget;
            this.mixProcessPopVisible = false;
            this.mixProcessPopReference = target;
            this.$nextTick(() => {
                this.mixProcessPopVisible = true;
            });
        },
        /**
         * 点击表格中的值展示pop提示层的方法
         * 通用的表格提示层方法
         * @param {Event} e required 单击的事件对象
         * @param {String} popVisbleName required 提示层绑定的变量名称
         * @param {String} referenceName required 提示层绑定的reference的变量名称
         */
        mixHandlerPopClick(e, popvisble, reference) {
            let target = e.currentTarget;
            this[popvisble] = false;
            this[reference] = target;
            this.$nextTick(() => {
                this[popvisble] = true;
            });
        },
        /**
         * 处理点击滚动条时无法隐藏的bug
         */
        handleWindowClick(event) {
            let isClickWindow = false;
            let process = this.$el.querySelectorAll('.process-cell');
            for (let index = 0; index < process.length; index++) {
                const item = process[index];
                if (item.contains(event.target) || item === event.target) {
                    break;
                } else if (index === process.length - 1) {
                    isClickWindow = true;
                }
            }
            if (isClickWindow) {
                this.mixProcessPopVisible = false;
            }
        }
    }
};

let editFormHasIframeMix = {
    methods: {
        /**
         * 公共的执行编辑表单内部方法的方法
         * 注: 仅正对有嵌入iframe的表单使用
         * @param {formRefName} Stirng 编辑表单的ref名称
         * @param {funName} String 要执行的方法名称
         * @param {...args} 传入给真正需要执行的方法的参数
         */
        mixDoEditFormFun(formRefName, funName, ...args) {
            let formRef = this.$refs[formRefName];
            if (formRef) {
                formRef[funName](...args);
            } else {
                this.$nextTick(() => {
                    formRef[funName](...args);
                });
            }
        }
    }
};

let iframeFormMix = {
    methods: {
        /**
         * 给iframe表单提供公共的事件处理方法
         * @param {String} iframeName iframe的名称
         */
        mixInitEvent(iframeName) {

            if (window.parent && window.parent.$bus) {
                let eventName = 'iframeComponent' + iframeName;
                console.log(eventName,this)
                window.parent.$bus.$emit(eventName, this);
            }
        }
    }
};

export {
    dictionaryMix,
    constantMix,
    comMix,
    tokenMix,
    styleMix,
    tableProcessMix,
    rootNavMix,
    childNavMix,
    subFormMix,
    editFormHasIframeMix,
    iframeFormMix,
    formUtilsMix
};
