export default {
    expandFieldListUrl: '/api/fsmc/expand-template/query-expand-template-by-page', // 拓展字段模板配置
    queryExpandFieldByIdUrl: '/api/fsmc/expand-template/query-expand-template/-by-/', // 拓展字段模板配置
    queryExpandElementsByTemplateIdUrl: '/api/fsmc/expand-temp-elements/query-expand-temp-elements-by-page', // 拓展字段模板配置
    saveExpandElementUrl: '/api/fsmc/expand-template/expand-template-add', // 添加拓展模板元素
    updateExpandElementUrl: '/api/fsmc/expand-template/expand-template-update', // 更新扩展模板元素
    deleteExpandElementUrl: '/api/fsmc/expand-temp-elements/expand-temp-elements-delete', // 删除拓展模板元素
    validateNoRepeatUrl: '/api/fsmc/expand-template/validate-template-code', // 校验模板编号是否重复
    deleteTemplateUrl: '/api/fsmc/expand-template/expand-template-delete', // 删除模板信息
    enableTemplateUrl: '/api/fsmc/expand-template/expand-template-update-enable', // 启用模板信息
    disableTemplateUrl: '/api/fsmc/expand-template/expand-template-update-disable', // 停用模板信息
    queryExpandElementName: '/api/fsmc/expand-template/query-expand-element-name', //查询分配给扩展字段的name属性
    //查询注册的模块信息
    queryModuleRegisterListUrl: '/api/fsmc/system/module-register/get-module-combo-list' //查询注册模块信息
};
