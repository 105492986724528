<!-- 账户搜索下拉框 -->
<template>
    <com-select-scroll
        v-model="select"
        :formModel.sync="exportData"
        v-bind="$attrs"
        v-on="listeners"
        :dataSource="dataSource"
        :extraData="extraData"
        :placeholder="placeholder || $t('i18n.common.components.acctSelectSingle.placeholder')"
        :infiniteComplete="$t('i18n.common.infiniteComplete')"
        :infiniteLoading="$t('i18n.common.infiniteLoading')"
        :props="props"
        :matchKey="matchKey"
        :remoteFilterKey="remoteFilterKey"
        filterable
        remote
        infiniteScroll
        :keyNames="keyNames"
        :scroll="scroll"
        @select="selectHandle"
        :labelText="labelText"
    >
        <template v-slot:default="{ item }">
            <f-row type="flex">
                <span class="f-ellipsis">{{ item.acctName }} / {{ item.acctNo }}</span>
                <span v-if="inShowColumn(item)" style="color: #8492a6; font-size: 13px">{{ item.currencyName }}</span>
            </f-row>
        </template>
    </com-select-scroll>
</template>
<script>
import selectSingleMix from '@/common/comMixin/selectSingleMix.js';
export default {
    name: 'AcctSelectSingle',
    mixins: [selectSingleMix],
    props: {
        placeholder: {
            type: String
        },
        props: {
            type: Object,
            default: () => {
                return {
                    label: 'acctName',
                    value: 'acctId',
                    angKey: 'currencyName'
                };
            }
        },
        matchKey: {
            type: Object,
            default: () => {
                return {
                    acctName: 'acctName',
                    acctId: 'acctId',
                    orgKey: 'orgKey',
                    currencyName: 'currencyName',
                    bankTypeCode: 'bankTypeCode',
                    bankCode: 'bankCode',
                    acctInWay: 'acctInWay',
                    acctNature: 'acctNature',
                    payReceiveAttr: 'payReceiveAttr',
                    acctProvince: 'acctProvince',
                    acctCity: 'acctCity',
                    orgCode: 'orgCode',
                    acctKey: 'acctKey',
                    orgName: 'orgName',
                    officeCode: 'officeCode',
                    officeName: 'officeName'
                };
            }
        },
        labelText: [String, Number, Array],
        remoteFilterKey: {
            type: Array,
            default: () => {
                return ['acctName', 'acctId', 'currencyName'];
            }
        }
    },
    methods: {
        inShowColumn(row) {
            if (!this.showColumn) {
                return true;
            } else if (typeof this.showColumn === 'function') {
                return this.showColumn(row);
            } else {
                return true;
            }
        }
    }
};
</script>
