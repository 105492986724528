<template>
    <div class="edit-form-wrap" :class="[formType + '-form', isPopView ? 'pop-view' : '', verticalForm ? 'is-vertical': '']">
        <f-popover popper-class="custom-pop" ref="custom" placement="bottom" v-model="customPopVisible">
            <div class="edit-form-custom">
                <f-tabs v-model="tabActiveName">
                    <f-tab-pane :label="$t('i18n.common.components.form.order')" name="order">
                        <div class="collapse-order-set-wrap">
                            <template v-for="(item, index) in collapseItemsTagsList">
                                <f-tag
                                    size="small"
                                    type="info"
                                    :disable-transitions="true"
                                    :draggable="true"
                                    :key="item.name"
                                    @dragstart.native.stop="handleOrderDragStart($event, item, index)"
                                    @dragend.native.stop="handleOrderDragEnd($event, item, index)"
                                    @dragenter.native.stop="handleOrderDragEnter($event, item, index)"
                                    @dragleave.native.stop="handleOrderDragLeave($event, item, index)"
                                    @dragover.native.stop="handleOrderDragOver($event, item, index)"
                                    @drop.native.stop="handleOrderDrop($event, item, index)"
                                >
                                    {{ item.title }}
                                </f-tag>
                            </template>
                            <div v-show="showDropIndicator" class="drop-indicator" ref="dropIndicator"></div>
                        </div>
                    </f-tab-pane>
                    <!-- 管理员权限才有此功能 -->
                    <f-tab-pane v-if="userInfo && userInfo.userType == 1" :label="$t('i18n.common.components.form.hiddenCollapse')" name="exist">
                        <div class="collapse-order-set-wrap">
                            <!-- iframe的情况下不可配置显示隐藏 -->
                            <f-checkbox v-model="item.exist" v-for="item in collapseItemsTagsList" :key="item.title" :label="item.title" :disabled="Boolean(iframeInfoObj[item.name] && iframeInfoObj[item.name].iframeUrl)">
                                {{ item.title }}
                            </f-checkbox>
                        </div>
                    </f-tab-pane>
                </f-tabs>
                <f-row class="custom-btns-wrap" justify="center">
                    <f-col :span="24">
                        <f-button type="primary" :size="mixSize" @click="saveFormItemsOrder">{{
                            $t('i18n.common.confirmText')
                        }}</f-button>
                        <f-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('i18n.common.components.form.tooltip')"
                            placement="bottom"
                        >
                            <f-button plain :size="mixSize" @click="orderSettingResetClick">{{
                                $t('i18n.common.resetText')
                            }}</f-button>
                        </f-tooltip>
                        <f-button plain :size="mixSize" @click="orderSettingCancelClick">{{
                            $t('i18n.common.cancelText')
                        }}</f-button>
                    </f-col>
                </f-row>
            </div>
        </f-popover>
        <div v-if="(titleConfig || custom) && formType != 'approval'" class="edit-form-header">
            <span class="edit-form-title">
                <i :class="titleConfig.icon ? titleConfig.icon : 'el-icon-document'"></i>
                {{ titleConfig.title }}
            </span>
            <span class="edit-form-date" v-if="titleConfig.date"
                >{{ $t('i18n.common.components.form.titleDate') }}: {{ titleConfig.date }}
            </span>
            <span class="edit-form-info" v-if="titleConfig.info">
                <i class="el-icon-info"></i>
                {{ titleConfig.info }}
            </span>
            <f-tooltip
                class="item"
                effect="dark"
                :content="$t('i18n.common.components.comEditForm.editFormSettingTipText1')"
                placement="bottom"
            >
                <f-button
                    class="tools-icon-btn custom-btn"
                    v-if="custom"
                    icon="el-icon-setting"
                    :size="mixSize"
                    circle
                    v-popover:custom
                >
                </f-button>
            </f-tooltip>
            <f-tooltip
                class="item"
                effect="dark"
                :content="$t('i18n.common.components.comEditForm.editFormHiddenParamsTipText1')"
                placement="bottom"
            >
                <f-button
                    class="tools-icon-btn custom-btn"
                    v-if="userInfo && userInfo.userType == 1"
                    icon="el-icon-document"
                    :size="mixSize"
                    circle
                    @click="openHiddenParam"
                >
                </f-button>
            </f-tooltip>

            <f-tooltip
                class="item"
                effect="dark"
                :content="this.$t('i18n.common.components.comEditForm.operationGuide')"
                :disabled="operationGuide"
                placement="bottom"
            >
                <f-button
                    class="tools-icon-btn custom-btn proicon_button"
                    icon="icon proicon icon-header-help"
                    :size="mixSize"
                    circle
                    @click="openOperationGuide"
                >
                    <div class="triangle" v-if="operationGuidelines"></div>
                    <div class="float_box" v-if="operationGuidelines">
                        <i class="el-icon-close" @click.stop="operationGuidelines = false;operationGuide=false"></i>
                        <div>
                            <h3>{{ $t('i18n.common.components.comEditForm.operateTip') }}</h3>
                            <!-- <p>新增功能，可自行编辑操作指引。</p> -->
                        </div>
                        <div class="float_button_box">
                            <button @click.stop="noLongerRemind">{{ $t('i18n.common.components.comEditForm.noRemind') }}</button>
                            <button>{{ $t('i18n.common.components.comEditForm.view') }}</button>
                        </div>
                    </div>
                </f-button>
            </f-tooltip>

            <hidden-param
                ref="hiddenParam"
                v-if="userInfo && userInfo.userType == 1"
                :paramObj="currParamObj"
                @success="getCollapseItemsOrder()"
                :code="customComponentsCode">
            </hidden-param>
        </div>
        <div class="edit-form-cont">
            <slot>
                <f-collapse v-model="actives" size="mini">
                    <f-form
                        ref="editForm"
                        :model="formData"
                        :size="mixSize"
                        :label-width="formLabelWidth ? formLabelWidth : mixLabelWidth"
                        :labelPosition="mixLabelPosition"
                        :disabled="formDisabled"
                        :rules="currRules"
                        :column="verticalForm !== null && verticalForm ? 1 : mixColumn"
                        :gutter="mixGutter"
                        :showInvalidPrompt="false"
                        :next-enter="nextEnter"
                    >
                        <template v-for="item in currentCollapseItems">
                            <f-collapse-item
                                v-if="item.iframeUrl && (item.visible == undefined ? true : item.visible) && (item.exist == undefined ? true : item.exist)"
                                :id="'collapse-iframe-' + item.id + '-' + item.name"
                                :key="'collapse-iframe-' + item.id + '-' + item.name"
                                :ref="'collapse-iframe-'+ item.name"
                                :title="item.title"
                                :name="item.name"
                                :iframeUrl="item.iframeUrl + '?pageType=' + formType"
                                iframeHeight="300px">
                            </f-collapse-item>
                            <f-collapse-item
                                :ref="'collapse' + item.name"
                                v-if="!item.iframeUrl && (item.visible == undefined ? true : item.visible) && (item.exist == undefined ? true : item.exist)"
                                :id="'collapse' + item.name"
                                :key="item.name"
                                :title="item.title"
                                :name="item.name"
                                :disabled="item.disabled"
                            >
                                <!-- 当为default的显示隐藏时才使用此render -->
                                <render-form-item
                                    v-if="hiddenParamConfig.type == 'default'"
                                    :hiddenParams="hiddenParams"
                                    :hiddenParamsVal="hiddenParamsVal"
                                    :renderModel="fieldConfig"
                                    :disabled="formDisabled"
                                    @getParamsArr="(arr)=>getParamsArr(arr, item.name)"
                                    :verticalForm="verticalForm">
                                    <slot :name="item.name"></slot>
                                </render-form-item>
                                <div v-else :style="curStyle">
                                    <slot :name="item.name"></slot>
                                </div>
                            </f-collapse-item>
                        </template>
                    </f-form>
                </f-collapse>
            </slot>
        </div>
        <div v-if="formType != 'approval'" class="edit-form-btn" :style="{ width: formBtnWidth }">
            <div class="edit-form-point" v-if="isPoint">
                <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                <div class="edit-form-point-wrap">
                    <template v-for="(item, i) in currentCollapseItems">
                        <a
                            :href="item.iframeUrl ? ('#collapse-iframe-' + item.id + '-' + item.name) : ('#collapse' + item.name)"
                            class="edit-form-point-item"
                            v-if="(item.visible == undefined ? true : item.visible)  && (item.exist == undefined ? true : item.exist)"
                            :class="[item.name === activePoint ? 'active' : '']"
                            :key="item.name"
                            @click="jumpItem(item)"
                        >
                            <i class="el-icon" :class="item.icon ? item.icon : 'el-icon-info'"></i>
                            {{ item.title }}
                        </a>
                    </template>
                </div>

            </div>
            <slot name="buttons">
                <div class="buttons-wrap">
                    <f-button v-if="formType == 'edit'" type="primary" :size="mixSize" @click="submitForm">
                        {{ $t('i18n.common.submitText') }}
                    </f-button>
                    <!-- 新增时保存按钮为蓝色 -->
                    <f-button v-if="formType == 'add'" type="primary" :size="mixSize" @click="saveForm">
                        {{ $t('i18n.common.saveText') }}
                    </f-button>
                    <!-- 编辑时保存按钮为空心的 -->
                    <f-button v-if="formType == 'edit'" plain :size="mixSize" @click="saveForm">
                        {{ $t('i18n.common.saveText') }}
                    </f-button>
                    <f-button v-if="formType != 'view'" plain :size="mixSize" @click="back">{{ $t('i18n.common.returnText') }}</f-button>
                    <f-button v-if="formType == 'view'" type="primary" class="view-back-btn" :size="mixSize" @click="viewBack">{{ $t('i18n.common.closeText') }}</f-button>
                </div>

            </slot>
        </div>
    </div>
</template>

<script>
import { styleMix, childNavMix ,formUtilsMix} from '@/common/comMixin';
import { setStyle } from '@iss/vmui-vue/lib/utils/dom';
import throttle from 'throttle-debounce/throttle';
import RenderFormItem from './render-form-item.js';
import HiddenParam from './hidden-param.vue'
import {hiddenParamCollapseMix} from '@/common/comMixin/hiddenParamMix.js';
import { addResizeListener, removeResizeListener } from '@iss/vmui-vue/lib/utils/resize-event';
import storageUtils from '@/common/utils/storageUtils';
import utils from "@/common/utils";
/**
 * 封装新增,编辑,查看表单功能
 * 1.提供折叠面板动态配置方式
 * 2.提供锚点功能
 * 3.提供表单保存/提交前的验证
 * 4.提供表单保存事件
 * 5.提供表单提交事件
 * 6.提供返回事件
 * 7.提供自定义折叠面板的功能slot:default
 * 8.提供自定义按钮的功能slot:buttons
 */
export default {
    name: 'ComEditForm',
    mixins: [styleMix, childNavMix, hiddenParamCollapseMix,formUtilsMix],
    components: {
        RenderFormItem,
        HiddenParam
    },
    provide() {
        return {
            ComEditForm: this
        };
    },
    inject: {
        rootNav: {
            default: {}
        }
    },
    data() {
        return {
            // 折叠面板顺序配置弹出层是否展示
            customPopVisible: false,
            // 折叠面板顺序配置弹出层 -- 页签 active项
            tabActiveName: 'order',
            // 折叠面板active项
            actives: [],
            // 锚点active项
            activePoint: '',
            // 表单是否禁用
            formDisabled: false,
            // collapseItemsDefault转换为对象进行存放
            collapseItemsDefaultObj: {},
            // 当前的collapseItem
            currentCollapseItems: [],
            // 折叠面板项顺序设置显示的tag的list
            collapseItemsTagsList: [],
            // 折叠面板项的顺序
            collapseItemsOrder: [],
            // 当前拖动的对象数据
            draggingItem: null,
            // 当前被放置的对象数据
            droppinItem: null,
            // 拖动的类别
            dragType: '',
            // 拖动时的线条
            showDropIndicator: false,
            isPopView: false, // 判断是否为pop层弹出, 需要给出不同的样式
            formBtnWidth: '',
            hiddenParams: [], // 存放隐藏字段名称
            hiddenParamsVal: {}, // 存放隐藏字段默认值
            paramObj: {}, // 字段信息对象
            iframeInfo: [ // 需要嵌入的iframe的配置信息
                // {
                //     id: 1, // 必须唯一
                //     title: 'iframe内容', // 必须
                //     name: 'iframe-1', // 必须
                //     active: true, // 必须,默认给true
                //     icon: 'el-icon-info',
                //     iframeUrl: 'http://www.cms.com/demo.html#/demo/iframeForm' // 必须
                //     visible: false // 必须,默认给false
                // }
            ],
            iframeInfoObj: {}, // 存储iframe折叠面板数据, 方便取用
            dataLoaded: {
                iframeInfo: false,// iframe数据已经加载完毕
                collapseItemsOrder: false, // 折叠面板数据是否已经加载完毕
            },
            operationGuidelines: true,
            operationGuide: true,
            customRequired: {}, // 字段是否必填对象
            currParamObj: {}, // 传入隐藏字段的数据的对象
            templateFormData: {},//临时的formData
            fieldConfig:[],
            autoSaveTimer: null,//自动保存的定时器
        };
    },
    props: {
        /**
         * 表单类别
         */
        formType: {
            type: String,
            default: 'add',
            required: true
        },
        nextEnter:{
            type: Boolean,
            default: false
        },
        /**
         * 折叠面板配置
         */
        collapseItemsDefault: {
            type: Array,
            required: true
        },
        /**
         * 折叠面板顺序查询请求地址
         */
        collapseItemOrderUrl: String,
        fieldConfigUrl:{
            type: String,
            default: ''
        },
        /**
         * 折叠面板顺序设置保存请求地址
         */
        collapseItemOrderSaveUrl: String,
        titleConfig: Object,
        formData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        formRules: {
            type: Object,
            default: () => {
                return {};
            }
        },
        custom: {
            type: Boolean,
            default: true
        },
        /**
         * 组件编号
         */
        customComponentsCode: {
            type: String
        },
        /**
         * 设置表单项label的宽度
         */
        formLabelWidth: String,
        beforeSaveForm: {
            type: Function,
            default: function() {
                return true;
            }
        },
        // 是否启用锚点
        isPoint : {
            type: Boolean,
            default: true
        },
        hiddenParamConfig: {
            type: Object,
            default: () => {
                return {
                    // type可以为default : 使用renderFormItem去寻找fformitem并进行显示隐藏
                    // type可以为custom  : 需要开发人员在fformitem上添加指令处理显示隐藏
                    type: 'default',
                    hiddenParamList: {}, //  当type为custom的时候 需要开发人员传入字段列表数据
                    hiddenParams: [] // 当type为custom的时候 需要开发人员定义此参数
                }
            }
        },
        formIframeInfoUrl: String, // 获取嵌入的iframe的数据地址
        formDataLoaded: Boolean, // 标记表单数据是否已经准备ok
        verticalForm: {
            type: [Object, Boolean],
            defualt: null
        }, // 表单是否为垂直居中显示 verticalForm.width [string] 可以配置表单的宽度
        customApprovalValid: {
            type: Function,
            default: function() {
                return true;
            }
        },
        isAutoSave:{
            type: [Object ,Boolean],
            defualt: null
        },
    },
    created() {
        window.editForm = {
            getEditFormData: this.getEditFormData,
        }
        this.init();
        this.getAnOperationGuidelines()
    },
    mounted() {
        this.$bus.$on('changeColumn', num => {
            this.mixColumn = num;
        });
        this.throttledResizeHandler = throttle(300, this.resizeHandle);
        this.throttledResizeHandler();
        window.addEventListener('resize', this.throttledResizeHandler);
        addResizeListener(this.$el, this.throttledResizeHandler);

        if((this.formType == "add" && (this.isAutoSave == null || this.isAutoSave )) || (this.formType != "add" && this.isAutoSave )){//增加缓存表单
            let local = localStorage.getItem(this.customComponentsCode);
            if(!local || local === ''){
                localStorage.setItem(this.customComponentsCode,JSON.stringify(this.formData));
            }else{
                this.$success(this.$t('i18n.common.components.form.autoFrom'));
                Object.assign(this.formData,JSON.parse(local));
            }
            let tips = 1;
            this.autoSaveTimer = setInterval(()=>{
                if(tips == 10){
                    this.$success(this.$t('i18n.common.components.form.autoSaveForm'));
                }
                tips ++;
                localStorage.setItem(this.customComponentsCode,JSON.stringify(this.formData));
            },1000)
        }
    },
    beforeDestroy() {
        this.$bus.$off('changeColumn');
        window.removeEventListener('resize', this.throttledResizeHandler);
        if (this.$el && this.throttledResizeHandler) removeResizeListener(this.$el, this.throttledResizeHandler);
        this.iframeInfo.forEach(item => {
            let eventName = 'iframeComponent' + item.name;
            this.$bus.$off(eventName);
        });
        delete window.editForm;
    },
    methods: {
        /**
         * 组件的初始化
         */
        init() {
            this.code = this.customComponentsCode ? this.customComponentsCode : '';
            this.initIframeInfo()
            // 1.获取item项顺序的数据, 成功获取时以后台数, 否则赋值default的配置的数据
            if (this.collapseItemsDefault && this.collapseItemsDefault.length) {
                this.initCollapseItems();
            }
            if (this.collapseItemOrderUrl) {
                // 获取formItems的顺序
                this.getCollapseItemsOrder();
            } else {
                this.dataLoaded.collapseItemsOrder = true;
            }
            switch (this.formType) {
                case 'add':
                    break;
                case 'edit':
                    break;
                case 'view':
                    // 当是查看页面时,对表单disabled处理
                    this.formDisabled = true;
                    break;
                case 'approval':
                    // 当是审批流页面时,对表单disabled处理
                    this.formDisabled = true;
                    break;
                default:
                    break;
            }
        },
        /**
         * 初始化嵌入的iframe信息
         */
        initIframeInfo() {
            if (!this.formIframeInfoUrl) {
                this.dataLoaded.iframeInfo = true;
                return;
            }
            this.$http
                .get(this.formIframeInfoUrl+this.code ).then((res) => {
                    if (res.code == 0) {
                        this.iframeInfo = res.data.map((item) => {
                            return {
                                id: item.id,
                                title: item.iframeTitle,
                                name: item.iframeName,
                                active: item.iframeActive,
                                visible: false,
                                iframeUrl: item.iframeUrl,
                                iframeHeight: item.iframeHeight,
                                order: item.iframeOrder,
                                exist: true // // iframem默认就是true
                            }
                        });
                        this.iframeInfo.forEach(item => {
                            this.iframeInfoObj[item.name] = Object.assign(item, {
                                iframeCreatedLoaded: false, // 标记iframe的created,methods是否已经加载完成
                                iframeVm: null, // 存放iframe组件对象
                                waitFun: [] // 存放需要在iframe组件对象加载时需要执行的方法
                            });
                            if(item.active) {
                                this.actives.push(item.name);
                            }
                            (function bindEvent(name, currentVm){

                                let eventName = 'iframeComponent' + name;
                                currentVm.$bus.$on(eventName, (iframeVm) => {
                                    // 标记iframe的created,methods已经加载完成
                                    currentVm.iframeInfoObj[name].iframeCreatedLoaded = true;
                                    currentVm.iframeInfoObj[name].iframeVm = iframeVm;
                                    let { waitFun } = currentVm.iframeInfoObj[name];
                                    let { changeFormData } = iframeVm;
                                    let doWaitFun = new Set(waitFun);
                                    // iframe的created执行完成时
                                    try {
                                        // 去请求数据
                                        changeFormData(currentVm.formData);
                                        if(doWaitFun.length > 0){
                                            // 依次执行等待中的函数
                                            while (doWaitFun.length) {
                                                let funName = doWaitFun.shift();
                                                iframeVm[funName](currentVm.formData);
                                            }
                                        }
                                    } catch (error) {
                                        console.log('iframe中未定义的方法被使用了')
                                    }

                                })
                            })(item.name, this)
                        });
                    }
                    this.dataLoaded.iframeInfo = true;
                });
        },
        /**
         * 对collapseitem的顺序进行初始化操作
         * 1.根据设置的collapseItemsDefault,以其中的name值作为key,item项做为值生成collapseItemsDefaultObj,方便后续的排序操作
         * 2.根据设置的collapseItemsDefault,初始化collapseItemsOrder的值
         * 3.获取后台已保存的顺序值
         */
        initCollapseItems() {
            let collapseItemsDefaultObj = {};
            this.collapseItemsDefault.forEach((item, i) => {
                if (item.name) {
                    collapseItemsDefaultObj[item.name] = Object.assign(item, {
                        // 初始化的exist给true
                        exist: item.exist == undefined ? true : false,
                    });
                    // 默认的排序
                    this.collapseItemsOrder.push({
                        name: item.name,
                        exist: item.exist,
                    });
                }
                if (i === 0) {
                    // 默认第一个锚点高亮状态
                    this.activePoint = item.name;
                    // 默认第一个折叠面板是打开的状态
                    item.active = true;
                }
                if (item.active) {
                    // 初始化折叠面板的打开状态
                    this.actives.push(item.name);
                }
            });
            // 将fcollapseItemsDefault数组转换成Object的形式,方便后面好根据name值拿到对应的collapseItem的配置
            this.collapseItemsDefaultObj = collapseItemsDefaultObj;
        },
        /**
         * 获取后台已经保存的顺序值
         * 1.如果请求成功这里将按请求返回回的顺序值进行渲染
         * 2.请求失败或者没有保存过顺序数据是按collapseItemsDefault的顺序进行渲染
         */
        getCollapseItemsOrder() {
            // 这里向后台请求collapseItems的顺序值
            let hiddenFun = this.$http.post(this.collapseItemOrderUrl, {
                code: this.code
            }).then(res => {
                return Promise.resolve(res.data);
            }).catch(() => {
                this.dataLoaded.collapseItemsOrder = true;
            });

            let otherFun = Promise.resolve(undefined);
            if(this.$route.query && this.$route.query.approvalField){
                this.fieldConfigUrl = this.fieldConfigUrl ? this.fieldConfigUrl : this.$api.common.fieldConfigUrl;
                console.log('otherFun',this.fieldConfigUrl);
                otherFun = this.$http.post(this.fieldConfigUrl, {
                    code: this.$route.query.code
                }).then(res => {
                    console.log('otherFun',res.data);
                    return Promise.resolve(res.data);
                }).catch(() => {
                    return Promise.resolve(undefined);
                });
            }

            this.$http.all([hiddenFun,otherFun]).then(
                this.$http.spread((res1,res2)=>{

                    if(res2){
                        let errorNumber = 0;
                        this.formDisabled = false;
                        let loading = null;
                        let time = setInterval(()=>{
                            if(loading == null){
                                loading = this.$loading({
                                  lock: true,
                                  text: 'Loading',
                                  spinner: 'el-icon-loading',
                                  background: 'rgba(0, 0, 0, 0.7)'
                                });
                            }
                            res2.forEach(item=>{
                                this.templateFormData[item.prop] = this.formData[item.prop];

                                if(this.templateFormData[item.prop]){
                                    let _isValidValue = item.isValidValue !== undefined ?item.isValidValue:true;
                                    console.log('templateFormData',_isValidValue,item.prop)
                                    if(_isValidValue) this.formData[item.prop] = '';
                                    loading.close();
                                    clearInterval(time);
                                }
                            })
                            errorNumber++;
                            if(errorNumber > 3){
                                loading.close();
                                clearInterval(time);
                            }
                        },500)
                        this.fieldConfig = res2;
                    }


                    if (res1) {
                        let hiddenParams = [];
                        let hiddenParamsVal = {};
                        let customRequired = {}
                        if(res1 && res1.hiddenParams && res1.hiddenParams[0]){
                            Object.keys(res1.hiddenParams[0]).forEach((item) => {
                                res1.hiddenParams[0][item].forEach((item1) => {
                                    if(!item1.showOrHide) {
                                        hiddenParams.push(item1.prop);
                                    }
                                    if(item1.defaultVal) {
                                        hiddenParamsVal[item1.prop] = item1.defaultVal;
                                        let defaultVal = JSON.parse(item1.defaultVal);
                                        for (let key in defaultVal) {
                                            if(defaultVal[key] === "$now"){
                                                if(this.model[key] instanceof Array){
                                                    this.model[key] = [utils.formatDateOnly(new Date()),utils.formatDateOnly(new Date())];
                                                }else{
                                                    this.model[key] = utils.formatDateOnly(new Date());
                                                }
                                            }else{
                                                this.formData[key] = defaultVal[key];
                                            }
                                        }
                                    }
                                    if (item1.required !== undefined && item1.required !== 1) {
                                        customRequired[item1.prop] = item1.required;
                                    }
                                });
                            })
                        }
                        this.customRequired = customRequired;
                        if (this.hiddenParamConfig.type === 'custom') {
                            this.hiddenParamConfig.hiddenParams = hiddenParams;
                            let hiddenParamObj = this.mixFilterHiddenParams(this.hiddenParamConfig.hiddenParamList, this.collapseItemsDefault);
                            Object.keys(hiddenParamObj).forEach(item => {
                                hiddenParamObj[item].forEach(item1 => {
                                    Object.assign(item1, {
                                        showOrHide: hiddenParams.includes(item1.prop) ? false : true,
                                        defaultVal: hiddenParamsVal[item1.prop] ? hiddenParamsVal[item1.prop] : '',
                                        required: customRequired[item1.prop] !== undefined ? customRequired[item1.prop] : 1
                                    })
                                })

                            })
                            this.currParamObj = this.paramObj = Object.assign(this.paramObj, hiddenParamObj);

                            //console.log(this.currParamObj,55555555)
                        } else {
                            this.debounce(this.setCurrParamObj)();
                        }
                        this.hiddenParams = hiddenParams;
                        this.hiddenParamsVal = hiddenParamsVal;
                    }
                    //console.log(this.hiddenParams,this.hiddenParamsVal,this.hiddenParamConfig, this.collapseItemsDefault)
                    this.customOrder = res1.customOrder;
                    this.dataLoaded.collapseItemsOrder = true;
                })
            );


        },
        /**
         * 验证返回的自定义配置数据与组件代码中定义的组件数据是否完全匹配
         * 完全匹配则返回true
         * 有1个不匹配则返回false
         */
        validCollapseItemsData() {
            let data = this.customOrder;
            if (!data || data.length !== (this.collapseItemsDefault.length + this.iframeInfo.length)) {
                return false;
            }
            let flag = false;
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (!this.collapseItemsDefaultObj[item.name] && !this.iframeInfoObj[item.name]) {
                    break;
                } else if (i === data.length - 1) {
                    flag = true;
                }
            }

            return flag;
        },
        setDefaultOrder() {
            this.iframeInfo.forEach(item => {
                if (item.order) {
                    this.collapseItemsOrder.splice(item.order - 1, 0, {
                        name: item.name
                    });
                } else {
                    this.collapseItemsOrder.push({
                        name: item.name
                    });
                }
            });
            this.defaultOrder = JSON.parse(JSON.stringify(this.collapseItemsOrder));
        },
        setCollapseItemsOrder() {
            /**
             * 1.如果custom为false或者collapseItemOrderUrl不存在
             *  1.1 查找iframeInfo中的order配置, 如果没有order,则默认排在最后
             * 2.如果custom为true且collapseItemOrderUrl存在
             *  2.1 直接按请求返回的order进行排序, 此时需要校验order是否与当前页面配置的default和iframe的item完全匹配,如果不匹配按1的规则处理
             */
            this.setDefaultOrder();
            if (!this.custom || !this.collapseItemOrderUrl || !this.validCollapseItemsData()) {
                this.collapseItemsOrder = JSON.parse(JSON.stringify(this.defaultOrder));
            } else {
                this.collapseItemsOrder = JSON.parse(JSON.stringify(this.customOrder));
            }
            console.log('currentCollapseItems',this.collapseItemsOrder,this.collapseItemsDefaultObj);
            // 设置当前展示的formItems, 这里是为了将当前的和默认的配置区分开
            this.currentCollapseItems = this.collapseItemsOrder.map(item => {
                if (this.collapseItemsDefaultObj[item.name]) {
                    return Object.assign(this.collapseItemsDefaultObj[item.name], {
                        // 将数据库中的exist值复制到collapseItemsDefaultObj上
                        exist: item.exist == undefined ? true : item.exist,
                    });
                } else if (this.iframeInfoObj[item.name]) {
                    return this.getIframeCollapseItem(this.iframeInfoObj[item.name])
                } else {
                    return item
                }
            });
        },
        updateCollapseItemsOrder() {
            this.currentCollapseItems = this.collapseItemsOrder.map(item => {
                if (this.collapseItemsDefaultObj[item.name]) {
                    return Object.assign(this.collapseItemsDefaultObj[item.name], {
                        // 修改面板隐藏属性
                        exist: item.exist
                    });
                } else if (this.iframeInfoObj[item.name]) {
                    return this.getIframeCollapseItem(this.iframeInfoObj[item.name])
                } else {
                    return item
                }
            });
            console.log('currentCollapseItems',this.currentCollapseItems,this.collapseItemsOrder,this.collapseItemsDefaultObj);
        },
        getIframeCollapseItem(item) {
            let {iframeVm, waitFun, ...iframeItem} = item;
            return iframeItem;
        },
        /**
         * 点击锚点事件
         */
        jumpItem(item /*, index*/) {
            if (this.actives.indexOf(item.name) < 0) {
                // 打开相应的折叠面板
                this.actives.push(item.name);
            }
            if (this.isPopView) {
                // 如果是pop层弹出
            } else {
                //否则认为是一个完整的页面
                // 滚动到相应的折叠面板项
               /* let offsetTop = this.$refs['collapse' + item.name][0].$el.offsetTop;
                window.scrollTo(0, offsetTop);*/
                // 高亮当前所处在的锚点
                this.activePoint = item.name;
            }
        },
        /**
         * 校验iframe表单
         */
        validateIframeForm() {
            // 当有显示的iframe时,先校验所有的iframe表单数据
            return new Promise((resove, reject) => {
                let iframeValidates = []
                this.iframeInfo.forEach((item) => {
                    if (item.visible) {
                        iframeValidates.push(item.iframeVm.validate(this.formData));
                    }
                });
                if (iframeValidates.length > 0) {
                    Promise.all(iframeValidates).then((values) => {
                        let flag = values.every((item) => item)
                        if (flag) {
                            resove();
                        }
                    })
                } else {
                    resove();
                }
            })
        },
        saveOrSubmitIframeForm(name) {
            return new Promise((resove, reject) => {
                let iframeForms = []
                this.iframeInfo.forEach((item) => {
                    if (item.visible) {
                        iframeForms.push(item.iframeVm[name]());
                    }
                });
                if (iframeForms.length > 0) {
                    Promise.all(iframeForms).then((values) => {
                        let masseges = [];
                        values.forEach(item => {
                            if(item.code != 0) {
                                masseges.push(item.msg);
                            }
                        });
                        if (masseges.length > 0) {
                            this.$error(masseges.join(','));
                        } else {
                            resove();
                        }
                    })
                } else {
                    resove();
                }
            })
        },
        /**
         * 保存表单操作
         */
        saveForm() {
            this.validateIframeForm().then(() => {
                let flag = true;
                let result;
                if (this.beforeSaveForm) {
                    result = this.beforeSaveForm();
                }
                // 当返回的是一个function的时候, function内部必须返回一个promise对象
                // 此是为了处理异步情况
                if(typeof result == 'function') {
                    result().then(data => {
                        this.validateForm(data, 'saveForm');
                    }).catch((data)=> {
                        this.validateForm(data, 'saveForm');
                    });
                } else {
                    flag = result;
                    this.validateForm(flag, 'saveForm');
                }
                localStorage.removeItem(this.customComponentsCode)
                clearInterval( this.autoSaveTimer );
            });
        },
        validateForm(flag, emitName) {
            this.$refs.editForm.validate(valid => {
                if (valid && flag) {
                    this.saveOrSubmitIframeForm(emitName).then(() => {
                        this.$emit(emitName);
                    })
                }
            });
        },
        /**
         * 提交表单操作
         */
        submitForm() {
            this.validateIframeForm().then(() => {
                let flag = true;
                let result;
                if (this.beforeSaveForm) {
                    result = this.beforeSaveForm();
                }
                // 当返回的是一个function的时候, function内部必须返回一个promise对象
                // 此是为了处理异步情况
                if(typeof result == 'function') {
                    result().then(data => {
                        this.validateForm(data, 'submitForm');
                    }).catch((data)=> {
                        this.validateForm(data, 'submitForm');
                    });
                } else {
                    flag = result;
                    this.validateForm(flag, 'submitForm');
                }
                localStorage.removeItem(this.customComponentsCode)
                clearInterval( this.autoSaveTimer );
            });
        },
        /**
         * 返回操作
         */
        back() {
            this.$emit('back');
        },
        viewBack() {
            let that = this;
            if (this.rootNav && this.rootNav.closeRootNav) {
                this.rootNav.closeRootNav(that);
                if(this.$el.parentNode.offsetParent.className.indexOf('rootnav__content') == 0){
                    var arry = top.document.getElementsByTagName('iframe');
                    for(let i =0 ;i <arry.length;i++ ){
                        if(arry[i].src == location.href){
                            let thisIframe = arry[i].parentNode;
                            let id = thisIframe.getAttribute('aria-labelledby');
                            let thisTab = top.document.getElementById(id);
                            thisIframe.remove();thisTab.remove();
                        }
                    }
                }
            }
        },
        getForm() {
            return this.$refs.editForm;
        },
        /**
         * 设置sCollapseItem的属性值
         */
        setCollapseItemProp(name, obj) {
            this.currentCollapseItems.forEach(item => {
                if (item.name == name) {
                    Object.assign(item, obj);
                }
            });
        },
        /**
         * 折叠面板更改顺序--保存按钮操作
         * 1.保存当前更改后的顺序数据
         * 2.重新向后台获取顺序数据
         * 3.保存完成后关闭pop层
         */
        saveFormItemsOrder() {
            if (!this.collapseItemOrderSaveUrl) {
                console.log('折叠面板顺序保存url不存在!');
                return;
            }
            this.$http
                .post(
                    this.collapseItemOrderSaveUrl,
                    JSON.stringify({
                        code: this.code,
                        collapseItemsOrder: this.collapseItemsOrder
                    })
                )
                .then(res => {
                    if (res.code == 0) {
                        this.$success(this.$t('i18n.common.success'));
                        // 重新请求已保存的查询条件的数据
                        this.updateCollapseItemsOrder()
                    } else {
                        this.$error(res.msg);
                    }
                    this.customPopVisible = false;
                });
        },
        /**
         * 折叠面板更改顺序--重置按钮按钮操作
         * 1.将collapseItemstagsList重置到collapseItemsDefault的顺序
         */
        orderSettingResetClick() {
            this.setCollapseItemsTagsList();
        },
        /**
         * 表单更改顺序--取消按钮操作
         * 1.将collapseItemstagsList重置到当前的顺序
         * 2.关闭pop层
         */
        orderSettingCancelClick() {
            this.customPopVisible = false;
            // 将collapseItemstagsList重置到当前的折叠面板顺序状态
            this.setCollapseItemsTagsList(this.currentCollapseItems);
        },
        setCollapseItemsTagsList(collapseItems) {
            if (!collapseItems) {
                collapseItems = JSON.parse(JSON.stringify(this.collapseItemsDefault));

                this.iframeInfo.forEach(item => {
                    if (item.order) {
                        collapseItems.splice(item.order - 1, 0, item);
                    } else {
                        collapseItems.push(item);
                    }
                });
            }

            this.collapseItemsTagsList = collapseItems.map(item => {
                return {
                    name: item.name,
                    title: item.title,
                    visible: item.visible == undefined ? true : false,
                    exist: item.exist,
                };
            });

        },
        /**
         * 更改顺序拖动事件
         */
        handleOrderDragStart(event, item, index) {
            this.draggingItem = {
                el: event.target,
                data: item,
                index: index
            };
        },
        handleOrderDragEnd() {
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragEnter(event, item, index) {
            let target = event.target;
            let draggingItemIndex = this.draggingItem.index;
            let droppinItemIndex = index;
            if (droppinItemIndex == draggingItemIndex) {
                // 当放置区域就是本身拖动的对象时不做处理
                return;
            } else if (droppinItemIndex > draggingItemIndex) {
                // 向后拖动
                this.dragType = 'after';
            } else {
                // 向前拖动
                this.dragType = 'before';
            }
            let dropIndicator = this.$refs.dropIndicator;
            let top = target.offsetTop;
            let left = this.dragType == 'before' ? target.offsetLeft - 6 : target.offsetLeft + 6 + target.offsetWidth;
            setStyle(dropIndicator, {
                top: top + 'px',
                left: left + 'px'
            });
            // 显示drop位置线条
            this.showDropIndicator = true;
        },
        handleOrderDragLeave() {
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragOver(event) {
            // 这里需要阻止默认的事件,才会执行drop事件
            event.preventDefault();
        },
        handleOrderDrop(event, item, index) {
            event.preventDefault();
            let draggingItemIndex = this.draggingItem.index;
            let droppingItemIndex = index;
            let newArr = [];
            let oldArr = this.collapseItemsTagsList;
            if (droppingItemIndex == draggingItemIndex) {
                return;
            }
            oldArr.forEach((item, i) => {
                if (i == droppingItemIndex && this.dragType == 'after') {
                    // 向后时需要再droppingItemIndex的后面插入draggingitem的数据
                    newArr.push(item);
                    newArr.push(this.draggingItem.data);
                } else if (i == droppingItemIndex && this.dragType == 'before') {
                    // 向后时需要再droppingItemIndex的前面插入draggingitem的数据
                    newArr.push(this.draggingItem.data);
                    newArr.push(item);
                } else if (i !== draggingItemIndex) {
                    // 当i不在draggingItemIndex及droppingItemIndex时直接添加item数据
                    newArr.push(item);
                }
            });
            this.collapseItemsTagsList = newArr;
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        resizeHandle() {
            this.$nextTick(() => {
                this.formBtnWidth = this.$el.offsetWidth - 16 > 0 ? this.$el.offsetWidth - 16 + 'px' : '100%';
            });
        },
        /**
         * 打开隐藏字段设置弹窗
         */
        openHiddenParam() {
            this.$refs.hiddenParam.open();
        },
        /**
         * 获取字段列表
         * arr: 当type为default的时候 arr为数组, 当type为custom的时候arr为对象
         */
        getParamsArr(arr, name) {
            if (this.hiddenParamConfig.type === 'default') {
                this.$set(this.paramObj, name, arr)
                // 这里设置调用间隔时间,间隔时间内不调用
                let fn = this.debounce(this.setCurrParamObj);
                fn();
            }
        },
        /**
         * 简单的防抖
         */
        debounce(fun, wait = 100) {
            return (...args) => {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    fun.apply(this, args);
                }, wait);
            }
        },
        /**
         * 给隐藏指端设置数据
         */
        setCurrParamObj() {
            let obj = {};
            //console.log(222222, this.paramObj, this.customRequired)
            for (const key in this.paramObj) {
                if (Object.hasOwnProperty.call(this.paramObj, key)) {
                    const elem = this.paramObj[key];
                    obj[key] = elem.map(item => {
                        let required = 1;
                        if (this.customRequired[item.prop] !== undefined) {
                            console.log(555)
                            required = this.customRequired[item.prop]
                        }
                        return {
                            ...item,
                            required: required
                        }
                    })
                }
            }
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    const elem = obj[key];
                    this.$set(this.currParamObj, key, elem);
                }
            }
            //console.log(1111, this.currParamObj)
        },
        /**
         * 加载或者删除iframe
         * @param {iframeName} string 需要加载或删除的iframe的名称
         * @param {flag} Boolean true:加载iframe  false:删除iframe
         */
        setIframe(iframeName, flag) {
            if (!this.validateHasIframe(iframeName)) {
                return;
            }
            this.iframeInfoObj[iframeName].visible = flag;
            this.currentCollapseItems.forEach(item => {
              if (item.name === iframeName) {
                item.visible = flag;
              }
            });
            if (flag === false) {
                Object.assign(this.iframeInfoObj[iframeName], {
                    iframeCreatedLoaded: false,
                    iframeVm: null,
                    waitFun: []
                });
            }
        },
        /**
         * 只刷新iframe中的数据
         * @param {iframeName} string 要调刷新数据的iframe的名称
         */
        reloadIframeData(iframeName){
            if (!this.validateHasIframe(iframeName)) {
                return;
            }
            let iframeVm = this.iframeInfoObj[iframeName].iframeVm;
            if(iframeVm) {
                // 存在这个iframe的时候才去刷新
                iframeVm.changeFormData(this.formData)
            }
        },
        /**
         * 提供统一的调用iframe组件内部的定制化方法的方法
         * @param {iframeName} string 要调用的iframe的名称
         * @param {funName} string 要调用的iframe中的方法名
         * @param {waitLoaded} Boolean 因为在父组件中调用的时候iframe有可能还没加载完,配置为true的时候在此种情况会在iframe加载完成之后去执行一遍
         */
        executionIframeFun(iframeName, funName, waitLoaded = false) {
            if (!this.validateHasIframe(iframeName)) {
                return;
            }
            let iframeVm = this.iframeInfoObj[iframeName].iframeVm;
            if(iframeVm) {
                // 存在这个iframe的时候直接去调用它的方法
                iframeVm[funName](this.formData);
            } else if (waitLoaded) {
                // 当配置了等待加载完成之后去调用时
                this.iframeInfoObj[iframeName].waitFun.push(funName);
            }
        },
        /**
         * 判断是否存在该iframe
         */
        validateHasIframe(iframeName) {
            return !!this.iframeInfoObj[iframeName];
        },
        getEditFormData() {
            return this.formData;
        },
        /**
         * 打开操作指引弹窗
         */
        openOperationGuide() {
            this.$bus.$emit('operationGuideOpen', this.code + this.formType);
        },
        /**
         * 操作指引不再提醒
         */
        noLongerRemind(){
            window.localStorage.setItem('noLongerRemind','YES')
            this.operationGuidelines = false
            this.operationGuide = false
        },
        /**
         * 获取操作指引提示框状态
         */
        getAnOperationGuidelines(){
            const status = window.localStorage.getItem('noLongerRemind')
            if(status === 'YES'){
                this.operationGuidelines = false
            }
        }
    },
    computed: {
        userInfo: {
            get() {
                return JSON.parse(storageUtils.getItem('userInfo')).loginUserInfo;
            }
        },
        curStyle() {
            let style = {
                display: 'flex',
                flexWrap: 'wrap'
            }
            if (this.verticalForm !== null && this.verticalForm) {
                style = {
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: this.verticalForm.width || '800px',
                    margin: '0 auto'
                }
            }
            return style;
        },
        currRules() {
            // 对this.rules进行深拷贝
            let rules = {};
            Object.keys(this.formRules).forEach( key=> {
                rules[key] = this.formRules[key].map(item=> {
                    return {
                        ...item
                    }
                })
            })
            Object.keys(this.customRequired).forEach(key => {
                let codeRules = rules[key] || [];
                // 用户设置的是否必填
                let customRequired = this.customRequired[key];
                // required: 1默认 2必填  3非必填
                let rule = [{required: customRequired == 2 ? true : false, message: '不能为空'}];
                codeRules.forEach((item, i) => {
                    if(item.required == undefined) {
                        rule.push(item)
                    }
                });
                rules[key] = rule;
            });
            return rules;
        },
    },
    watch: {
        currentCollapseItems: {
            deep: true,
            handler(val) {
                // currentCollapseItems更新时, 需要同步更新tags的顺序
                this.setCollapseItemsTagsList(val);
            },
        },
        collapseItemsTagsList: {
            deep: true,
            handler(val) {
                // 当tags的顺序改变时, 需要更新formItemsOrder的顺序
                // 保存时是保存collapseItemsOrder中的值
                this.collapseItemsOrder = val.map(item => {
                    return {
                        name: item.name,
                        exist: item.exist
                    };
                });
            }
        },
        // iframeInfoLoaded: {
        //     immediate: true,
        //     handler(val) {
        //         if (val && this.formDataLoaded) {
        //             // 标记formData和iframeInfo都已经尊别就绪
        //             this.$emit('allDataLoaded')
        //         }
        //     }
        // },
        formDataLoaded: {
            immediate: true,
            handler(val) {
                if (val && this.dataLoaded.iframeInfo) {
                    // 标记formData和iframeInfo都已经尊别就绪
                    this.$emit('allDataLoaded')
                }
            }
        },
        dataLoaded: {
            deep: true,
            handler(val) {
                if (val.iframeInfo && this.formDataLoaded) {
                    // 标记formData和iframeInfo都已经准备就绪
                    this.$emit('allDataLoaded')
                }
                if (val.collapseItemsOrder && val.iframeInfo) {
                    // 处理collapseItems的顺序
                    this.setCollapseItemsOrder();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$tools-icon-btn-light-icon: #7784ab; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--图标颜色
$tools-icon-btn-light-icon-hover: #4577ff; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--图标颜色(hover状态)
$tools-icon-btn-light-icon-focus: #1d50db; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--图标颜色(foucs状态)
$tools-icon-btn-light-bg: #f5f5f5; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色
$tools-icon-btn-light-bg-hover: #ffffff; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色(hover状态)
$tools-icon-btn-light-bg-focus: #dfdfdf; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色(foucs状态)
$tools-icon-btn-light-border: #f5f5f5; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色
$tools-icon-btn-light-border-hover: #ffffff; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色(hover状态)
$tools-icon-btn-light-border-focus: #dfdfdf; // 编辑表单工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色(foucs状态)
.edit-form-wrap {
    background: #fff;
    border-radius:2px;
    padding: 16px 16px 64px 16px;
    margin: 8px;
    .el-form.f-form {
        margin: 0px;
    }
    .view-back-btn {
        // margin-right: 10px;
        // padding: 4px;
    }
    /deep/ .el-row {
        width: 100%;
    }
    /deep/ .el-form-item {
        margin: 6px 0;
        font-size: 12px;
        .el-form-item__label {
            font-size: 12px;
            color: #303030;
        }
    }
    /deep/ .el-input {
        border-radius: 2px;
    }
    /deep/ .el-input--mini .el-input__inner {
        height: 30px;
        line-height: 30px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
    }
    /deep/ .query-form-wrap .el-input--mini .el-input__inner {
        border: 1px solid #e4e4e4;
        border-radius: 2px;
    }
    /deep/ .el-input.is-disabled .el-input__inner,
    /deep/ .el-textarea.is-disabled .el-textarea__inner {
        background-color: #f7f8f9;
        border-color: #f3f3f3;
        color: #000;
    }
    /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner,
    /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner
    /deep/ .el-radio__input.is-disabled .el-radio__inner,
    /deep/ .el-radio__input.is-disabled.is-checked .el-radio__inner {
        background-color: #f7f7f7;
        border-color: #f3f3f3;
    }
    /deep/ .el-col {
        text-align: left;
    }
    .el-collapse-item {
        width: 100%;
    }
    /deep/ .is-partition-layout {
        /*border: 1px solid #eeeeee;
        box-shadow: 0px 0px 2px #d2d2d2;*/
        margin-bottom: 8px;
        border-radius: 4px;
        overflow: hidden;
    }
    /deep/ .el-form-item__content {
        text-align: left;
    }
    /deep/ .el-collapse-item__content {
        // color: #000;
        // display: flex;
        // flex-wrap: wrap;
    }

    /deep/ .el-collapse-item__header {
        background: #ebeff4;
        height: 32px;
        line-height: 32px;
    }
    /deep/.el-collapse-item.el-collapse-item--mini .f-collapse-item__header__title {
        font-size: 14px;
        border-radius: 2px;
    }
    /deep/.el-collapse-item .el-collapse-item__wrap {
        /*border-top:1px solid #f9f9f9;*/
    }
    &.view-form {
        .buttons-wrap{
            text-align: left;
        }
    }
    &.approval-form {
        padding-bottom: 8px;
    }
}
.edit-form-wrap.pop-view {
    position: relative;
    .edit-form-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .edit-form-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .edit-form-cont {
        position: absolute;
        bottom: 40px;
        top: 32px;
        right: 0;
        left: 0;
        overflow: auto;
    }
}
.edit-form-header {
    text-align: left;
    margin-bottom: 16px;
    .edit-form-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #46464c;
    }
    .edit-form-date {
        font-size: 12px;
        line-height: 22px;
        color: #bbbbbb;
        padding-left: 18px;
        margin-left: 18px;
        border-left: 1px solid #e5e5e5;
    }
    .edit-form-info {
        font-size: 12px;
        line-height: 22px;
        color: #bbbbbb;
    }
    .el-icon-info {
        color: #46be8a;
        margin-left: 40px;
    }
    .tools-icon-btn {
        float: right;
        color: $tools-icon-btn-light-icon;
        background: $tools-icon-btn-light-bg;
        border-color: $tools-icon-btn-light-border;
        padding: 2px;
        font-size: 16px;
        margin-left: 0 4px
        i {
            font-size: 18px;
            color: $tools-icon-btn-light-icon;
        }
        &:hover {
            color: $tools-icon-btn-light-icon-hover;
            background: $tools-icon-btn-light-bg-hover;
            border-color: $tools-icon-btn-light-border-hover;
            i {
                color: $tools-icon-btn-light-icon-hover;
            }
        }
        &:focus {
            color: $tools-icon-btn-light-icon-focus;
            background: $tools-icon-btn-light-bg-focus;
            border-color: $tools-icon-btn-light-border-focus;
            i {
                color: $tools-icon-btn-light-icon-focus;
            }
        }
    }
    .tools-icon-btn + .tools-icon-btn{
        margin-left: 6px;
    }
}

.edit-form-btn {
    position: fixed;
    width: 100%;
    bottom: 0px;
    box-sizing: border-box;
    text-align: left;
    height: auto;
    border-top: 1px solid #fff;
    background: #edeef2;
    box-shadow: 0px -3px 4px rgba(208, 216, 226, 0.4);
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

}
.edit-form-point {
    flex: 1;
    .edit-form-point-wrap{
        max-height: 28px;
        transition: max-height 1.5s;
    }
    &:hover {
        .edit-form-point-wrap{
            max-height: 100px;
        }
    }
    span,a {
        display: inline-block;
        line-height: 28px;
        cursor: pointer;
        color: #636365;
        font-size: 13px;
        margin-right: 8px;
        text-decoration: none;
        &.active {
            color: #0053d6;
            font-weight: bold;
        }
    }

}
.buttons-wrap{
    width:270px;
    min-width: 270px;
    text-align: right;
}
.custom-pop {
    padding: 8px;
}
.edit-form-custom {
    width: 330px;
    background: #ffffff;
}
.collapse-order-set-wrap {
    padding: 4px 6px;
    border: 2px dashed #b8bbc4;
    position: relative;
    .el-tag {
        margin: 4px 6px;
    }
    .drop-indicator {
        position: absolute;
        height: 24px;
        width: 1px;
        background: blue;
    }
}
.custom-btns-wrap {
    text-align: center;
    margin-top: 8px;
}
/deep/.icon .proicon .icon-header-help{
    color: #00B528;
}
.proicon_button{
    position: relative;
    .triangle{
        width: 10px;
        height: 10px;
        position: absolute;
        right: 5px;
        top: 26px;
        background: #29BD88;
        transform: rotate(-135deg);
    }
    .float_box{
        width: 218px;
        height: 100px;
        position: absolute;
        right: -18px;
        top: 30px;
        background: #29BD88;
        border-radius: 2px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-top: 8px;
        z-index: 10;
        color: #FFFFFF;
        text-align: left;
        h3{
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        p{
            font-size: 12px;
            margin: 0;
            margin-bottom: 8px;
        }
        .float_button_box{
            margin-right: 16px;
            button{
                height: 23px;
                width: 64px;
                border-radius: 2px;
                padding: 4px, 8px, 4px, 8px;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                border-radius: 2px;
                background: #29BD88;
                font-size: 12px;
                color: #fff;
                float: right;
                margin-right: 10px;
            }
        }
        .el-icon-close{
            position: absolute;
            right: 16px;
            top: 15px;
        }
    }
}

/deep/.proicon{
    color: #00B528;
}
</style>
