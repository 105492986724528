export default {
    sensitiveInformSetUrl:'/api/fsmc/settset/sensitive-set/query-sensitive-set-by-page', // 敏感信息设置
    queryBusinessTypeUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/query-business-type-set-by-page',//敏感信息设置-业务类型查询
    queryUserUrl:'/api/fsmc/auth/userinfo/query-auth-userinfo-by-page',//敏感信息设置-用户名称查询
    queryApplyNoUrl:'/api/fsmc/settset/sensitive-set/query-apply-no',//敏感信息设置-用户名称查询
    queryReceiptsPaymentsListUrl:'/api/fsmc/settset/sensitive-set/query-payment-type-list/-by-/',//敏感信息设置-收付类型信息查询
    saveSensitiveInformSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-add',//保存敏感信息
    updateSensitiveInformSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-update',//修改敏感信息
    deleteSensitiveInformSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-delete',//删除敏感信息
    querySensitiveInfoByIdUrl:'/api/fsmc/settset/sensitive-set/query-sensitive-set/-by-/',//保存敏感信息
}
