export default {
    namespaced: true,
    state: {
        tokenUrl:'',
        isLeftOpen: false,
        loginLogo: [],
        menuLogoIconDark: [],
        menuLogoIconWhite: [],
        menuLogoTextDark: [],
        menuLogoTextWhite: [],
        menuLogoLoaded: false, // 菜单的logo图标是否已经请求完成
        initLang:false,
    },
    getters: {
        getTokenUrl:state => state.tokenUrl,
        getIsleftOpen:state => state.isLeftOpen,
        getIsNoNotify:(state) => () => {
            let isNoNotify = localStorage.getItem("isNoNotify")
            if(isNoNotify && isNoNotify != '') {
                isNoNotify = JSON.parse(isNoNotify);
                return isNoNotify;
            } else {
                return false;
            }
        },
        getLoginLogo: state => state.loginLogo,
        getMenuLogoIconDark: state => state.menuLogoIconDark,
        getMenuLogoIconWhite: state => state.menuLogoIconWhite,
        getMenuLogoTextDark: state => state.menuLogoTextDark,
        getMenuLogoTextWhite: state => state.menuLogoTextWhite,
        getMenuLogoLoaded: state => state.menuLogoLoaded,
        getInitLang:state => state.initLang,
    },
    mutations: {
        setTokenUrl(state,value){
            state.tokenUrl = value;
        },
        setIsLeftOpen(state, value){
            state.isLeftOpen = value;
        },
        changeIsleftOpen(state, value) {
            state.isLeftOpen = value;
            localStorage.setItem('isLeftOpen', value);
        },
        changeIsNoNotify(state, value) {
            console.log('changeIsNoNotify', value)
            localStorage.setItem('isNoNotify', value);
        },
        setLoginLogo(state, value) {
            state.loginLogo = value
        },
        setMenuLogoIconDark(state, value) {
            state.menuLogoIconDark = value
        },
        setMenuLogoIconWhite(state, value) {
            state.menuLogoIconWhite = value
        },
        setMenuLogoTextDark(state, value) {
            state.menuLogoTextDark = value
        },
        setMenuLogoTextWhite(state, value) {
            state.menuLogoTextWhite = value
        },
        setMenuLogoLoaded(state, value) {
            state.menuLogoLoaded = value
        },
        setInitLang(state,value){
            state.initLang = value;
        },
    },

};
