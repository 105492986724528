/**
 * 整个项目的http请求再封装
 * 使用时通过以下方式在各模块入口挂载到Vue的原型上
 * import http from '@/common/http';
 * Vue.prototype.$http = http;
 * 方便在任何组件中使用this.$http的形式调用
 * 例如:
 * 组件中通过this.$http.post(this.$api.account.xxx),即可发送一个账户模块url为xxx的post请求
 * ...
 */
import axios from './axios';
import MessageBox from '@/common/components/messageBox.js';
import { i18n } from '@/common/i18n';

// post, put, delete 传入token

export default {
    post(url, opts = {}, config = {}) {
        return axios
            .post(url, opts, config)
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }

            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    get(url, opts = {}, headers = {},loading) {
        return axios
            .get(url, { params: opts, headers: headers ,loading:loading})
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    add(url, data = {}) {
        return axios({ method: 'post', url: url, data: data })
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    update(url, data = {}, config = {}) {
        return axios({ method: 'put', url: url, data: data, headers: config })
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    delete(url, params) {
        return new Promise((success, error) => {
            MessageBox.delete(action => {
                if (action === 'confirm') {
                    // 确定
                    if (typeof params == 'number' || typeof params == 'string') {
                        params = { id: params };
                    }
                    axios({ method: 'POST', url: url, data: params })
                        .then(response => {
                            success(response.data);
                        })
                        .catch(err => {
                            error(err);
                        });
                } else {
                    // 取消
                    MessageBox.info(i18n.t('i18n.common.delCancelText'));
                }
            });
        });
    },
    findById(url, id) {
        return axios({ method: 'get', url: url, params: { id: id } })
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    find(url, queryCondition = {}, page = {}) {
        return axios({
            method: 'get',
            url: url,
            params: { ...queryCondition, ...page }
        })
            .then(res => {
                if(res && res.data){
                    return Promise.resolve(res.data);
                }else{
                    return Promise.resolve(res);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    /*
    当多个请求都完成时，执行回调函数
     */
    all: axios.all,
    spread: axios.spread,
    /**
     * f-upload 上传组件专属
     * interface Option {
     *   headers: object;
     *   withCredentials: boolean;
     *   files: Library[];
     *   file: Library;
     *   data: any;
     *   filename: string;
     *   action: Url;
     *   onProgress(e: any):void;
     *   onSuccess(res: Request):void;
     *   onError(err: Error):void
     * }
     */
    // 附件上传不进行加密处理
    upload(option /* Option */) {
        // 设置 axios 配置
        let config = {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data;', notUseEncrypt: true, ...option.headers },
            withCredentials: option.withCredentials,
            onUploadProgress: e => {
                if (e.total > 0) {
                    e.percent = (e.loaded / e.total) * 100;
                }
                option.onProgress(e);
            }
        };

        // 设置额外参数
        let formData = new FormData();
        if (option.data) {
            Object.keys(option.data).forEach(key => {
                formData.append(key, option.data[key]);
            });
        }

        // 多选操作
        if (option.files) {
            option.files.forEach(file => {
                formData.append(option.filename, file.raw, file.name);
            });
        } else if (option.file) {
            formData.append(option.filename, option.file, option.file.name);
        }

        formData.append('contentFilter', false);
        // 上传
        return axios
            .post(option.action, formData, config)
            .then(res => {
                if (res.data.code === 0) {
                    option.onSuccess(res.data);
                } else {
                    option.onError(res.data);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    },
    MIMETYPES:{
        '.csv': 'text/csv',
        '.doc': 'application/msword',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.htm': 'text/html',
        '.html': 'text/html',
        '.txt': 'text/plain',
        '.xls': 'application/vnd.ms-excel',
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.xml': 'text/xml',
        '.pdf': 'application/pdf;chartset=UTF-8',
        '.jpeg': 'image/jpeg',
        '.jpg': 'image/jpeg',
        '.jpe': 'image/jpeg',
        '.bmp': 'image/bmp',
        '.png': 'image/png',
        '.gif':'image/gif',
        '.ico':'image/ico',
        '.apng':'image/apng',
        '.webp':'image/webp',
        '.pcx':'image/pcx',
        '.svg':'image/svg',
        '.tif':'image/tif',
        '.tga':'image/tga',
        '.exif':'image/exif',
        '.fpx':'image/fpx',
        '.psd':'image/psd',
        '.cdr':'image/cdr',
        '.pcd':'image/pcd',
    },
    blob(url,opts,type='post'){
        let config = {
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            responseType: 'blob'
        };
        if(type == 'post'){
            return new Promise((resolve, reject) => {
                axios
                    .post(url, opts, config)
                    .then(res => {
                        if(res.data && res.data.code === 1){
                            reject(res.data.msg);
                            return;
                        }
                        let contentDisposition = res.headers['content-disposition'];
                        if (!contentDisposition) {
                            MessageBox.error(i18n.t('i18n.common.serverError'));
                            return;
                        }
                        var reg = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
                        var result = reg.exec(contentDisposition);
                        let fileName = decodeURI(result[1]);
                        let fileType = result[1].substring(result[1].lastIndexOf('.'));
                        let blob = new Blob([res.data], {
                            type: this.MIMETYPES[fileType] || this.MIMETYPES['.'+fileType] || '.txt'
                        });


                        resolve({blob,fileName,fileType});
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        }else{
            return new Promise((resolve, reject) => {
                axios
                    .get(url, {...config})
                    .then(res => {
                        if(res.data && res.status !== 200){
                            reject(res.data.msg);
                            return;
                        }
                        /*let contentDisposition = res.headers['content-disposition'];
                        if (!contentDisposition) {
                            MessageBox.error(i18n.t('i18n.common.serverError'));
                            return;
                        }
                        var reg = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
                        var result = reg.exec(contentDisposition);
                        let fileName = decodeURI(result[1]);
                        let fileType = result[1].substring(result[1].lastIndexOf('.'));*/
                        let blob = new Blob([res.data], {
                            type: '.pdf'
                        });

                        let fileName = '查看';
                        let fileType = 'pdf';
                        resolve({blob,fileName,fileType});
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        }


    },
    export(url, opts) {
        let config = {
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            responseType: 'blob'
        };
        return new Promise((resolve, reject) => {
            axios
                .post(url, opts, config)
                .then(res => {
                    let contentDisposition = res.headers['content-disposition'];
                    if (!contentDisposition) {
                        MessageBox.error(i18n.t('i18n.common.serverError'));
                        return;
                    }
                    var reg = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
                    var result = reg.exec(contentDisposition);
                    let fileName = decodeURI(result[1]);
                    let fileType = result[1].split('.')[1];
                    let blob = new Blob([res.data], {
                        type: this.MIMETYPES[fileType] || this.MIMETYPES['.'+fileType] || '.txt'
                    });
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                        //释放内存
                        window.URL.revokeObjectURL(link.href);
                    }
                    MessageBox.success(i18n.t('i18n.common.success'));
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};
