<template>
    <!-- 付款账号放大镜 -->
    <f-dialog
        :visible.sync="visible"
        :title="title"
        :class="['com-magnifier-dialog']"
        width="800px"
        height="auto"
        :append-to-body="true"
        @close="handleClose"
    >
        <f-magnifier-search ref="searchForm" :formSize="mixSize" :rules="searchRules" :model="searchForm" @query="handleQuery">
            <slot name="search"></slot>
        </f-magnifier-search>
        <f-magnifier-table
            ref="table"
            :tableSize="mixSize"
            :tableData="tableData"
            :pageSizes="pageSizes"
            :page="page"
            :pagination="pagination"
            :beforeOpen="beforeOpen"
            @row-dblclick="handleRowDblclick"
            @currentRowEnter="currentRowEnter"
            @pageChange="handlePageChange"
        >
            <slot></slot>
        </f-magnifier-table>
        <div v-if="warming" class="warming">
            {{ $t('i18n.common.components.magnifier.warming') }}
        </div>
    </f-dialog>
</template>

<script>
    import { dictionaryMix, constantMix,comMix } from '@/common/comMixin';
    import btnMagnifierSingleMix from '@/common/comMixin/btnMagnifierSingleMix.js';
    /**
     * 付款账号-单选
     */
    export default {
        name: 'ComBtnmagnifierSingle',
        mixins: [btnMagnifierSingleMix],
        data() {
            return {};
        },
        props: {
            searchModel: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            warming: {
                type: Boolean,
                default: true
            },
            beforeOpen: {
                type: Function,
                default: function() {
                    return true;
                }
            },
        },
        created() {
            this.searchForm = this.searchModel;
        }
    };
</script>

<style lang="scss" scoped></style>
