function Certificate(r) {
    var t = {};
    if (t.certInfo = null, t._parseCert = function(r) {
        var t = TCACore.getInstance(),
        e = {
            certStoreNameArr: ["MY", "Address"],
            b64Cert: r
        };
        e = tcu.json2Str(e);
        var n;
        try {
            n = t.call("Certificate/parseCert", e)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.CERTIFICATE_PARSE_ERROR),
            null
        }
        return n.certInfo
    },
    this._keyStoreName = "", this._keyStoreType = "", this._keyStoreContainer = "", this._keySpec = 0, this._certStoreName = "", tcu.isStr(r)) t.certInfo = t._parseCert(r),
    t.certInfo.b64 = r;
    else {
        if (!tcu.isObj(r)) return void TCACErr.throwErr(ERRMAP.CERTIFICATE_INIT_WRONGTYPE);
        t.certInfo = r
    }
    this._keyStoreName = t.certInfo.keyStoreName,
    this._keyStoreType = t.certInfo.keyStoreType,
    this._keyStoreContainer = t.certInfo.certContainerName,
    this._keySpec = t.certInfo.certKeySpec,
    this._certStoreName = t.certInfo.certStoreName,
    this.serialNumber = function() {
        return t.certInfo.SN
    },
    this.subject = function() {
        return t.certInfo.subject.winDn
    },
    this.issuer = function() {
        return t.certInfo.issuer.winDn
    },
    this.notBefore = function() {
        return t.certInfo.notBefore
    },
    this.notAfter = function() {
        return t.certInfo.notAfter
    },
    this.crlUrl = function() {
        return t.certInfo.crlUrl
    },
    this.signAlg = function() {
        return tcu.convSignAlg2Str(t.certInfo.signAlg)
    },
    this.publicKeyAlg = function() {
        return tcu.convPubKeyAlg2Str(t.certInfo.pubKeyAlg)
    },
    this.publicKeySize = function() {
        return tcu.convPubKeyAlg2Size(t.certInfo.pubKeyAlg)
    },
    this.keyUsage = function() {
        return tcu.convKeyUsageNum2Arr(t.certInfo.keyUsage)
    },
    this.extededKeyUsage = function() {
        return tcu.convExtKeyUsageOID2Name(t.certInfo.extKeyUsage)
    },
    this.toBase64 = function() {
        return t.certInfo.b64
    },
    this.signMessage = function(r, e, n, i) {
        var c = tcu.convPubKeyAlg2Str(t.certInfo.pubKeyAlg);
        if (e = void 0 === e || e, void 0 === n) n = "RSA" === c ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(c, n)) return null;
        i = void 0 === i ? TCA.IO.U8: i;
        var a = TCACore.getInstance(),
        o = TCA.IO.MSG | i,
        s = r,
        A = TCA.IO.MSG | TCA.IO.B64,
        u = "noSet",
        C = tcu.tcaAlg2SDKAlg(n),
        l = a.getCfg("signPkcs7WithAttr"),
        T = ["MY", "Address"],
        E = [t.certInfo.certID],
        R = tcu.sign(T, E, C, !1, !0, l, e, !0, o, s, A, u);
        return null != R ? R.result: null
    },
    this.signMessageRaw = function(r, e, n) {
        var i = tcu.convPubKeyAlg2Str(t.certInfo.pubKeyAlg);
        if (void 0 === e) e = "RSA" === i ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(i, e)) return null;
        n = void 0 === n ? TCA.IO.U8: n;
        var c = TCA.IO.MSG | n,
        a = r,
        o = TCA.IO.MSG | TCA.IO.B64,
        s = "noSet",
        A = tcu.tcaAlg2SDKAlg(e),
        u = ["MY", "Address"],
        C = [t.certInfo.certID],
        l = tcu.sign(u, C, A, !1, !1, !1, !1, !1, c, a, o, s);
        return null != l ? l.result: null
    },
    this.signB64EncodedBytesRaw = function(r, e) {
        var i = tcu.convPubKeyAlg2Str(t.certInfo.pubKeyAlg);
        if (void 0 === e) e = "RSA" === i ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(i, e)) return null;
        var n = TCA.IO.MSG | TCA.IO.B64;
        var c = TCA.IO.MSG | n,
        a = r,
        o = TCA.IO.MSG | TCA.IO.B64,
        s = "noSet",
        A = tcu.tcaAlg2SDKAlg(e),
        u = ["MY", "Address"],
        C = [t.certInfo.certID],
        l = tcu.sign(u, C, A, !1, !1, !1, !1, !1, c, a, o, s);
        return null != l ? l.result: null
    },
    this.signFile = function(r, e, n, i, c) {
        if (n = void 0 === n || n, i = void 0 === i ? TCA.IO.BIN: i, void 0 === c) c = "RSA" === this.publicKeyAlg() ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(this.publicKeyAlg(), c)) return null;
        var a = TCACore.getInstance(),
        o = TCA.IO.FILE | i,
        s = r,
        A = TCA.IO.FILE | i,
        u = e,
        C = tcu.tcaAlg2SDKAlg(c),
        l = a.getCfg("signPkcs7WithAttr"),
        T = ["MY", "Address"],
        E = [t.certInfo.certID],
        R = tcu.sign(T, E, C, !1, !0, l, n, !0, o, s, A, u);
        return null != R
    },
    this.signFile2Msg = function(r, e, n, i) {
        if (e = void 0 === e || e, n = void 0 === n ? TCA.IO.BIN: n, void 0 === i) i = "RSA" === this.publicKeyAlg() ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(this.publicKeyAlg(), i)) return null;
        var c = TCACore.getInstance(),
        a = TCA.IO.FILE | n,
        o = r,
        s = TCA.IO.MSG | TCA.IO.B64,
        A = "noset",
        u = tcu.tcaAlg2SDKAlg(i),
        C = c.getCfg("signPkcs7WithAttr"),
        l = ["MY", "Address"],
        T = [t.certInfo.certID],
        E = tcu.sign(l, T, u, !1, !0, C, e, !0, a, o, s, A);
        return null != E ? E.result: null
    },
    this.signFileRaw = function(r, e, n) {
        if (e = void 0 === e ? TCA.IO.BIN: e, void 0 === n) n = "RSA" === this.publicKeyAlg() ? TCA.SHA1: TCA.SM3;
        else if (!tcu.checkHashAlg(this.publicKeyAlg(), n)) return null;
        var i = TCA.IO.FILE | e,
        c = r,
        a = TCA.IO.MSG | TCA.IO.B64,
        o = "noSet",
        s = tcu.tcaAlg2SDKAlg(n),
        A = ["MY", "Address"],
        u = [t.certInfo.certID],
        C = tcu.sign(A, u, s, !1, !1, !1, !1, !1, i, c, a, o);
        return null != C ? C.result: null
    },
    this.encryptMessage = function(r, t) {
        void 0 === t && (t = "SM2" == this.publicKeyAlg() ? TCA.SM4: TCA.DES3, t = tcu.tcaAlg2SDKAlg(t));
        var e = [this.toBase64()],
        n = TCA.IO.MSG | TCA.IO.U8,
        i = r,
        c = TCA.IO.MSG | TCA.IO.B64,
        a = "noSet",
        o = tcu.enc(e, t, !0, n, i, c, a);
        return null != o ? o.result: null
    },
    this.encryptMessageRaw = function(r) {
        var t = [this.toBase64()],
        e = TCA.IO.MSG | TCA.IO.U8,
        n = r,
        i = TCA.IO.MSG | TCA.IO.B64,
        c = "noSet",
        a = tcu.tcaAlg2SDKAlg(TCA.SM4),
        o = tcu.enc(t, a, !1, e, n, i, c);
        return null != o ? o.result: null
    },
    this.encryptFile = function(r, t, e, n) {
        e = void 0 === e ? TCA.IO.BIN: e,
        n = tcu.tcaAlg2SDKAlg(TCA.SM4);
        var i = [this.toBase64()],
        c = TCA.IO.FILE | e,
        a = r,
        o = TCA.IO.FILE | e,
        s = tcu.enc(i, n, !0, c, a, o, t);
        return null != s || null
    },
    this.verifyMessageRaw = function(r, t, e, n) {
        if (void 0 === e) e = "RSA" === this.publicKeyAlg() ? TCA.SHA1: TCA.SHA256;
        else if (!tcu.checkHashAlg(this.publicKeyAlg(), e)) return null;
        n = void 0 === n ? TCA.IO.U8: n;
        var i = this.toBase64(),
        c = TCA.VERIFYOPT.CONTEXT_IN_INPUT | TCA.VERIFYOPT.CERT_IN_INPUT,
        a = TCA.IO.MSG | TCA.IO.B64,
        o = r,
        s = TCA.IO.MSG | n,
        A = tcu.tcaAlg2SDKAlg(e),
        u = tcu.verify(i, !1, !1, A, c, a, o, s, t);
        return null != u && u.verifyResult
    },
    this.verifyFileRaw = function(r, t, e, n) {
        if (void 0 === n) n = "RSA" === this.publicKeyAlg() ? TCA.SHA1: TCA.SHA256;
        else if (!tcu.checkHashAlg(this.publicKeyAlg(), n)) return null;
        var i = this.toBase64(),
        c = TCA.VERIFYOPT.CONTEXT_IN_INPUT | TCA.VERIFYOPT.CERT_IN_INPUT,
        a = TCA.IO.MSG | TCA.IO.B64,
        o = r,
        s = TCA.IO.FILE | TCA.IO.BIN,
        A = tcu.tcaAlg2SDKAlg(n),
        u = tcu.verify(i, !1, !1, A, c, a, o, s, t);
        return null != u && u.verifyResult
    },
    this.decryptMessageRaw = function(r) {
        var e = ["MY", "Address"],
        n = [t.certInfo.certID],
        i = TCA.IO.MSG | TCA.IO.B64,
        c = r,
        a = TCA.IO.MSG | TCA.IO.U8,
        o = "noSet",
        s = tcu.dec(e, n, !1, i, c, a, o);
        return null != s ? s.result: null
    },
    this.deleteCert = function() {
        var r, e = [t.certInfo.certID],
        n = TCACore.getInstance(),
        i = '{"certStore" : ["MY", "Address"], "certIDArr" : ["' + e + '"]}';
        try {
            r = n.call("Certificate/deleteCert", i)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.CERTIFICATE_DELETE_ERROR),
            !1
        }
        return null != r
    },
    this.signLogonData = function(r) {
        return this.signMessage("LOGONDATA:" + r)
    },
    this.signFile2File = this.signFile,
    this.signLogondata = this.signLogonData,
    this.encryptRaw = this.encryptMessageRaw,
    this.verifyFile = function(r, t, e) {
        return r = null,
        t = null,
        e = null,
        TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.verifyRaw = this.verifyMessageRaw,
    this.Delete = this.deleteCert,
    this.b64str = this.toBase64,
    this.exportPFX = function(r) {
        return r = null,
        TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.exportPFX2File = function(r, t) {
        return r = null,
        t = null,
        TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.show = function() {
        return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.clearPinCache = function() {
        var r = {
            clid: "",
            method: "clearpin",
            params: [t.certInfo.keyStoreName]
        };
        r = tcu.json2Str(r);
        var e, n = TCACore.getInstance();
        try {
            e = n.call("KeyCom/call", r)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.DECRYPT_ERROR),
            null
        }
    }
}
function CertSet(r) {
    var t = {};
    if (t.certArr = [], r instanceof Array) {
        if (0 != r.length) if (r[0] instanceof Certificate) t.certArr = r;
        else {
            if (!tcu.isStr(r[0])) return void TCACErr.throwErr(ERRMAP.CERT_SET_INIT_ERROR_TYPE);
            for (var e = 0; e < r.length; e++) t.certArr.push(new Certificate(r[e]))
        }
    } else if (tcu.isStr(r)) t.certArr.push(new Certificate(r));
    else {
        if (r instanceof Pkcs7) return void TCACErr.throwErr(ERRMAP.CERT_SET_INIT_ERROR_TYPE_2);
        if (r instanceof Certificate) t.certArr.push(r);
        else if (0 != arguments.length) return void TCACErr.throwErr(ERRMAP.CERT_SET_INIT_ERROR_TYPE_3)
    }
    this.size = function() {
        return t.certArr.length
    },
    this.get = function(r) {
        return t.certArr[r]
    },
    this.bySerialnumber = function(r) {
        for (var e = 0; e < t.certArr.length; e++) if (t.certArr[e].serialNumber() === r.toUpperCase()) return new CertSet(t.certArr[e]);
        return new CertSet
    },
    this.bySubject = function(r) {
        for (var e = [], n = 0; n < t.certArr.length; n++) t.certArr[n].subject().search(r) >= 0 && e.push(t.certArr[n]);
        return 0 == e.length ? new CertSet: new CertSet(e)
    },
    this.byIssuer = function(r) {
        for (var e = [], n = 0; n < t.certArr.length; n++) t.certArr[n].issuer().search(r) >= 0 && e.push(t.certArr[n]);
        return 0 == e.length ? new CertSet: new CertSet(e)
    },
    this.byValidity = function(r) {
        for (var e = 0 == arguments.length ? new Date: new Date(date), n = [], i = 0; i < t.certArr.length; i++) t.certArr[i].notBefore() <= e && e <= t.certArr[i].notAfter() && n.push(t.certArr[i]);
        return 0 == n.length ? new CertSet: new CertSet(n)
    },
    this.byKeyUsage = function(r) {
        var e = 0;
        if (r instanceof Array) e = tcu.convKeyUsageArr2Num(r);
        else {
            if (!tcu.isNum(r)) return TCACErr.throwErr(ERRMAP.CERT_SET_FILTER_KEYUSAGE_ERROR),
            null;
            e = r
        }
        for (var n = [], i = 0; i < t.certArr.length; i++) {
            var c = tcu.convKeyUsageArr2Num(t.certArr[i].keyUsage()); (c & e) == e && n.push(t.certArr[i])
        }
        return 0 == n.length ? new CertSet: new CertSet(n)
    },
    this.forSign = function() {
        return this.byKeyUsage(128)
    },
    this.forEncrypt = function() {
        return this.byKeyUsage(32)
    },
    this.bySubjcet = this.bySubject,
    this.encryptMessage = function(r, t) {
        return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.encryptFile = function(r, t, e, n) {
        return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.uiSelect = function() {
        return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    }
}
function CertStore(r, t) {
    if (!tcu.isStr(r) || !tcu.isNum(t)) return TCACErr.throwErr(ERRMAP.CERTSTORE_INIT_WRONGTYPE),
    null;
    var e = TCACore.getInstance();
    r = e.getCfg("useAlias") ? e.alias2Full(r) : r;
    var n = {};
    n.storeName = r,
    n.storeType = t,
    this.genCsr = function(r, t) {
        var e, i, c = TCACore.getInstance(),
        a = 1,
        o = "topca",
        s = c.getCfg("genKeySpec"),
        A = c.getCfg("exportKeyAble") ? 1 : 0,
        u = "noSet",
        C = c.getCfg("genCsrUseSHA256") ? TCA.SHA256: TCA.SHA1;
        if (1 === arguments.length) if (tcu.isStr(r)) a = 1,
        e = tcu.tcaAlg2SDKAlg(r),
        i = r == TCA.SM2 ? tcu.tcaAlg2SDKAlg(TCA.SM3) : tcu.tcaAlg2SDKAlg(C);
        else {
            if (! (r instanceof Certificate)) return TCACErr.throwErr(ERRMAP.GEN_CSR_ERROR_PARAMS),
            null;
            var l, T = r.publicKeyAlg(),
            E = r.publicKeySize();
            if ("RSA" === T && 1024 === E) l = TCA.RSA1024;
            else if ("RSA" == T && 2048 === E) l = TCA.RSA2048;
            else {
                if ("SM2" != T) return TCACErr.throwErr(ERRMAP.GEN_CSR_ERR_PUBKEY_ALG),
                null;
                l = TCA.SM2
            }
            a = 2,
            e = tcu.tcaAlg2SDKAlg(l),
            i = l == TCA.SM2 ? tcu.tcaAlg2SDKAlg(TCA.SM3) : tcu.tcaAlg2SDKAlg(C),
            s = r._keySpec,
            u = r._keyStoreContainer
        } else if (2 === arguments.length) a = 1,
        o = t,
        e = tcu.tcaAlg2SDKAlg(r),
        i = r == TCA.SM2 ? tcu.tcaAlg2SDKAlg(TCA.SM3) : tcu.tcaAlg2SDKAlg(C);
        else {
            if (0 !== arguments.length) return TCACErr.throwErr(ERRMAP.GEN_CSR_ERROR_PARAMS),
            null;
            a = 1,
            e = tcu.tcaAlg2SDKAlg(TCA.SM2),
            i = tcu.tcaAlg2SDKAlg(TCA.SM3)
        }
        u = u.replace(/\r/g, "\\r").replace(/\n/g, "\\n").replace(/\"/g, '\\"').replace(/\\/g, "\\\\");
        var R, g = '{"mode" : ' + a + ',"subject" : "' + o + '","provName" : "' + n.storeName + '","provType" : ' + n.storeType + ',"containerName" : "' + u + '","keySpec" :' + s + ',"keyAlg" :' + e + ',"hashAlg" : ' + i + ',"opation" : ' + A + "}";
        try {
            R = c.call("Csr/genCsr", g)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.GEN_CSR_ERROR),
            null
        }
        return new Csr(R.csrInfo)
    },
    this.listCerts = function() {
        for (var r = CertStore.listAllCerts(), t = [], e = 0; e < r.size(); e++) {
            var i = r.get(e);
            i._keyStoreName === n.storeName && t.push(i)
        }
        return new CertSet(t)
    },
    this.getSupportedKeyAlgs = function() {
        return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.importPFX = function(r, t) {
        return r = null,
        t = null,
        TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    },
    this.importPFXFile = function(r, t) {
        return r = null,
        t = null,
        TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
        null
    }
}
function Csr(r) {
    var t = {};
    t.csrInfo = null,
    tcu.isObj(r) ? t.csrInfo = r: TCACErr.throwErr(ERRMAP.CSR_INIT_ERROR_TYPE),
    this.subject = function() {
        return t.csrInfo.subject.winDN
    },
    this.toBase64 = function() {
        return t.csrInfo.b64
    }
}
function Pkcs7(r) {
    var t = {};
    if (t.pkcs7Info = null, !tcu.isStr(r)) return TCACErr.throwErr(ERRMAP.PKCS7_INIT_ERROR_TYPE),
    null;
    var e, n = '{"inType" : ' + (TCA.IO.MSG | TCA.IO.B64) + ', "inParam" : "' + r + '", "certs" : true, "content" : true, "b64" : false}',
    i = TCACore.getInstance();
    try {
        e = i.call("Pkcs7/parsePkcs7", n)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.LIST_CERT_ERROR),
        null
    }
    t.pkcs7Info = e.pkcs7Info,
    t.pkcs7Info.b64 = r,
    this.verifyMessage = function(r) {
        var e, n = "",
        i = TCA.VERIFYOPT.CERT_IN_P7,
        c = TCA.IO.MSG | TCA.IO.U8;
        void 0 != r ? (i |= TCA.VERIFYOPT.CONTEXT_IN_INPUT, e = r) : (i |= TCA.VERIFYOPT.CONTEXT_IN_P7, e = "noSet");
        var a = TCA.IO.MSG | TCA.IO.B64,
        o = t.pkcs7Info.b64,
        s = tcu.verify(n, !1, !0, 1, i, a, o, c, e);
        return null === s ? null: s.verifyResult ? new Certificate(s.b64Cert) : null
    },
    this.decryptMessage = function() {
        var r = ["MY", "Address"],
        e = [],
        n = TCA.IO.MSG | TCA.IO.B64,
        i = t.pkcs7Info.b64,
        c = TCA.IO.MSG | TCA.IO.U8,
        a = "noSet",
        o = tcu.dec(r, e, !0, n, i, c, a);
        return null != o ? o.result: null
    },
    this.contentMessage = function() {
        return t.pkcs7Info.isSigned && t.pkcs7Info.hasContent ? t.pkcs7Info.content: null
    },
    this.verify = this.verifyMessage,
    this.toBase64 = function() {
        return t.pkcs7Info.b64
    }
}
function TCACErr(r) {
    this._errarr = [],
    r instanceof TCACErr && (this._errarr = r._errarr),
    this.addErr = function(r) {
        var t = r.code,
        e = r.msg;
        this.number = t > 0 ? t: 4294967296 + t,
        this.description = e,
        this._errarr.push({
            code: this.number.toString(16).toUpperCase(),
            msg: e
        })
    },
    this.toStr = function() {
        for (var r = "",
        t = 0; t < this._errarr.length; t++) r += this._errarr[t].msg + ", 错误码：0x" + this._errarr[t].code + ", \r\n";
        return r.substr(0, r.length - 4)
    }
}
function tcu() {}
CertStore.listAllCerts = function() {
    for (var r = TCACore.getInstance(), t = ["MY", "Address"], e = [], n = 0; n < t.length; n++) {
        var i = '{"certStoreName" : "' + t[n] + '", "needB64" : true}',
        c = null;
        try {
            c = r.call("CertStore/listCerts", i)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.LIST_CERT_ERROR),
            new CertSet
        }
        for (var a = c.certArr,
        o = 0; o < a.length; o++) {
            a[o].notBefore = new Date(a[o].notBefore),
            a[o].notAfter = new Date(a[o].notAfter);
            var s = new Certificate(a[o]);
            r.certInLic(s) && e.push(s)
        }
    }
    return new CertSet(e)
},
CertStore.listStore = function(r) {
    for (var t = CertStore.listKeyStore(r), e = [], n = 0; n < t.length; n++) e.push(t[n].name);
    return e
},
CertStore.byName = function(r) {
    for (var t = CertStore.listKeyStore(!1), e = 0; e < t.length; e++) if (r === t[e].name) return new CertStore(t[e].name, t[e].type);
    return null
},
CertStore.byCert = function(r) {
    return r instanceof Certificate ? new CertStore(r._keyStoreName, r._keyStoreType) : (TCACErr.throwErr(ERRMAP.BY_CERT_ERR_TYPE), null)
},
CertStore.installCert = function(r, t, e) {
    var n, i, c = TCACore.getInstance();
    if (1 === arguments.length) n = "InstallSignCert/installSignCert",
    i = '{"certStoreName"   : "MY", "caCertStoreName" : "Ca", "b64SignCertP7"   : "' + r + '"}';
    else {
        if (3 !== arguments.length) return TCACErr.throwErr(ERRMAP.INSTALL_CERT_ERR_PARAMS),
        null;
        n = "InstallEncCert/installEncCert";
        for (var a = e.split("&"), o = {},
        s = 0; s < a.length; s++) {
            var A = a[s].split("=");
            o[A[0]] = tcu.urlDec(A[1])
        }
        var u, C = o.userSeal,
        l = o.encPrivateKeyUser,
        T = o.userCipher,
        E = o.userIV;
        switch (T) {
        case "AES":
        case "AES_128":
            u = TCA.AES;
            break;
        case "DES":
            u = TCA.DES;
            break;
        case "DESEDE":
        case "DES3":
        case "3DES":
            u = TCA.DES3;
            break;
        case "SM4":
        case "SMS4":
            u = TCA.SM4;
            break;
        case "SM1":
            u = TCA.SM1;
            break;
        case "rc2":
        case "AES_192":
        case "AES_256":
            return TCACErr.throwErr(ERRMAP.INSTALL_CERT_ERR_UNKNOWN_SYMMALG),
            null;
        default:
            u = TCA.AES
        }
        u = tcu.tcaAlg2SDKAlg(u);
        var R, g = new Certificate(r),
        S = g._keyStoreName;
        R = S.indexOf("Microsoft") >= 0 || S.indexOf("iTrus") >= 0 || "RSA" == S ? 1 : c.getCfg("installMode") ? 2 : 1,
        i = '{"certStoreName" : "MY", "mode" : ' + R + ', "b64SignCert" : "' + r + '", "b64UserSeal" : "' + C + '", "b64EncUserCert" : "' + t + '", "b64EncUserPrivateKey" : "' + l + '", "symmAlg" : ' + u + ', "b64IV" : "' + E + '"}'
    }
    var f;
    try {
        f = c.call(n, i)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.INSTALL_CERT_ERR),
        null
    }
    return f.onlyCertChain ? null: new Certificate(f.certInfo)
},
CertStore.listKeyStore = function(r) {
    var t = TCACore.getInstance(),
    e = '{"mode" : 1 }',
    n = null;
    try {
        n = t.call("KeyStore/listKeyStore", e)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.LIST_STORE_ERROR),
        []
    }
    var i = n.keyStoreArr;
    t.setKeyStoreListCache(i),
    r = !t.getCfg("disableWhiteList") && r;
    for (var c = t.getCfg("whiteList"), a = t.getCfg("useAlias"), o = [], s = 0; s < i.length; s++) {
        var A = i[s].name;
        r ? tcu.strInList(A, c) && o.push({
            name: a ? t.full2Alias(A) : A,
            type: i[s].type
        }) : o.push({
            name: a ? t.full2Alias(A) : A,
            type: i[s].type
        })
    }
    return o
};
var CryptoUtil = {};
CryptoUtil.random = function(r) {
    var t = tcu.random(r);
    return null != t ? t.result: null
},
CryptoUtil.symmEnc = function(r, t, e, n) {
    var i = TCA.IO.FILE | TCA.IO.BIN,
    c = n,
    a = TCA.IO.MSG | TCA.IO.B64,
    o = "noSet";
    e = tcu.tcaAlg2SDKAlg(e);
    var s = tcu.symm(r, t, e, !0, i, c, a, o);
    return null != s ? s.result: null
},
CryptoUtil.symmEncFile2File = function(r, t, e, n, i) {
    var c = TCA.IO.FILE | TCA.IO.BIN,
    a = n,
    o = TCA.IO.FILE | TCA.IO.BIN,
    s = i;
    e = tcu.tcaAlg2SDKAlg(e);
    var A = tcu.symm(r, t, e, !0, c, a, o, s);
    return null != A
},
CryptoUtil.symmDec = function(r, t, e, n, i) {
    var c = TCA.IO.MSG | TCA.IO.B64,
    a = n,
    o = TCA.IO.FILE | TCA.IO.BIN,
    s = i;
    e = tcu.tcaAlg2SDKAlg(e);
    var A = tcu.symm(r, t, e, !1, c, a, o, s);
    return null != A ? A.result: null
},
CryptoUtil.symmDecFile2File = function(r, t, e, n, i) {
    var c = TCA.IO.FILE | TCA.IO.BIN,
    a = n,
    o = TCA.IO.FILE | TCA.IO.BIN,
    s = i;
    e = tcu.tcaAlg2SDKAlg(e);
    var A = tcu.symm(r, t, e, !1, c, a, o, s);
    return null != A
},
Pkcs7.verifyFile = function(r, t, e, n) {
    var i, c = "",
    a = TCA.VERIFYOPT.CERT_IN_P7,
    o = TCA.IO.FILE | TCA.IO.BIN;
    void 0 != t ? (a |= TCA.VERIFYOPT.CONTEXT_IN_INPUT, i = t) : (a |= TCA.VERIFYOPT.CONTEXT_IN_P7, i = "noSet");
    var s = TCA.IO.FILE | TCA.IO.BIN,
    A = r,
    u = tcu.verify(c, !1, !0, 1, a, s, A, o, i);
    return null === u ? null: u.verifyResult ? new Certificate(u.b64Cert) : null
},
Pkcs7.verifyFileEx = function(r, t, e, n) {
    var i = "",
    c = TCA.VERIFYOPT.CERT_IN_P7 | TCA.VERIFYOPT.CONTEXT_IN_INPUT,
    a = TCA.IO.FILE | TCA.IO.BIN,
    o = t,
    s = TCA.IO.MSG | TCA.IO.B64,
    A = r,
    u = tcu.verify(i, !1, !0, 1, c, s, A, a, o);
    return null === u ? null: u.verifyResult ? new Certificate(u.b64Cert) : null
},
Pkcs7.contentFile = function(r, t, e) {
    return TCACErr.throwErr(ERRMAP.UNIMPLEMENTED),
    null
},
Pkcs7.decryptFile = function(r, t, e) {
    e = void 0 === e ? TCA.IO.BIN: e;
    var n = ["MY", "Address"],
    i = [""],
    c = TCA.IO.FILE | e,
    a = r,
    o = TCA.IO.FILE | e,
    s = t,
    A = tcu.dec(n, i, !0, c, a, o, s);
    return null != A
};
var TCA = {};
TCA.config = function(r) {
    TCACore.getInstance(r)
},
TCA.SM1 = "SM1",
TCA.SM4 = "SM4",
TCA.DES = "DES",
TCA.DES3 = "3DES",
TCA.AES = "AES",
TCA.RC4 = "RC4",
TCA.SM2 = "SM2",
TCA.RSA1024 = "RSA1024",
TCA.RSA2048 = "RSA2048",
TCA.SHA256 = "SHA256",
TCA.SHA1 = "SHA1",
TCA.SM3 = "SM3",
TCA.MD5 = "MD5",
TCA.digitalSignature = 128,
TCA.nonRepudiation = 64,
TCA.keyEncipherment = 32,
TCA.dataEncipherment = 16,
TCA.keyAgreement = 8,
TCA.keyCertSign = 4,
TCA.cRLSign = 2,
TCA.encipherOnly = 1,
TCA.decipherOnly = 32768,
TCA.contentCommitment = 64,
TCA.serverAuth = "1.3.6.1.5.5.7.3.1",
TCA.clientAuth = "1.3.6.1.5.5.7.3.2",
TCA.codeSigning = "1.3.6.1.5.5.7.3.3",
TCA.emailProtection = "1.3.6.1.5.5.7.3.4",
TCA.ipsecEndSystem = "1.3.6.1.5.5.7.3.5",
TCA.ipsecTunnel = "1.3.6.1.5.5.7.3.6",
TCA.ipsecUser = "1.3.6.1.5.5.7.3.7",
TCA.timeStamping = "1.3.6.1.5.5.7.3.8",
TCA.OCSPSigning = "1.3.6.1.5.5.7.3.9",
TCA.dvcs = "1.3.6.1.5.5.7.3.10",
TCA.sbgpCertAAServerAuth = "1.3.6.1.5.5.7.3.11",
TCA.scvpResponder = "1.3.6.1.5.5.7.3.12",
TCA.eapOverPPP = "1.3.6.1.5.5.7.3.13",
TCA.eapOverLAN = "1.3.6.1.5.5.7.3.14",
TCA.scvpServer = "1.3.6.1.5.5.7.3.15",
TCA.scvpClient = "1.3.6.1.5.5.7.3.16",
TCA.ipsecIKE = "1.3.6.1.5.5.7.3.17",
TCA.capwapAC = "1.3.6.1.5.5.7.3.18",
TCA.capwapWTP = "1.3.6.1.5.5.7.3.19",
TCA.smartcardlogon = "1.3.6.1.4.1.311.20.2.2",
TCA.EX = {},
TCA.EX.szOID_RSA_MD5RSA = "1.2.840.113549.1.1.4",
TCA.EX.szOID_RSA_SHA1RSA = "1.2.840.113549.1.1.5",
TCA.EX.szOID_SM2_SM3SM2 = "1.2.156.10197.1.501",
TCA.EX.INPUT_BASE64 = 1,
TCA.EX.INPUT_HEX = 2,
TCA.EX.OUTPUT_BASE64 = 4,
TCA.EX.OUTPUT_HEX = 8,
TCA.EX.INNER_CONTENT = 16,
TCA.EX.PLAINTEXT_UTF8 = 32,
TCA.EX.ONLY_SIGNATURE = 512,
TCA.EX._pta_OverlapPeriod = 30,
TCA.EX.FUNCOPT_NOALERT = 2147483648,
TCA.EX.PARAM_FILENAME = 4096,
TCA.EX.PARAM_STRING = 8192,
TCA.EX.PTA_CALG_SHA256 = 2097152,
TCA.EX.PFX_BASE64 = 8,
TCA.EX.SIGNOPTION_P7 = 1,
TCA.EX.SIGNOPTION_RAW = 2,
TCA.EX.SIGNOPTION_P7_INNER_CONTEXT = 256,
TCA.EX.SIGNOPTION_P7_INNER_CERT = 512,
TCA.EX.SIGNOPTION_P7_INNER_AUTHATTR = 1024,
TCA.EX.PARAM_IN_FILE = 1,
TCA.EX.PARAM_IN_MSG = 2,
TCA.EX.PARAM_OUT_FILE = 4,
TCA.EX.PARAM_OUT_MSG = 8,
TCA.EX.PARAM_TO_U16 = 256,
TCA.EX.PARAM_TO_U8 = 512,
TCA.EX.PARAM_IN_B64 = 1024,
TCA.EX.PARAM_OUT_B64 = 2048,
TCA.EX.PARAM_INC_CERT = 16777216,
TCA.EX.PARAM_INC_CONTEXT = 33554432,
TCA.EX.SM2_SOFT_CSP_NAME = "iTrusChina Cryptographic Service Provider v1.0.0",
TCA.EX.CRYPT_EXPORTABLE = 1,
TCA.IO = {},
TCA.IO.MSG = 1,
TCA.IO.FILE = 2,
TCA.IO.SELFILE = 4,
TCA.IO.BIN = 65536,
TCA.IO.B64 = 131072,
TCA.IO.HEX = 262144,
TCA.IO.U8 = 524288,
TCA.VERIFYOPT = {},
TCA.VERIFYOPT.CONTEXT_IN_P7 = 1,
TCA.VERIFYOPT.CONTEXT_IN_INPUT = 2,
TCA.VERIFYOPT.CERT_IN_P7 = 16,
TCA.VERIFYOPT.CERT_IN_INPUT = 32,
TCA.EX.exkeyusagemap = {
    "1.3.6.1.5.5.7.3.1": "serverAuth",
    "1.3.6.1.5.5.7.3.2": "clientAuth",
    "1.3.6.1.5.5.7.3.3": "codeSigning",
    "1.3.6.1.5.5.7.3.4": "emailProtection",
    "1.3.6.1.5.5.7.3.5": "ipsecEndSystem",
    "1.3.6.1.5.5.7.3.6": "ipsecTunnel",
    "1.3.6.1.5.5.7.3.7": "ipsecUser",
    "1.3.6.1.5.5.7.3.8": "timeStamping",
    "1.3.6.1.5.5.7.3.9": "OCSPSigning",
    "1.3.6.1.5.5.7.3.10": "dvcs",
    "1.3.6.1.5.5.7.3.11": "sbgpCertAAServerAuth",
    "1.3.6.1.5.5.7.3.12": "scvpResponder",
    "1.3.6.1.5.5.7.3.13": "eapOverPPP",
    "1.3.6.1.5.5.7.3.14": "eapOverLAN",
    "1.3.6.1.5.5.7.3.15": "scvpServer",
    "1.3.6.1.5.5.7.3.16": "scvpClient",
    "1.3.6.1.5.5.7.3.17": "ipsecIKE",
    "1.3.6.1.5.5.7.3.18": "capwapAC",
    "1.3.6.1.5.5.7.3.19": "capwapWTP",
    "1.3.6.1.4.1.311.20.2.2": "smartcardlogon"
},
TCA.filter2Str = function(r) {
    for (var t = "",
    e = 0; e < r.length; e++) {
        var n = r[e],
        i = "";
        for (var c in n) i += n[c] + "|";
        t += i
    }
    return t
},
TCA.doFileDialog = function(r, t, e, n) {
    function i(r) {
        var t = "" != r.result;
        e(t, r.result)
    }
    null == e && TCACErr.throwErr(ERRMAP.ERROR_NULL_CALLBACK),
    null !== t && tcu.isArr(t) || (t = [{
        typeTxt: "All(*.*)",
        typeExt: "*.*"
    }]);
    var c = TCA.filter2Str(t),
    a = {
        isOpen: n,
        title: r,
        filter: c
    };
    a = tcu.json2Str(a);
    var o = TCACore.getInstance();
    try {
        o.callAsyn("FileUtil/SelFile", a, i)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.ERROR_FILE_DIALOG),
        null
    }
},
TCA.showOpenDialog = function(r, t, e) {
    null !== r && "" !== r || (r = "打开"),
    TCA.doFileDialog(r, t, e, !0)
},
TCA.showSaveDialog = function(r, t, e) {
    null !== r && "" !== r || (r = "关闭"),
    TCA.doFileDialog(r, t, e, !1)
};
var TCACore = function() {
    function saveConfig(r) {
        priData.config = {},
        priData.config.ajaxHdl = void 0 !== r.ajaxHdl ? r.ajaxHdl: tcu.ajax,
        priData.config.str2JsonHdl = void 0 !== r.str2JsonHdl ? r.str2JsonHdl: tcu.convStr2Json,
        priData.config.certkitClsID = "40CAB063-EF31-4ff6-B512-E1E4E454EB19",
        priData.config.certKitSrvHost = "127.0.0.1",
        priData.config.certKitSrvPort = "9655",
        priData.config.certKitSrvUrl = "https://" + priData.config.certKitSrvHost + ":" + priData.config.certKitSrvPort + "/",
        priData.config.certKitSrvVer = "3.6.0.2",
        priData.config.certKitActiveXVer = "3.6.0.2",
        priData.config.licver = "3.6.0.2",
        priData.config.licsoftver = "3.6.0.2",
        priData.config.selfNameFmt = "TopCertKit-\\d+(\\.\\d+)*.js",
        priData.config.selfPath = getSelfPath(),
        priData.config.useAlias = void 0 === r.useAlias || r.useAlias,
        priData.config.disableExeUrl = void 0 !== r.disableExeUrl && r.disableExeUrl,
        void 0 !== r.cspAlias ? priData.config.cspAlias = r.cspAlias: priData.config.cspAlias = {
            "RSA软证书": "Microsoft Enhanced Cryptographic Provider v1.0",
            "飞天 ePass 3000 GM": "EnterSafe ePass3000GM CSP v1.0",
            "华大智宝 SJK102": "CIDC Cryptographic Service Provider v3.0.0",
            "SM2软证书": "iTrusChina Cryptographic Service Provider v1.0.0",
            "神州龙芯": "ChinaCpu USB Key CSP v1.0"
        },
        priData.config.addCspAlias = void 0 !== r.addCspAlias ? r.addCspAlias: {};
        for (var t in priData.config.addCspAlias) priData.config.cspAlias[t] = priData.config.addCspAlias[t];
        priData.config.disableWhiteList = void 0 !== r.disableWhiteList && r.disableWhiteList,
        void 0 !== r.whiteList ? priData.config.whiteList = r.whiteList: priData.config.whiteList = ["Microsoft Enhanced Cryptographic Provider v1.0", "iTrusChina Cryptographic Service Provider v1.0.0"],
        priData.config.addWhiteList = void 0 !== r.addWhiteList ? r.addWhiteList: [];
        for (var e = 0; e < priData.config.addWhiteList.length; e++) priData.config.whiteList.push(priData.config.addWhiteList[e]);
        priData.config.lic = void 0 !== r.license ? r.license: null,
        priData.config.licinfo = null,
        priData.config.urlArr = null,
        priData.config.autoExePath = void 0 !== r.autoExePath && r.autoExePath,
        priData.config.installMode = void 0 === r.installMode || r.installMode,
        priData.config.exportKeyAble = void 0 === r.exportKeyAble || r.exportKeyAble,
        void 0 === r.exepath ? priData.config.exepath = priData.config.selfPath + "TopCertKit-" + priData.config.exeVer + ".exe": priData.config.exepath = r.exepath,
        priData.config.genKeySpec = void 0 !== r.genKeySpec ? r.genKeySpec: 1,
        1 != priData.config.genKeySpec && 2 != priData.config.genKeySpec && (priData.config.genKeySpec = 1),
        priData.config.genCsrUseSHA256 = void 0 !== r.genCsrUseSHA256 && r.genCsrUseSHA256,
        priData.config.signPkcs7WithAttr = void 0 !== r.signPkcs7WithAttr && r.signPkcs7WithAttr,
        priData.config.enableAutoAuth = void 0 !== r.enableAutoAuth && r.enableAutoAuth,
        priData.config.autoAuthUrl = void 0 !== r.autoAuthUrl ? r.autoAuthUrl: ""
    }
    function getSelfPath() {
        for (var r, t = document.getElementsByTagName("script"), e = new RegExp("(.*/)+"), n = 0; n < t.length; n++) if (r = t[n].getAttribute("src"), null !== r && !(r.search("TopCertKit-\\d{5}.js") < 0)) {
            r = r.match(e)[0];
            break
        }
        return r
    }
    function doGetEnct() {
        var r, t = '{"method" : "gen","param1" : "", "param2" : "", "param3" : ""}',
        e = TCACore.getInstance();
        try {
            r = e.call("token/token", t)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.AUTOAUTH_ERROR_2),
            null
        }
        return r.retStr1
    }
    function doVerifyToken(r, t) {
        var e, n = '{"method" : "verify","param1" : "' + r + '", "param2" : "' + t + '", "param3" : ""}',
        i = TCACore.getInstance();
        try {
            e = i.call("token/token", n)
        } catch(r) {
            return TCACErr.throwErr(r, ERRMAP.AUTOAUTH_ERROR_2),
            !1
        }
        return ! 0
    }
    function verifyAutoAuth() {
        var r = priData.config.autoAuthUrl;
        "" !== r && void 0 !== r && null !== r || TCACErr.throwErr(ERRMAP.INVALID_CONFIG);
        var t = doGetEnct(),
        e = "d={'winterIsComing': \"" + t + '", \'v\' : "1"}';
        $.ajax({
            type: "post",
            async: !1,
            url: r,
            dataType: "json",
            data: e,
            success: function(r) {
                ret = doVerifyToken(t, r.t)
            },
            error: function(r, t, e) {
                ret = !1
            }
        }),
        ret ? priData.config.licinfo = {
            Issuer: ".*"
        }: TCACErr.throwErr(ERRMAP.AUTOAUTH_ERROR)
    }
    function verifyLicense() {
        var pkcs7 = new Pkcs7(priData.config.lic),
        cert = pkcs7.verifyMessage();
        "5F259CDACC82BB7EF305B3007B8B8EC9BDF9B5F2" != cert.serialNumber() && TCACErr.throwErr(ERRMAP.INVALID_LICENSE_2);
        var info = pkcs7.contentMessage();
        priData.config.licinfo = eval("(" + info + ")")
    }
    function gotoExeUrl() {
        if (priData.config.disableExeUrl) throw TCACErr.throwErr(ERRMAP.INSTALL_ACTIVEX_ERR);
        window.location = priData.config.exepath
    }
    function loadCertKitSrv() {
        return ! 0
    }
    function getSrvVersion() {
        var r = tcu.getSrvVersion();
        return r.version
    }
    function loadCertKitActiveX() {
        try {
            var r = "<object id='TopCertKit' classid='clsid:" + priData.config.certkitClsID + "' ";
            r += "></object>";
            var t = document.getElementById("CertKitDiv");
            null !== t && "" !== t || (t = document.createElement("div"), t.setAttribute("id", "CertKitDiv"), t.innerHTML = r, document.body.appendChild(t)),
            priData.certKitSrvActiveX = document.getElementById("TopCertKit")
        } catch(r) {
            throw r
        }
    }
    function checkCertKitVer() {
        var r = !1;
        return r = isIE() ? priData.certKitSrvActiveX.ver == priData.config.certKitActiveXVer: getSrvVersion() == priData.config.certKitActiveXVer,
        r || TCACErr.throwErr(ERRMAP.INVALID_CERTKIT_VER),
        r
    }
    function isIE() {
        var r = navigator.userAgent;
        return r.indexOf("MSIE") > 0 || r.toLocaleLowerCase().indexOf("trident") > -1
    }
    function loadCertKit() {
        return isIE() ? loadCertKitActiveX() : loadCertKitSrv()
    }
    function callSrv(r, t) {
        var e = priData.config.certKitSrvUrl + r,
        n = {},
        i = !1,
        c = function(r) {
            i = !0,
            n = r
        },
        a = function(r, t, e) {
            i = !1,
            n.request = r,
            n.status = t,
            n.err = e
        };
        if (priData.config.ajaxHdl({
            type: "POST",
            url: e,
            async: !1,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: t,
            success: c,
            error: a
        }), !i) {
            var o = "[" + n.request.readyState + "][" + n.request.status + "][" + n.status + "][" + n.err.toString() + "]";
            throw new TCACErr(4278190081, o)
        }
        return n.retCode != ERRMAP.SUPER_SUCCESS.code && TCACErr.throwErr(TCACErr.newErr(ERRMAP.CALL_HTTP_CLIENT_ERR), {
            code: n.retCode,
            msg: n.retMsg
        }),
        n
    }
    function callSrvAsyn(r, t, e) {
        var n = priData.config.certKitSrvUrl + r,
        i = {},
        c = !1,
        a = function(r) {
            c = !0,
            i = r,
            e(r)
        },
        o = function(r, t, e) {
            TCACErr.throwErr(TCACErr.newErr(ERRMAP.CALL_HTTP_CLIENT_ERR), {
                code: i.retCode,
                msg: i.retMsg
            }),
            c = !1,
            i.request = r,
            i.status = t,
            i.err = e
        };
        priData.config.ajaxHdl({
            type: "POST",
            url: n,
            async: !0,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: t,
            success: a,
            error: o
        })
    }
    function callActiveX(r, t) {
        var e = null;
        try {
            r = "/" + r,
            e = priData.certKitSrvActiveX.reqHdl(r, t)
        } catch(r) {
            var n = TCACErr.newErr({
                code: r.number,
                msg: r.description
            });
            return TCACErr.throwErr(n, ERRMAP.CALL_ACTIVEX_ERR),
            null
        }
        if (e = priData.config.str2JsonHdl(e), 0 != e.retCode) {
            var i = TCACErr.newErr(ERRMAP.CALL_ACTIVEX_ERR_METHOD);
            return TCACErr.throwErr(i, {
                code: e.retCode,
                msg: e.retMsg
            }),
            null
        }
        return e
    }
    function callActiveXAsyn(r, t, e) {
        var n = null;
        try {
            r = "/" + r,
            n = priData.certKitSrvActiveX.reqHdl(r, t)
        } catch(r) {
            var i = TCACErr.newErr({
                code: r.number,
                msg: r.description
            });
            return TCACErr.throwErr(i, ERRMAP.CALL_ACTIVEX_ERR),
            null
        }
        if (n = priData.config.str2JsonHdl(n), 0 != n.retCode) {
            var c = TCACErr.newErr(ERRMAP.CALL_ACTIVEX_ERR_METHOD);
            return TCACErr.throwErr(c, {
                code: n.retCode,
                msg: n.retMsg
            }),
            null
        }
        e(n)
    }
    var priData = {};
    priData.config = null,
    priData.cacheKeyStoreList = null,
    priData.certKitSrvActiveX = null,
    saveConfig({});
    var instanceClass = function() {
        this.getCfg = function(r) {
            return priData.config[r]
        },
        this.setKeyStoreListCache = function(r) {
            priData.cacheKeyStoreList = r
        },
        this.getLicInfo = function(r) {
            return null == priData.config.licinfo,
            priData.config.licinfo[r]
        },
        this.full2Alias = function(r) {
            for (var t in priData.config.cspAlias) if (priData.config.cspAlias.hasOwnProperty(t) && priData.config.cspAlias[t] == r) return t;
            return r
        },
        this.alias2Full = function(r) {
            var t = priData.config.cspAlias[r];
            return void 0 === t ? r: t
        },
        this.certInLic = function(r) {
            var t = new RegExp(this.getLicInfo("Issuer"));
            return t.test(r.issuer())
        };
        var r = {};
        r.query = isIE() ? callActiveX: callSrv,
        r.queryAsyn = isIE() ? callActiveXAsyn: callSrvAsyn,
        this.call = function(t, e) {
            return r.query(t, e)
        },
        this.callAsyn = function(t, e, n) {
            return r.queryAsyn(t, e, n)
        }
    },
    _instance = null,
    getInstance = function(r) {
        if (void 0 !== r && null !== r && saveConfig(r), null === _instance) {
            if (_instance = new instanceClass, loadCertKit(), !checkCertKitVer()) return void gotoExeUrl();
            priData.config.enableAutoAuth ? verifyAutoAuth() : verifyLicense()
        }
        return _instance
    };
    return {
        getInstance: getInstance
    }
} ();
TCACErr.newErr = function(r, t) {
    var e = null;
    return r instanceof TCACErr ? (e = new TCACErr(r), e.addErr(t)) : (e = new TCACErr, e.addErr(r)),
    e
},
TCACErr.throwErr = function(r, t) {
    throw r instanceof TCACErr ? TCACErr.newErr(r, t) : TCACErr.newErr(r)
};
var ERRMAP_ZHCN = {
    CERTSTORE_INIT_WRONGTYPE: {
        code: 16777217,
        msg: "CertStore实例初始化失败，参数类型不匹配"
    },
    LIST_STORE_ERROR: {
        code: 16777218,
        msg: "列举密钥库错误"
    },
    BY_CERT_ERR_TYPE: {
        code: 16777219,
        msg: "根据证书获取CertStore实例时失败，参数类型不匹配"
    },
    LIST_CERT_ERROR: {
        code: 16777220,
        msg: "列举证书错误"
    },
    GEN_CSR_ERROR_PARAMS: {
        code: 16777221,
        msg: "产生CSR失败，输入参数错误"
    },
    GEN_CSR_ERR_PUBKEY_ALG: {
        code: 16777222,
        msg: "产生CSR失败，未知的证书公钥算法"
    },
    GEN_CSR_ERROR: {
        code: 16777223,
        msg: "产生CSR失败"
    },
    INSTALL_CERT_ERR_PARAMS: {
        code: 16777224,
        msg: "安装证书失败，输入参数错误"
    },
    INSTALL_CERT_ERR_UNKNOWN_SYMMALG: {
        code: 16777225,
        msg: "安装加密证书失败，未知的对称加密算法"
    },
    INSTALL_CERT_ERR: {
        code: 16777226,
        msg: "安装证书失败"
    },
    CERTIFICATE_INIT_WRONGTYPE: {
        code: 33554433,
        msg: "Certificate实例初始化失败，参数类型不匹配"
    },
    CERTIFICATE_PARSE_ERROR: {
        code: 33554434,
        msg: "从Base64解析证书失败"
    },
    CERTIFICATE_DELETE_ERROR: {
        code: 33554435,
        msg: "删除证书失败"
    },
    CERT_SET_INIT_ERROR_TYPE: {
        code: 50331649,
        msg: "初始化CertSet实例失败，错误的输入类型"
    },
    CERT_SET_INIT_ERROR_TYPE_2: {
        code: 50331650,
        msg: "初始化CertSet实例失败，错误的输入类型"
    },
    CERT_SET_INIT_ERROR_TYPE_3: {
        code: 50331651,
        msg: "初始化CertSet实例失败，错误的输入类型"
    },
    CERT_SET_FILTER_KEYUSAGE_ERROR: {
        code: 50331652,
        msg: "通过密钥用法过滤失败，错误的输入类型"
    },
    CSR_INIT_ERROR_TYPE: {
        code: 67108865,
        msg: "初始化Csr实例失败，错误的输入类型"
    },
    PKCS7_INIT_ERROR_TYPE: {
        code: 83886081,
        msg: "初始化Pkcs7实例失败，错误的输入类型"
    },
    PKCS7_INIT_PARSE_ERROR: {
        code: 83886082,
        msg: "初始化Pkcs7实例失败，解析Pkcs7数据失败"
    },
    SIGN_ERROR: {
        code: 2684354561,
        msg: "签名操作失败"
    },
    SIGN_ERROR_HASH_ALG: {
        code: 100663297,
        msg: "哈希算法与签名算法不匹配"
    },
    VERIFY_ERROR: {
        code: 2684354562,
        msg: "验证签名操作失败"
    },
    ENCRYPT_ERROR: {
        code: 2684354563,
        msg: "加密操作失败"
    },
    DECRYPT_ERROR: {
        code: 2684354564,
        msg: "解密操作失败"
    },
    ERROR_HASH_ALG: {
        code: 2952790017,
        msg: "指定了错误的Hash算法算法"
    },
    ERROR_JSON_TYPE: {
        code: 2952790018,
        msg: "错误的json类型"
    },
    ERROR_CALL_SRV_VER: {
        code: 2952790019,
        msg: "查询服务版本失败"
    },
    CALL_HTTP_CLIENT_ERR: {
        code: 4026531841,
        msg: "执行本地方法失败"
    },
    INVALID_LICENSE: {
        code: 4026531842,
        msg: "使用了无效的License"
    },
    INVALID_CERTKIT_VER: {
        code: 4026531843,
        msg: "不匹配的CertKit版本"
    },
    CALL_ACTIVEX_ERR: {
        code: 4026531844,
        msg: "执行本地方法失败"
    },
    CALL_ACTIVEX_ERR_METHOD: {
        code: 4026531845,
        msg: "执行操作失败"
    },
    INSTALL_ACTIVEX_ERR: {
        code: 4026531846,
        msg: "无法加载ActiveX控件"
    },
    INVALID_LICENSE_2: {
        code: 4026531847,
        msg: "使用了无效的License"
    },
    INVALID_CONFIG: {
        code: 4026531848,
        msg: "无效的配置"
    },
    AUTOAUTH_ERROR: {
        code: 4026531849,
        msg: "autoautherror"
    },
    AUTOAUTH_ERROR_2: {
        code: 4026531850,
        msg: "autoautherror2"
    },
    ERROR_FILE_DIALOG: {
        code: 218103810,
        msg: "打开对话框失败"
    },
    UNIMPLEMENTED: {
        code: 1,
        msg: "未实现的方法"
    },
    BIG_ERROR: {
        code: 4294967295,
        msg: "错误"
    },
    SUPER_SUCCESS: {
        code: 0,
        msg: "成功"
    }
},
ERRMAP = ERRMAP_ZHCN;
TCACErr.prototype = new Error,
tcu.extKeyUsageMap = {
    "1.3.6.1.5.5.7.3.1": "serverAuth",
    "1.3.6.1.5.5.7.3.2": "clientAuth",
    "1.3.6.1.5.5.7.3.3": "codeSigning",
    "1.3.6.1.5.5.7.3.4": "emailProtection",
    "1.3.6.1.5.5.7.3.5": "ipsecEndSystem",
    "1.3.6.1.5.5.7.3.6": "ipsecTunnel",
    "1.3.6.1.5.5.7.3.7": "ipsecUser",
    "1.3.6.1.5.5.7.3.8": "timeStamping",
    "1.3.6.1.5.5.7.3.9": "OCSPSigning",
    "1.3.6.1.5.5.7.3.10": "dvcs",
    "1.3.6.1.5.5.7.3.11": "sbgpCertAAServerAuth",
    "1.3.6.1.5.5.7.3.12": "scvpResponder",
    "1.3.6.1.5.5.7.3.13": "eapOverPPP",
    "1.3.6.1.5.5.7.3.14": "eapOverLAN",
    "1.3.6.1.5.5.7.3.15": "scvpServer",
    "1.3.6.1.5.5.7.3.16": "scvpClient",
    "1.3.6.1.5.5.7.3.17": "ipsecIKE",
    "1.3.6.1.5.5.7.3.18": "capwapAC",
    "1.3.6.1.5.5.7.3.19": "capwapWTP",
    "1.3.6.1.4.1.311.20.2.2": "smartcardlogon"
},
tcu.isStr = function(r) {
    return "string" == typeof r
},
tcu.isNum = function(r) {
    return "number" == typeof r
},
tcu.isBool = function(r) {
    return "boolean" == typeof r
},
tcu.isUndef = function(r) {
    return "undefined" == typeof r
},
tcu.isNull = function(r) {
    return "null" == typeof r
},
tcu.isObj = function(r) {
    return "object" == typeof r
},
tcu.isArr = function(r) {
    return "[object Array]" === Object.prototype.toString.call(r)
},
tcu.urlDec = function(r) {
    var t = decodeURIComponent(r);
    return t.replace(/[\r\n]/g, "")
},
tcu.convKeyUsageArr2Num = function(r) {
    for (var t = 0,
    e = 0; e < r.length; e++)"digitalSignature" != r[e] ? "nonRepudiation" != r[e] ? "keyEncipherment" != r[e] ? "dataEncipherment" != r[e] ? "keyAgreement" != r[e] ? "keyCertSign" != r[e] ? "cRLSign" != r[e] ? "encipherOnly" != r[e] ? "decipherOnly" != r[e] ? "contentCommitment" == r[e] && (t |= TCA.contentCommitment) : t |= TCA.decipherOnly: t |= TCA.encipherOnly: t |= TCA.cRLSign: t |= TCA.keyCertSign: t |= TCA.keyAgreement: t |= TCA.dataEncipherment: t |= TCA.keyEncipherment: t |= TCA.nonRepudiation: t |= TCA.digitalSignature;
    return t
},
tcu.convKeyUsageNum2Arr = function(r) {
    var t = [];
    return 0 === r ? t: (r & TCA.digitalSignature && t.push("digitalSignature"), r & TCA.nonRepudiation && t.push("nonRepudiation"), r & TCA.keyEncipherment && t.push("keyEncipherment"), r & TCA.dataEncipherment && t.push("dataEncipherment"), r & TCA.keyAgreement && t.push("keyAgreement"), r & TCA.keyCertSign && t.push("keyCertSign"), r & TCA.cRLSign && t.push("cRLSign"), r & TCA.encipherOnly && t.push("encipherOnly"), r & TCA.decipherOnly && t.push("decipherOnly"), r & TCA.contentCommitment && t.push("contentCommitment"), t)
},
tcu.convSignAlg2Str = function(r) {
    switch (r) {
    case 81:
        return "SM3WithSM2";
    case 98:
        return "SHA1WithRSA"
    }
    return "UnknownSignAlg"
},
tcu.convAlg2SignAlgId = function(r) {
    return r == TCA.RSA1024 ? 98 : r == TCA.RSA2048 ? 98 : r == TCA.SM2 ? 81 : -1
},
tcu.convPubKeyAlg2Str = function(r) {
    switch (r) {
    case 1:
    case 2:
    case 3:
    case 16:
        return "RSA";
    case 17:
        return "SM2"
    }
    return "UnknownAlg"
},
tcu.convPubKeyAlg2Size = function(r) {
    switch (r) {
    case 1:
        return 1024;
    case 2:
        return 2048;
    case 3:
        return 4096;
    case 17:
        return 256
    }
    return - 1
},
tcu.convExtKeyUsageOID2Name = function(r) {
    for (var t = [], e = 0; e < r.length; e++) t.push(tcu.extKeyUsageMap[r[e]]);
    return t
},
tcu.convStr2Json = function(jsonStr) {
    return eval("(" + jsonStr + ")")
},
tcu.convKeySize2CSP = function(r) {
    return r == TCA.RSA1024 ? 67108864 : r == TCA.RSA2048 ? 134217728 : r == TCA.SM2 ? 16777216 : -1
},
tcu.strInList = function(r, t) {
    if (t.length <= 0) return ! 1;
    for (var e = 0; e < t.length; e++) if (r === t[e]) return ! 0;
    return ! 1
},
tcu.tcaAlg2SDKAlg = function(r) {
    switch (r) {
    case TCA.SM4:
        return 65;
    case TCA.DES:
        return 67;
    case TCA.DES3:
        return 68;
    case TCA.AES:
        return 69;
    case TCA.RC4:
        return 70;
    case TCA.SM2:
        return 17;
    case TCA.RSA1024:
        return 1;
    case TCA.RSA2048:
        return 2;
    case TCA.SHA256:
        return 50;
    case TCA.SHA1:
        return 49;
    case TCA.SM3:
        return 33;
    case TCA.MD5:
        return 51;
    case TCA.SM1:
    default:
        return 0
    }
},
tcu.arr2Str = function(r) {
    for (var t = "",
    e = 0; e < r.length; e++) {
        var n = r[e];
        n = n.replace(/\r/g, "\\r").replace(/\n/g, "\\n"),
        t = t + '"' + n + '", '
    }
    return t.length > 0 && (t = t.substr(0, t.length - 2)),
    "[" + t + "]"
},
tcu.json2Str = function(r) {
    var t = "{";
    for (var e in r) {
        t += '"' + e + '":';
        var n = r[e];
        tcu.isStr(n) ? (n = n.replace(/\r/g, "\\r").replace(/\n/g, "\\n").replace(/\"/g, '\\"').replace(/\\/g, "\\\\"), t += '"' + n + '",') : tcu.isNum(n) || tcu.isBool(n) ? t += n + ",": tcu.isArr(n) ? t += tcu.arr2Str(n) + ",": TCACErr.throwErr(ERRMAP.ERROR_JSON_TYPE)
    }
    return t += '"_jsonEnd":"_jsonEnd"}'
},
tcu.sign = function(r, t, e, n, i, c, a, o, s, A, u, C) {
    var l = {
        certStore: r,
        certIDArr: t,
        hash: e,
        bSignHash: n,
        bSignP7: i,
        bAttr: c,
        bIncCtx: a,
        bIncCert: o,
        inType: s,
        inParam: A,
        outType: u,
        outParam: C
    };
    l = tcu.json2Str(l);
    var T, E = TCACore.getInstance();
    try {
        T = E.call("Sign/sign", l)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.SIGN_ERROR),
        null
    }
    return T
},
tcu.verify = function(r, t, e, n, i, c, a, o, s) {
    var A = {
        b64Cert: r,
        isHashSign: t,
        isPkcs7: e,
        hash: n,
        verifyOption: i,
        signInType: c,
        signInParam: a,
        plainInType: o,
        plainInParam: s
    };
    A = tcu.json2Str(A);
    var u, C = TCACore.getInstance();
    try {
        u = C.call("Verify/verify", A)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.VERIFY_ERROR),
        null
    }
    return u
},
tcu.enc = function(r, t, e, n, i, c, a) {
    var o = {
        certArr: r,
        symmAlg: t,
        bEncP7: e,
        inType: n,
        inParam: i,
        outType: c,
        outParam: a
    };
    o = tcu.json2Str(o);
    var s, A = TCACore.getInstance();
    try {
        s = A.call("Enc/enc", o)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.ENCRYPT_ERROR),
        null
    }
    return s
},
tcu.dec = function(r, t, e, n, i, c, a) {
    var o = {
        certStore: r,
        certIDArr: t,
        bIsP7: e,
        inType: n,
        inParam: i,
        outType: c,
        outParam: a
    };
    o = tcu.json2Str(o);
    var s, A = TCACore.getInstance();
    try {
        s = A.call("Dec/dec", o)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.DECRYPT_ERROR),
        null
    }
    return s
},
tcu.checkHashAlg = function(r, t) {
    return ! ("SM2" === r && t != TCA.SM3 || "RSA" === r && t === TCA.SM3) || (TCACErr.throwErr(ERRMAP.SIGN_ERROR_HASH_ALG), !1)
},
tcu.getSrvVersion = function() {
    var r, t = TCA.IO.MSG,
    e = '{"outType" :' + t + "}",
    n = TCACore.getInstance();
    try {
        r = n.call("Server/version", e)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.ERROR_CALL_SRV_VER),
        null
    }
    return r
},
tcu.ajax = function(jsonParam) {
    var url = jsonParam.url,
    data = jsonParam.data,
    successCallback = jsonParam.success,
    errorCallback = jsonParam.error,
    request = new XMLHttpRequest;
    try {
        if (request.open("POST", url, !1), request.setRequestHeader("Content-Type", "application/json; charset=UTF-8"), request.send(data), 200 == request.status) return void successCallback(eval("(" + request.responseText + ")"))
    } catch(r) {
        return void errorCallback(request, request.statusText, "send error & no error thrown")
    }
    errorCallback(request, request.statusText, "no error thrown")
},
tcu.hash = function(r, t, e, n, i) {
    var c = {
        hash: r,
        inType: t,
        inParam: e,
        outType: n,
        outParam: i
    };
    c = tcu.json2Str(c);
    var a, o = TCACore.getInstance();
    try {
        a = o.call("Hash/hash", c)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.DECRYPT_ERROR),
        null
    }
    return a
},
tcu.random = function(r) {
    var t = {
        byteLen: r
    };
    t = tcu.json2Str(t);
    var e, n = TCACore.getInstance();
    try {
        e = n.call("rand/rand", t)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.DECRYPT_ERROR),
        null
    }
    return e
},
tcu.symm = function(r, t, e, n, i, c, a, o) {
    var s = {
        isEnc: n,
        b64Key: r,
        b64IV: t,
        symmAlg: e,
        inType: i,
        inParam: c,
        outType: a,
        outParam: o
    };
    s = tcu.json2Str(s);
    var A, u = TCACore.getInstance();
    try {
        A = u.call("symm/symm", s)
    } catch(r) {
        return TCACErr.throwErr(r, ERRMAP.DECRYPT_ERROR),
        null
    }
    return A
};
export default {Certificate,CertStore,CertSet,Csr,Pkcs7,TCACErr,tcu,TCA,TCACore};
