var _urlTheme = {}; // {[url]: {id,colors}}
export default {
    _tryNum: 0,
    changeTheme(options, promiseForIE) {
        var win = window;
        var Promise = promiseForIE || win.Promise;
        let theme = options.theme;

        let cssUrl = `static/css/themes/${process.env.VUE_APP_BUILD_THEMES}/${theme.url}`;

        return new Promise(resolve => {
            // var last = _urlTheme[cssUrl];
            // setCssText(last, cssUrl, resolve, reject);
            setCssLink(cssUrl, resolve);
        });

        function setCssText(last, url, resolve, reject) {
            var elStyle = last && document.getElementById(last.id);
            if (elStyle && last.themeName) {
                setCssTo(elStyle.innerText);
                last.themeName = theme.name;
                resolve();
            } else {
                var id = 'css__' + +new Date();
                elStyle = document
                    .querySelector(options.appendToEl || 'body')
                    .appendChild(document.createElement('style'));
                elStyle.setAttribute('id', id);
                getCssString(
                    url,
                    cssText => {
                        setCssTo(cssText);
                        _urlTheme[url] = { id, themName: theme.name };
                        resolve();
                    },
                    reject
                );
            }

            function setCssTo(cssText) {
                elStyle.innerText = cssText;
                /**
                 * 加载 子iframe 里面的内容
                 */
                var iframes = document.querySelectorAll('iframe');
                [].forEach.call(iframes, iframe => {
                    (function(iframe) {
                        var iframeEl = iframe.contentWindow.document
                            .querySelector(options.appendToEl || 'body')
                            .appendChild(document.createElement('style'));
                        iframeEl.innerText = cssText;
                    })(iframe);
                });
            }
        }

        function getCssString(url, resolve, reject) {
            const comUrl = `/src/style/themes${url}`;
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.responseText);
                    } else {
                        reject(xhr.status);
                    }
                }
            };
            xhr.onerror = function(e) {
                reject(e);
            };
            xhr.ontimeout = function(e) {
                reject(e);
            };
            xhr.open('GET', comUrl);
            xhr.send();
        }

        function setCssLink(url, resolve) {
            function setThemeLink(document, url) {
                const head = document.head || document.getElementsByTagName('head')[0];
                function clean() {
                    for (let i = head.children.length - 1; i > -1; i--) {
                        const currentChild = head.children[i];
                        if (currentChild.rel === 'stylesheet' && currentChild.href.includes('element')) {
                            let isInclude = ['nav'].some(item => url.includes(item));
                            setTimeout(() => {
                                currentChild.href.indexOf(url) < 0 &&
                                    !isInclude &&
                                    currentChild.parentNode.removeChild(currentChild);
                                resolve();
                            }, 200);
                            continue;
                        }

                        if (
                            currentChild.rel === 'stylesheet' &&
                            ['nav'].some(item => currentChild.href.includes(item))
                        ) {
                            let isInclude = url.includes('element');
                            setTimeout(() => {
                                currentChild.href.indexOf(url) < 0 &&
                                    !isInclude &&
                                    currentChild.parentNode.removeChild(currentChild);
                                resolve();
                            }, 200);
                            continue;
                        }
                    }
                }

                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = url;
                link.onload = clean;
                if (head) {
                    let links = head.querySelectorAll('link');
                    links.forEach(item => {
                        if (item.href.indexOf(url) != -1) {
                            item.remove();
                        }
                    });
                    // 1. 导航色在主题色之后
                    // title 后面添加 element-var-change.css
                    let title = head.querySelector('title');
                    let nextElement = title.nextElementSibling;
                    if (
                        nextElement &&
                        nextElement.rel === 'stylesheet' &&
                        (nextElement.href || '').includes('element')
                    ) {
                        nextElement.insertAdjacentElement('afterend', link);
                    } else {
                        title.insertAdjacentElement('afterend', link);
                    }
                }
                //head && head.appendChild(link);
                var iframes = document.querySelectorAll('iframe');
                [].forEach.call(iframes, iframe => {
                    (function(iframe) {
                        setThemeLink(iframe.contentWindow.document, url);
                    })(iframe);
                });
            }
            setThemeLink(window.document, url);
        }
    }
};
