/**
 * 模块请求地址统一管理
 * 注意: 导出对象中的key对应的value值只能是字符串, 不能再嵌套对象
 */
export default {

    //示例布局使用
    common: {
        //表单公共部分
        formItemOrder: 'api/formItemOrder.json', // 公共的查询表单--查询表单项顺序
        formItemOrderSave: 'api/formItemOrderSave.json', // 公共的查询表单--表单项顺序设置保存
        queryFormList: 'api/common/queryFormList.json', // 公共的查询表单--查询已保存查询条件列表
        queryFormSave: 'api/common/queryFormSave.json', // 公共的查询表单--查询条件保存
        queryFormDel: 'api/common/queryFormDel.json', // 公共的查询表单--查询条件删除
        getToken: '/api/common/token/getToken', // 公共获取token
        //当前位置公共请求
        breadCrumb: 'api/common/breadCrumb', //当前位置公共请求
        //表格公共请求
        tableColumnsSave: 'api/common/tableColumnsSave.json', // 公共的查询列表--列表列配置保存
        tableColumns: 'api/common/tableColumns.json' // 公共的查询列表--列表列配置查询
    }

};
