export default {
  // 默认为静态下拉
  default: {
    props: {
      props: { key: 'value', label: 'text', value: 'value' },
      data: null,
      url: "",
      extraData: {},
      remoteFilterKey: "",
      clearable: true,
      multiple: false,
      matchKey: {},
      placeholder: "",
      beforeOpen: () => true,
      extraKey: {}
    },
    attrs: {
      size: 'mini'
    },
    events: {}
  },
  // custom 通用自定义下拉
  custom: {
    props: {
      props: { key: 'value', label: 'text', value: 'value' },
      dataSource: "",
      extraData: {},
      remoteFilterKey: "",
      infiniteScroll: false,
      keyNames: { currentPage: 'start', total: 'total', pageSize: 'limit', rows: 'rows' },
      scroll: { currentPage: 1, pageSize: 10, total: 5, height: 200 },
      clearable: true,
      multiple: false,
      matchKey: {},
      placeholder: "",
      extraKey: {}
    },
    attrs: {
      size: 'mini',
      disabled: false,
      multipleLimit: 0,
      remote: false,
      filterable: false
    },
    events: {}
  }
}