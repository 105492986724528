<template>
    <div class="table-toolbar-custom">
        <f-tabs v-model="activeName">
            <f-tab-pane :label="$t('i18n.common.components.form.order')"  name="order">
                <div class="order-wrap" @drop.stop="handleOrderDrop">
                    <f-tag
                        v-for="(item, index) in currentColumns.filter(item => item.exist)"
                        :key="item.title"
                        :draggable="true"
                        @dragstart.native.stop="handleOrderDragStart($event, item, index)"
                        @dragend.native.stop="handleOrderDragEnd($event, item, index)"
                        @dragenter.native.stop="handleOrderDragEnter($event, item, index)"
                        @dragleave.native.stop="handleOrderDragLeave($event, item, index)"
                        @dragover.native.stop="handleOrderDragOver($event, item, index)"
                        @drop.native.stop="handleOrderDrop($event, item, index)"
                        v-bind="tagConfig"
                    >
                        {{ setTagName(item) }}
                    </f-tag>
                    <div v-show="showDropIndicator" class="drop-indicator" ref="dropIndicator"></div>
                </div>
            </f-tab-pane>
            <f-tab-pane :label="$t('i18n.common.components.form.fixedColumn')" name="fixed">
                <f-row>
                    <f-col :span="24">
                        <span>{{$t('i18n.common.components.form.NoFixedColumn')}}</span>
                        <div
                            class="fixed-wrap"
                            @dragenter.stop="handleFixedDragEnter($event)"
                            @dragleave.stop="handleFixedDragLeave($event)"
                            @dragover.stop="handleFixedDragOver($event)"
                            @drop.stop="handleFixedDrop($event, false)"
                        >
                            <f-tag
                                v-for="item in currentColumns.filter(item => !item.fixed && item.exist)"
                                :key="item.title"
                                v-bind="tagConfig"
                                :draggable="true"
                                @dragstart.native.stop="handleFixedDragStart($event, item)"
                                @dragend.stop="handleFixedDragEnd($event, item)"
                            >
                                {{ setTagName(item) }}
                            </f-tag>
                        </div>
                    </f-col>
                </f-row>
                <f-row :gutter="5">
                    <f-col :span="12">
                        <span>{{$t('i18n.common.components.form.fixedColumnLeft')}}</span>
                        <div
                            class="fixed-wrap"
                            @dragenter.stop="handleFixedDragEnter($event)"
                            @dragleave.stop="handleFixedDragLeave($event)"
                            @dragover.stop="handleFixedDragOver($event)"
                            @drop.stop="handleFixedDrop($event, 'left')"
                        >
                            <f-tag
                                v-for="item in currentColumns.filter(item => item.fixed === 'left' && item.exist)"
                                :key="item.title"
                                v-bind="tagConfig"
                                :draggable="true"
                                @dragstart.native.stop="handleFixedDragStart($event, item)"
                                @dragend.stop="handleFixedDragEnd($event, item)"
                            >
                                {{ setTagName(item) }}
                            </f-tag>
                        </div>
                    </f-col>
                    <f-col :span="12">
                        <span>{{$t('i18n.common.components.form.fixedColumnRight')}}</span>
                        <div
                            class="fixed-wrap"
                            @dragenter="handleFixedDragEnter($event)"
                            @dragleave="handleFixedDragLeave($event)"
                            @dragover.stop="handleFixedDragOver($event)"
                            @drop="handleFixedDrop($event, 'right')"
                        >
                            <f-tag
                                v-for="item in currentColumns.filter(item => item.fixed === 'right' && item.exist)"
                                :key="item.title"
                                v-bind="tagConfig"
                                :draggable="true"
                                @dragstart.native.stop="handleFixedDragStart($event, item)"
                                @dragend.stop="handleFixedDragEnd($event, item)"
                            >
                                {{ setTagName(item) }}
                            </f-tag>
                        </div>
                    </f-col>
                </f-row>
            </f-tab-pane>
            <f-tab-pane :label="$t('i18n.common.components.form.hideColumn')" name="hidden">
                <f-checkbox v-model="item.visible" v-for="item in currentColumns.filter(item => item.exist)" :key="item.title" :label="item.title">
                    {{ setTagName(item) }}
                </f-checkbox>
            </f-tab-pane>
            <f-tab-pane :label="$t('i18n.common.components.form.useColumn')" name="exist" v-if="isQueryTable && userInfo && userInfo.userType == 1">
                <f-checkbox v-model="item.exist" v-for="item in currentColumns" :key="item.title" :label="item.title">
                    {{ setTagName(item) }}
                </f-checkbox>
            </f-tab-pane>
        </f-tabs>
        <f-row class="custom-btns-wrap" justify="center">
            <f-col :span="24">
                <f-button type="primary" size="mini" @click="confirm">{{$t('i18n.common.confirmText')}}</f-button>
                <f-tooltip class="item" effect="dark" :content="$t('i18n.common.components.form.tooltip')" placement="bottom">
                    <f-button plain size="mini" @click="reset">{{$t('i18n.common.resetText')}}</f-button>
                </f-tooltip>
                <f-button plain size="mini" @click="cancel">{{$t('i18n.common.cancelText')}}</f-button>
            </f-col>
        </f-row>
    </div>
</template>

<script>
import { addClass, removeClass, setStyle } from '@iss/vmui-vue/lib/utils/dom';
import storageUtils from '@/common/utils/storageUtils';
export default {
    data() {
        return {
            activeName: 'order',
            tagConfig: {
                size: 'small',
                type: 'info'
            },
            currentColumns: [],
            // 当前拖动的对象数据
            draggingItem: null,
            // 更改顺序item,是否正在拖动中
            orderItemDragging: false,
            dragCopyText: '',
            dragType: '',
            showDropIndicator: false
        };
    },
    props: {
        tableColumns: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isQueryTable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        userInfo: {
            get() {
                return JSON.parse(storageUtils.getItem('userInfo')).loginUserInfo;
            }
        }
    },
    mounted() {
        if (this.tableColumns && this.tableColumns.length > 0) {
            this.setCurrentColumns(this.tableColumns);
        }
    },
    methods: {
        setTagName(item) {
            if (!item.title && item.type == 'checkbox') {
                return this.$t('i18n.common.components.form.checkboxColumn');
            } else if (!item.title && item.type == 'radio') {
                return this.$t('i18n.common.components.form.radioColumn');
            } else {
                return item.title;
            }
        },
        setCurrentColumns(colums) {
            this.currentColumns = colums.map(item => {
                let { fixed, title, field, visible, type } = item;
                return {
                    fixed,
                    title,
                    field,
                    visible,
                    type,
                    exist: item.exist === false ? false : true
                };
            });
        },
        getCurrentColums() {
            return this.currentColumns;
        },
        /**
         * 更改顺序拖动事件
         */
        handleOrderDragStart(event, item, index) {
            this.draggingItem = {
                el: event.target,
                data: item,
                index: index
            };
        },
        handleOrderDragEnd() {
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragEnter(event, item, index) {
            let target = event.target;
            let draggingItemIndex = this.draggingItem.index;
            let droppinItemIndex = index;
            if (droppinItemIndex == draggingItemIndex) {
                // 当放置区域就是本身拖动的对象时不做处理
                return;
            } else if (droppinItemIndex > draggingItemIndex) {
                // 向后拖动
                this.dragType = 'after';
            } else {
                // 向前拖动
                this.dragType = 'before';
            }
            let dropIndicator = this.$refs.dropIndicator;
            let top = target.offsetTop;
            let left = this.dragType == 'before' ? target.offsetLeft - 6 : target.offsetLeft + 6 + target.offsetWidth;
            setStyle(dropIndicator, {
                top: top + 'px',
                left: left + 'px'
            });
            // 显示drop位置线条
            this.showDropIndicator = true;
        },
        handleOrderDragLeave() {
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragOver(event) {
            // 这里需要阻止默认的事件,才会执行drop事件
            event.preventDefault();
        },
        handleOrderDrop(event, item, index) {
            event.preventDefault();
            let draggingItemIndex = this.draggingItem.index;
            let droppingItemIndex = index;
            let newArr = [];
            let oldArr = this.currentColumns;
            if (droppingItemIndex == draggingItemIndex) {
                return;
            }
            oldArr.forEach((item, i) => {
                if (i == droppingItemIndex && this.dragType == 'after') {
                    // 向后时需要再droppingItemIndex的后面插入draggingitem的数据
                    newArr.push(item);
                    newArr.push(this.draggingItem.data);
                } else if (i == droppingItemIndex && this.dragType == 'before') {
                    // 向后时需要再droppingItemIndex的前面插入draggingitem的数据
                    newArr.push(this.draggingItem.data);
                    newArr.push(item);
                } else if (i !== draggingItemIndex) {
                    // 当i不在draggingItemIndex及droppingItemIndex时直接添加item数据
                    newArr.push(item);
                }
            });
            this.currentColumns = newArr;
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleFixedDragStart(event, item) {
            this.draggingItem = {
                data: item,
                el: event.target
            };
        },
        handleFixedDragEnd() {
            this.draggingItem = null;
        },
        handleFixedDragEnter(event) {
            // 进入可放置区域时, 高亮放置区域
            addClass(event.target, 'is-drag-inner');
        },
        handleFixedDragOver(event) {
            // 这里需要阻止默认的事件,才会执行drop事件
            event.preventDefault();
        },
        handleFixedDragLeave(event) {
            // 离开可放置区域时, 置灰放置区域
            removeClass(event.target, 'is-drag-inner');
        },
        handleFixedDrop(event, type) {
            event.preventDefault();
            // 拖动放置时改变数据
            if (this.draggingItem && this.draggingItem != null) {
                this.draggingItem.data.fixed = type;
            }
            // 置灰放置区域
            removeClass(event.target, 'is-drag-inner');
            // 当前拖动对象置空
            this.draggingItem = null;
        },
        confirm() {
            this.$emit('confirm', this.currentColumns);
        },
        cancel() {
            this.setCurrentColumns(this.tableColumns);
            this.$emit('cancel');
        },
        reset() {
            this.$emit('reset');
        }
    },
    watch: {
        tableColumns(val) {
            this.setCurrentColumns(val);
        }
    }
};
</script>

<style lang="scss" scoped>
.table-toolbar-custom {
    background: #ffffff;
    width: 330px;
}
.order-wrap {
    padding: 4px 6px;
    border: 2px dashed #b8bbc4;
    position: relative;
    .el-tag {
        margin: 4px 6px;
    }
    .drop-indicator {
        position: absolute;
        height: 24px;
        width: 1px;
        background: blue;
    }
}
.order-item {
    display: inline-block;
    margin: 4px 6px;
    position: relative;
    z-index: 1;
    &.is-dragging {
        .el-tag {
            visibility: hidden;
        }
    }
    &.drag-copy {
        position: absolute;
    }
}
.drag-placeholder {
    border: 1px dashed #b8bbc4;
    position: absolute;
    z-index: 0;
}
.fixed-wrap {
    padding-bottom: 20px;
    min-height: 30px;
    border: 2px dashed #b8bbc4;
    .el-tag {
        margin: 4px 6px;
    }
    &.is-drag-inner {
        border-color: #333;
    }
}
.custom-btns-wrap {
    text-align: center;
    margin-top: 8px;
}
</style>
