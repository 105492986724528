<template>
    <div :class="['process-cell', isEmpty ? 'is-empty' : '']" @click="handleClick($event)">
        <span :class="getClassName">{{ getValue() }}</span>
        <i v-if="!isEmpty" class="el-icon-info"></i>
    </div>
</template>

<script>
import { VXETable, emptyText } from '@/common/plugins/table-formaters';
import XEUtils from 'xe-utils';
export default {
    name: 'ProcessCell',
    data() {
        return {
            isEmpty: true // 单元格数据是否为空
        };
    },
    props: {
        /**
         * 单元格所在行数据
         */
        rowData: {
            type: Object,
            defualt: () => {
                return {};
            }
        },
        /**
         * @param linkConfig 的数据说明如下
         * @param {Function(rowData, e)} events.click value上的事件配置, 统一做了e.stopPropagation阻止事件冒泡处理
         * @param {String, Array, Funciton(rowData)} formatter 格式化数据
         * @param {String} emptyText 空数据时显示的文本值
         * @param {Function, String} className 样式名称
         */
        processConfig: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 当前render中的filed的值
         */
        field: {
            type: String
        }
    },
    computed: {
        getClassName() {
            let className = this.processConfig.className;
            if (className && typeof className == 'function') {
                return className(this.rowData);
            } else {
                return className || '';
            }
        }
    },
    methods: {
        getValue() {
            let cellValue = this.rowData[this.field];
            let cellLabel = cellValue;
            // 空数据处理
            let empty = emptyText(cellLabel, this.processConfig.emptyText);
            this.isEmpty = empty.isEmpty;
            if (empty.isEmpty) {
                // 如果cellValue为空, 直接返回emptyText
                return (cellLabel = empty.text);
            }
            // 数据formatter处理
            let formatter = this.processConfig.formatter;
            if (formatter) {
                if (XEUtils.isString(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter] || XEUtils[formatter];
                    cellLabel = globalFunc ? globalFunc(cellValue) : cellValue;
                } else if (XEUtils.isArray(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter[0]] || XEUtils[formatter[0]];
                    cellLabel = globalFunc ? globalFunc(...[cellValue].concat(formatter.slice(1))) : cellValue;
                } else {
                    cellLabel = formatter(Object.assign({ cellValue }, this.rowData)) || cellValue;
                }
            }
            return cellLabel;
        },
        handleClick(e) {
            if (this.processConfig.events && this.processConfig.events.click && !this.isEmpty) {
                e.stopPropagation();
                this.processConfig.events.click(this.rowData, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.process-cell {
    cursor: pointer;
    i {
        margin-left: 5px;
        color: #e0e0e0;
    }
    &.is-empty {
        cursor: default;
    }
}
</style>
