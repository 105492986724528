<template>
    <!--适合行编辑使用的cascader  -->
    <f-cascader ref="cascader" v-model="val" v-bind="$attrs">
        <template v-slot:default>
            <slot name="default"></slot>
        </template>
        <template v-slot:empty>
            <slot name="empty"></slot>
        </template>
    </f-cascader>
</template>

<script>
/**
 * 二次封装el-cascader 让它适合行编辑使用
 * 详细地址: https://element.eleme.cn/#/zh-CN/component/cascader
 */
export default {
    inheritAttrs: false,
    name: 'ComEditCascader',
    data() {
        return {
            val: this.value || null
        };
    },
    props: {
        value: {}
    },
    watch: {
        val(val) {
            let node = this.$refs.cascader.getCheckedNodes(false);
            let label = node.reduce((iter, item) => [...iter, ...item.pathLabels], []);
            this.$emit('input', val);
            this.$emit('label', label);
        }
    }
};
</script>

<style></style>
