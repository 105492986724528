export default {

    queryModuleListUrl:'/api/fsmc/cert-set/query-module-list/-by-/',
    queryButtonListUrl:'',
    addCertSetUrl:'/api/fsmc/cert-set/cert-set-add',
    updateCertSetUrl:'/api/fsmc/cert-set/cert-set-update',
    deleteCertSetUrl:'/api/fsmc/cert-set/cert-set-delete',
    certSetListUrl:'/api/fsmc/cert-set/query-cert-set-by-page',
    queryFunctionMenuByModuleCode:'/api/fsmc/cert-set/get-function-tree-with-module/-by-/',
    queryCertSetByIdUrl:'/api/fsmc/cert-set/query-cert-set/-by-/',
    queryCertSetTempByIdUrl:'/api/fsmc/cert-set/query-cert-set-temp/-by-/',
    submitCertSetUrl:'/api/fsmc/cert-set/cert-set-submit',
    batchSubmitCertSetUrl:'/api/fsmc/cert-set/cert-set-batch-submit',
    cancelCertSetUrl:'/api/fsmc/cert-set/cert-set-cancel',

    //复核部分
    certSetReviewListUrl:'/api/fsmc/cert-set-review/query-cert-set-review-by-page',
    updateCertCertReviewPassUrl:'/api/fsmc/cert-set-review/cert-set-review-pass',
    updateCertCertReviewRefuseUrl:'/api/fsmc/cert-set-review/cert-set-review-refuse',
    queryCertSetReviewByIdUrl:'/api/fsmc/cert-set-review/query-cert-set-review/-by-/',


}

