// 状态管理相关代码，请参考文档：https://vuex.vuejs.org/

import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import user from './modules/user';
Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {},
    mutations: {},
    actions: {},
    modules: {
        common: common,
        user: user,
        // event
    }
});
