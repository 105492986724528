<template>
    <div>
        <com-operation-guide></com-operation-guide>
        <keep-alive >
            <router-view ref="routerview" v-if="$route.meta.history"></router-view>
        </keep-alive>
        <router-view ref="routerview" v-if="!$route.meta.history"></router-view>
        <template v-for="(name, index) in routerViews">
            <f-drawer
                class="rootnav"
                :visible.sync="drawers[name]"
                :with-header="false"
                size="100%"
                :style="configs[name] | widthDirection"
                :modal="false"
                :wrapperClosable="false"
                :destroy-on-close="true"
                @closed="drawerclosed(name)"
                :key="index"
                v-bind="configs[name]"
            >
                <el-scrollbar class="rootnav__content" :style="configs[name] | contentDirection">
                    <div v-if="isIframeType(name)">
                        <drawer-iframe
                            v-if="drawers[name] && routers[name]"
                            :src="$route.query.url"
                            :height="configs[name].height"
                        ></drawer-iframe>
                    </div>
                    <div v-else>
                        <router-view :name="name" v-if="drawers[name] && routers[name]"></router-view>
                    </div>
                </el-scrollbar>
                <img
                    class="el-icon-circle-close drawer-close"
                    :src="closeImg"
                    :style="configs[name] | closeDirection"
                    @click="drawers[name] = false"
                    @hover="closeImg = closeImgHover"
                />
            </f-drawer>
        </template>
    </div>
</template>

<script>
import closeImg from '@/assets/close-floatlayer.png';
import closeImgHover from '@/assets/close-floatlayer-hover.png';
import { rootNavMix } from '@/common/comMixin';
import DrawerIframe from './drawer-iframe.vue';
import storageUtils from "@/common/utils/storageUtils";
import Watermark from "@/common/utils/water";
import utils from "@/common/utils";
export default {
    name: 'comDrawer',
    mixins: [rootNavMix],
    components: {
        DrawerIframe
    },
    data() {
        return {
            closeImg: closeImg,
            closeImgHover: closeImgHover
        };
    },
    mounted() {
        self.parent.$bus.$emit('valid-tab',process.env.VERSION);
       /* let userInfo = storageUtils.getItem('userInfo');
        if (userInfo && userInfo != '' && (userInfo = JSON.parse(userInfo))) {
            Watermark.setOne(userInfo.loginUserInfo.userName + '          ' + utils.formatDateOnly(userInfo.userLoginTime));
        } else {
            Watermark.clear(warter);
        }*/
    }
};
</script>

<style lang="scss" scoped></style>
