<template>
    <div>
        <f-dialog
            :visible.sync="visible"
            :title="title"
            :class="['f-magnifier-dialog']"
            :width="getSize.width"
            :height="getSize.height"
            :append-to-body="true"
            @close="close"
            v-if="dialogShow">
            <div style="border-radius: 4px;border: 1px solid #F2F2F2;width:100%;height:400px;margin: 8px;">
                <h3 style="text-align: center"><img src="./CA.png" alt=""></h3>
                <p style="color: #888B95;font-size: 14px;line-height: 28px;text-align: left; margin: 9px 31px 10px;height: 190px;">
                    {{i18nStr.tips}}
                </p>
                <div style="border-radius: 2px;border: 1px solid #F2F2F2;width: 590px; margin: 0 auto;padding: 12px 0;text-align:center">
                    <span style="color: #3C435C;font-size: 16px;margin-right: 5px;">{{i18nStr.selectCA}}：</span>
                    <com-select v-model="certList" :data="certDataList" :size="inputSize" style="width:380px;display:inline-block" @select="certChange"></com-select>
                    <i class="el-icon-refresh-left" style="color:#BABBC0;font-size: 24px;margin: 0 5px;"  @click="refreshCertListOnClick"></i>
                    <f-button @click="validateSing" type="primary" style="width: 92px;height:38px;font-size: 16px;margin-top: 8px;">
                        {{i18nStr.validCA}}
                    </f-button>

                </div>
            </div>
        </f-dialog>
        <div v-else style="position: relative" >
                <div style="width: 24px;  position: absolute;  display: inline-block; top: 4px;  left:4px;   z-index: 1;">
                    <span class="icon proicon icon-login-CA " style="font-size: 23px;color: #c0c4cc;"></span>
                </div>
                <div style="width: 100%;  position: relative;  display: inline-block; ">
                    <com-select class="ca-select" v-model="certList" @change="certChange" size="medium" :placeholder="$t('i18n.common.components.comCert.selectCA')" :data="certDataList" ></com-select>
                </div>
            <div style="width: 32px; position: absolute; display: inline-block; top: -5px;
    right: -24px;">
                <i class="el-icon-refresh-left" style="color:#BABBC0;font-size: 24px;margin: 8px 10px;cursor: pointer;"  @click="refreshCertListOnClick"></i>
            </div>

        </div>
    </div>
</template>

<script>

/**
 * 证书组件
 */

let paramCert;

import tianwei from "./certjs/tianwei/tianwei";
import tianwei2021 from "./certjs/tianwei2021/tianwei-2021";
import xinan from "./certjs/xinan/xinan";
import cfca from "./certjs/cfca/cf";
import {i18n} from "@/common/i18n";
import logoImg from './logo.png'

export default {
    name: 'ComCert',
    data() {
        return {
            certDataList: [],
            certList: '',
            config: {},
            visible: false,
            title: i18n.t('i18n.common.components.comCert.title'),
            dialogShow: false,
            pageShow: false,
            inputSize: '200px',
            requestCallAble: false,
            certsAll:[],
            logoImg: logoImg,
            i18nStr:{
                tips: i18n.t('i18n.common.components.comCert.tip'),
                selectCA:i18n.t('i18n.common.components.comCert.selectCA'),
                validCA:i18n.t('i18n.common.components.comCert.validCA'),
            }
        };
    },
    props: {
        /**
         * 是否回调
         * */
        callAble: Boolean,
        /**
         * 请求菜单的url列表
         */
        funcUrl: String,
        /**
         * 请求为json时，请求结束后跳转的页面
         */
        directUrl: String,
        /**
         * 请求为json时，请求结束后跳转的页面
         */
        directParam: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 请求类型  json  或  页面跳转 (page)
         * */
        funcType: String,
        /**
         * 请求参数
         * */
        funcParam: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 请求参数
         * */
        businessRequestMethod: {
            type: String,
            default: () => {
                return 'POST';
            }
        },
        /**
         * 展现方式，弹窗或页面
         * */
        showType: String,
        callback: Function,
        dialogSize: {
            type: String,
            default: 'medium'
        }
    },
    computed: {
        /**
         * 放大镜弹窗的尺寸
         */
        getSize() {
            let dialogWidth = 800;
            switch (this.dialogSize) {
                case 'medium':
                    dialogWidth = 700;
                    break;
                case 'small':
                    dialogWidth = 600;
                    break;
                case 'mini':
                    dialogWidth = 500;
                    break;
                default:
                    break;
            }
            return {
                width: this.multiple ? dialogWidth + 300 + 'px' : dialogWidth + 'px',
                height: 'auto'
            };
        },
        isDialog() {
            return this.showType === 'dialog';
        }
    },
    created() {

        if (this.showType === 'page') {
            sessionStorage.setItem('certVaild',1);
            //自动执行验签
            this.certDataList = [];
            this.certList = '';
            if (this.showType === 'page') {
                this.pageShow = true;
            } else if (this.showType === 'dialog') {
                this.dialogShow = true;
            }
            this.requestCallAble = this.callAble == null ? false : this.callAble;
            this.getParamCertObject().then(
                data => {
                    this.refreshCertListOnClickExecute(data.asyncFlag, data.license);
                }
            ).catch(
                ex => {
                    this.certDataList = [];
                    this.visible = true;
                    throw ex
                });
        } else {
            this.certDataList = [];
            this.certList = '';
        }
    },
    mounted() {
        if (this.showType === 'dialog') {
            this.certDataList = [];
            this.certList = '';
        }
    },
    methods: {
        certChange() {
            //传递选中的序列号
            paramCert.certChange(this.certList);
        },
        // 获取厂商 并确定其 调用方式
        getParamCertObject(){
            return  new Promise((resolve, reject) => {
                //判断厂商
                let param = { userName: this.funcParam.userName };
                this.$http
                    .post(this.$api.common.cert.queryFactoryAndLicense, JSON.stringify(param), {
                        headers: { __expires__: 'true', notUseEncrypt: true },loading:false
                    })
                    .then(res => {
                        let object = {};
                        //绑定到acctForm数据模型中
                        if (res.code == '0') {
                            let factory = res.data.factory;
                            let license = res.data.license;
                            let asyncFlag = false;
                            if (factory == 'tianwei') {
                                paramCert = tianwei;
                            } else if (factory === 'tianwei2021') {
                                paramCert = tianwei2021;
                            }else if (factory === 'xinan') {
                                paramCert = xinan;
                            } else if (factory === 'cfca') {
                                paramCert = cfca;
                                asyncFlag = true;
                            }
                            object.asyncFlag = asyncFlag;
                            object.license = license;
                            resolve(object);
                        }
                    });
            }).catch(ex => reject(ex));
        },

        getSingData(){
            return new Promise((resolve, reject) => {
                let str = this.getUserInfo();
                this.getParamCertObject().then(
                    data => {
                        if (!data.asyncFlag) {
                            resolve(paramCert.getSingData(str))
                        } else {
                            paramCert.getSingData(str).then(
                                res => {
                                    resolve(res.result);
                            }).catch(ex => {
                                reject(ex);
                            });
                        }
                    }
                ).catch(
                    ex => {
                        reject(ex);
                    }
                )
            });
        },
        getUserInfo(){
            let result = this.funcParam.userName;
            if (typeof (result) == 'string'){
                if(result.length == 0){
                    result = JSON.parse(sessionStorage.getItem("userInfo")).loginUserInfo.userCode;
                }
            } else {
                result = JSON.parse(sessionStorage.getItem("userInfo")).loginUserInfo.userCode;
            }
            return result;
        },
        //验证签名
        validateSing() {
            try {
                if (this.certList == null || this.certList.length == 0) {
                    this.certList = paramCert.getCertSelectedCertSN();
                }
                this.getSingData().then(signStr => {
                    this.executeSign(signStr);
                });
            }catch (e) {
                throw e;
            }
        },
        executeSign(signStr){
            if (signStr != null && signStr != '') {
                this.$http
                    .post(
                        this.$api.common.cert.validateCert,
                        {
                            signedData: signStr,
                            userName: this.getUserInfo()
                        },
                        { headers: { __expires__: 'true', notUseEncrypt: true } }
                    )
                    .then(res => {
                        //绑定到acctForm数据模型中
                        if (res.code == '0') {
                            sessionStorage.removeItem('certVaild');
                            if (!this.requestCallAble) {
                                if (this.funcUrl != null && this.funcUrl != '') {
                                    if (this.funcType == 'json') {
                                        let method = this.businessRequestMethod;
                                        if (method == 'PUT') {
                                            this.$http
                                                .update(this.funcUrl, this.funcParam, { validateFlag: res.data })
                                                .then(res1 => {
                                                    if (this.showType === 'dialog') {
                                                        this.close();
                                                    }
                                                    if (res1.code == 0) {
                                                        this.$success(i18n.t('i18n.common.success'));
                                                        if (this.directUrl != null && this.directUrl != '') {
                                                            this.$bus.$emit('ca-success-callback');
                                                            window.location.href = this.directUrl;
                                                        } else {
                                                            return Promise.resolve(res1.data);
                                                        }
                                                    } else {
                                                        this.$error(res1.msg);
                                                    }
                                                })
                                                .catch(err => {
                                                    paramCert.setCertDataList([]);
                                                    return Promise.reject(err);
                                                });
                                        } else if (method == 'POST') {
                                            this.$http
                                                .post(this.funcUrl, this.funcParam, {
                                                    headers: { validateFlag: res.data }
                                                })
                                                .then(res1 => {
                                                    if (this.showType === 'dialog') {
                                                        this.close();
                                                    }
                                                    if (res1.code == 0) {
                                                        this.$success(i18n.t('i18n.common.success'));
                                                        if (this.directUrl != null && this.directUrl != '') {
                                                            this.$bus.$emit('ca-success-callback');
                                                            window.location.href = this.directUrl;
                                                        } else {
                                                            return Promise.resolve(res1.data);
                                                        }
                                                    } else {
                                                        this.$error(res1.msg);
                                                    }
                                                })
                                                .catch(err => {
                                                    paramCert.setCertDataList([]);
                                                    return Promise.reject(err);
                                                });
                                        }
                                    } else if (this.funcType == 'html') {
                                        this.$router.push({
                                            path: this.funcUrl,
                                            query: this.funcParam
                                        });
                                    }
                                }
                            } else {
                                this.$emit('successRecall', new Object());
                            }
                        } else {
                            sessionStorage.setItem('certVaild',1);
                            this.$message.warning(i18n.t('i18n.common.components.comCert.validFail'));
                            throw (i18n.t('i18n.common.components.comCert.validFail'))
                        }
                    }).catch(e => {
                    if (e != null) {
                        this.$message.warning(i18n.t('i18n.common.components.comCert.validFail'));
                        this.certDataList = [];
                        paramCert.setCertDataList([]);
                        this.certList = '';
                        this.visible =true;
                        this.requestCallAble = this.callAble == null ? false : this.callAble;
                        this.getParamCertObject().then(
                            data => {
                                this.initSendCertDataList(data.asyncFlag, data.license, false);
                            }
                        ).catch(
                            ex => {
                                this.certDataList = [];
                                this.visible = true;
                                sessionStorage.setItem('certVaild',1);
                                throw ex;
                            });
                    }
                });
            }
        },
        /**
         * 打开证书选择窗口
         */
        open() {
            sessionStorage.setItem('certVaild',1);
            //自动执行验签
            this.certDataList = [];
            this.certList = '';
            if (this.showType === 'page') {
                this.pageShow = true;
            } else if (this.showType === 'dialog') {
                this.dialogShow = true;
            }
            this.requestCallAble = this.callAble == null ? false : this.callAble;
            this.getParamCertObject().then(
                res => {
                    this.initSendCertDataList(res.asyncFlag, res.license, true);
                }
            ).catch(
                ex =>{
                    this.certDataList = [];
                    this.visible = true;
                    throw ex;
                }
            );
        },
        initSendCertDataList(asyncFlag, license , signFlag) {
            if (!asyncFlag){
                paramCert.sendAuto(license);
                this.certDataList = paramCert.getCertDataList();
                this.certList = this.certDataList[0].value;
                if (signFlag){
                    this.validateSing();
                }
            } else{
                let dnFilter =  '';
                if (sessionStorage.getItem("userInfo") != null &&
                    sessionStorage.getItem("userInfo") != ''){
                    dnFilter =  JSON.parse(sessionStorage.getItem("userInfo")).userCert || '';
                }

                paramCert.refreshCertListOnClick(license, dnFilter).then(
                    res => {
                        this.certDataList = paramCert.getCertDataList();
                        this.certList = this.certDataList[0].value;
                        if (signFlag){
                            this.validateSing();
                        }
                    }
                ).catch(
                    e => {
                        this.certDataList = [];
                        this.visible = true;
                        throw e;
                    }
                );
            }
        },
        close() {
            this.certDataList = [];
            this.visible = false;
            sessionStorage.removeItem('certVaild')
        },
        refreshCertListOnClick(){
            this.getParamCertObject().then(
                data => {
                    this.refreshCertListOnClickExecute(data.asyncFlag, data.license);
                }
            ).catch(
                ex => {
                    this.certDataList = [];
                    this.visible = true;
                }
            )
        },
        refreshCertListOnClickExecute (asyncFlag, license){
            if (!asyncFlag) {
                paramCert.refreshCertListOnClick(license, true);
                this.certDataList = paramCert.getCertDataList();
                this.certList = this.certDataList[0].value;
            } else{
                let dnFilter =  '';
                if (sessionStorage.getItem("userInfo") != null &&
                    sessionStorage.getItem("userInfo") != ''){
                    dnFilter =  JSON.parse(sessionStorage.getItem("userInfo")).userCert || '';
                }
                paramCert.refreshCertListOnClick(license, dnFilter).then(
                    res => {
                        this.certDataList = paramCert.getCertDataList();
                        this.certList = this.certDataList[0].value;
                    }
                ).catch(
                    e => {
                        console.log("cert load error, details:", e)
                        this.certDataList = [];
                        this.visible = true;
                        throw e;
                    }
                );
            }
        },
    },
    watch: {}
};
</script>
<style lang="scss" scoped>
.f-magnifier-dialog {
    /deep/ .el-dialog__header {
        padding: 8px;
        background: #409eff;
        .el-dialog__title {
            color: #ffffff;
        }
        .el-dialog__headerbtn {
            top: 10px;
            right: 8px;
        }
        .el-dialog__close {
            font-size: 18px;
            color: #ffffff;
        }
    }
    /deep/ .el-dialog__body {
        padding: 16px 8px 50px 8px;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding: 10px;
        background-color: #f2f7f7;
    }
}

.ca-select{
    top:1px
}
/deep/ .el-input__inner{
    padding-left:32px !important;
}
</style>
