<template>
    <div class="edit-table-wrap">
        <f-popover popper-class="custom-pop" ref="custom" placement="bottom" v-model="customVisible">
            <com-table-custom
                ref="tableCustom"
                v-if="custom"
                :tableColumns="tableColumns"
                @confirm="customConfirm"
                @cancel="customCancel"
                @reset="customReset"
            ></com-table-custom>
        </f-popover>
        <edit-dialog v-if="dialogConfig" :type="dialogType" :visible.sync="dialogVisible" :dialog-config="dialogConfig">
            <template v-for="item in dialogConfig.formItems">
                <slot
                    :name="'dialog' + item.name"
                    :slot="'dialog' + item.name"
                    v-bind:item="item"
                    v-if="item.propsConfig && item.propsConfig.visible"
                ></slot>
            </template>
            <slot v-if="!dialogConfig.formItems.length" name="dialog" slot="dialog"></slot>
        </edit-dialog>
        <div v-if="toolbars" style="height: 46px;">
            <vxe-toolbar
                v-bind="toolbars"
                :custom="false"
                ref="tableToolbar"
                :class="{ 'is-toolbar-fixed': fixedToolbar }"
            >
                <template v-slot:buttons>
                    <slot name="buttons">
                        <f-button
                            class="toolbars-btn"
                            v-for="(btn, index) in buttons"
                            :key="'toolbarButton' + index"
                            size="mini"
                            v-bind="btn"
                            @click="toolbarClick(btn, $event)"
                        >
                            {{ btn.name }}
                        </f-button>
                    </slot>
                </template>
                <template v-slot:tools>
                    <div class="toolbars-tools">
                        <slot name="tools"></slot>
                    </div>
                    <f-tooltip
                        effect="dark"
                        :content="$t('i18n.common.components.comQueryTable.exportTipText1')"
                        placement="bottom"
                    >
                        <!-- localExport 优先级比exportConfig高 -->
                        <f-form v-if="!localExport && exportConfig" style="padding: 0;margin:0;">
                            <f-button
                                class="toolbars-icon-btn toolbars-export-btn"
                                icon="proicon icon-export toolbars-export-icon"
                                size="mini"
                                circle
                                @click="openExport"
                            >
                            </f-button>
                        </f-form>
                        <f-form v-if="localExport" style="padding: 0;margin:0;">
                            <f-button
                                class="toolbars-icon-btn toolbars-export-btn"
                                icon="proicon icon-export toolbars-export-icon"
                                size="mini"
                                circle
                                @click="exportTable"
                            >
                            </f-button>
                        </f-form>
                    </f-tooltip>
                    <f-tooltip
                        effect="dark"
                        :content="$t('i18n.common.components.comQueryTable.tableSettingTipText1')"
                        placement="bottom"
                    >
                        <f-button
                            v-if="custom"
                            class="toolbars-icon-btn toolbars-custom-btn"
                            icon="el-icon-setting toolbars-custom-icon"
                            size="mini"
                            circle
                            v-popover:custom
                        ></f-button>
                    </f-tooltip>
                    <com-table-export
                        v-if="exportConfig"
                        ref="tableExport"
                        v-bind="exportConfig"
                        :exportUrl="exportConfig.exportUrl"
                        :tableColums="exportTableColumns"
                        :tablePage="tablePage"
                        :pagination="pagination"
                        :keyNames="keyNames"
                        :tableUrl="exportTableUrl"
                    >
                    </com-table-export>
                </template>
            </vxe-toolbar>
        </div>
        <div @mouseenter="handleTableMouseenter" @mouseleave="handleTableMouseout">
            <vxe-table
                ref="tableList"
                keep-source
                show-overflow
                border
                highlight-hover-row
                resizable
                :data="tableData"
                v-bind="$attrs"
                :height="height"
                :size="size"
                :stripe="stripe"
                :checkbox-config="checkboxConfigDef"
                :seq-config="{ startIndex: this.seqStartIndex() }"
                :radio-config="tableRadioConfig"
                :sort-config="{ sortMethod: initSort }"
                :edit-config="tableEditConfig"
                v-on="$listeners"
                :class="{ 'is-header-fixed': fixedHeader }"
            >
                <slot></slot>
            </vxe-table>
            <div
                ref="tableScrollCopyWrap"
                v-show="fixedPage && tableHover"
                class="table-wrap-width-copy"
                @scroll="handleTableScrollCopyScroll"
            >
                <div ref="tableScrollCopy" class="table-full-width-copy"></div>
            </div>
        </div>
        <div style="height: 36px;" v-if="pagination">
            <vxe-pager
                ref="tablePager"
                :class="{ 'is-page-fixed': fixedPage }"
                border
                :current-page="tablePage.currentPage"
                :page-size="tablePage.pageSize"
                :page-sizes="tablePage.pageSizes"
                :total="tablePage.total"
                align="right"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                @page-change="handlePageChange"
            ></vxe-pager>
        </div>
    </div>
</template>

<script>
/**
 * 重新封装vxe-table
 * 1.集成了分页, 分页与不分页
 * 2.集成了tableToorbar, vxe中的button样式太少, buttons修改成了f-button,因此btns的配置与f-button中的使用方式一样
 * 3.提供dataSource数据源配置,
 *      可以是请求的url,
 *      也可以直接是表格数据, 当是表格数据是要区分有分页和无分页的情况, 无分页时为Array类型, 有分页时为Object类型
 * 4.提供extraData配置表格查询参数
 * 5.提供表格reload刷新方法
 * 6.提供多选框的初始配置,同时继承了vxe-table的checkboxConfig属性
 * 7.提供序号的初始配置,主要针对有分页的情况做了处理, 未继承vxe-table的seqConfig属性
 * 8.前后端交互的字段名可以在keyNames中进行配置
 * 9. 新增编辑行
 */
import ComTableCustom from '../../comQueryTable/src/com-table-custom';
import EditDialog from './edit-dialog/edit-dialog';
import XEUtils from 'xe-utils';
const _funcs = 'getTableData,setFilter,clearFilter,getCheckedFilters,closeMenu,setActiveCellArea,getActiveCellArea,getCellAreas,clearCellAreas,copyCellArea,cutCellArea,pasteCellArea,getCopyCellArea,clearCopyCellArea,setCellAreas,openFind,openReplace,getSelectedCell,clearSelected,insert,insertAt,remove,removeCheckboxRow,removeRadioRow,removeCurrentRow,getRecordset,getInsertRecords,getRemoveRecords,getUpdateRecords,clearActived,getActiveRecord,isActiveByRow,setActiveRow,setActiveCell,setSelectCell,clearValidate,fullValidate,validate,openExport,openPrint,exportData,openImport,importData,saveFile,readFile,importByFile,print'.split(
    ','
);
export default {
    name: 'ComEditTable',
    inheritAttrs: false,
    data() {
        return {
            /**
             * 前后端交互的字段名配置,
             * 当后端字段名有变动时,改这里的配置即可
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * table数据
             */
            tableData: [],
            /**
             * table分页配置
             */
            tablePage: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100, 200, 300]
            },
            checkboxConfigDef: {
                trigger: 'row',
                highlight: true
            },
            /**
             * 用户自定义表格配置
             */
            customVisible: false,
            /**
             * 列表默认的列配置
             */
            tableColumnsDefaultObj: {},
            tableColumns: [],
            // 表格的单选配置
            tableRadioConfig: {},
            // 表格的编辑配置
            dialogVisible: false,
            dialogType: {},
            // 表格分页是否为固定状态
            fixedPage: false,
            // 表格工具栏是否为固定状态
            fixedToolbar: false,
            // 表格头部是否为固定状态
            fixedHeader: false,
            // 鼠标时候浮在表格上
            tableHover: false,
            scrollCopyShow: false
        };
    },
    props: {
        /**
         * 源数据, 可以配置为请求地址, 也可以配置成为具体的数据
         * pagination=false && dataSource为具体的数据时, dataSource的类别为Array
         * pagination=true && dataSource为具体的数据时, dataSource的类别为Object,其中必须包含{rows, total}这两个字段
         */
        dataSource: {
            type: [Array, String, Object],
            default: () => []
        },
        /**
         * 请求发送到后台的数据
         * 仅在dataSource配置为String的时候有效
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 表格工具栏按钮配置
         */
        buttons: Array,
        /**
         * 表格动态列默认配置
         */
        tableColumnsDefault: Array,
        /**
         * 表格列动态配置
         */
        // tableColumns: Array,
        /**
         * 是否显示分页
         * true显示分页
         * false不显示分页
         */
        pagination: {
            type: Boolean,
            default: true
        },
        /**
         * 复选框配置
         */
        checkboxConfig: Object,
        height: {
            type: [Number, String],
            required: false,
            default: null
        },
        size: {
            type: String,
            required: false,
            default: 'mini'
        },
        stripe: {
            type: Boolean,
            required: false,
            default: true
        },
        // vxe-toolbar的自带功能，可以通过该属性进行暴露
        toolbars: {
            type: [Object, Boolean],
            default: () => {
                return {
                    export: false
                };
            }
        },
        custom: {
            type: [Boolean, Object],
            required: false,
            default: true
        },
        /**
         * 导出表格数据的配置
         * export: {
         *      exportUrl: '/api/export' // 导出的url地址
         * }
         */
        exportConfig: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        /**
         * 组件编号
         */
        customComponentsCode: {
            type: String
        },
        // table列配置保存地址
        tableColumnsSaveUrl: String,
        // table列配置查询地址
        tableColumnsUrl: String,
        /**
         * 重新封装单选的配置, 匹配data.rows[checkField]==true的值为选中状态
         * 继承了vxetable中radioConfig中除了checkRowKey的其他的所有配置项
         */
        radioConfig: Object,
        editConfig: Object,
        dialogConfig: Object,
        parseDataFn: Function,
        // table的表头和分页是否随滚动条一起浮动
        tableFixed: {
            type: Boolean,
            default: false
        },
        /**
         * 初始化不加载数据, 只有用户点击查询的时候才加载数据
         */
        lazy: Boolean,
        /**
         * 本地导出excel表格
         */
        localExport: Boolean
    },
    computed: {
        exportTableUrl() {
            if (this.exportConfig && this.exportConfig.tableUrl) {
                return this.exportConfig.tableUrl;
            } else if (typeof this.dataSource == 'string') {
                return this.dataSource;
            } else {
                return '';
            }
        },
        exportTableColumns() {
            if (this.exportConfig && this.exportConfig.tableColumns) {
                return this.exportConfig.tableColumns;
            } else {
                return this.tableColumns;
            }
        },
        tableEditConfig() {
            return {
                trigger: 'manual',
                mode: 'row',
                autoClear: false,
                ...this.editConfig
            };
        }
    },
    components: {
        ComTableCustom,
        EditDialog
    },
    created() {
        this.init();
    },
    mounted() {
        this.$nextTick(() => {
            if (this.tableFixed) {
                this.$bus.$on('appScroll', this.scrollHandler);
                document.body.addEventListener('resize', this.scrollHandler, false);
                window.addEventListener('resize', this.scrollHandler, false);
            }
        });
        window.addEventListener('mousewheel', this.handleScroll, true) ||
            window.addEventListener('DOMMouseScroll', this.handleScroll, false);
    },
    beforeDestroy() {
        if (this.scrollHandler && this.tableFixed) {
            this.$bus.$off('appScroll', this.scrollHandler);
            document.body.removeEventListener('resize', this.scrollHandler);
            window.removeEventListener('resize', this.scrollHandler);
        }
    },
    methods: {
        init() {
            if (!this.$slots.default || this.$slots.default.length == 0) {
                this.code = this.customComponentsCode ? this.customComponentsCode : '';
                this.initTableColumns();
            }
            /**
             * 继承vxe-table的复选框配置属性
             */
            if (this.checkboxConfig) {
                this.checkboxConfigDef = Object.assign(this.checkboxConfigDef, this.checkboxConfig);
            }
            // if (!this.pagination) {
            //     this.tablePage = false;
            // }
            if (!this.lazy) {
                this.$nextTick(() => {
                    this.getTableData();
                });
            }
        },
        /**
         * 排序设置
         **/
        initSort(table) {
            table.data.sort((prev, next) => {
                let v1 = prev[table.property];
                let v2 = next[table.property];
                if (table.order == 'asc') return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
                else {
                    return v2 < v1 ? -1 : v2 > v1 ? 1 : 0;
                }
            });
            return table.data;
        },
        /**
         * 初始化table列相关数据
         */
        initTableColumns() {
            if (this.custom && this.tableColumnsUrl) {
                if (this.tableColumnsDefault && this.tableColumnsDefault.length) {
                    let tableColumnsDefaultObj = {};
                    this.tableColumnsDefault.forEach(item => {
                        if (item.field) {
                            tableColumnsDefaultObj[item.field] = item;
                        } else if (item.type) {
                            tableColumnsDefaultObj[item.type] = item;
                        }
                    });
                    this.tableColumnsDefaultObj = tableColumnsDefaultObj;
                    this.getTableColumns();
                }
            } else {
                this.tableColumns = this.tableColumnsDefault;
            }
        },
        /**
         * 获取表格数据
         */
        getTableData() {
            let { parseDataFn } = this;
            if (typeof this.dataSource == 'string') {
                if (!this.dataSource) return false;
                // 当dataSource配置为字符串时,发送请求
                let param = {};
                if (this.pagination) {
                    param = Object.assign({}, this.extraData, {
                        [this.keyNames.currentPage]: this.tablePage.currentPage,
                        [this.keyNames.pageSize]: this.tablePage.pageSize
                    });
                } else {
                    param = this.extraData;
                }
                this.$http.post(this.dataSource, param).then(res => {
                    let result = this.pagination ? res.data[this.keyNames.rows] : res.data;
                    this.tableData = parseDataFn ? parseDataFn(result) : result;
                    // 设置radio的默认选中
                    this.setRadioDefault();
                    this.pagination && (this.tablePage.total = res.data.total);
                });
            } else {
                // 当dataSource为对象时,直接赋值tableData
                if (this.pagination) {
                    let result = this.dataSource[this.keyNames.rows];
                    this.tableData = result;
                    // 设置radio的默认选中
                    this.setRadioDefault();
                    this.tablePage.total = this.dataSource[this.keyNames.total];
                } else {
                    // 当没有分页时, dataSource为数组
                    this.tableData = this.dataSource;
                }
            }
        },
        setRadioDefault() {
            if (this.radioConfig && this.radioConfig.checkField) {
                // 设置默认的选中项
                for (let i = 0; i < this.tableData.length; i++) {
                    const row = this.tableData[i];
                    if (row[this.radioConfig.checkField]) {
                        this.$refs.tableList.setRadioRow(row);
                        return;
                    }
                }
            }
        },
        /**
         * 获取后台已经保存的列数据
         * 1.如果请求成功这里将按请求返回的值进行渲染
         * 2.请求失败或者没有保存过顺序数据是按tableColumnsDefault的顺序进行渲染
         */
        getTableColumns() {
            this.$http
                .post(this.tableColumnsUrl, {
                    code: this.code
                })
                .then(res => {
                    // 重新渲染
                    if (this.validTableColumnsData(res.data.customOrder)) {
                        this.setTableColumns(res.data.customOrder);
                    } else {
                        this.setTableColumns(this.tableColumnsDefault);
                    }
                })
                .catch(() => {
                    this.setTableColumns(this.tableColumnsDefault);
                });
        },
        /**
         * 验证返回的自定义配置数据与组件代码中定义的组件数据是否完全匹配
         * 完全匹配则返回true
         * 有1个不匹配则返回false
         */
        validTableColumnsData(data) {
            if (!data || data.length !== this.tableColumnsDefault.length) {
                return false;
            }
            let flag = false;
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (!this.tableColumnsDefaultObj[item.field] && !this.tableColumnsDefaultObj[item.type]) {
                    break;
                } else if (i === data.length - 1) {
                    flag = true;
                }
            }
            return flag;
        },
        /**
         * 设置table列配置
         */
        setTableColumns(columns) {
            this.tableColumns = columns.map(item => {
                // 这里需要将item中的title剔除
                let obj = {
                    field: item.field,
                    fixed: item.fixed,
                    type: item.type,
                    visible: item.visible
                };
                if (item.field) {
                    return Object.assign({}, this.tableColumnsDefaultObj[item.field] || {}, obj);
                } else if (item.type) {
                    return Object.assign({}, this.tableColumnsDefaultObj[item.type] || {}, obj);
                } else {
                    return obj;
                }
            });
        },
        /**
         * 表格列设置--确定按钮事件
         * 1.保存当前更改后的列数据
         * 2.重新向后台获取table列数据
         * 3.保存完成后关闭pop层
         */
        customConfirm(columns) {
            if (!this.tableColumnsSaveUrl) {
                console.log('表格列设置保存地址不存在!');
                return;
            }
            this.$http
                .post(
                    this.tableColumnsSaveUrl,
                    JSON.stringify({
                        code: this.code,
                        tableColumns: columns
                    })
                )
                .then(res => {
                    this.$success(this.$t('i18n.common.success'));
                    // 重新请求已经保存的列表列配置数据
                    this.getTableColumns();
                    // 关闭pop成
                    this.customVisible = false;
                });
        },
        customCancel() {
            // 这里直接关闭pop
            this.customVisible = false;
        },
        /**
         * 表格列数据重置到默认状态
         */
        customReset() {
            // 这里重置列设置中的数据到默认的状态
            this.$refs.tableCustom.setCurrentColumns(this.tableColumnsDefault);
        },
        /**
         * 刷新表格
         */
        reload() {
            // 分页的情况才需要重置当前页码数
            if (this.pagination) {
                this.tablePage.currentPage = 1;
            }
            this.getTableData();
        },
        /**
         * 分页事件
         */
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.getTableData();
        },
        /**
         * 重写工具栏按钮点击事件
         */
        toolbarClick(btn) {
            this.$emit('buttonClick', btn);
        },
        /**
         * 返回动态序号
         */
        seqStartIndex() {
            if (this.pagination) {
                return (this.tablePage.currentPage - 1) * this.tablePage.pageSize;
            } else {
                return 0;
            }
        },
        /**
         * 获取table组件
         */
        getTable() {
            return this.$refs.tableList;
        },
        /**
         * 获取table工具栏组件
         */
        getTableToolbar() {
            return this.$refs.tableToolbar;
        },
        /**
         * 获取table分页组件
         */
        getTablePager() {
            return this.$refs.tablePager;
        },
        /**
         * 此方法只提供一个接口，导出功能需要自己实现
         */
        exportXls(all = false) {
            if (typeof this.dataSource == 'string') {
                let param = {};
                if (!all && this.pagination) {
                    param = Object.assign({}, this.extraData, {
                        [this.keyNames.currentPage]: this.tablePage.currentPage,
                        [this.keyNames.pagesize]: this.tablePage.pageSize
                    });
                } else {
                    param = this.extraData;
                }
                this.$emit('exportXls', { url: this.dataSource, data: param });
            }
        },
        openExport() {
            this.$refs.tableExport.open();
        },
        exportTable() {
            const attrs = this.$attrs;
            // 去除操作列，复选列， 单选列
            let exColType = ['checkbox', 'radio'];
            // this.getExportGroup(this.tableColumns), // 此处可以自定义
            let columns = this.getExportGroup(this.tableColumns.filter(item => !exColType.includes(item.type)));
            this.getTable().exportData({
                filename: new Date().getTime(),
                sheetName: 'Sheet1',
                type: 'xlsx',
                columns, // 此处可以自定义
                useStyle: true,
                sheetMethod({ options, worksheet }) {
                    let { data, columns, colgroups } = options;
                    let start = colgroups.length;
                    let mergeCells = [];
                    data.forEach((row, $rowIndex) => {
                        columns.forEach((column, $columnIndex) => {
                            let position =
                                attrs['span-method'] &&
                                attrs['span-method']({ row, $rowIndex, column, $columnIndex, data });
                            if (position && (position.rowspan || position.colspan)) {
                                mergeCells.push({
                                    s: { r: $rowIndex + start + 1, c: $columnIndex + 1 },
                                    e: {
                                        r: position.rowspan + $rowIndex + start,
                                        c: position.colspan + $columnIndex
                                    }
                                });
                            }
                        });
                    });
                    mergeCells.forEach(item => {
                        let { s, e } = item;
                        worksheet.mergeCells(s.r, s.c, e.r, e.c);
                    });
                }
            });
        },
        // 获取表格导出数据
        getExportGroup(customCols) {
            return XEUtils.searchTree(customCols, item => item, {
                children: 'children',
                mapChildren: 'childNodes',
                original: true
            });
        },
        scrollHandler() {
            this.pageFixedFun();
            this.toolbarFixedFun();
            this.headerFixedFun();
        },
        pageFixedFun() {
            if (!this.pagination) {
                return;
            }
            const { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            this.fixedPage =
                bottom > document.documentElement.clientHeight && top + 36 <= document.documentElement.clientHeight;
            this.$refs.tablePager.$el.style.left = this.fixedPage ? `${left}px` : '0';
            this.$refs.tablePager.$el.style.width = this.fixedPage ? `${right - left}px` : 'auto';
        },
        headerFixedFun() {
            //内容头部-----
            let tableMainWrap = this.$refs.tableList.$el.querySelector('.vxe-table--main-wrapper');
            let tableMainBody = this.$refs.tableList.$el.querySelector('.vxe-table--header-wrapper.body--wrapper');
            tableMainBody.style.width = tableMainWrap.clientWidth + 'px';

            let { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            let vxeTableHeaderLeft = this.$refs.tableList.$el.querySelector(
                '.vxe-table--header-wrapper.fixed-left--wrapper'
            );
            let vxeTableHeaderLeftWrap = this.$el.querySelector('.vxe-table--fixed-left-wrapper');
            let vxeTableHeaderRight = this.$refs.tableList.$el.querySelector(
                '.vxe-table--header-wrapper.fixed-right--wrapper'
            );
            let vxeTableHeaderRightWrap = this.$el.querySelector('.vxe-table--fixed-right-wrapper');
            let scrollheader = this.$el.querySelector('.vxe-table--fixed-right-wrapper');

            this.fixedHeader = top - 46 <= 0;

            if (vxeTableHeaderLeft) {
                // 计算左边固定列的left值
                vxeTableHeaderLeft.style.left = this.fixedHeader ? `${left}px` : '0';
                // 计算左边固定列的宽度值
                vxeTableHeaderLeft.style.width = this.fixedHeader
                    ? `${vxeTableHeaderLeftWrap.style.width || 0}`
                    : 'auto';
                // 计算左边固定列的高度值
                let leftBody = this.$el.querySelector('.vxe-table--fixed-left-wrapper .vxe-table--body-wrapper');
                this.$el.querySelector('.vxe-table--fixed-left-wrapper').style.height = this.fixedHeader
                    ? `${leftBody.clientHeight || 0}px`
                    : `${leftBody.clientHeight + vxeTableHeaderLeft.clientHeight}px`;
            }
            if (vxeTableHeaderRight) {
                // 计算右边固定列的right值
                vxeTableHeaderRight.style.right = this.fixedHeader
                    ? `${document.documentElement.clientWidth - right}px`
                    : '0';
                // 计算右边固定列的宽度
                vxeTableHeaderRight.style.width = this.fixedHeader
                    ? `${vxeTableHeaderRightWrap.style.width || 0}`
                    : 'auto';
                // 计算左边固定列的高度值
                let rightBody = this.$el.querySelector('.vxe-table--fixed-right-wrapper .vxe-table--body-wrapper');
                this.$el.querySelector('.vxe-table--fixed-right-wrapper').style.height = this.fixedHeader
                    ? `${rightBody.clientHeight || 0}px`
                    : `${rightBody.clientHeight + vxeTableHeaderRight.clientHeight}px`;
                //右侧固定头部显示-----
                let vxeTableHeaderRightWrapTable = vxeTableHeaderRight.firstElementChild;
                vxeTableHeaderRightWrapTable.style.position = this.fixedHeader ? 'relative' : 'static';
                vxeTableHeaderRightWrapTable.style.left = this.fixedHeader
                    ? '-' + (vxeTableHeaderRightWrapTable.clientWidth - scrollheader.clientWidth) + 'px'
                    : 'auto';
            }
        },
        //判断滚动方向
        handleScroll(e) {
            let direction = e.deltaY > 0 ? 'down' : 'up';
            if (document.documentElement.scrollTop == this.scrollPage && direction == 'down') {
                //向下滚动卡住修复
                document.documentElement.scrollTop++;
            }
            if (document.documentElement.scrollTop == this.scrollPage && direction == 'up') {
                //向上滚动卡住修复
                document.documentElement.scrollTop--;
            }
            this.scrollPage = document.documentElement.scrollTop;
        },
        toolbarFixedFun() {
            if (!this.toolbars) {
                return;
            }
            const { top, bottom, left, right } = this.$el.getBoundingClientRect();
            this.fixedToolbar = top < 0;
            this.$refs.tableToolbar.$el.style.left = this.fixedToolbar ? `${left}px` : '0';
            this.$refs.tableToolbar.$el.style.width = this.fixedToolbar ? `${right - left}px` : 'auto';
        },
        handleTableMouseenter() {
            this.tableHover = true;
            this.scrollCopySize();
        },
        handleTableMouseout() {
            this.tableHover = false;
        },
        handleTableScrollCopyScroll($event) {
            this.$el.querySelector('.vxe-table--main-wrapper .vxe-table--body-wrapper').scrollLeft =
                $event.target.scrollLeft;
        },
        // 浮起的滚动条
        scrollCopySize() {
            let { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            if (this.$refs.tableScrollCopyWrap) {
                this.$refs.tableScrollCopyWrap.style.left = `${left}px`;
                this.$refs.tableScrollCopyWrap.style.width = `${right - left}px`;
            }
            if (this.$refs.tableScrollCopy) {
                this.$refs.tableScrollCopy.style.width = this.$el.querySelector(
                    '.vxe-table--main-wrapper .vxe-table--body'
                ).style.width;
            }
        },
        /**
         * 往表格插入临时数据，从指定位置插入一行或多行；
         * 第二个参数：row 指定位置（不支持树表格）、
         * null从第一行插入、-1 从最后插入
         * 返回: records: Array<object>, row?: Row
         * 用法:
         */
        insertAt(records, row) {
            let table = this.$refs.tableList;
            let hasActive = table.getActiveRecord();
            if (hasActive) {
                return Promise.reject(hasActive);
            }
            let result = { row_status: 'add', ...records, row_add: true };
            return table.insertAt(result, row);
        },
        // 获取新增内容
        getInsertRecords() {
            return Promise.resolve(this.$refs.tableList.getInsertRecords());
        },
        // 获取修改内容
        getUpdateRecords() {
            return Promise.resolve(this.$refs.tableList.getUpdateRecords());
        },
        // 获取删除内容
        getRemoveRecords() {
            return Promise.resolve(this.$refs.tableList.getRemoveRecords());
        },
        getAllRecords() {
            return Promise.resolve(this.$refs.tableList.getTableData());
        },
        // 还原操作
        revertAt() {
            return this.$refs.tableList.revertData();
        },
        // 打开编辑操作
        setActiveRow(row) {
            return this.$refs.tableList.setActiveRow(row);
        },
        clearActived() {
            return this.$refs.tableList.clearActived();
        },
        // 打开弹窗编辑行
        openDialog(type) {
            this.dialogType = type;
            this.dialogVisible = true;
        },
        batchLines(row) {
            let rows = this.batchSave(row);
            return new Promise(resolve => {
                this.$refs.tableList.reloadData(rows).then(() => {
                    resolve({ rows, row });
                });
            });
        },
        batchSave(row) {
            /**
             * 此处待优化
             * 1. vxe-table 里面有getCheckboxRecords()方法
             */
            let isCheckboxConfig = !!Object.keys(this.checkboxConfig || {}).length,
                checkboxKey = isCheckboxConfig ? this.checkboxConfig.checkField : '';
            return this.tableData.map(tr => {
                if (isCheckboxConfig && tr[checkboxKey]) {
                    for (let key in row) {
                        let td = row[key];
                        if (!(td == null || td.length === 0)) {
                            tr[key] = td;
                        }
                    }
                }
                return tr;
            });
        },
        dialogConfirm(row) {
            this.$emit('dialog:confirm', row, this.dialogType);
        },
        dialogCancel() {
            this.$emit('dialog:cancel', this.dialogType);
        },
        removeRow(row) {
            return this.$refs.tableList.remove(row);
        },
        getVxeTable() {
            return this.$refs.tableList;
        },
        // 表格调度中心 = 代理 + 适配 模式
        tableDispatch(methodName, ...args) {
            return this.getTable()[methodName](...args);
        }
        /* ..._funcs.reduce((t, name) => {
            t[name] = function(...args) {
                return this[`_${name}`] ? this[`_${name}`](...args) : this.$refs.tableList[name](...args);
            };
            return t;
        }, {})*/
    },
    watch: {
        fixedToolbar(val) {
            // if (this.fixedToolbar) {
            //     if (!(document.body.scrollHeight - 78 < window.parent.document.body.clientHeight)) {
            //         window.parent.document.getElementsByClassName('zhuan-ye-tuan-dui-hide')[0].click();
            //     }
            // } else {
            //     window.parent.document.getElementsByClassName('zhuan-ye-tuan-dui-show')[0].click();
            // }
        },
        tableColumns(value) {
            this.$nextTick(() => {
                this.$refs.tableList.loadColumn(value).then(() => {
                    // 这里表格列,表格数据均渲染完成, 调用一次scrollHandler
                    if (this.tableFixed) {
                        this.scrollHandler();
                    }
                });
            });
        },
        tableColumnsDefault(val) {
            this.initTableColumns(val);
        },
        radioConfig: {
            handler(val) {
                this.tableRadioConfig = Object.assign({}, val, {
                    checkRowKey: ''
                });
            },
            deep: true
        },
        /**
         * dataSource变化时对表格数据进行更新
         */
        dataSource() {
            this.getTableData();
        },
        tableData: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    // 这里表格数据变化时, 调用一次scrollHandler
                    if (this.tableFixed) {
                        this.scrollHandler();
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
$toolbars-btn-light-bg: #fdfdfd; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮背景颜色
$toolbars-btn-light-bg-hover: #eeeeee; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮背景颜色(hover状态)
$toolbars-btn-light-border: #d6dfeb; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮边框颜色
$toolbars-btn-light-border-hover: #b9c5d6; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮边框颜色(hover状态)
$toolbars-btn-light-border-focus: #aab3c0; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮边框颜色(focus状态)
$toolbars-btn-light-text: #4577ff; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮文字颜色
$toolbars-btn-light-text-hover: #1d50db; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮文字颜色(hover状态)
$toolbars-btn-light-text-focus: #1546cc; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮文字颜色(foucs状态)
$toolbars-btn-light-icon: #4577ff; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮图标颜色(hover状态)
$toolbars-btn-light-icon-hover: #1d50db; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮图标颜色(hover状态)
$toolbars-btn-light-icon-focus: #1241c4; // 表格工具栏--文字按钮(图标+文字的)--浅色系--按钮图标颜色(foucs状态)

$toolbars-icon-btn-light-icon: #7784ab; // 表格工具栏--图标按钮(仅图标的)--浅色系--图标颜色
$toolbars-icon-btn-light-icon-hover: #4577ff; // 表格工具栏--图标按钮(仅图标的)--浅色系--图标颜色(hover状态)
$toolbars-icon-btn-light-icon-focus: #1d50db; // 表格工具栏--图标按钮(仅图标的)--浅色系--图标颜色(foucs状态)
$toolbars-icon-btn-light-bg: #e8e8e8; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色
$toolbars-icon-btn-light-bg-hover: #ffffff; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色(hover状态)
$toolbars-icon-btn-light-bg-focus: #dfdfdf; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮背景颜色(foucs状态)
$toolbars-icon-btn-light-border: #f5f5f5; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色
$toolbars-icon-btn-light-border-hover: #ffffff; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色(hover状态)
$toolbars-icon-btn-light-border-focus: #dfdfdf; // 表格工具栏--图标按钮(仅图标的)--浅色系--按钮边框颜色(foucs状态)

.edit-table-wrap {
    // 工具栏样式--start
    .toolbars-tools {
        margin-right: 4px;
    }
    .toolbars-btn + .toolbars-btn {
        margin-left: 4px;
    }
    .toolbars-btn:not(.el-button--primary) {
        min-width: 64px;
        padding: 7px 8px;
        color: $toolbars-btn-light-text;
        background: $toolbars-btn-light-bg;
        border-color: $toolbars-btn-light-border;
        i {
            color: $toolbars-btn-light-icon;
        }
        &:hover {
            color: $toolbars-btn-light-text-hover;
            border-color: $toolbars-btn-light-border-hover;
            i {
                color: $toolbars-btn-light-icon-hover;
            }
        }
        &:focus {
            color: $toolbars-btn-light-text-focus;
            border-color: $toolbars-btn-light-border-focus;
            i {
                color: $toolbars-btn-light-icon-focus;
            }
        }
    }
    .toolbars-icon-btn + .toolbars-icon-btn {
        margin-right: 8px;
        margin-left: 4px;
    }
    .toolbars-icon-btn {
        //background: $toolbars-icon-btn-light-bg;
        background: none;
        border-color: $toolbars-icon-btn-light-border;
        border-width: 0;
        padding: 4px;
        font-size: 16px;
        i {
            font-size: 16px;
            color: $toolbars-icon-btn-light-icon;
        }
        &:hover {
            background: $toolbars-icon-btn-light-bg-hover;
            border-color: $toolbars-icon-btn-light-border-hover;
            i {
                color: $toolbars-icon-btn-light-icon-hover;
            }
        }
        &:focus {
            background: $toolbars-icon-btn-light-bg-focus;
            border-color: $toolbars-icon-btn-light-border-focus;
            i {
                color: $toolbars-icon-btn-light-icon-focus;
            }
        }
    }
    // 工具栏样式--end

    // table样式--start
    .vxe-table {
        color: #101010;
    }
    &.is-bigdata .vxe-table--body-wrapper {
        overflow: hidden;
    }
    .vxe-table .vxe-cell {
        max-height: 1000px !important;
    }
    .vxe-table.border--default .vxe-table--header-wrapper,
    .vxe-table.border--full .vxe-table--header-wrapper,
    .vxe-table.border--outer .vxe-table--header-wrapper {
        background-color: #eaf0f3;
    }
    .vxe-table .vxe-body--row.row--stripe {
        background-color: #f7f8f9;
    }
    .vxe-table .vxe-body--row.row--cheched,
    .vxe-table .vxe-body--row.row--radio {
        background-color: #fcfcce;
    }
    .query-table-wrap .vxe-table .vxe-body--row.row--cheched,
    .query-table-wrap .vxe-table .vxe-body--row.row--radio {
        background-color: #fcfcce;
    }
    .vxe-table.border--full .vxe-body--column,
    .vxe-table.border--full .vxe-footer--column,
    .vxe-table.border--full .vxe-header--column {
        background-image: linear-gradient(#f4f4f4, #f4f4f4), linear-gradient(#f4f4f4, #f4f4f4);
    }
    .vxe-table .vxe-body--row.row--hover,
    .vxe-table .vxe-body--row.row--hover.row--stripe {
        background-color: #f2fcfc;
    }
    // table样式--end
    .link-url {
        color: #1d50db;
        text-decoration: underline;
        cursor: pointer;
    }
    .link-code {
        color: #1d50db;
    }
    .state-amount {
        color: #f45f49;
    }
    .state-minor {
        color: #808080;
    }
    .state-process {
        color: #1d50db;
    }
    .state-success {
        color: #16c352;
    }
    .state-refuse {
        color: #ff3315;
    }
    .operation-btn-default {
        color: #1d50db;
        &.is-disabled {
            color: #bbbbbb;
        }
    }
    .operation-btn-danger {
        color: #f45f49;
        &.is-disabled {
            color: #bbbbbb;
        }
    }

    // 启用
    .operation-btn-start {
        color: #16c352;
        &.is-disabled {
            color: #bbbbbb;
        }
    }
    // 停用
    .operation-btn-stop {
        color: #ff3315;
        &.is-disabled {
            color: #ff3315;
        }
    }

    .operation-cell {
        .el-button {
            padding: 0px;
        }
    }
    .process-cell {
        cursor: pointer;
        i {
            margin-left: 5px;
            color: #e0e0e0;
        }
    }
    .multiple-cell {
        vertical-align: top;
    }
    .el-button.is-circle {
        border-radius: 4px;
    }
    .is-empty {
        text-decoration: none;
        cursor: default;
    }

    .table-footer {
        position: relative;
        .table-info {
            position: absolute;
            height: 36px;
            line-height: 36px;
            z-index: 11;
        }
    }
    .is-footer-fixed {
        position: fixed;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 10;
        left: 24px;
        right: 24px;
        /deep/ .com-table-info {
            color: #fff;
            .table-info-item {
                .label {
                    color: #fff;
                }
                .amount {
                    color: #f9b0b0;
                }
            }
        }
        .vxe-pager {
            background: transparent;
            color: #fff;
            &.is--border:not(.is--background) .vxe-pager--jump-next,
            &.is--border:not(.is--background) .vxe-pager--jump-prev,
            &.is--border:not(.is--background) .vxe-pager--next-btn,
            &.is--border:not(.is--background) .vxe-pager--num-btn,
            &.is--border:not(.is--background) .vxe-pager--prev-btn,
            &.is--perfect:not(.is--background) .vxe-pager--jump-next,
            &.is--perfect:not(.is--background) .vxe-pager--jump-prev,
            &.is--perfect:not(.is--background) .vxe-pager--next-btn,
            &.is--perfect:not(.is--background) .vxe-pager--num-btn,
            &.is--perfect:not(.is--background) .vxe-pager--prev-btn {
                border-color: #808080;
                background: #666;
            }
            .vxe-pager--jump-next,
            .vxe-pager--jump-prev,
            .vxe-pager--next-btn,
            .vxe-pager--num-btn,
            .vxe-pager .vxe-pager--prev-btn {
                border-radius: 0.25em;
                margin: 0 0.15em;
            }
            .vxe-pager--jump-next:not(.is--disabled).is--active,
            .vxe-pager--jump-next:not(.is--disabled):focus,
            .vxe-pager--jump-prev:not(.is--disabled).is--active,
            .vxe-pager--jump-prev:not(.is--disabled):focus,
            .vxe-pager--next-btn:not(.is--disabled).is--active,
            .vxe-pager--next-btn:not(.is--disabled):focus,
            .vxe-pager--num-btn:not(.is--disabled).is--active,
            .vxe-pager--num-btn:not(.is--disabled):focus,
            .vxe-pager--prev-btn:not(.is--disabled).is--active,
            .vxe-pager--prev-btn:not(.is--disabled):focus {
                color: #000000;
                background: #fff;
            }
            &.is--border:not(.is--background) .vxe-pager--jump-next,
            &.is--border:not(.is--background) .vxe-pager--jump-prev,
            &.is--border:not(.is--background) .vxe-pager--next-btn,
            &.is--border:not(.is--background) .vxe-pager--num-btn,
            &.is--border:not(.is--background) .vxe-pager--prev-btn,
            &.is--perfect:not(.is--background) .vxe-pager--jump-next,
            &.is--perfect:not(.is--background) .vxe-pager--jump-prev,
            &.is--perfect:not(.is--background) .vxe-pager--next-btn,
            &.is--perfect:not(.is--background) .vxe-pager--num-btn,
            &.is--perfect:not(.is--background) .vxe-pager--prev-btn {
                border-color: #808080;
            }
            .vxe-pager--jump-next:not(.is--disabled):hover,
            .vxe-pager--jump-prev:not(.is--disabled):hover,
            .vxe-pager--next-btn:not(.is--disabled):hover,
            .vxe-pager--num-btn:not(.is--disabled):hover,
            .vxe-pager--prev-btn:not(.is--disabled):hover {
                color: #86ddff;
                font-weight: bold;
            }
            .is--active {
                font-weight: bold;
            }
            .vxe-pager--jump .vxe-pager--goto {
                border-radius: 0.1em;
                border: 1px solid #969696;
                background: #666;
                color: #fff;
            }
            .vxe-input--inner {
                border-radius: 0.1em;
                padding: 0 0.6em;
                color: #fff;
                border: 1px solid #969696;
                background-color: #666;
            }
        }
    }
    .is-toolbar-fixed.vxe-toolbar {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 11;
    }
    .is-header-fixed.vxe-table {
        border-left: 1px solid #e7e9eb;
        border-right: 1px solid #e7e9eb;
        .vxe-table--fixed-left-wrapper {
            z-index: 10;
            .vxe-table--header {
                // color: white;
            }
            .vxe-table--header-wrapper {
                border-top: 1px solid #e7e9eb;
                position: fixed;
                top: 46px;
                z-index: 10;
                //background: rgba(0, 0, 0, 0.65);
                overflow: hidden;
            }
            .vxe-table--body-wrapper {
                top: 0px !important;
            }
        }
        .vxe-table--main-wrapper {
            .vxe-table--header {
                //color: white;
            }
            .vxe-table--header-wrapper {
                border-top: 1px solid #e7e9eb;
                position: fixed;
                top: 46px;
                z-index: 10;
                //background: rgba(0, 0, 0, 0.7);
                overflow: hidden;
            }
        }
        .vxe-table--fixed-right-wrapper {
            z-index: 10;
            .vxe-table--header {
                //color: white;
            }
            .vxe-table--header-wrapper {
                border-top: 1px solid #e7e9eb;
                position: fixed;
                top: 46px;
                z-index: 10;
                //background: rgba(0, 0, 0, 0.7);
                overflow: hidden;
            }
            .vxe-table--body-wrapper {
                top: 0px !important;
            }
        }
    }
    .vxe-pager--count,
    .vxe-pager .vxe-pager--jump-next,
    .vxe-pager .vxe-pager--jump-prev,
    .vxe-pager .vxe-pager--next-btn,
    .vxe-pager .vxe-pager--num-btn,
    .vxe-pager .vxe-pager--prev-btn {
        min-width: 2.4em;
        height: 2em;
        line-height: 2em;
    }
    .vxe-pager.is--border:not(.is--background) .vxe-pager--jump-next,
    .vxe-pager.is--border:not(.is--background) .vxe-pager--jump-prev,
    .vxe-pager.is--border:not(.is--background) .vxe-pager--next-btn,
    .vxe-pager.is--border:not(.is--background) .vxe-pager--num-btn,
    .vxe-pager.is--border:not(.is--background) .vxe-pager--prev-btn,
    .vxe-pager.is--perfect:not(.is--background) .vxe-pager--jump-next,
    .vxe-pager.is--perfect:not(.is--background) .vxe-pager--jump-prev,
    .vxe-pager.is--perfect:not(.is--background) .vxe-pager--next-btn,
    .vxe-pager.is--perfect:not(.is--background) .vxe-pager--num-btn,
    .vxe-pager.is--perfect:not(.is--background) .vxe-pager--prev-btn {
        border-color: #cecece;
    }
    .table-wrap-width-copy {
        width: 100%;
        overflow-x: auto;
        position: fixed;
        bottom: 36px;
        height: 20px;
        z-index: 30;
    }
    .table-full-width-copy {
        height: 1px;
    }
}
.custom-pop {
    padding: 8px;
}
.toolbars-btn-item {
    display: inline-block;
}
.toolbars-btn-item + .toolbars-btn-item {
    margin-left: 8px;
}
.resize-notify {
    .resize-notify-text {
        margin-bottom: 8px;
    }
    .resize-notify-btn {
        margin-top: 8px;
    }
}

.f-magnifier-dialog {
    /deep/ .el-dialog__header {
        padding: 8px;
        background: #409eff;
        .el-dialog__title {
            color: #ffffff;
        }
        .el-dialog__headerbtn {
            top: 10px;
            right: 8px;
        }
        .el-dialog__close {
            font-size: 18px;
            color: #ffffff;
        }
    }
    /deep/ .el-dialog__body {
        padding: 16px 8px 50px 8px;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding: 10px;
        background-color: #f2f7f7;
    }
}
</style>
