import Vue from 'vue';
import { MessageBox, Message } from '@iss/frontend-ui';
import i18n from '@/common/i18n';

Vue.prototype.$confirm = (message, title, options) => {
    if (typeof title === 'object') {
        options = title;
        title = '';
    } else if (title === undefined) {
        title = '';
    }
    options = Object.assign(
        {
            title: title || i18n.t('i18n.common.prompt'),
            message: message,
            type: 'warning',
            $type: 'confirm',
            showCancelButton: true,
            confirmButtonLeft: true,
            confirmButtonText: i18n.t('i18n.common.confirmText'),
            cancelButtonText: i18n.t('i18n.common.cancelText'),
            cancelButtonClass: 'is-plain'
        },
        options
    );
    return MessageBox(options);
};

Vue.prototype.$delete = cb => {
    let options = {
        title: i18n.t('i18n.common.prompt'),
        message: i18n.t('i18n.common.delPromptCont'),
        type: 'warning',
        $type: 'confirm',
        customClass: 'delete-message-box',
        showCancelButton: true,
        confirmButtonLeft: true,
        confirmButtonText: i18n.t('i18n.common.delText'),
        cancelButtonText: i18n.t('i18n.common.cancelText'),
        cancelButtonClass: 'is-plain',
        callback: cb
    };
    return MessageBox(options);
};

Vue.prototype.$success = (message, options) => {
    if (typeof message === 'object') {
        options = message;
        message = '';
    } else if (message === undefined) {
        message = '';
    }
    if(message === ''){
        return;
    }
    options = Object.assign(
        {
            message: message,
            type: 'success'
        },
        options
    );
    return Message(options);
};

Vue.prototype.$error = (message, options) => {
    if (typeof message === 'object') {
        options = message;
        message = '';
    } else if (message === undefined) {
        message = '';
    }
    options = Object.assign(
        {
            message: message,
            type: 'error'
        },
        options
    );
    return Message(options);
};

Vue.prototype.$info = (message, options) => {
    if (typeof message === 'object') {
        options = message;
        message = '';
    } else if (message === undefined) {
        message = '';
    }
    if(message === ''){
        return;
    }
    options = Object.assign(
        {
            message: message,
            type: 'info'
        },
        options
    );
    return Message(options);
};

export default {
    confirm: Vue.prototype.$confirm,
    delete: Vue.prototype.$delete,
    success: Vue.prototype.$success,
    error: Vue.prototype.$error,
    info: Vue.prototype.$info
}
