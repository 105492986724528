export const formatAmount = {
    formatAmount(value, decimals = 2, sign = '') {
        /*
         * 参数说明：
         * value：要格式化的数字
         * decimals：保留几位小数
         * sign: 金额符号
         * */
        let number = (value + '').replace(/[^0-9+-Ee.]/g, '');
        let n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };

        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        let re = /(-?\d+)(\d{3})/;
        while (re.test(s[0])) {
            s[0] = s[0].replace(re, '$1' + ',' + '$2');
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return sign + s.join('.');
    },
    formatAmountChies(amount) {
        if (!amount) return '';
        // 汉字的数字
        const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
        // 基本单位
        const cnIntRadice = ['', '拾', '佰', '仟'];
        // 对应整数部分扩展单位
        const cnIntUnits = ['', '万', '亿', '兆'];
        // 对应小数部分单位
        const cnDecUnits = ['角', '分'];
        // 整数金额时后面跟的字符
        const cnInteger = '整';
        // 整型完以后的单位
        const cnIntLast = '元';
        // 最大处理的数字
        const maxNum = 9999999999999999.99;
        // 金额整数部分
        let integerNum;
        // 金额小数部分
        let decimalNum;
        // 输出的中文金额字符串
        let chineseStr = '';
        // 分离金额后用的数组，预定义
        let parts;
        if (amount === '') {
            return '';
        }
        amount = parseFloat(amount);
        if (amount >= maxNum) {
            // 超出最大处理数字
            return '';
        }
        if (amount === 0) {
            chineseStr = cnNums[0] + cnIntLast + cnInteger;
            return chineseStr;
        }
        // 转换为字符串
        amount = amount.toString();
        if (amount.indexOf('.') === -1) {
            integerNum = amount;

            decimalNum = '';
        } else {
            parts = amount.split('.');
            integerNum = parts[0];
            decimalNum = parts[1].substr(0, 4);
        }
        // 获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
            let zeroCount = 0;
            const IntLen = integerNum.length;
            for (let i = 0; i < IntLen; i++) {
                const n = integerNum.substr(i, 1);
                const p = IntLen - i - 1;
                const q = p / 4;
                const m = p % 4;
                if (n === '0') {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseStr += cnNums[0];
                    }
                    // 归零
                    zeroCount = 0;
                    //alert(cnNums[parseInt(n)])
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if (m === 0 && zeroCount < 4) {
                    chineseStr += cnIntUnits[q];
                }
            }
            chineseStr += cnIntLast;
        }
        // 小数部分
        if (decimalNum !== '') {
            const decLen = decimalNum.length;
            for (let i = 0; i < decLen; i++) {
                const n = decimalNum.substr(i, 1);
                if (n !== '0') {
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (chineseStr === '') {
            chineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (decimalNum === '') {
            chineseStr += cnInteger;
        }
        return chineseStr;
    },
    add(a,b){
        var c, d, e;
        try {
            c = a.toString().split(".")[1].length;
        } catch (f) {
            c = 0;
        }
        try {
            d = b.toString().split(".")[1].length;
        } catch (f) {
            d = 0;
        }
        return e = Math.pow(10, Math.max(c, d)), (this.mul(a, e) + this.mul(b, e)) / e;
    },
    sub(a,b){
        return this.add(a,b*-1);
    },
    //乘法
    mul(a, b) {
        var c = 0,
            d = a.toString(),
            e = b.toString();
        try {
            c += d.split(".")[1].length;
        } catch (f) {}
        try {
            c += e.split(".")[1].length;
        } catch (f) {}
        return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
    },
    div(a, b) {
        var c, d, e = 0,
            f = 0;
        try {
            e = a.toString().split(".")[1].length;
        } catch (g) {}
        try {
            f = b.toString().split(".")[1].length;
        } catch (g) {}
        return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), mul(c / d, Math.pow(10, f - e));
    },
    toFixed(number, m) {//toFixed 再chrome和火狐下会有BUG，使用自定义函数处理
        if (typeof number !== 'number') {
            throw new Error("number不是数字");
        }
        let result = Math.round(Math.pow(10, m) * number) / Math.pow(10, m);
        result = String(result);
        if (result.indexOf(".") == -1) {
            if(m != 0){
                result += ".";
                result += new Array(m + 1).join('0');
            }
        } else {
            let arr = result.split('.');
            if (arr[1].length < m) {
                arr[1] += new Array(m - arr[1].length + 1).join('0')
            }
            result = arr.join('.')
        }
        return result
    }
};
