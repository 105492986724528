/**
 * 多选放大镜公共的mixin
 */
export default {
    data() {
        // eslint-disable-next-line no-unused-vars
        let { pageChange, change, query, clear, fieldReset, ...listeners } = this.$listeners;
        return {
            /**
             * 前后端交互的字段名
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * 存放表格数据
             */
            tableData: {
                data: [],
                total: 0
            },
            listeners: listeners,
            initialValue: {},
            autoSelect: false,
            reInitialized: false
        };
    },
    props: {
        /**
         * 表格数据的查询地址
         */
        dataSource: {
            type: String
        },
        /**
         * 额外的查询参数配置
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 放大镜双向绑定值
         */
        value: [Array],
        /**
         * 关联表单配置
         */
        formModel: Object,
        /**
         * 清除关联值方法
         */
        clear: {
            type: Function,
            default: null
        },
        placeholder: {
            type: String
        },
        isCloseResetSearchForm: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // 由于多选的值都是数组,所以需要进行stringify处理下
            // this.initialValue[this.valueKey] = JSON.parse(JSON.stringify(this.formModel[this.valueKey]));
        },
        handleOpen() {
            this.tableData.data = [];
            this.tableData.total = 0;
            this.$refs.magnifier.page.currentPage = 1;
            this.$refs.magnifier.page.pageSize = 10;
            this.$nextTick(() => {
                this.getTableData();
            });
            this.$emit('open');
        },
        /**
         * 获取表格的分页信息
         */
        getPageInfo() {
            if (this.$refs.magnifier) {
                return this.$refs.magnifier.getPageInfo();
            } else {
                console.log('缺少需要的ref');
            }
        },
        /**
         * 将分页数据根据keyNames的配置进行转换
         */
        setPageInfo(pageInfo) {
            return {
                [this.keyNames.currentPage]: pageInfo.currentPage,
                [this.keyNames.pageSize]: pageInfo.pageSize
            };
        },
        getTableData() {
            if (!this.dataSource) {
                console.log('缺少请求的url,请配置dataSource属性!');
                return;
            }
            let param = Object.assign({}, this.extraData, this.$refs.magnifier.searchModel);
            if (!(this.$attrs.pagination === false)) {
                let page = this.setPageInfo(this.getPageInfo());
                param = Object.assign(param, page);
            }
            console.log('当前的放大镜的查询参数===', param)
            this.$http.post(this.dataSource, param).then(res => {
                if (this.$attrs.pagination === false) {
                    this.tableData.data = res.data;
                }else {
                    this.tableData.data = res.data[this.keyNames.rows];
                    this.tableData.total = res.data[this.keyNames.total];
                }

            });
        },
        handleQuery() {
            this.$refs.magnifier.page.currentPage = 1;
            // 加载表格数据
            this.getTableData();
        },
        handlePageChange() {
            // 加载表格数据
            this.getTableData();
        },
        handlerChange(val) {
            // 实现双向绑定事件
            this.$emit('change', val);
        },
        handleClear() {
            if (this.clear) {
                // 如果自定义了清除方法, 则交给外部处理
                this.clear();
            } else {
                // 置空关联值
                this.setMatchKeyData([]);
            }
            this.$emit('clear');
        },
        handleReset() {
            // 重置关联值
            for (let key in this.matchKey) {
                this.formModel[key] = this.initialValue[key] || [];
            }
            this.$emit('reset')
        }
    }
};
