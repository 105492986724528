<template>
    <!-- 此组件只考虑字典(静态) -->
    <f-select
        ref="select"
        v-model="select"
        v-bind="{ size: 'mini', ...$attrs }"
        v-on="listeners"
        :props="props"
        :data="optionsData"
        :placeholder="placeholder"
        :loading="loading"
        :multiple="multiple"
        @change="handleChange"
        @reset="resetFormModel"
        @clear="handleClear"
        @visible-change="visibleChange"
        :clearable="clearable"
        :auto-select="autoSelect"
    >
        <template v-slot:optionSlot="slotProps">
            <slot :item="slotProps.item"></slot>
        </template>
        <slot v-if="!optionsData"></slot>
    </f-select>
</template>

<script>
/**
 * 重新封装f-select下拉框组件,继承f-select的所有功能, 并增加以下功能
 * 1.提供配置url的方式
 * 2.提供extraData配置请求参数
 * 3.前后端交互的字段名可以在props中进行配置
 * 4.提供远程筛选的方法remoteFilter
 * 5.提供reload方法
 * 6.提供optionGroup配置是否分组下拉框(暂未开放)
 */
export default {
    inheritAttrs: false,
    name: 'ComSelect',
    data() {
        // eslint-disable-next-line no-unused-vars
        let { change, update: formModel, loadMore, clear, reset, visibleChange, ...listeners } = this.$listeners;
        return {
            optionsData: null,
            loading: false,
            listeners: listeners
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    computed: {
        select: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('change', val);
            }
        }
    },
    props: {
        /**
         * 双向绑定值
         */
        value: [String, Number, Array],
        props: {
            type: Object,
            default: () => {
                return {
                    key: 'value',
                    label: 'text',
                    value: 'value'
                };
            }
        },
        data: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * 源数据, 可以配置为请求地址
         */
        url: {
            type: String,
            required: false,
            default: null
        },
        /**
         * 请求发送到后台的数据
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        remoteFilterKey: [Array, String],
        infiniteScroll: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        multiple: Boolean,
        formModel: Object,
        matchKey: Object,
        placeholder: String,
        beforeOpen: Function,
        autoSelect: {
            type: Boolean,
            default: false
        },
        /**
         * 是否分组的options
         * 此功能暂未开放
         */
        optionGroup: {
            type: Boolean,
            default: false
        },
        parseDataFn: Function
    },
    mounted() {
        this.initFormModel();
        this.init();
    },
    methods: {
        init() {
            /* 由于不涉及分页和搜索, 参数直接使用extraData 传入的数据 */
            this.getSelectData();
        },
        /* 设置forModel初始值， 方便重置且不改变其他数据 */
        initFormModel() {
            Object.defineProperty(this, 'initValue', {
                value: this.formModel ? Object.assign({}, this.formModel) : this.formModel
            });
        },
        getSelectData() {
            //当下拉组件没有直接配置数据项，且url存在时，从后台请求数据
            if (this.data === null && this.url) {
                this.loading = true;
                let param = this.extraData;
                this.$http.get(this.url, param).then(res => {
                    this.loading = false;
                    this.optionsData = res.data;
                });
            } else {
                if (this.data !== null) {
                    this.optionsData = this.data;
                }
            }
        },
        reload() {
            let { multiple } = this;
            this.handleChange(multiple ? [] : '');
            this.getSelectData();
        },
        handleChange(val) {
            let selects = this.getSelect(val);
            this.$emit('select', selects);
            this.$emit('update:formModel', { ...this.formModel, ...this.updateFormModel(selects) });
        },
        getSelect(val) {
            let { multiple } = this;
            return multiple ? this.getSelectRows(val) : this.getSelectRow(val);
        },
        getSelectRow(val) {
            return this.optionsData && this.optionsData.find(item => item[this.props.value] === val);
        },
        getSelectRows(vals) {
            if (!Array.isArray(vals)) return [];
            return vals.map(val => this.getSelectRow(val));
        },
        updateFormModel(val) {
            let obj = {};
            if (val == null) return obj;
            /**
             * val 可能是对象， 可能是数组, null, undefined
             * 如果是对象 { axx: 'xxx'}
             * 如果使数组 { axx: ['xxx']}
             */
            for (let key in this.matchKey) {
                let value = this.matchKey[key];
                obj[key] = Array.isArray(val)
                    ? val.map(item => item[value])
                    : typeof val === 'object'
                    ? val[value]
                    : '';
            }
            return obj;
        },
        /* 重置方法 清空formModel*/
        resetFormModel(val) {
            let result = { [this.props.value]: val };
            this.$emit('update:formModel', { ...this.initValue, ...result });
            this.$emit('reset', val);
        },
        /* 清空方法 清空formModel */
        clearFormModel(val) {
            /**
             * 清空需要做的事情有;
             * 1. 合并 初始值，formModel， value 值， 然后删除matchKey 里面的属性
             */
            const value = this.multiple ? [] : '';
            this.select = value;
            this.$emit('update:formModel', this.deleteMatchKey({ ...this.initValue, ...this.formModel }));
            this.$emit('clear', val);
        },
        /* 删除matchKey 里面相关的值 */
        deleteMatchKey(option) {
            let { multiple } = this;
            for (let okey in option) {
                for (let mkey in this.matchKey) {
                    if (okey === mkey) {
                        option[okey] = multiple ? [] : '';
                    }
                }
            }
            return option;
        },
        visibleChange(visible) {
            visible && this.beforeVisible();
        },
        beforeVisible() {
            if (!this.beforeOpen) {
                this.focus();
            } else if (typeof this.beforeOpen === 'function') {
                const before = this.beforeOpen();
                if (before && before.then) {
                    before.then(
                        () => {
                            this.focus();
                        },
                        () => {
                            this.blur();
                        }
                    );
                } else if (before !== false) {
                    this.focus();
                } else {
                    this.blur();
                }
            }
        },
        focus() {
            this.$refs.select.focus();
        },
        blur() {
            this.$refs.select.blur();
        },
        /* 清空方法 */
        handleClear() {
            this.clearFormModel();
        },
        /**
         * 外部提供的方法 清空方法
         */
        clear() {
            /**
             * 使用nextTick让formModel更新一轮，再来支持清空
             */
            this.$nextTick(() => {
                this.handleClear();
            });
        }
    },
    watch: {
        /** 防止初始化获取不到数据 */
        data() {
            this.init();
        }
    }
};
</script>
