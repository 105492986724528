/**
 * 放大镜公共的mixin
 */
import { styleMix } from '@/common/comMixin';

export default {
    mixins: [styleMix],
    data() {
        return {
            /**
             * 前后端交互的字段名
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * 放大镜默认隐藏
             */
            visible: false,
            /**
             * 放大镜查询条件
             */
            searchForm: {},
            /**
             * 存放表格数据
             */
            tableData: {
                data: [],
                total: 0
            },
            /**
             * 每页显示条数可配
             */
            pageSizes: [10, 20, 50, 100],
            /**
             * 分页信息可配
             */
            page: {
                currentPage: 1,
                pageSize: 10
            }
        };
    },
    props: {
        /**
         * 表格数据的查询地址
         */
        dataSource: {
            type: String
        },
        /**
         * 额外的查询参数配置
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        pagination: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        /**
         * 关联表单配置
         */
        formModel: Object,
        /**
         * 关联值配置
         */
        matchKey: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 是否在弹窗关闭的时候重置查询表单中的内容
         */
        isCloseResetSearchForm: {
            type: Boolean,
            default: true
        },
        autoSelect:{
            type: Boolean,
            default: false
        },
        searchRules:{
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        getTableData() {
            if (!this.dataSource) {
                console.log('缺少请求的url,请配置dataSource属性!');
                return;
            }
            let param = Object.assign({}, this.extraData, this.searchForm);
            if (!(this.pagination === false)) {
                param = Object.assign(param, {
                    [this.keyNames.currentPage]: this.page.currentPage,
                    [this.keyNames.pageSize]: this.page.pageSize
                });
            }
            //console.log('付款账号放大镜的查询参数===', param);
            this.$http.post(this.dataSource, param).then(res => {
                if (this.pagination === false) {
                    this.tableData.data = res.data;
                } else {
                    this.tableData.data = res.data[this.keyNames.rows];
                    this.tableData.total = res.data[this.keyNames.total];
                }

            });
        },
        handleQuery() {
            this.page.currentPage = 1;
            // 加载表格数据
            this.getTableData();
        },
        handlePageChange() {
            // 加载表格数据
            this.getTableData();
        },
        handlerConifrm(row) {
            this.setMatchKeyData(row);
            this.$emit('confirm', row);
            this.handleClose();
        },
        /**
         * 表格行双击事件
         */
        handleRowDblclick(row) {
            this.handlerConifrm(row);
        },
        /**
         * 当前高亮行状态enter事件
         */
        currentRowEnter(row) {
            this.handlerConifrm(row);
        },
        open() {
            this.page.currentPage = 1;
            this.page.pageSize = 10;

            if (this.beforeOpen && !this.beforeOpen()) {
                return;
            }
            this.visible = true;
            this.$nextTick(() => {
                this.$emit('beforeGetData')
                this.getTableData();
            });
            this.$emit('open');
        },
        close() {
            this.visible = false;
        },
        handleClose() {
            if (this.isCloseResetSearchForm && this.$refs.searchForm) {
                this.$refs.searchForm.reset()
            }
            this.close();
        },
        setMatchKeyData(row) {
            // 对matchKey进行赋值
            // [fix] 当赋值不为undefined， null 时 均原样返回;
            for (let key in this.matchKey) {
                let value = row[this.matchKey[key]];
                this.formModel[key] = value == null ? '' : value;
            }
        }
    },
    watch:{
        tableData: {
            handler(nval) {
                if(this.autoSelect && nval.data.length === 1
                    && ((this.pagination && this.page.currentPage == 1) || !this.pagination)){
                       this.handlerConifrm(nval.data[0])
                }
            },
            immediate: true,
            deep: true
        }
    }
};
