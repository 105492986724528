<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ComPage',
        props: {
            pageConfig: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>