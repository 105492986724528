<template>
    <f-form
        style="width: 100%"
        ref="form"
        :model="form"
        v-on="formConfig"
        :size="mixSize"
        :label-width="formLabelWidth ? formLabelWidth : mixLabelWidth"
        :labelPosition="mixLabelPosition"
        :disabled="formDisabled"
        :rules="formRules"
        :gutter="mixGutter"
        :validMessage="validMessage"
        :showInvalidPrompt="false"
    >
        <transition-group name="el-list" tag="div" class="form-item_group" v-if="status === 'success'">
            <template v-for="(item, i) in data">
                <f-form-item
                    :key="item.name + item.text + '_' + i"
                    :prop="item.name"
                    :label="item.text"
                    :column="['textarea', 'file'].includes(item.type) ? 1 : mixColumn"
                    v-if="(item.type === 'file' && fileConfig) || item.type !== 'file'"
                >
                    <sub-form-template
                        :formModel.sync="form"
                        :config="item"
                        :mixDictionaries="mixDictionaries"
                    ></sub-form-template>
                </f-form-item>
            </template>
        </transition-group>
        <f-form-item :column="1" v-else>{{ statusText[status] }}</f-form-item>
    </f-form>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import i18n from "@/common/i18n";

/**
 * 1. 不要忘记 加载中 loading 状态
 * 2. 不要忘记 设置表格模板的高度
 * 3. 顺序先进行post 配置加载   -> 收集字典 -> 字典加载完毕之后 ->
 */
function decodeStr(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/(&quot;)/g, '"');
}

function jsonParse(str) {
    let result;
    if (typeof str !== 'string') return str;
    try {
        result = JSON.parse(str);
    } catch (error) {
        result = [];
    }
    return result;
}
import { styleMix, dictionaryMix } from '@/common/comMixin';
import FormMagnifierSingle from './form-magnifier-single.vue';
import FormMagnifierMulti from './form-magnifier-multi.vue';
import SubFormTemplate from './sub-form-template';
export default {
    name: 'FormTemplate',
    mixins: [styleMix, dictionaryMix],
    inject: {
        ComEditForm: {
            default: ''
        }
    },
    components: {
        [FormMagnifierSingle.name]: FormMagnifierSingle,
        [FormMagnifierMulti.name]: FormMagnifierMulti,
        [SubFormTemplate.name]: SubFormTemplate
    },
    filters: {
        decodeStr,
        jsonParse,
        matchKey(arr) {
            if (!Array.isArray(arr)) return arr;
            let result = {};
            for (let item of arr) {
                for (let key in item) {
                    result[key] = item[key];
                }
            }
            return result;
        }
    },
    data() {
        let { queryInfo, queryCode, saveInfo, updateInfo } = this.$api.common.comForm;
        return {
            data: [],
            haveDic: ['select', 'radio', 'checkbox'],
            dicType: ['constant', 'dictionary', 'custom'],
            status: 'pending', // finsh, pending, error, success
            statusText: {
                empty: this.$t('i18n.common.components.form.dataEmpty')
            },
            comUrl: {
                editUrl: queryInfo,
                addUrl: queryCode,
                saveInfo,
                updateInfo
            },
            privateForm: {},
            validMessage:{
                default: this.$t('i18n.common.validMessage.defalut'),
                required: this.$t('i18n.common.validMessage.required'),
                enum: this.$t('i18n.common.validMessage.enum'),
                whitespace: this.$t('i18n.common.validMessage.whitespace'),
                date: {
                    format: this.$t('i18n.common.validMessage.date.format'),
                    parse: this.$t('i18n.common.validMessage.date.parse'),
                    invalid: this.$t('i18n.common.validMessage.date.invalid')
                },
                types: {
                    string: this.$t('i18n.common.validMessage.types.string'),
                    method: this.$t('i18n.common.validMessage.types.method'),
                    array: this.$t('i18n.common.validMessage.types.array'),
                    object: this.$t('i18n.common.validMessage.types.object'),
                    number: this.$t('i18n.common.validMessage.types.number'),
                    date: this.$t('i18n.common.validMessage.types.date'),
                    boolean: this.$t('i18n.common.validMessage.types.boolean'),
                    integer: this.$t('i18n.common.validMessage.types.integer'),
                    float: this.$t('i18n.common.validMessage.types.float'),
                    regexp: this.$t('i18n.common.validMessage.types.regexp'),
                    email: this.$t('i18n.common.validMessage.types.email'),
                    url: this.$t('i18n.common.validMessage.types.url'),
                    hex: this.$t('i18n.common.validMessage.types.hex')
                },
                string: {
                    len: this.$t('i18n.common.validMessage.string.len'),
                    min: this.$t('i18n.common.validMessage.string.min'),
                    max: this.$t('i18n.common.validMessage.string.max'),
                    range: this.$t('i18n.common.validMessage.string.range')
                },
                number: {
                    len: this.$t('i18n.common.validMessage.number.len'),
                    min: this.$t('i18n.common.validMessage.number.min'),
                    max: this.$t('i18n.common.validMessage.number.max'),
                    range: this.$t('i18n.common.validMessage.number.range')
                },
                array: {
                    len: this.$t('i18n.common.validMessage.array.range'),
                    min: this.$t('i18n.common.validMessage.array.min'),
                    max: this.$t('i18n.common.validMessage.array.max'),
                    range: this.$t('i18n.common.validMessage.array.range')
                },
                pattern: {
                    mismatch: this.$t('i18n.common.validMessage.pattern.mismatch')
                }
            }
        };
    },
    mounted() {
        this.init();
    },
    props: {
        formModel: Object,
        formType: String,
        urlConfig: Object,
        extraData: {
            type: Object,
            default: () => {}
        },
        // 其他配置 formLabel 宽度
        formLabelWidth: String,
        // 表单验证方式
        validTrigger: {
            type: String,
            default: 'blur'
        },
        // form表单的一些配置
        formConfig: Object,
        fileConfig: Object,
        dictionariesUrl: String // 字典url
    },
    computed: {
        // form 返回的数据
        form: {
            get() {
                return this.formModel || this.privateForm;
            },
            set(val) {
                this.formModel ? this.$emit('update:formModel', val) : (this.privateForm = val);
            }
        },
        // form 验证配置项
        formRules() {
            let rules = {};
            this.data.forEach(item => {
                rules[item.name] = [].concat(item.rules).map(rule => {
                    let types = {};
                    // 当数据类型type 为 amount 或 number 时， 校验规则添加 {type: 'number'}
                    if (['amount', 'number'].includes(item.type)) {
                        types = { type: 'number' };
                    }
                    return { ...rule, ...types, trigger: this.validTrigger };
                });
            });
            return rules;
        },
        pageType() {
            let type = 'add';
            if (this.ComEditForm) {
                type = this.formType || this.ComEditForm.formType;
            } else {
                type = this.formType;
            }
            return type;
        },
        // form 是否禁用
        formDisabled() {
            let disabled = false;
            let isDisabled = ['approval', 'view'].includes(this.formType);
            if (this.ComEditForm) {
                disabled = isDisabled || this.ComEditForm.formDisabled;
            } else {
                disabled = isDisabled;
            }
            return disabled;
        }
    },
    methods: {
        /** 初始化 */
        async init() {
            // this.reload();
        },
        // 获取初始化 新增 模板
        getInitTemplateAdd() {
            return this.$http.post(this.urlConfig.addUrl, { ...this.extraData });
        },
        // 获取初始化 修改和查看模板及数据
        getInitTemplateView() {
            return this.$http.post(this.urlConfig.editUrl, { ...this.extraData });
        },
        /** 设置初始数据 */
        setInitData(data, isAdd) {
            let form = {};
            // 处理切换时数据不清空问题
            if (!(data && Array.isArray(data.template))) {
                this.form = form;
                return [];
            }

            if (!isAdd) {
                form = this.setInitForm(data.templateData);
            }
            this.form = form;
            return data.template;
        },
        /** 初始化 form 数据 */
        setInitForm(data, cf = { key: 'name', value: 'value' }) {
            let result = {},
                { filterArray } = this;
            if (!Array.isArray(data)) return result;
            for (let item of data) {
                let obj = filterArray(item[cf.value]);
                // 此处数据不处理， 主要是做number 类型和 string 类型排错处理
                let val = obj && obj.v;
                result[item[cf.key]] = val == null ? null : val;
            }
            return result;
        },
        /** 选中 按钮触发的 */
        getDictionariesConfig(data, haveDic, dicType) {
            if (!Array.isArray(data)) return [];
            let dicConfig = [];
            /** 使用 Set 去重 let dic = new Set() */

            let mixs = data
                .filter(item => {
                    return haveDic.includes(item.type) && dicType.indexOf(item.dataType) > -1;
                })
                .reduce((total, mix) => {
                    if (total.has(mix.dataType)) {
                        total.set(mix.dataType, total.get(mix.dataType).add(mix.data));
                    } else {
                        total.set(mix.dataType, new Set([mix.data]));
                    }
                    return total;
                }, new Map());

            for (let [key, value] of mixs) {
                dicConfig.push({ dictType: key, dictKey: Array.from(value) });
            }
            return dicConfig;
        },
        uploadFile() {
            this.$refs['files'][0].submit();
        },
        // 手动触发 获取模板及模板数据
        async reload() {
            await this.getTemplate();
        },
        // 手动触发 获取处理好的表单数据
        getFormData() {
            return { ...this.arrayToString(this.form) };
        },
        // 获取组件中form表单控件
        getForm() {
            return this.$refs.form;
        },
        // 获取模板所有配置
        async getTemplate() {
            this.status = 'pending';
            let isAdd = this.pageType === 'add';
            let data;
            // 根据新增还是修改 选择不同的接口请求
            let { data: temp } = isAdd ? await this.getInitTemplateAdd() : await this.getInitTemplateView();
            if (!temp) {
                this.status = 'error';
                return false;
            }
            data = this.setInitData(JSON.parse(temp), isAdd);
            if (!data.length) {
                this.status = 'empty';
                return false;
            }
            data &&
                this.dictionariesUrl &&
                (await this.mixGetDictionaries(
                    this.dictionariesUrl,
                    this.getDictionariesConfig(data, this.haveDic, this.dicType)
                ));
            this.data = data;
            // 完成请求需传递当前组件
            this.$emit('loadData', this);
            // 走到这里一般都是成功
            this.status = 'success';
        },
        // 保存按钮触发
        saveForm() {
            return this.saveTemplateData();
        },
        // 修改按钮触发
        submitForm() {
            return this.updateTemplateData();
        },
        // 返回按钮
        back() {},

        // 过滤数组
        filterArray(str) {
            return jsonParse(decodeStr(str));
        },
        arrayToString(obj) {
            let result = Object.assign({}, obj);
            for (let key in result) {
                let val = result[key];
                result[key] = JSON.stringify({ v: val == null ? null : result[key] });
            }
            return result;
        }
    }
};
</script>
<style scoped lang="scss">
.form-item_group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
</style>
