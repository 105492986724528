export default {
    queryOrgStructListUrl:'/api/fsmc/org-struct/query-org-struct-by-page',//查詢组织架构列表
    queryOrgStructByIdUrl:'/api/fsmc/org-struct/query-org-struct/-by-/',
    insertOrgStructUrl:'/api/fsmc/org-struct/org-struct-add',
    updateOrgStructUrl:'/api/fsmc/org-struct/org-struct-update',
    deleteOrgStructUrl:'/api/fsmc/org-struct/org-struct-delete',
    validateStructUsedUrl:'/api/fsmc/org-struct/validate-org-struct-used-by-rel',
    queryUncheckOrgInfoListUrl:'/api/fsmc/org-struct/query-uncheck-org-info-list',
    queryCheckOrgInfoListUrl:'/api/fsmc/org-struct/query-checked-org-info-list',
    validateMainStructHasUrl:'/api/fsmc/org-struct/validate-org-struct-main',
    validateStructCodeHasUrl:'/api/fsmc/org-struct/validate-org-struct-code-repeat/-by-/',
    validateStructHasOrgInfoUrl:'/api/fsmc/org-struct//validate-org-struct-contain-org-info/-by-/',
    enableStructUrl:'/api/fsmc/org-struct/org-struct-enable',
    disableStructUrl:'/api/fsmc/org-struct/org-struct-disable',
    queryOrgStructComboUrl:'/api/fsmc/org-struct/query-org-struct-value-list',
    modifyOrgStructAddOrgInfoUrl:'/api/fsmc/org-struct/org-struct-update-with-add-org',
}
