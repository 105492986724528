import nmCryptokit from './nmCryptoKit';

var initFlag=false;
function initCert() {
    return new Promise((resolve, reject) => {
        try {
            const license_arr = global_license.split('|');
            var extensionVer;
            var hostVer;
            var browser = BrowserInfo();
            if (("Edge" == browser.name) ||
                ("Chrome" == browser.name && parseInt(browser.version) > 59) ||
                ("Firefox" == browser.name && parseInt(browser.version) > 52)) {
                nmCryptokit.nmCryptokitCore.setInstance();
                //初始化liencese
                nmCryptokit.nmCryptokitObject.initConfig(browser.name, license_arr[1], license_arr[0]);
                nmCryptokit.nmCryptokitObject.uninit();
                //初始化证书
                nmCryptokit.nmCryptokitObject.init().then(() => {
                    resolve()
                });
            }
            else {
                throw 'Unsupported browser!';
            }
        }
        catch (e) {
            throw e;
        }
    })

}

function BrowserInfo() {
    var res = {
        name: "",
        version: "",
    };
    var reg;
    var userAgent = self.navigator.userAgent;

    if (reg = /edge\/([\d\.]+)/i.exec(userAgent)) {
        res.name = "Edge";
        res.version = reg[1];
    }
    else if (/msie/i.test(userAgent)) {
        res.name = "Internet Explorer";
        res.version = /msie ([\d\.]+)/i.exec(userAgent)[1];
    }
    else if (/Trident/i.test(userAgent)) {
        res.name = "Internet Explorer";
        res.version = /rv:([\d\.]+)/i.exec(userAgent)[1];
    }
    else if (/chrome/i.test(userAgent)) {
        res.name = "Chrome";
        res.version = /chrome\/([\d\.]+)/i.exec(userAgent)[1];
    }
    else if (/safari/i.test(userAgent)) {
        res.name = "Safari";
        res.version = /version\/([\d\.]+)/i.exec(userAgent)[1];
    }
    else if (/firefox/i.test(userAgent)) {
        res.name = "Firefox";
        res.version = /firefox\/([\d\.]+)/i.exec(userAgent)[1];
    }
    return res;
}

// Select certificate
// InfoTypeID: SubjectDN; SubjectCN; IssuerDN; SerialNumber; CSPName; CertType 可获取证书的信息
let global_license;
function selectCertificate(license, CNFilter) {
    return new Promise((resolve, reject) =>{
        global_license = license;
        initCert().then((data) => {
            if (CNFilter == undefined){
                CNFilter = '';
            }
            if (data != undefined){
                if (!data.result){
                    reject(data)
                } else {
                    nmCryptokit.nmCryptokitObject.SelectCertificate(CNFilter, '', '').then((response) => {
                        // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
                        if (!response.result) {
                            reject(response);
                        } else {
                            getCertInfo('SubjectCN').then(
                                response => {
                                    resolve(response);
                                }
                            ).catch(e => {
                                reject(e);
                            })
                        }
                    }).catch(e => {reject(e)});
                }
            } else {
                nmCryptokit.nmCryptokitObject.SelectCertificate(CNFilter, '', '').then((response) => {
                    // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
                    if (!response.result) {
                        reject(response);
                    } else {
                        getCertInfo('SubjectCN').then(
                            response => {
                                resolve(response);
                            }
                        ).catch(e => {
                            reject(e);
                        })
                    }
                }).catch(e => {reject(e)});
            }
        }).catch(e => {reject(e)});
    } );
}

//get cert info
function getCertInfo(InfoTypeID){
    return new Promise((resolve, reject) =>{
        var InfoContent;
        try {
            nmCryptokit.nmCryptokitCore.getSignCertInfo(InfoTypeID).then(function (response) {
                InfoContent = response.result;
                resolve(response.result);
            }).catch(function (response) {
                reject(response)
            });;
            // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
            if (!InfoContent) {
                nmCryptokit.nmCryptokitCore.getLastErrorDesc().then(response => function(){
                    reject(response)
                }).catch(response => {
                    reject(response)
                });
            }
        } catch (e) {
            reject(e);
        }
    });

}

// Sign message
//certType: 0：从windows证书库中选择签名证书 1：使用指定的软证书签名
//selectedAlg : SHA-1   SHA-256
//signType : Attach : PKCS#7带原文签名     Detach : PKCS#7不带原文签名   PKCS#1 ： 只签名
function sign(sigMsg) {
    return new Promise((resolve, reject) =>{
        var signature = '';
        var signType = 'Attach';
        var selectedAlg = 'SHA-1';
        var certType = 0;
        if (certType == 0) {
            //硬件证书消息签名
            hardCertSign(selectedAlg, sigMsg, signType).then(res => resolve(res)).catch(ex =>reject(ex));
        } else {
            reject('暂不支持软证书签名');
        }
    });
}

//硬件证书消息签名
function hardCertSign(selectedAlg, sigMsg, signType) {
    return new Promise((resolve, reject) =>{
            var signature = '';
            var source = sigMsg;
            if ('Attach' == signType) {
                executetSignMsgPKCS7(source, selectedAlg, true).then( res => resolve(res)).catch(ex => reject(ex));
            } else if ('Detach' == signType) {
                executetSignMsgPKCS7(source, selectedAlg, false).then( res => resolve(res)).catch(ex => reject(ex));
            } else {
                executetSignMsgPKCS1(source, selectedAlg).then( res => resolve(res)).catch(ex => reject(ex));
            }
            if (!signature) {
                // eslint-disable-next-line no-undef
                nmCryptokit.nmCryptokitObject.getLastErrorDesc().then(res=> reject(res)).catch(res=> reject(res) );
            }
    });
}

function executetSignMsgPKCS7(source, selectedAlg, flag){
    return new Promise((resolve, reject) =>{
        nmCryptokit.nmCryptokitCore.signMsgPKCS7(source, selectedAlg, flag)
            .then(
                res => resolve(res)
            ).catch(ex => reject(ex));
    });
}

function executetSignMsgPKCS1(source, selectedAlg){
    return new Promise((resolve, reject) =>{
        nmCryptokit.nmCryptokitCore.SignMsgPKCS1(source, selectedAlg)
            .then(
                res => resolve(res)
            ).catch(ex => reject(ex));
    });
}

//软证书消息签名
//certificatetype: 软证书类型 SM2  RSA
// eslint-disable-next-line no-unused-vars
function softCertSign(selectedAlg, sigMsg, signType) {
    var signature = '';
    try {
        var filename = '';
        var password = '';
        var source = sigMsg;
        var certificatetype = 'SM2';

        if ('Attach' == signType) {
            // PKCS#7 Attach
            // eslint-disable-next-line no-undef
            signature = CryptoAgent.signMsgPKCS7(
                filename,
                password,
                certificatetype,
                source,
                selectedAlg,
                true
            );
        } else if ('Detach' == signType) {
            // PKCS#7 Detach
            // eslint-disable-next-line no-undef
            signature = CryptoAgent.signMsgPKCS7(
                filename,
                password,
                certificatetype,
                source,
                selectedAlg,
                false
            );
        } else {
            // PKCS#1
            // eslint-disable-next-line no-undef
            signature = CryptoAgent.SignMsgPKCS1(filename, password, certificatetype, source, selectedAlg);
        }
        // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
        if (!signature) {
            // eslint-disable-next-line no-undef
            throw CryptoAgent.GetLastErrorDesc();
        }
    } catch (e) {
        throw e;
    }
    return signature;
}
export default { selectCertificate, sign };
