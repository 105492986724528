import client from 'webpack-theme-color-replacer/client';
import forElementUI from 'webpack-theme-color-replacer/forElementUI';
import ThemeChanger from './themeChanger';
// const ThemeChanger = require('../../../../../common/plugins/themeChanger.js');
// const ThemeFile = require('./themeNode.js');

const DEFAULT_THEMTE = 'var6';
const DEFAULT_NAV = 'light';

export let curColor = '#409EFF';
export let curThemeName = DEFAULT_THEMTE;
export let curColorName = DEFAULT_NAV;

export function changeThemeColor(newColor) {
    const options = {
        newColors: [...forElementUI.getElementUISeries(newColor), '#ff0000', '#ffff00']
    };
    return client.changer.changeColor(options, Promise).then(t => {
        curColor = newColor;
        localStorage.setItem('theme_color', curColor);
    });
}

export function initThemeColor() {
    const savedColor = localStorage.getItem('theme_color');
    if (savedColor) {
        curColor = savedColor || curColor;
        changeThemeColor(savedColor);
    }
}

export function changeThemeFile(themeName = DEFAULT_THEMTE) {
    let options = {
        theme: {
            url: `element-${themeName}-change.css`,
            name: themeName
        }
    };
    // import(`@/style/themes/element-${themeName}-change/index.css`);
    ThemeChanger.changeTheme(options).then(() => {
        curThemeName = themeName;
        localStorage.setItem('theme_name', curThemeName);
    });
    // let themeFile = new ThemeFile({ file, appendFile, prependFile });
    // themeFile.handle();
}

export function initThemeFile() {
    const savedName = localStorage.getItem('theme_name');
    curThemeName = savedName || curThemeName;
    changeThemeFile(curThemeName);
}

export function changeNavColor(colorName = DEFAULT_NAV) {
    let options = {
        theme: {
            url: `nav-${colorName}.css`,
            name: colorName
        }
    };
    // import(`@/style/themes/element-${themeName}-change/index.css`);
    ThemeChanger.changeTheme(options).then(() => {
        curColorName = colorName;
        localStorage.setItem('nav_color', curColorName);
    });
}

export function initNavColor() {
    const savedName = localStorage.getItem('nav_color');
    curColorName = savedName || curColorName;
    changeNavColor(curColorName);
}
