import ComQueryTable from './src/com-query-table';
import MultipleCell from './src/multiple-cell';
import LinkCell from './src/link-cell';
import ProcessCell from './src/process-cell';
import ComTableUpload from './src/com-table-upload';
import ComTableExport from './src/com-table-export';
import ComTableInfo from './src/com-table-info';
import ComEditTable from './src/com-edit-table';

/* istanbul ignore next */
ComQueryTable.install = function(Vue) {
    Vue.component(ComQueryTable.name, ComQueryTable);
    Vue.component(MultipleCell.name, MultipleCell);
    Vue.component(LinkCell.name, LinkCell);
    Vue.component(ProcessCell.name, ProcessCell);
    Vue.component(ComTableUpload.name, ComTableUpload);
    Vue.component(ComTableExport.name, ComTableExport);
    Vue.component(ComTableInfo.name, ComTableInfo);
    Vue.component(ComEditTable.name, ComEditTable);
};

export default ComQueryTable;
