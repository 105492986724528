<template>
    <!-- 账户放大镜-单选 -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="acctNo"
        filterKey="acctNo"
        :title="$t('i18n.common.components.account.title')"
        :placeholder="placeholder || $t('i18n.common.components.account.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        v-on="listeners"
        :autoSelect="autoSelect">
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.account.acctNo')" prop="acctNo">
                <f-input v-model="searchForm.acctNo"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.account.acctName')" prop="acctName">
                <f-input v-model="searchForm.acctName"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 账户名称 -->
            <f-magnifier-column prop="acctName" :label="$t('i18n.common.components.account.acctName')"></f-magnifier-column>
            <!-- 账户号 -->
            <f-magnifier-column prop="acctNo" :label="$t('i18n.common.components.account.acctNo')"></f-magnifier-column>
            <!-- 单位名称 -->
            <f-magnifier-column prop="orgName" :label="$t('i18n.common.components.account.orgName')"></f-magnifier-column>
            <!-- 机构名称 -->
            <f-magnifier-column prop="officeName" :label="$t('i18n.common.components.account.officeName')"></f-magnifier-column>
            <!-- 币种 -->
            <f-magnifier-column prop="currencyName" :label="$t('i18n.common.components.account.currencyName')"></f-magnifier-column>
            <!-- 开户行名称 -->
            <f-magnifier-column prop="bankName" :label="$t('i18n.common.components.account.bankName')"></f-magnifier-column>
            <!-- 账户性质 -->
            <f-magnifier-column prop="acctNature" :label="$t('i18n.common.components.account.acctNature')">
                <template v-slot="$scope">
                    {{ mixMatchDictionary($scope.row.acctNature, mixDictionaries.constant['AcctNature.getList.obj']) }}
                </template>
            </f-magnifier-column>
        </template>
    </f-magnifier-single >
</template>

<script>
    import { dictionaryMix } from '@/common/comMixin';
    import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
    /**
     * 单位放大镜-单选
     */
    export default {
        name: 'AcctMagnifierSingle',
        mixins: [magnifierSingleMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    acctName: '', // 银行名称
                    acctNo: '' // 银行编号
                },
                /**
                 * 单位类型/单位性质/是否结算中心
                 */
                dictionariesConfig: [
                    {
                        "dictType": "constant",
                        "dictKey": ["AcctNature.getList"]
                    }
                ]
            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        acctName: 'acctName',
                        acctId :'acctId',
                        orgKey: 'orgKey',
                        acctNo: 'acctNo',
                        bankTypeCode :'bankTypeCode',
                        bankCode: 'bankCode',
                        acctInWay :'acctInWay',
                        acctNature: 'acctNature',
                        payReceiveAttr :'payReceiveAttr',
                        acctProvince :'acctProvince',
                        acctCity: 'acctCity',
                        orgCode :'orgCode',
                        orgName: 'orgName',
                        officeCode :'officeCode',
                        officeName :'officeName',
                        acctKey: 'acctKey'
                    }
                }
            },
            /**
             * 数据字典URL地址
             **/
            dictionariesSource:{
                type: String
            },
        },
        async created() {
            // 获取数据字典
            await this.mixGetDictionaries(this.dictionariesSource, this.dictionariesConfig);
        }
    }
</script>

<style lang="scss" scoped>

</style>
