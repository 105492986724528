<template>
    <!-- 用户信息放大镜 -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="userName"
        filterKey="userCode"
        :title="$t('i18n.common.components.user.title')"
        :placeholder="placeholder || $t('i18n.common.components.user.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        v-on="listeners"
        :autoSelect="autoSelect">
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.user.userName')" prop="name">
                <f-input v-model="searchForm.userName"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.user.userCode')" prop="code">
                <f-input v-model="searchForm.userCode"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 用户名称 -->
            <f-magnifier-column prop="userName" :label="$t('i18n.common.components.user.userName')"></f-magnifier-column>
            <!-- 用户编号 -->
            <f-magnifier-column prop="userCode" :label="$t('i18n.common.components.user.userCode')"></f-magnifier-column>
        </template>
    </f-magnifier-single >
</template>

<script>
import { dictionaryMix } from '@/common/comMixin';
import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
/**
 * 单位放大镜-单选
 */
    export default {
        name: 'UserMagnifierSingle',
        mixins: [magnifierSingleMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    userName: '', // 用户编号
                    userCode: '' // 用户名称
                },

            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        id: 'id',
                        userCode :'userCode',
                        userName: 'userName',
                        orgId :'orgId',
                        userNameEn: 'userNameEn',
                        userType :'userType'
                    }
                }
            },

        },
        async created() {

        }
    }
</script>

<style lang="scss" scoped>

</style>
