let command = process.argv;
console.log("command:======================", command);
/**
 * 模块请求地址统一管理
 * 注意: 导出对象中的key对应的value值只能是字符串, 不能再嵌套对象
 */
export default {
    appQrcode: '/api/fsmc/login/out/app/download/generate', // APP下载二维码
    userQrcode: '/api/fsmc/login/qrcode/in/generate', // 用户登录二维码
    userInfoMore: '/api/fsmc/auth/userinfo/query-auth-userinfo/-by-/', // 用户详情
    dictionaries: '/api/fsmc/common/query/dictionary',
    constants: '/api/fsmc/common/query/constant/class/value', // 公共的常量判断固定值
    loginUrl: '/api/fsmc/login/login',
    authLoginUrl: '/api/fsmc/login/auth/authLogin', //单点登录校验方法
    getEncryptKey: '/api/fsmc/login/encrypt-key',
    getAuthInfo: '/api/fsmc/login/auth/getAuthInfo', // 是否开启单点登录
    queryLoginTypeUrl: '/api/fsmc/login/query-login-type',
    loginOutUrl: '/api/fsmc/login/login-out',
    common: {
        getToken: '/api/fsmc/common/token/getToken', // 公共获取token
    },
    //菜单
    menu: '/api/fsmc/auth/userMenu/query-user-menu', // 全局菜单
    star: '/api/fsmc/oftenMenu/often-menu-add', // 添加常用菜单
    unStar: '/api/fsmc/oftenMenu/often-menu-delete', // 取消常用菜单
    ofenMenu: '/api/fsmc/oftenMenu/query-user-often-menu', // 查询用户常用菜单
    saveOfenMenu: '/api/index/saveOfenMenu', //排序
    userInfo: '/api/fsmc/session/share/query-session-info', //获取用户
    setLangUrl: '/api/fsmc/login/lang-setting', //国际化语言后端设置
    queryLangUrl: '/api/fsmc/login/query/lang-info-list', //查询国际化语言
    passwordRulesUrl: '/api/fsmc/auth/password-rule/query-password-rule', // 密码规则设置
    changePasswordUrl: '/api/fsmc/auth/userinfo/edit-password', //修改密码
    remindTypeSetUrl: '/api/fsmc/remind-user-set/remind-user-set-add-exists', //提醒订阅
    queryRemindSetListUrl: '/api/fsmc/remind-user-set/query-remind-user-set-list', //查询用户订阅的提醒
    queryRemindInfoByPageUrl: '/api/fsmc/workbench/query/remind-info/page', // 提醒信息列表
    deleteRemindInfoUrl: '/api/fsmc/workbench/cancel/remind-info', // 提醒数据删除单个
    deleteRemindInfoAllUrl: '/api/fsmc/workbench/cancel/remind-info-all', // 提醒数据删除全部

    queryDefaultMenu:'/api/fsmc/common/default-menu/query-default-menu',//查询默认打开页签
    setDefaultMenu:'/api/fsmc/common/default-menu/set-default-menu',//设置默认打开页签
    unsetDefaultMenu:'/api/fsmc/common/default-menu/unset-default-menu',//取消设置默认打开页签
    //theme
    queryThemesUrl: '/api/fsmc/themes/query-themes', //查询主题列表
    saveThemesUrl: '/api/fsmc/themes/themes-add', //新增主题
    queryLoginInitUrl: '/api/fsmc/common/query-sys-param-set/-by-/login-page',
    getLogoInfo: '/api/fsmc/common/query-upload-file-list', // 获取系统logo图标数据地址
    saas_getLogoInfo: '/api/portal/login/query-system-setting', // 获取系统logo图标数据地址

    sendCodeUrl: '/api/fsmc/common/forget-password/send/identify-code',
    verifyEmailUrl: '/api/fsmc/common/forget-password/validate/identify-code',
    restPassword: '/api/fsmc/common/forget-password/modfify/password/with/identify-code',
    makeLicense: '/api/fsmc/system/license/make-product-license',
};
