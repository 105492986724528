import certJ from './topEsa.min-2021';

let certsAll = [],certDataList = [],certSelectedCertSN = [], myCert=null, config=false;


export default {
    setCertsAll(certsAllParam){
        certsAll = certsAllParam;
    },
    getCertsAll(){
        return certsAll;
    },
    setCertDataList(certDataListParam){
        certDataList = certDataListParam;
    },
    getCertDataList(){
        return certDataList;
    },
    setCertSelectedCertSN(certSelectedCertSNParam){
        certSelectedCertSN = certSelectedCertSNParam;
    },
    getCertSelectedCertSN(){
        return certSelectedCertSN;
    },
    setMyCert(mycertParam){
        myCert = mycertParam;
    },
    getMyCert(){
        return myCert;
    },
    //刷新证书列表
    refreshCertList() {
        try {
            var certs = this.getCertsAll();
            if (certs == undefined || certs == 'undefined') {
                throw('没有找到符合License的证书');
            }
            // certs = this.filterCert();
            this.initCertList(certs);
            if (certs.size() == 0) {
                throw('没有找到符合License的证书');
            }
        } catch (e) {
            throw e;
        }
    },
    //过滤证书
    filterCert(val) {
        try {
            var certs = this.getCertsAll();
            if (val != undefined || val != null) {
                if (val == 1) {
                    certs = certs.forSign();
                    return certs;
                } else if (val == 2) {
                    certs = certs.forEncrypt();
                    return certs;
                }
            } else {
                var r = certs;
                var datetime = new Date();
                if (datetime != null && datetime != ""  ) {
                    // 这里可以选择使用无参的重载
                    r = r.byValidity(datetime);
                }
                return r;
            }
        } catch (e) {
            throw e;
        }
    },
    //传入CertSet, 将certs中的证书显示在CertList中
    initCertList(certs) {
        try {
            var count = certs.size();
            if (count <= 0) {
                let dataValue = {};
                dataValue.text = '无';
                dataValue.value = '';
                this.getCertDataList().push(dataValue);
                return;
            }
            for (var i = 0; i < count; i++) {
                var cert = certs.get(i);
                var cn = this.getCNFromSubject(cert) + "  -  " + this.getOUFromSubject(cert);
                this.addOption(cert.serialNumber(), cn);
            }
        } catch (e) {
            throw e;
        }
    },
    addOption(oValue, oName) {
        let this_certDataList = this.getCertDataList();
        let dataValue = {};
        dataValue.text = oName;
        dataValue.value = oValue;
        this_certDataList.push(dataValue);
        this.setCertDataList(this_certDataList);
    },
    getCNFromSubject(cert) {
        try {
            var t = cert.subject().match(/(S(?!N)|L|O(?!U)|OU|SN|CN|E)=([^=]+)(?=, |$)/g);
            for (var i = 0; i < t.length; i++) {
                if (t[i].indexOf('CN=') === 0) return t[i].substr(3, t[i].length);
            }
            return null;
        } catch (e) {
            throw e;
        }
    },
    getOUFromSubject(cert) {
        try {
            var t = cert.subject().match(/(S(?!N)|L|O(?!U)|OU|SN|CN|E)=([^=]+)(?=, |$)/g);
            for (var i = 0; i < t.length; i++) {
                if (t[i].indexOf('OU=') === 0) return t[i].substr(7, t[i].length);
            }
            return null;
        } catch (e) {
            throw e;
        }
    },
    // 返回Certificate对象
    getSelectedCert(serialNumber) {
        try {
            var certs = this.getCertsAll();
            var r = certs.bySerialnumber(serialNumber);
            return r.get(0);
        } catch (e) {
            throw e;
        }
    },
    sign(b, str) {
        try {
            var toSign = Math.random() + "";
            if (str != null && str != '') {
                toSign = str;
            }
            var cert = this.getMyCert();
            if (cert == null) {
                var selectedCertSN = this.getCertDataList()[0].value;
                this.setCertSelectedCertSN(selectedCertSN)
                var r = this.getCertsAll().bySerialnumber(selectedCertSN);
                this.setMyCert(r.get(0));
                cert = this.getMyCert();
            }
            var P7 = null;
            if (b == false) {
                P7 = cert.signMessage(toSign, false);
            } else {
                P7 = cert.signMessage(toSign);
            }
            return P7;
        } catch (e) {
            throw e;
        }
    },
    //加密
    encrypt() {
        try {
            var toEnc = Math.random() + "";
            var cert = this.getSelectedCert();
            var P7 = cert.encryptMessage(toEnc);
            return P7;
        } catch (e) {
            throw e;
        }
    },
    //解密
    decrypt() {
        try {
            var b64 = this.encrypt();
            var P7 = new certJ.Pkcs7(b64);
            var result = P7.decryptMessage();
            return result;
        } catch (e) {
            throw e;
        }
    },
    refreshCertListOnClick(license, flushFlag){
        try {
            this.setCertDataList([]);
            this.initConfig(license)
            if(flushFlag){
                this.setCertsAll(certJ.CertStore.listAllCerts());
                this.refreshCertList();
            }
        } catch (e) {
            console.log("e", e)
            throw e;
            this.setCertDataList([]);
        }
    },
    certChange(serialNumber) {
        try {
            var cert = this.getSelectedCert(serialNumber);
            this.setMyCert(cert);
            this.setCertSelectedCertSN(serialNumber);
        } catch (e) {
            throw e;
        }
    },
    sendAuto(license){
        try {
            this.initConfig(license)
            this.setCertsAll(certJ.CertStore.listAllCerts());
            this.refreshCertList();
            var selectedCertSN = this.getCertDataList()[0].value;
            this.setCertSelectedCertSN(selectedCertSN)
            var r = this.getCertsAll().bySerialnumber(selectedCertSN);
            this.setMyCert(r.get(0));
        } catch (e) {
            throw e;
        }
    },
    initAuto(license){
        try {
            this.setCertDataList([]);
            this.initConfig(license)
            this.setCertsAll(certJ.CertStore.listAllCerts());
            this.refreshCertList();
        } catch (e) {
            this.setCertDataList([]);
        }
    },
    getSingData(str){
        return this.sign(true, str);
    },
    initConfig(license){
        if (!this.getConfig()) {
            let configParam = {};
            configParam.license = license;
            configParam.certDateFmtMode = true;
            configParam.disableExeUrl   = true;
            configParam.installMode = false;
            configParam.genKeySpec = 2;
            certJ.TCA.config(configParam);
            this.setConfig(true);
        }
    },
    setConfig(configParam){
        config = configParam;
    },
    getConfig() {
        return config;
    }
};

