
    /**
     * 设置缓存
     * @param key
     * @param value
     * @param expirea  时间控制
     */
    function set(key,value,expired){
        var source= {};
        source[key] = JSON.stringify(value);
        if (expired){
            source['__expires__']  = Date.now() + 1000*60*expired
        };
        setItem(key,JSON.stringify(source));
        return value;
    }

    /**
     * 获取缓存
     * @param key
     */
    function get(key){
        var source = getItem(key);
        if(!source){
            return;
        }
        try{
            source = JSON.parse(source);
            var expired = source['__expires__']||Date.now+1;
            var now = Date.now();
            if ( now >= expired ) {
                this.remove(key);
                return;
            }
            var value = source[key] ? JSON.parse(source[key]) : source[key];
            return value;
        }catch (e) {
            return;
        }
    }
    var key = 'sessionKey';
    function uploadHttp(){
        if(get(key)){
            set(key,Date.now()*120,120);
            return true;
        }else{
            return false;
        }
    }
    function initHttp(){
        if(!get(key)){
            set(key,Date.now()*120,120);
            return true;
        }else{
            return false;
        }
    }

    export function getAxiosURL(code){
        let hideBtn = JSON.parse(getItem('hideBtn'));
        if (hideBtn && code && hideBtn[code] && hideBtn[code].funcUrl) {
            return hideBtn[code].funcUrl;
        }
        return "/";
    }
    const local = ["ENCRYPT_KEY",document.location.origin];
    function setItem(key,value){
        local.indexOf(key) != -1 ? localStorage.setItem(key,value):sessionStorage.setItem(key,value);
        if("sessionKey" === key){
            localStorage.setItem(key,value);
        }
    }

    function getItem(key){
        let result = sessionStorage.getItem(key);
        if(result === null || result === "" || result === undefined ){
            result = localStorage.getItem(key);
        }
        return result;
    }
    function clear(){
        //localStorage.clear();
        sessionStorage.clear();
    }
    export default {
        uploadHttp,
        initHttp,
        clear,
        setItem,
        getItem
    }
