function GenerateRandomId() {
    var charstring = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    var maxPos = charstring.length;
    var randomId = '';
    for (let i = 0; i < 10; i++) {
        randomId += charstring.charAt(Math.floor(Math.random() * maxPos));
    }
    return randomId;
}


var nmCryptokit = {};
var nmCryptokitObject = {};
nmCryptokitObject.initConfig = function (browser, chromeExtension, productID){
    nmCryptokitCore.initConfigCert(browser, chromeExtension, productID);
}

nmCryptokitObject.uninit = function (){
    nmCryptokitCore.uninit();
}


nmCryptokitObject.init = function (){
    return nmCryptokitCore.init()
}

nmCryptokitObject.SelectCertificate =  function (subjectDNFilter, issuerDNFilter, serialNumFilter) {
    return new Promise((resolve, reject) => {
        nmCryptokitCore.SelectCertificate(subjectDNFilter, issuerDNFilter, '', serialNumFilter)
            .then(function (response) {
                resolve(response)
            }).catch(error => {
                reject(error)
            });
    })
}

nmCryptokitObject.getSignCertInfo = function(InfoTypeID) {
    return new Promise((resolve, reject) => {
        nmCryptokitCore.getSignCertInfo(InfoTypeID)
            .then(function (response) {
                resolve(response)
            }).catch(error => {
            reject(error)
        });
    })
}

nmCryptokitObject.getLastErrorDesc = function(InfoTypeID) {
    return new Promise((resolve, reject) => {
        nmCryptokitCore.getLastErrorDesc()
            .then(function (response) {
                console.log("cfca 证书请求错误，" ,response)
                resolve(response)
            }).catch(error => {
                reject(error)
        });
    })
}



var nmCryptokitCore = function () {
    function getInstance(){
        return nmCryptokit
    }
    function setInstance(){
        nmCryptokit = {};
    }

    function initConfigCert(browser, chromeExtension, productID){
        nmCryptokit.browser = browser;
        nmCryptokit.chromeExtension = chromeExtension;
        nmCryptokit.productID = productID;
        nmCryptokit.extensionName = productID + ".extension";
        nmCryptokit.reqEventName  = productID + ".request";
        nmCryptokit.respEventName = productID + ".response";
        nmCryptokit.Browser = {
            IE:      "Internet Explorer",
            Edge:    "Edge",
            Chrome:  "Chrome",
            Safari:  "Safari",
            Firefox: "Firefox",
        };
    }

    // Encapsulate Chrome sendMessage callback to Promise
    function SendMessageforChrome(request) {
        return new Promise( function (resolve, reject) {
            chrome.runtime.sendMessage(nmCryptokit.chromeExtension, request, function (response) {
                if (response) {
                    if(0 == response.errorcode){
                        resolve(response);
                    }
                    else{
                        reject(response);
                    }
                    return true;
                }
                else {
                    var result = new Object();
                    result.errorcode = 1;
                    result.result = chrome.runtime.lastError.message;
                    reject(result);
                }
            });
        });
    }

    // Encapsulate Edge&Firefox event to Promise
    function SendMessagebyEvent(request) {
        var event = new CustomEvent(nmCryptokit.reqEventName, { detail: request });
        document.dispatchEvent(event);
        return new Promise( function (resolve, reject) {

            var responseEventName = nmCryptokit.respEventName;
            if(request.funcInfo != undefined && request.funcInfo.randomId != undefined)
                responseEventName += ("." + request.funcInfo.randomId);

            document.addEventListener(responseEventName, function CallBack(e) {
                document.removeEventListener(e.type, CallBack);
                var eJson = JSON.parse(e.detail);
                if (null != eJson && 0 == eJson.errorcode) {
                    resolve(eJson);
                }
                else {
                    reject(eJson);
                }
            }, false);
        });
    }


     function SendMessage(browser, requestJSON) {
        if (nmCryptokit.Browser.Chrome == browser) {
            return SendMessageforChrome(requestJSON);
        }
        else {
            return SendMessagebyEvent(requestJSON);
        }
    }

    function checkExtension(browser) {
        return new Promise(function (resolve, reject) {
            var result = new Object();
            if (nmCryptokit.Browser.Chrome == browser) {
                // chrome.runtime.sendMessage() could check extension  existence.
                if(chrome.runtime) {
                    resolve(browser);
                }
                else{
                    result.errorcode = 2;
                    result.result = "Extension does not exist!";
                    reject(result);
                }
            }
            else if (nmCryptokit.Browser.Edge == browser || nmCryptokit.Browser.Firefox == browser) {
                if (document.getElementById(nmCryptokit.extensionName)) {
                    resolve(browser);
                }
                else {
                    result.errorcode = 2;
                    result.result = "Extension does not exist!";
                    reject(result);
                }
            }
            else{
                result.errorcode = 3;
                result.result = "Only support Chrome/Edge/Firefox";
                reject(result);
            }
        });
    }

     function init() {
        var browser = nmCryptokit.browser;
        return checkExtension(browser)
            .then(function (browser) {
                var request = new Object();
                request.action = "connect";
                request.host = nmCryptokit.productID;
                return SendMessage(browser, request);
            })/*.then(function () {
                var request = new Object();
                var funcInfo = new Object();
                var randomId = GenerateRandomId();
                funcInfo.function = "checkHost";
                funcInfo.params = null;
                funcInfo.randomId = randomId;
                request.action = "invoke";
                request.funcInfo = funcInfo;
                return SendMessage(browser, request);
            })*/;
    }

     function uninit() {

        var request = new Object();
        request.action = "disconnect";
        request.host = nmCryptokit.productID;
        return  SendMessage(nmCryptokit.browser, request);
    }

     function getExtensionVersion ( ) {

        var request = new Object();
        var funcInfo = new Object();
        var randomId = GenerateRandomId();

        funcInfo.function = "getExtensionVersion";
        funcInfo.params = null;
        funcInfo.randomId = randomId;
        request.action = "getExtensionVersion";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

     function getHostVersion () {

        var request = new Object();
        var funcInfo = new Object();
        var randomId = GenerateRandomId();

        funcInfo.function = "GetVersion";
        funcInfo.params = null;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

     function SelectCertificate (strSubjectDNFilter, strIssuerDNFilter, strSerialNo, strCSPFilter) {
        var request = new Object();
        var funcInfo = new Object();
        var paramArr = new Array();
        var randomId = GenerateRandomId();

        paramArr.push(strSubjectDNFilter);
        paramArr.push(strIssuerDNFilter);
        paramArr.push(strSerialNo);
        paramArr.push(strCSPFilter);

        funcInfo.function = "SelectCertificate";
        funcInfo.params = paramArr;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;
        return  SendMessage(nmCryptokit.browser, request);
    }

     function getSignCertInfo(strInfoType) {

        var request = new Object();
        var funcInfo = new Object();
        var paramArr = new Array();
        var randomId = GenerateRandomId();

        paramArr.push(strInfoType);

        funcInfo.function = "getSignCertInfo";
        funcInfo.params = paramArr;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

     function signMsgPKCS7(bstrSourceData, bstrHashAlg, bWithSourceData) {
        console.log("bstrSourceData, bstrHashAlg, bWithSourceData", bstrSourceData, bstrHashAlg, bWithSourceData)
        var request = new Object();
        var funcInfo = new Object();
        var paramArr = new Array();
        var randomId = GenerateRandomId();

        paramArr.push(bstrSourceData);
        paramArr.push(bstrHashAlg);
        paramArr.push(bWithSourceData);

        funcInfo.function = "SignMsgPKCS7";
        funcInfo.params = paramArr;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

     function signMsgPKCS1(bstrSourceData, bstrHashAlg) {

        var request = new Object();
        var funcInfo = new Object();
        var paramArr = new Array();
        var randomId = GenerateRandomId();

        paramArr.push(bstrSourceData);
        paramArr.push(bstrHashAlg);

        funcInfo.function = "signMsgPKCS1";
        funcInfo.params = paramArr;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

       function getVersion() {
        var request = new Object();
        var funcInfo = new Object();
        var randomId = GenerateRandomId();

        funcInfo.function = "GetVersion";
        funcInfo.params = null;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

     function getLastErrorDesc() {

        var request = new Object();
        var funcInfo = new Object();
        var randomId = GenerateRandomId();

        funcInfo.function = "GetLastErrorDesc";
        funcInfo.params = null;
        funcInfo.randomId = randomId;

        request.action = "invoke";
        request.funcInfo = funcInfo;

        return  SendMessage(nmCryptokit.browser, request);
    }

    return  {
        initConfigCert: initConfigCert,
        init: init,
        getExtensionVersion: getExtensionVersion,
        getHostVersion: getHostVersion,
        SelectCertificate: SelectCertificate,
        getLastErrorDesc: getLastErrorDesc,
        getSignCertInfo: getSignCertInfo,
        signMsgPKCS7: signMsgPKCS7,
        SignMsgPKCS1: signMsgPKCS1,
        setInstance: setInstance,
        getInstance: getInstance,
        uninit: uninit,
    }
} ();

export default {nmCryptokitObject, nmCryptokitCore};
