<template>
    <iframe src="url" :style="{ height: height, width: '100%', border: 'none' }"></iframe>
</template>
<script>
export default {
    name: 'DrawerIframe',
    data() {
        return {};
    },
    props: {
        url: String,
        height: String
    },
    inject: {
        rootNav: {
            default: {}
        }
    },
    mounted() {
        this.$bus.$on('iframe:closeRootNav', () => {
            if (this.rootNav && this.rootNav.closeRootNav) {
                this.rootNav.closeRootNav(this);
            }
        });
    },
    beforeDestroy() {
        this.$bus.$off('iframe:closeRootNav');
    }
};
</script>
