import ComSelect from './src/com-select';
import ComSelectScroll from './src/com-select-scroll'

/* istanbul ignore next */
ComSelect.install = function(Vue) {
    Vue.component(ComSelect.name, ComSelect);
    Vue.component(ComSelectScroll.name, ComSelectScroll);
};

export default ComSelect;
