// vxe-table格式化工具函数

import XEUtils from 'xe-utils';
import VXETable from 'vxe-table';
import selectConfig from './select-config.js';
import utils from '@/common/utils';

function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * 单元格的空值处理
 * @param {*} val 单元格的值
 * @param {*} text 当为空值时的替代文本
 */
function emptyText(val, text = '—') {
    if (val === undefined || val === null || val === '') {
        return {
            text: text,
            isEmpty: true
        };
    } else {
        return {
            text: val,
            isEmpty: false
        };
    }
}

/**
 * 处理cellValue为undefined或null时 hasOwnProperty报错的问题
 * @param {*} cellValue 单元格数据
 */
function getCellValue(cellValue) {
    if (cellValue === undefined || cellValue === null) {
        return cellValue;
    } else {
        return cellValue.hasOwnProperty('cellValue') ? cellValue.cellValue : cellValue;
    }
}

/**
 * 设置当前行数据: 应用编辑行通用下拉框， 通用放大镜
 * @param {Object} row
 * @param {String} property
 * @param {Any} value
 */
function setRow(row, property, value) {
    XEUtils.set(row, property, value);
}

VXETable.formats.add('money', (cellValue, digits, defaultValue) => {
    let value = getCellValue(cellValue);
    let empty = emptyText(value, defaultValue);
    if (empty.isEmpty) {
        return empty.text;
    }
    return utils.formatAmount(value, digits);
});

VXETable.formats.add('dateTime', (cellValue, format) => {
    let value = getCellValue(cellValue);
    let empty = emptyText(value);
    if (empty.isEmpty) {
        return empty.text;
    }
    cellValue = XEUtils.isDate(value) ? value : XEUtils.toStringDate(value);
    if (!format) {
        format = 'yyyy-MM-dd HH:mm:ss';
    }
    return XEUtils.toDateString(value, format);
});

VXETable.formats.add('emptyText', (cellValue, text) => {
    let value = getCellValue(cellValue);
    return emptyText(value, text).text;
});

/**
 * 创建一个数据字典的format
 * @param dictionariesObj 数据字典转换之后的obj对象
 * 如:
 * {
 *    '1': {value: '1', label: '人民币'}
 *    '2': {value: '2', label: '美元'}
 * }
 */
VXETable.formats.add('matchDictionary', (cellValue, dictionariesObj) => {
    let value = getCellValue(cellValue);
    let empty = emptyText(value);
    if (empty.isEmpty) {
        return empty.text;
    }
    /** 单选 or 多选 */
    /** 单选 string，number 类型； 多选 array 类型 */
    let formatVal = '';
    if (dictionariesObj && dictionariesObj[value] && dictionariesObj[value].text) {
        formatVal = dictionariesObj[value].text;
    } else {
        formatVal = value;
    }
    return formatVal;
});

/**
 * 多选 数据字典的format
 */
VXETable.formats.add('multiMatchDictionary', (cellValue, dictionariesObj) => {
    let value = getCellValue(cellValue);
    if (!Array.isArray(value)) {
        return value;
    }
    if (!dictionariesObj) {
        return value;
    }
    return value.map(item => dictionariesObj[item].text);
});

/**
 * 表格超链接列单元格的渲染器
 * 使用方式
 * <vxe-table-column :cell-render="linkCell"></vxe-table-column>
 * 使用时的参数配置 详细的参数使用方式请查看src\common\components\comQueryTable\src\link-cell.vue
 * linkCell: {
 *      // 链接列渲染器名称
        name: 'linkCell',
    },
 */
VXETable.renderer.add('linkCell', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params;
        return [<link-cell linkConfig={renderOpts} field={column.property} rowData={row}></link-cell>];
    }
});
/**
 * 表格流程单元格的渲染器
 * 使用方式
 * <vxe-table-column :cell-render="processCell"></vxe-table-column>
 * 使用时的参数配置, 详细的参数使用方式请查看src\common\components\comQueryTable\src\process-cell.vue
 * processCell: {
 *      // 链接列渲染器名称
        name: 'processCell',
    },
 */
VXETable.renderer.add('processCell', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { row, column } = params;
        return [<process-cell processConfig={renderOpts} field={column.property} rowData={row}></process-cell>];
    }
});
/**
 * 创建一个操作列单元格的渲染器
 * 使用方式
 * <vxe-table-column :cell-render="operationCellConfig"></vxe-table-column>
 * 使用时的参数配置
 * operationCellConfig: {
 *      // 操作列渲染器名称
        name: 'operationCell',
        // 按钮配置 {Array}
            // name: {String} 按钮中的文字名称
            // className: { String } 可根据按钮的类别配置不同的class名称, 有default和danger两种
            // props: {Object} 可配置f-button中拥有的所有属性, 如text, size等属性
            // events: {Object} 配置按钮的事件, 目前仅提供click事件
        btns: [
            {name: '查看', className: 'link-default', events: { click: this.viewInfo}},
            {name: '修改', className: 'link-default', events: { click: this.editInfo}},
            {name: '删除', props: {disabled: this.delBtnDisable}, className: 'link-danger', events: { click: this.delInfo}}
        ]
    },
 */
VXETable.renderer.add('operationCell', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { btns } = renderOpts;
        let { row } = params;
        let componentsDom = btns.map(btn => {
            let btnProps = Object.assign(
                {
                    type: 'text',
                    size: 'small',
                    visible: true
                },
                btn.props || {}
            );
            if (typeof btnProps.visible == 'function') {
                // 当传入的事function时, 为这个function的返回结果
                btnProps.visible = btn.props.visible(row);
            }
            if (!btnProps.visible) {
                // 如果visible为false 直接跳过此btn
                return;
            }

            if (typeof btnProps.disabled == 'function') {
                btnProps.disabled = btn.props.disabled(row);
            }
            let btnClassName = 'operation-btn-' + (btn.className ? btn.className : 'default');
            const directives = [
                {
                    name: 'hide-btn',
                    value: btn.vHideBtn || ''
                }
            ];

            if (typeof btn.events.click == 'function') {
                return (
                    <f-button
                        props={btnProps}
                        on-click={e => {
                            e.stopPropagation();
                            btn.events.click(row);
                        }}
                        class={btnClassName}
                        {...{ directives }}
                    >
                        {btn.name}
                    </f-button>
                );
            } else {
                return (
                    <f-button props={btnProps} class={btnClassName} {...{ directives }}>
                        {btn.name}
                    </f-button>
                );
            }
        });
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {componentsDom}
            </div>
        ];
    }
});

VXETable.renderer.add('multipleCell', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
        let { multipleConfig, labelWidth } = renderOpts;
        let { row } = params;
        return [<multiple-cell multipleConfig={multipleConfig} labelWidth={labelWidth} rowData={row}></multiple-cell>];
    }
});

VXETable.renderer.add('operationEdit', {
    // 表内容-编辑  tag 标记 save cancel
    renderEdit(h, renderOpts, { row, $table }) {
        // 取消方法
        const cancelRowEvent = async (row, btn) => {
            let { events } = btn;
            let onClick = events && events.click;
            await $table.clearActived();
            let operHandle;
            // 如果是新增状态就 删除 row
            if (row.row_status === 'add') {
                operHandle = $table.remove;
            } else {
                if (row.row_status === 'addEdit') {
                    Object.assign(row, row.oldData);
                }
                // 如果编辑状态就还原 row
                operHandle = $table.revertData;
            }
            await operHandle(row);
            onClick && onClick(row, btn);
        };
        // 保存方法
        const saveRowEvent = async (row, btn) => {
            let { events } = btn;
            let onClick = events && events.click;
            await $table.clearActived();
            const errMap = await $table.validate(row).catch(errMap => errMap);
            if (!errMap) {
                if (row.row_add) {
                    let a = Object.assign({}, row);
                    a.row_status = 'addEdit';
                    row.oldData = a;
                    setRow(row, 'row_status', 'addEdit');
                } else {
                    setRow(row, 'row_status', 'edit'); // 改变当前行的状态
                }
                onClick && onClick(row, btn);
            }
        };
        // 自定义方法
        const customRowEvent = async (row, btn) => {
            let { events } = btn;
            let onClick = events && events.click;
            await $table.clearActived();
            onClick && onClick(row, btn);
        };

        // 编辑行同意统一处理方法
        const btnClickHandler = btn => {
            switch (btn.tag) {
                case 'save':
                    saveRowEvent(row, btn);
                    break;
                case 'cancel':
                    cancelRowEvent(row, btn);
                    break;
                default:
                    customRowEvent(row, btn);
            }
        };

        let { edits } = renderOpts;
        let componentsDom = edits.map(btn => {
            let btnProps = Object.assign(
                {
                    type: 'text',
                    size: 'small'
                },
                btn.props || {}
            );

            if (typeof btnProps.visible == 'function') {
                // 当传入的事function时, 为这个function的返回结果
                btnProps.visible = btn.props.visible(row);
            } else {
                // 当传入的值非function 且
                btnProps.visible = btnProps.visible == null ? true : btnProps.visible;
            }
            if (!btnProps.visible) {
                // 如果visible为false 直接跳过此btn
                return;
            }

            if (typeof btnProps.disabled == 'function') {
                btnProps.disabled = btn.props.disabled(row);
            }
            let btnClassName = 'operation-btn-' + (btn.className ? btn.className : 'default');
            if (typeof btn.events.click == 'function') {
                return (
                    <f-button
                        props={btnProps}
                        on-click={e => {
                            e.stopPropagation();
                            btnClickHandler(btn);
                        }}
                        class={btnClassName}
                    >
                        {btn.name}
                    </f-button>
                );
            } else {
                return (
                    <f-button props={btnProps} class={btnClassName}>
                        {btn.name}
                    </f-button>
                );
            }
        });
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {componentsDom}
            </div>
        ];
    },
    //表内容-显示 非编辑状态 tag 标记， edit
    renderCell(h, renderOpts, { row, $table }) {
        let { btns } = renderOpts;
        // 自定义方法
        const editRowEvent = async (row, btn) => {
            let { events } = btn;
            let onClick = events && events.click;
            await $table.setActiveRow(row);
            onClick && onClick(row, btn);
        };
        // 自定义方法
        const customRowEvent = async (row, btn) => {
            let { events } = btn;
            let onClick = events && events.click;
            onClick && onClick(row, btn);
        };
        // 编辑行同意统一处理方法
        const btnClickHandler = btn => {
            Promise.resolve().then(async () => {
                let active = $table.getActiveRecord(),
                    errMap = null;
                if (active) {
                    errMap = await $table.validate(active.row).catch(errMap => errMap);
                }
                if (!errMap) {
                    switch (btn.tag) {
                        case 'edit':
                            editRowEvent(row, btn);
                            break;
                        default:
                            customRowEvent(row, btn);
                    }
                }
            });
        };
        let componentsDom = btns.map(btn => {
            let btnProps = Object.assign(
                {
                    type: 'text',
                    size: 'small'
                },
                btn.props || {}
            );

            if (typeof btnProps.visible == 'function') {
                // 当传入的事function时, 为这个function的返回结果
                btnProps.visible = btn.props.visible(row);
            } else {
                // 当传入的值非function 且
                btnProps.visible = btnProps.visible == null ? true : btnProps.visible;
            }
            if (!btnProps.visible) {
                // 如果visible为false 直接跳过此btn
                return;
            }

            if (typeof btnProps.disabled == 'function') {
                btnProps.disabled = btn.props.disabled(row);
            }
            let btnClassName = 'operation-btn-' + (btn.className ? btn.className : 'default');
            if (typeof btn.events.click == 'function') {
                return (
                    <f-button
                        props={btnProps}
                        on-click={e => {
                            e.stopPropagation();
                            btnClickHandler(btn);
                        }}
                        class={btnClassName}
                    >
                        {btn.name}
                    </f-button>
                );
            } else {
                return (
                    <f-button props={btnProps} class={btnClassName}>
                        {btn.name}
                    </f-button>
                );
            }
        });
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {componentsDom}
            </div>
        ];
    }
});
// 行编辑-放大镜
VXETable.renderer.add('magnifier', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, events, formItems, columns } = renderOpts;
        let defs = {
            props: {
                title: '',
                placeholder: '',
                size: 'mini',
                filterable: true,
                filterKey: 'input1',
                valueKey: 'name',
                tableData: {},
                searchModel: {},
                isCloseResetSearchForm: true
            },
            events: {
                change: () => {},
                reloadTable: () => {}
            },
            formItems: [],
            columns: []
        };

        props = Object.assign(defs.props, props);
        events = Object.assign(defs.events, events);
        formItems = Object.assign(defs.formItems, formItems);
        columns = Object.assign(defs.columns, columns);

        const scopedSlots = {
            search: () =>
                formItems.map(item => (
                    <f-form-item label={item.label} prop={item.prop}>
                        <f-input v-model={props.searchModel[item.prop]}></f-input>
                    </f-form-item>
                )),
            default: () =>
                columns.map(item => <f-magnifier-column label={item.label} prop={item.prop}></f-magnifier-column>)
        };
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <f-magnifier-single
                    v-model={row[column.property]}
                    props={props}
                    scopedSlots={scopedSlots}
                    on-change={events.change}
                    on-reloadTable={events.reloadTable}
                ></f-magnifier-single>
            </div>
        ];
    }
});

// 行编辑-多级联动
VXETable.renderer.add('cascader', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, events } = renderOpts;
        let defs = {
            props: {
                options: [],
                props: {},
                size: 'mini',
                placeholder: '',
                disabled: false,
                clearable: false,
                'show-all-levels': false,
                'collapse-tags': false,
                separator: '/',
                filterable: true,
                'popper-class': ''
            },
            events: {
                change: () => {}
            }
        };
        props = Object.assign(defs.props, props);
        events = Object.assign(defs.events, events);
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <com-edit-cascader
                    v-model={row[column.property]}
                    on-lable={label => {
                        row[column.property + 'label'] = label;
                    }}
                    {...{ attrs: props }}
                    on-change={events.change}
                ></com-edit-cascader>
            </div>
        ];
    },
    renderCell(h, renderOpts, { row, column }) {
        let { props } = renderOpts;
        let isShowAllLevels = props['show-all-levels'];
        let col = row[column.property + 'label'] || row[column.property] || '';
        let arr = isArray(col) ? col : col.split(',');
        let str = isShowAllLevels
            ? arr.join(props.separator || '/')
            : arr.slice(arr.length - 1).join(props.separator || '/') || row[column.property];
        return [str];
    }
});
// 行编辑-select联动
VXETable.renderer.add('linkage', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props } = renderOpts;
        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <edit-linkage
                    v-model={row[column.property]}
                    on-label={label => {
                        row[column.property + 'label'] = label;
                    }}
                    props={props}
                ></edit-linkage>
            </div>
        ];
    },
    renderCell(h, renderOpts, { row, column }) {
        let str = row[column.property + 'label'] || row[column.property] || '';
        return [str];
    }
});

// 行编辑 com-Input
VXETable.renderer.add('comInput', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, attrs, events, slots } = renderOpts;
        let on = {};
        let defs = {
            props: {
                trim: false,
                password: false,
                showPassword: false,
                clearable: false,
                size: 'mini',
                nextComponent: null,
                maxlength: null,
                minlength: null,
                showWordLimit: false,
                specialCharactor: 'none',
                placeholder: '',
                disabled: false,
                readonly: false,
                suffixIcon: '',
                prefixIcon: '',
                validateEvent: true
            },
            attrs: {},
            events: {},
            slots: {}
        };

        props = Object.assign(defs.props, props);
        attrs = Object.assign(defs.attrs, events);
        events = Object.assign(defs.events, events);
        slots = Object.assign(defs.slots, slots);

        if (typeof props.disabled == 'function') {
            props.disabled = props.disabled(row);
        }

        // 设置回调
        for (let key in events) {
            on[key] = val => {
                events[key](row, val, setRow);
            };
        }

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <f-input
                    v-model={row[column.property]}
                    {...{ attrs: attrs }}
                    {...{ on }}
                    props={props}
                    scopedSlots={slots}
                ></f-input>
            </div>
        ];
    }
});

// 行编辑 com-select 通用
function getComSelectType(type = 'default') {
    return selectConfig[type] ? selectConfig[type] : selectConfig['default'];
}

VXETable.renderer.add('comSelect', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, events, attrs, type } = renderOpts;
        let on = {
            'update:formModel': res => {
                row = Object.assign(row, res);
            }
        };

        let defs = getComSelectType(type);

        props = Object.assign(defs.props, props);

        attrs = Object.assign(defs.attrs, attrs);

        events = Object.assign(defs.events, events);

        // 设置回调
        for (let key in events) {
            on[key] = val => {
                events[key](row, val, setRow);
            };
        }

        if (typeof props.extraData === 'function') {
            props.extraData = props.extraData(row, column);
        }

        if (props.extraKey) {
            for (let key in props.extraKey) {
                row[props.extraKey[key]] != null && (props.extraData[key] = row[props.extraKey[key]]);
            }
        }

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {/* 静态下拉 */}
                {type === 'default' && (
                    <com-select
                        v-model={row[column.property]}
                        formModel={row}
                        {...{ attrs: attrs }}
                        {...{ on }}
                        props={props}
                    ></com-select>
                )}
                {/* 通用自定义下拉 */}
                {type === 'custom' && (
                    <com-select-scroll
                        v-model={row[column.property]}
                        {...{ attrs: attrs }}
                        formModel={row}
                        {...{ on }}
                        props={props}
                    ></com-select-scroll>
                )}
                {/* 默认不写则为 静态下拉 */}
                {!type && (
                    <com-select
                        v-model={row[column.property]}
                        formModel={row}
                        {...{ attrs: attrs }}
                        {...{ on }}
                        props={props}
                    ></com-select>
                )}
            </div>
        ];
    }
});

// 行编辑 - 省市联动

VXETable.renderer.add('province-and-city', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, events } = renderOpts;
        let on = {
            'update:formModel': res => {
                row = Object.assign(row, res);
            }
        };

        let defs = {
            props: {
                provinceUrl: '',
                cityUrl: '',
                provinceKey: '',
                cityKey: '',
                cityMatchKey: {},
                provinceMatchKey: {}
            }
        };

        props = Object.assign(defs.props, props);

        // 设置回调
        for (let key in events) {
            on[key] = val => {
                events[key](row, val, setRow);
            };
        }

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <f-form>
                    <province-and-city
                        v-model={row[column.property]}
                        formModel={row}
                        {...{ on }}
                        props={props}
                    ></province-and-city>
                </f-form>
            </div>
        ];
    }
});

// 行编辑  orgMaginifier （专属）单位放大镜
VXETable.renderer.add('orgMaginifier', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props } = renderOpts;
        let defs = {
            props: {
                dataSource: '',
                dictionariesSource: '',
                formModel: row,
                matchKey: {
                    orgType: 'orgType'
                }
            }
        };

        props = Object.assign(defs.props, props);

        return [<org-magnifier-single v-model={row[column.property]} props={props} />];
    }
});

// 行编辑 comMaginifierMulti （通用多选放大镜）
VXETable.renderer.add('comMaginifierMulti', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, attrs } = renderOpts;
        let defs = {
            props: {
                dataSource: '',
                dictionariesSource: '',
                extraData: {},
                formModel: row,
                matchKey: {}
            },
            attrs: {}
        };
        props = Object.assign(defs.props, props);
        attrs = Object.assign(defs.attrs, attrs);

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <org-magnifier-single v-model={row[column.property]} props={props} {...{ attrs: props }} />
            </div>
        ];
    }
});
// 行编辑 comMaginifierSingle （通用单选放大镜）
VXETable.renderer.add('comMaginifierSingle', {
    renderEdit(h, renderOpts, { row, column }) {
        let { props, attrs, events, columns } = renderOpts;
        let on = {};
        let defs = {
            props: {
                dataSource: '',
                dictionariesSource: '',
                extraData: {},
                formModel: row,
                matchKey: {},
                extraKey: {} // 专属编辑行功能
            },
            attrs: {
                valueKey: '',
                title: '',
                placeholder: '',
                dialogSize: '',
                size: '',
                filterable: true,
                filterKey: 'filterKey',
                pageSizes: [10, 20, 50, 100],
                page: { currentPage: 1, pageSize: 10 },
                pagination: true,
                autoSelect: false,
                disabled: false,
                beforeConfirm: null,
                beforeOpen: () => true
            },
            events: {},
            columns: []
        };

        props = Object.assign(defs.props, props);
        attrs = Object.assign(defs.attrs, attrs);
        events = Object.assign(defs.events, events);
        columns = Object.assign(defs.columns, columns);

        /**
         * 1. 判断 extraKey 是不是空的;  如果传入值  extraKey 会取当前行 key 放到  extraData 里面
         * 例如 extraKey = {orgCode: 'orgId'}  那么最后  extraData = { orgId: '123445'}
         * 2. 过滤 null， undefined
         */
        if (typeof props.extraData === 'function') {
            props.extraData = props.extraData(row, column);
        }

        for (let key in props.extraKey) {
            row[props.extraKey[key]] != null && (props.extraData[key] = row[props.extraKey[key]]);
        }

        // 设置回调
        for (let key in events) {
            on[key] = val => {
                events[key](row, val, setRow);
            };
        }

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                <com-magnifier-single v-model={row[column.property]} props={props} {...{ attrs: attrs }} {...{ on }}>
                    {columns.map(item => {
                        let scopedSlots = item.slots;
                        return item.slots ? (
                            <f-magnifier-column
                                prop={item.prop}
                                label={item.label}
                                scopedSlots={scopedSlots}
                            ></f-magnifier-column>
                        ) : (
                            <f-magnifier-column prop={item.prop} label={item.label}></f-magnifier-column>
                        );
                    })}
                </com-magnifier-single>
            </div>
        ];
    }
});

// 多列 checkbox
VXETable.renderer.add('multiCheckbox', {
    renderHeader(h, renderOpts, { column, $table }) {
        const headerTitle = column.getTitle();
        const columnKey = column.getKey();
        const { slots } = column;
        const { options } = renderOpts;
        const { trueValue, falseValue, headerDisabled, checkMethod, hiddenKey, hiddenValue } = options;
        let isChecked = false;
        let isDisabled = headerDisabled;
        let isIndeterminate = false,
            on;
        let { afterFullData } = $table;

        let isHiddenCell = row =>
            hiddenKey ? (hiddenValue ? row[hiddenKey] === hiddenValue : Object.keys(row).includes(hiddenKey)) : false;

        let tableData = afterFullData.filter(item => !isHiddenCell(item));

        const setValFn = (row, rowIndex) => {
            if (!checkMethod || checkMethod({ row, rowIndex: rowIndex, $rowIndex: rowIndex })) {
                XEUtils.set(row, columnKey, !isChecked ? trueValue : falseValue);
            }
        };
        if (!options.showHeader) {
            return [
                h(
                    'span',
                    {
                        class: 'vxe-cell--title'
                    },
                    slots && slots.header
                        ? slots.header.call($table, h)
                        : [
                              h(
                                  'span',
                                  {
                                      class: 'vxe-checkbox--label'
                                  },
                                  headerTitle
                              )
                          ]
                )
            ];
        }
        if (!options.isHidden) {
            isChecked = tableData.length && tableData.every(item => item[columnKey] === trueValue);
            isDisabled = !tableData.length;
            isIndeterminate = tableData.every(item => item[columnKey] === falseValue)
                ? false
                : tableData.some(item => item[columnKey] === falseValue);
            on = {
                click() {
                    if (!isDisabled) {
                        tableData.forEach(setValFn);
                    }
                }
            };
        }
        return [
            h(
                'span',
                {
                    class: 'vxe-cell--title'
                },
                [
                    h(
                        'span',
                        {
                            class: [
                                'vxe-cell--checkbox',
                                {
                                    'is--checked': isChecked,
                                    'is--disabled': isDisabled,
                                    'is--indeterminate': isIndeterminate
                                }
                            ],
                            attrs: {
                                title: headerTitle
                            },
                            on
                        },
                        [
                            h('span', {
                                class: 'vxe-checkbox--icon vxe-checkbox--checked-icon'
                            }),
                            h('span', {
                                class: 'vxe-checkbox--icon vxe-checkbox--unchecked-icon'
                            }),
                            h('span', {
                                class: 'vxe-checkbox--icon vxe-checkbox--indeterminate-icon'
                            })
                        ].concat(
                            headerTitle
                                ? slots && slots.header
                                    ? slots.header.call($table, h)
                                    : [
                                          h(
                                              'span',
                                              {
                                                  class: 'vxe-checkbox--label'
                                              },
                                              headerTitle
                                          )
                                      ]
                                : []
                        )
                    )
                ]
            )
        ];
    },
    renderDefault(h, renderOpts, { row, column }) {
        let { options } = renderOpts;
        let { trueValue, falseValue, hiddenKey, hiddenValue } = options;

        // value === '1' 为true
        // value === '2' 为false
        // trueValue: '1'  falseValue '2'

        trueValue = trueValue || true;
        falseValue = falseValue || false;

        let isHiddenCell = hiddenKey
            ? hiddenValue
                ? row[hiddenKey] === hiddenValue
                : Object.keys(row).includes(hiddenKey)
            : false;

        let getValue = val => {
            if (val === trueValue) return true;
            if (val === falseValue) return false;
        };
        let setValue = bool => {
            return bool ? trueValue : falseValue;
        };

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {!isHiddenCell && (
                    <f-checkbox
                        value={getValue(row[column.property])}
                        on-input={bool => {
                            row[column.property] = setValue(bool);
                        }}
                    ></f-checkbox>
                )}
            </div>
        ];
    }
});

// 多列 radio

VXETable.renderer.add('multiRadio', {
    renderEdit(h, renderOpts, { row, column }) {
        let { options } = renderOpts;
        let { dataSource, props, hiddenKey, hiddenValue, disabled } = options;

        let isHiddenCell = hiddenKey
            ? hiddenValue
                ? row[hiddenKey] === hiddenValue
                : Object.keys(row).includes(hiddenKey)
            : false;

        props = Object.assign({ label: 'text', value: 'value' }, props);

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {!isHiddenCell && (
                    <f-radio-group v-model={row[column.property]} disabled={disabled}>
                        {dataSource.map(item => (
                            <f-radio label={item[props.value]}>{item[props.label]}</f-radio>
                        ))}
                    </f-radio-group>
                )}
            </div>
        ];
    },
    renderDefault(h, renderOpts, { row, column }) {
        let { options } = renderOpts;
        let { dataSource, props, hiddenKey, hiddenValue, disabled } = options;

        let isHiddenCell = hiddenKey
            ? hiddenValue
                ? row[hiddenKey] === hiddenValue
                : Object.keys(row).includes(hiddenKey)
            : false;

        props = Object.assign({ label: 'text', value: 'value' }, props);

        return [
            <div
                on-click={e => {
                    e.stopPropagation();
                }}
            >
                {!isHiddenCell && (
                    <f-radio-group v-model={row[column.property]} disabled={disabled}>
                        {dataSource.map(item => (
                            <f-radio label={item[props.value]}>{item[props.label]}</f-radio>
                        ))}
                    </f-radio-group>
                )}
            </div>
        ];
    }
});

export default VXETable;

export { VXETable, emptyText };
