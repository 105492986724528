// 参考

import Vue from 'vue';
import comCertBox from './com-cert.vue';
import merge from '@iss/vmui-vue/lib/utils/merge';
import { isVNode } from '@iss/vmui-vue/lib/utils/vdom';

const defaults = {
    callAble: false,
    funcUrl: '',
    directUrl: '',
    directParam: {},
    funcType: '',
    funcParam: {},
    showType: 'dialog'
};

const ComCertBoxConstructor = Vue.extend(comCertBox);

let certQueue = [],
    instance,
    currentCert;

const initInstance = () => {
    instance = new ComCertBoxConstructor({
        el: document.createElement('div')
    });
};

const defaultCallback = action => {
    if (currentCert) {
        let callback = currentCert.callback;
        if (typeof callback === 'function') {
            callback(action);
        }

        if (action === 'sccuess') {
            currentCert.resolve(action);
        } else {
            currentCert.reject(action);
        }
    }
};

const showComCert = () => {
    if (!instance) {
        initInstance();
    }
    instance.action = '';

    if (!instance.visible) {
        if (certQueue.length > 0) {
            currentCert = certQueue.shift();
        }
        let options = currentCert.options;
        for (let prop in options) {
            if (options.hasOwnProperty(prop)) {
                instance[prop] = options[prop];
            }
        }
        if (options.callback === undefined) {
            instance.callback = defaultCallback;
        }
        document.body.appendChild(instance.$el);

        Vue.nextTick(() => {
            instance.open();
        });
    }
};

const ComCertBox = function(options, callback) {
    if (Vue.prototype.$isServer) return;
    if (!callback && options.callback) {
        callback = options.callback;
    }
    return new Promise((resolve, reject) => {
        certQueue.push({
            options: merge({}, defaults, options),
            callback: callback,
            resolve: resolve,
            reject: reject
        });
        showComCert();
    });
};

ComCertBox.open = options => {
    return ComCertBox(merge({}, options));
};

export default ComCertBox;
export { ComCertBox };
