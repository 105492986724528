const hiddenParamCollapseMix = {
    watch: {
        collapseItemsDefault: {
            handler(val) {
                if (this.hiddenParamConfig.type == 'custom') {
                    let hiddenParams = this.mixFilterHiddenParams(this.hiddenParamConfig.hiddenParamList, val);
                    this.paramObj = Object.assign(this.paramObj, hiddenParams);
                }
            },
            deep: true
        },
    },
    methods: {
        /**
         * 根据传入的collapseItemsDefault，过滤hiddenParams，
         * 返回在collapseItemsDefault中存在的hiddenParams
         */
        mixFilterHiddenParams(hiddenParams, collapseItemsDefault) {
            let obj = {};
            collapseItemsDefault.forEach(item => {
                if(hiddenParams[item.name]) {
                    obj[item.name] = hiddenParams[item.name];
                }
            })
            return obj;
        }
    }
}

export {
    hiddenParamCollapseMix
}