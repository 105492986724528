import { Geometry, registerGeometry, registerShapeFactory, registerShape } from '@antv/g2';

class Approval extends Geometry {
    constructor(...args) {
        super(...args);
        this.type = 'point';
        this.shapeType = 'approval';
        this.generatePoints = true;
    }
    getDrawCfg(mappingDatum) {
        const shapeCfg = super.getDrawCfg(mappingDatum);

        return {
            ...shapeCfg,
            params: this.attributeOption.shape,
            isStack: !!this.getAdjust('stack') // 层叠点图
        };
    }
    shape(field = 'flow', cfg) {
        if (typeof field !== 'string') {
            [field, cfg] = ['flow', field];
        }
        super.createAttrOption('shape', field, cfg);
        return this;
    }
}

export function splitPoints(obj) {
    // y 有可能是数组，对应原始数据中 y 为一个区间数据，如 [19, 30]，为了统一也将 x 转换为数组
    const x = obj.x;
    const y = Array.isArray(obj.y) ? obj.y : [obj.y];

    return y.map((eachY, index) => {
        return {
            x: Array.isArray(x) ? x[index] : x,
            y: eachY
        };
    });
}

function drawPoints(shape, cfg, container, params) {
    let { type } = cfg.data;
    const PARAMS = {
        number: 'number',
        unit: '笔',
        label: 'label',
        name: 'name'
    };
    const COLORS = {
        start: 'start'
    };
    let config = Object.assign(PARAMS, (params || {}).values);
    const points = shape.parsePoints(cfg.points);
    let pointPosition = points[0];
    const group = container.addGroup();

    if (type === 'start' || type === 'end') {
        group.addShape({
            type: 'marker',
            attrs: {
                r: 16,
                fill: type === 'start' ? '#B8BBC3' : type === 'end' ? '#37C088' : 'red',
                ...pointPosition,
                symbol: (x, y, r) => {
                    return [
                        ['M', x - r, y],
                        ['A', r, r, 0, 1, 0, x + r, y],
                        ['A', r, r, 0, 1, 0, x - r, y]
                    ];
                }
            }
        });

        group.addShape({
            type: 'text',
            attrs: {
                text: type === 'start' ? 'START' : type === 'end' ? 'END' : 'ALL',
                fill: '#FFFFFF',
                fontSize: 10,
                textAlign: 'center',
                textBaseline: 'middle',
                ...pointPosition
            }
        });
    } else {
        group.addShape({
            type: 'marker',
            attrs: {
                r: 8,
                fill: '#FFFFFF',
                lineWidth: 4,
                stroke: type === 'approval' ? '#FFBC4D' : '#37C088',
                ...pointPosition,
                symbol: (x, y, r) => {
                    return [
                        ['M', x - r, y],
                        ['A', r, r, 0, 1, 0, x + r, y],
                        ['A', r, r, 0, 1, 0, x - r, y]
                    ];
                }
            }
        });

        group.addShape({
            type: 'text',
            attrs: {
                text: cfg.data[config.number],
                fill: '#2C2C2C',
                fontSize: 20,
                textAlign: 'right',
                textBaseline: 'middle',
                x: pointPosition.x - 2,
                y: pointPosition.y - 8 - 6 - 6
            }
        });

        group.addShape({
            type: 'text',
            attrs: {
                text: config.unit,
                fill: '#4F5D79',
                fontSize: 12,
                textAlign: 'left',
                textBaseline: 'middle',
                x: pointPosition.x + 2,
                y: pointPosition.y - 8 - 6 - 6
            }
        });

        group.addShape({
            type: 'text',
            attrs: {
                text: cfg.data[config.label],
                fill: '#676A74',
                fontSize: 14,
                textAlign: 'center',
                textBaseline: 'middle',
                x: pointPosition.x,
                y: pointPosition.y + 8 + 6 + 7
            }
        });
    }

    return group;
}
// 绘制线
function drawLines(shape, cfg, container) {
    let { data } = cfg;
    const points = shape.parsePoints(cfg.points);
    console.log(cfg, points);
    let pointPosition = points[0];
    let group = container.addShape({
        type: 'path',
        attrs: {
            path: [
                ['M', cfg.x - 6, cfg.y],
                ['L', cfg.x + 6, cfg.y]
            ],
            symbol: function(x, y, r) {
                console.log(x, y, r);
                return [
                    ['M', x - r, y],
                    ['L', x + r, y]
                ];
            },
            lineWidth: 2,
            r: 6,
            stroke: '#000000'
        }
    });
    return group;
}

export function registerApproval() {
    registerShapeFactory('approval', {
        defaultShapeType: 'flow',
        getDefaultPoints(pointInfo) {
            return splitPoints(pointInfo);
        }
    });

    registerShape('approval', 'flow', {
        draw(cfg, group) {
            return drawPoints(this, cfg, group, 'approval', true);
        }
    });

    registerGeometry('Approval', Approval);
}

export function registerShapeApproval() {
    // 流程图 点
    registerPointApproval();
    // 流程图 线
    // registerLineApproval();
}

function registerPointApproval(params) {
    registerShape('point', 'approval', {
        draw(cfg, group) {
            return drawPoints(this, cfg, group, params);
        }
    });
}

function registerLineApproval() {
    registerShape('line', 'approval', {
        draw(cfg, group) {
            return drawLines(this, cfg, group);
        }
    });
}

// 格式化流程图
export function parseFlow(data, column) {
    return transitionFlow(parseStartAndEnd(data), column);
}
// 计算流程图点位  一维数组法
export function transitionFlow(data, num = 6) {
    let len = data.length,
        trans = Math.ceil(len / num),
        tempIndex = num - 1;
    return data.map((item, index) => {
        if (index % num === 0) {
            trans--;
            tempIndex = tempIndex === 0 ? num - 1 : 0;
        }
        item['X'] = Math.abs(tempIndex - (index % num));
        item['Y'] = trans;
        return item;
    });
}

// 计算流程图点位  二维数组法
export function transitionFlow1(data, num = 6) {
    let len = data.length,
        trans = Math.ceil(len / num);
    let twoDim = data
        .reduce((t, v, index) => {
            // 自上而下倒序排列, 图形路径是自上而下进行的
            if (index % num === 0) {
                trans--;
                t[trans] = new Array(num);
            }
            t[trans][index % num] = v;
            return t;
        }, [])
        .map((t0, i0, data0) => {
            let reverseIndex = data0.length - i0;
            // 对倒序排列进行， 2倍数进行翻转； 设置该点坐标
            if (reverseIndex % 2 === 0) {
                t0 = t0.reverse();
            }
            // 设置该点坐标
            t0.map((t1, i1) => {
                t1['X'] = i1;
                t1['Y'] = i0;
                return t1;
            });
            // 设置完该点坐标， 再进行翻转； 此处为了处理第二行不够一行时， 计算坐标出错
            if (reverseIndex % 2 === 0) {
                t0 = t0.reverse();
            }
            // 排除掉不够一行的内容
            return t0.filter(t2 => t2);
        })
        .reduceRight((t, v) => {
            // 倒置平铺数据
            v.forEach(vit => {
                t.push(vit);
            });
            return t;
        }, []);
    return twoDim;
}
// 设置开始与结束点位
export function parseStartAndEnd(data) {
    return [].concat({ type: 'start' }, data, { type: 'end' });
}
