<template>
    <div
        :class="[
            'query-form-wrap',
            currentIsOpen ? 'is-open' : '',
            custom ? 'custom' : '',
            getRows > 2 ? 'over-two-row' : '',
            getRows <= 1 ? 'only-one-row' : ''
        ]"
    >
        <f-popover popper-class="custom-pop" ref="custom" placement="bottom" v-model="isSetting">
            <div class="query-form-custom">
                <f-tabs v-model="activeName">
                    <f-tab-pane :label="$t('i18n.common.components.form.order')" name="order">
                        <div class="form-order-set-wrap">
                            <f-tag
                                size="small"
                                type="info"
                                :disable-transitions="true"
                                :draggable="true"
                                v-for="(item, index) in formItemsTagsList"
                                :key="item.name"
                                @dragstart.native.stop="handleOrderDragStart($event, item, index)"
                                @dragend.native.stop="handleOrderDragEnd($event, item, index)"
                                @dragenter.native.stop="handleOrderDragEnter($event, item, index)"
                                @dragleave.native.stop="handleOrderDragLeave($event, item, index)"
                                @dragover.native.stop="handleOrderDragOver($event, item, index)"
                                @drop.native.stop="handleOrderDrop($event, item, index)"
                            >
                                {{ item.label }}
                            </f-tag>
                            <div v-show="showDropIndicator" class="drop-indicator" ref="dropIndicator"></div>
                        </div>
                    </f-tab-pane>
                </f-tabs>
                <f-row class="custom-btns-wrap" justify="center">
                    <f-col :span="24">
                        <f-button type="primary" :size="mixSize" @click="saveFormItemsOrder">{{
                            $t('i18n.common.confirmText')
                        }}</f-button>
                        <f-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('i18n.common.components.form.tooltip')"
                            placement="bottom"
                        >
                            <f-button plain :size="mixSize" @click="orderSettingResetClick">{{
                                $t('i18n.common.resetText')
                            }}</f-button>
                        </f-tooltip>
                        <f-button plain :size="mixSize" @click="orderSettingCancelClick">{{
                            $t('i18n.common.cancelText')
                        }}</f-button>
                    </f-col>
                </f-row>
            </div>
        </f-popover>
        <div class="form-order-set-btns">
            <f-tooltip
                v-if="custom"
                effect="dark"
                :content="$t('i18n.common.components.form.queryFormSettingTipText1')"
                placement="bottom"
            >
                <i class="el-icon-setting" v-popover:custom></i>
            </f-tooltip>
            <f-tooltip
                v-if="userInfo && userInfo.userType == 1"
                effect="dark"
                :content="$t('i18n.common.components.form.queryFormHiddenParamsTipText1')"
                placement="bottom"
            >
                <i
                    class="el-icon-document"
                    v-if="userInfo && userInfo.userType == 1"
                    :size="mixSize"
                    circle
                    @click="openHiddenParam"
                >
                </i>
            </f-tooltip>
            <f-tooltip
                class="item"
                effect="dark"
                :content="$t('i18n.common.components.comEditForm.operationGuide')"
                placement="bottom"
            >
                <i
                    class="icon proicon icon-header-help"
                    :size="mixSize"
                    @click="openOperationGuide"
                >
                </i>
            </f-tooltip>
            <hidden-param
                v-if="userInfo && userInfo.userType == 1"
                ref="hiddenParam"
                :paramArr="paramArr"
                :code="customComponentsCode"
                @success="getFormItemsOrder()"
            >
            </hidden-param>
        </div>
        <div class="query-form-form">
            <f-form
                ref="queryForm"
                :model="model"
                :size="mixSize"
                :labelPosition="mixLabelPosition"
                :label-width="formLabelWidth ? formLabelWidth : mixLabelWidth"
                :column="mixColumn"
                :gutter="mixGutter"
                :rules="currRules"
                :showInvalidPrompt="false"
                :showMessage="false"
            >
                <f-form-item
                    v-for="item in currentFormItems.filter(item => !(item.visible === false))"
                    :key="item.name"
                    :column="mixColumn / (item.propsConfig.column || 1)"
                    v-bind="item.propsConfig"
                >
                    <sub-form-template
                        v-if="item.expandParam"
                        :config="item.expandParamItem"
                        :formModel="model"
                        :mixDictionaries="mixDictionaries"
                    ></sub-form-template>
                    <slot v-else :name="item.name"></slot>
                </f-form-item>
                <!-- 当currentFormItems的时候,不渲染这个插槽 -->
                <slot v-if="!currentFormItems.length"></slot>
            </f-form>
        </div>

        <div :class="['query-form-btn']">
            <div class="btns">
                <f-button type="primary" :size="mixSize" @click="query">{{
                    queryText ? queryText : $t('i18n.common.queryText')
                }}</f-button>
                <f-button plain :size="mixSize" @click="reset">{{ $t('i18n.common.resetText') }}</f-button>
                <f-button class="toggle-btn" v-if="getRows > 2" plain :size="mixSize" @click="toggleOpen()">
                    <i :class="['icon-toggle proicon', currentIsOpen ? 'icon-arrow-up' : 'icon-arrow-down']"></i>
                </f-button>
                <f-tooltip
                    effect="dark"
                    :content="$t('i18n.common.components.form.queryFormSaveTipText1')"
                    placement="bottom"
                >
                    <f-button class="save-btn" v-if="custom" plain :size="mixSize" @click="queryFormSave">
                        <i class="proicon icon-save"></i>
                    </f-button>
                </f-tooltip>
            </div>
            <div class="query-form-list" v-if="custom">
                <span class="info">{{ $t('i18n.common.components.form.historyQuery') }}</span>
                <f-tag
                    v-for="item in queryFormList.filter(item => item.isDefault)"
                    :key="item.id"
                    size="small"
                    closable
                    @close="queryFormDel(item)"
                    @click="setFormAndQuery(item)"
                    class="is-default"
                    :title="item.name"
                >
                    <span class="query-form-list-name">
                        {{ item.name }}
                    </span>
                    <span
                        :title="$t('i18n.common.components.form.queryFormListTipText1')"
                        :class="['icon proicon icon-data-fill']"
                        @click.stop="setDefaultQueryForm(item)"
                    ></span>
                </f-tag>
                <f-tag
                    v-for="item in queryFormList.filter(item => !item.isDefault)"
                    :key="item.id"
                    size="small"
                    closable
                    @close="queryFormDel(item)"
                    @click="setFormAndQuery(item)"
                    :title="item.name"
                >
                    <span class="query-form-list-name">
                        {{ item.name }}
                    </span>
                    <span
                        :title="$t('i18n.common.components.form.queryFormListTipText2')"
                        :class="['icon proicon icon-data-fill']"
                        @click.stop="setDefaultQueryForm(item)"
                    ></span>
                </f-tag>
            </div>
        </div>
        <f-dialog :visible.sync="saveQueryFormDialog" width="420px" :size="mixSize" @close="queryFormDialogClose">
            <div style="text-align: center; margin-bottom: 16px; font-size: 14px;">
                {{ $t('i18n.common.components.form.saveQuery') }}
            </div>
            <f-form :model="addQueryForm" :rules="addQueryFormRules" ref="addQueryForm">
                <f-form-item prop="name" style="margin-bottom: 10px;">
                    <f-input
                        v-model="addQueryForm.name"
                        :placeholder="$t('i18n.common.components.form.placeholderSaveQueryName')"
                    ></f-input>
                </f-form-item>
                <f-form-item prop="isDefault">
                    <f-checkbox v-model="addQueryForm.defaultFlag" true-label="1" false-label="2">{{
                        $t('i18n.common.components.form.queryFormListTipText2')
                    }}</f-checkbox>
                </f-form-item>
            </f-form>
            <template slot="footer">
                <div style="text-align: center;">
                    <f-button :size="mixSize" type="primary" @click="doQueryFormSave">{{
                        $t('i18n.common.confirmText')
                    }}</f-button>
                    <f-button :size="mixSize" plain @click="saveQueryFormDialog = false">{{
                        $t('i18n.common.cancelText')
                    }}</f-button>
                </div>
            </template>
        </f-dialog>
    </div>
</template>

<script>
import { styleMix, dictionaryMix } from '@/common/comMixin';
import { setStyle } from '@iss/vmui-vue/lib/utils/dom';
import HiddenParam from './hidden-param.vue';
import SubFormTemplate from '@/common/components/businessComponents/src/sub-form-template';
import storageUtils from '@/common/utils/storageUtils';
import utils from '@/common/utils';

export default {
    name: 'ComQueryForm',
    mixins: [styleMix, dictionaryMix],
    components: {
        HiddenParam,
        SubFormTemplate
    },
    data() {
        return {
            activeName: 'order',
            code: '',
            // isLoadCloumns:false,//设置默认查询后赋值查询和动态下来有时间差，用来控制。
            currentIsOpen: false,
            // 当前的formItem
            currentFormItems: [],
            // 默认的formItem对象,方便获取每个item的数据
            formItemsDefaultObj: {},
            // 已保存的查询条件列表
            queryFormList: [],
            // 可以保存的查询条件的最大个数0代表不限制,
            queryFormMax: 0,
            // 表单是否处于设置顺序的状态
            isSetting: false,
            // 表单项的顺序
            formItemsOrder: [],
            // 表单项顺序设置显示的tag的list
            formItemsTagsList: [],
            // 当前拖动的对象数据
            draggingItem: null,
            // 当前被放置的对象数据
            droppinItem: null,
            dragType: '',
            showDropIndicator: false,
            hiddenParams: [], // 字段的显示隐藏
            paramArr: [], // 存放隐藏字段设置弹窗中的字段
            dataReady: {
                queryFormListReady: false,
                formItemsOrderReady: false,
                expandParamReady: false,
                componentMounted: false
            },
            saveQueryFormDialog: false, // 保存查询条件的弹窗
            addQueryForm: {
                // 保存查询条件弹窗form
                name: '',
                defaultFlag: 2
            },
            addQueryFormRules: {
                name: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value || value == null) {
                                callback(new Error(this.$t('i18n.common.components.form.saveQueryEmpty')));
                            } else if (value.length > 6) {
                                callback(new Error(this.$t('i18n.common.components.form.maxSaveQuery')));
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            },
            expandParamlist: [], // 存储查询表格扩展字段的数据
            customRequired: {}, // 存放用户设置的是否必填字段信息
        };
    },
    props: {
        /**
         * 查询表单数据
         */
        model: Object,
        /**
         * 表单项默认的顺序配置
         * {
                name: 'input1', // 输入控件的唯一标识,通过这个进行插槽的识别
                propsConfig: { // f-form-item的属性配置
                    label: this.$t('i18n.list1.input1'),
                    prop: 'input1'
                }
            }
         */
        formItemsDefault: {
            type: Array,
            default: () => {
                return [];
            }
        },
        /**
         * 查询表单验证规则
         */
        rules: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 时候用户自定义
         */
        custom: {
            type: Boolean,
            default: true
        },
        /**
         * 组件编号
         */
        customComponentsCode: {
            type: String
        },
        /**
         * 表单元素顺序查询地址
         */
        formItemOrderUrl: String,
        /**
         * 表单元素顺序设置保存地址
         */
        formItemOrderSaveUrl: String,
        /**
         * 表单--保存的查询条件列表查询地址
         */
        queryFormListUrl: String,
        /**
         * 表单--查询条件保存地址
         */
        queryFormSaveUrl: String,
        /**
         * 表单--查询条件删除地址
         */
        queryFormDelUrl: String,
        /**
         * 设置表单项label的宽度
         */
        formLabelWidth: String,
        /**
         * 设置查询按钮的文字内容
         */
        queryText: String,
        isOpen: {
            type: Boolean,
            default: false
        },
        /**
         * 扩展字段查询参数配置
         */
        expandParam: Object,
        /**
         * 主要为了处理当一个页面有多个查询表单时, 标记table与表单一一对应的属性
         * 未设置时, 说明只有1个查询表单
         */
        queryFormName: String,
        // 常量请求地址 -> 拓展字段常量地址
        dictionariesUrl: String
    },
    computed: {
        getRows() {
            let column = 0;
            let formItems = this.formItemsDefault.concat(this.expandParamlist || []);
            for (let index = 0; index < formItems.length; index++) {
                const item = formItems[index];
                let currentColumn = item.propsConfig.column;
                if (item.propsConfig.column === undefined) {
                    column += 1;
                } else {
                    // 当自定义了column时要算入空列的量
                    let prev = column % this.mixColumn;
                    currentColumn = currentColumn / this.mixColumn >= 1 ? this.mixColumn : currentColumn;
                    if (prev + currentColumn > this.mixColumn) {
                        column += currentColumn + (this.mixColumn - prev);
                    } else {
                        column += currentColumn;
                    }
                }
            }
            let rows = column / this.mixColumn;
            return rows;
        },
        userInfo: {
            get() {
                return JSON.parse(storageUtils.getItem('userInfo')).loginUserInfo;
            }
        },
        currRules() {
            // 对this.rules进行深拷贝
            let rules = {};
            Object.keys(this.rules).forEach( key=> {
                rules[key] = this.rules[key].map(item=> {
                    return {
                        ...item
                    }
                })
            })
            Object.keys(this.customRequired).forEach(key => {
                let codeRules = rules[key] || [];
                // 用户设置的是否必填
                let customRequired = this.customRequired[key];
                // required: 1默认 2必填  3非必填
                let rule = [{required: customRequired == 2 ? true : false, message: '不能为空'}];
                codeRules.forEach((item, i) => {
                    if(item.required == undefined) {
                        rule.push(item)
                    }
                });
                rules[key] = rule;
            });
            return rules;
        }
    },
    inject: ['scrollApp'],
    created() {
        this.currentIsOpen = this.isOpen;
    },
    mounted() {
        this.$bus.$on('changeColumn', num => {
            this.mixColumn = num;
        });
        this.init();
        this.dataReady.componentMounted = true;
    },
    updated() {},
    beforeDestroy() {
        this.$bus.$off('changeColumn');
    },
    activated() {
        this.query();
        let scrollbarEl = this.scrollApp.$refs.scrollbar.wrap;
        scrollbarEl.scrollTop = 0;
    },
    methods: {
        init() {
            this.code = this.customComponentsCode ? this.customComponentsCode : '';
            // 1.获取item项顺序的数据, 成功获取时以后台数, 否则赋值default的配置的数据
            if (this.formItemsDefault && this.formItemsDefault.length) {
                this.initFormItems();
            }
            if (this.custom && this.queryFormListUrl) {
                // 当custom配置为true, 且配置了queryFormListUrl的时候, 才去后台请求数据
                // 2.获取已保存的查询条件列表
                this.getQueryFormList();
            } else {
                this.dataReady.queryFormListReady = true;
            }
        },
        /**
         * 对formitem的顺序进行初始化操作
         * 1.根据设置的formItemsDefault,以其中的name值作为key,item项做为值生成formItemsDefaultObj,方便后续的排序操作
         * 2.根据设置的formItemsDefault,初始化formItemsOrder的值
         * 3.获取后台已保存的顺序值
         */
        async initFormItems() {
            let formItemsDefault = [];
            if (this.expandParam) {
                let expandParam = this.expandParamlist = await this.getExpandParam();
                if (expandParam) {
                    formItemsDefault = this.formItemsDefault.concat(expandParam);
                } else {
                    formItemsDefault = this.formItemsDefault;
                }
            } else {
                formItemsDefault = this.formItemsDefault;
            }
            this.dataReady.expandParamReady = true;
            if (this.formItemOrderUrl) {
                // 当custom配置为true, 且配置了formItemOrderUrl的时候
                let formItemsDefaultObj = {};
                formItemsDefault.forEach(item => {
                    if (item.name) {
                        formItemsDefaultObj[item.name] = item;
                        // 默认的排序
                        this.formItemsOrder.push({
                            name: item.name
                        });
                    }
                });
                // 将formItemsDefault数组转换成Object的形式,方便后面好根据name值拿到对应的formItem的配置
                this.formItemsDefaultObj = formItemsDefaultObj;
                // 获取formItems的顺序
                this.getFormItemsOrder();
            } else {
                // 否则直接渲染formItemsDefault的值
                this.currentFormItems = formItemsDefault;
                this.dataReady.formItemsOrderReady = true;
            }
        },
        /**
         * 设置f-form-item的显示隐藏
         */
        setFormItemVisible(name, flag) {
            this.currentFormItems.forEach(item => {
                if (name === item.name) {
                    item.visible = flag;
                }
            });
            this.formItemsDefaultObj[name].visible = flag;
        },
        /**
         * 获取扩展字段
         */
        async getExpandParam() {
            let res = await this.$http.post(this.$api.common.query.queryFormExpandParam, this.expandParam);
            // 返回数据为JSON字符串
            let result = false;
            try {
                let temp = JSON.parse(res.data);
                if (res.code == 0 && temp && Array.isArray(temp.template)) {
                    this.dictionariesUrl &&
                        (await this.mixGetDictionaries(
                            this.dictionariesUrl,
                            this.getDictionariesConfig(
                                temp.template,
                                ['select', 'radio', 'checkbox'],
                                ['constant', 'dictionary', 'custom']
                            )
                        ));
                    result = temp.template.map(item => {
                        this.$set(this.model, item.name, item.value || null);
                        return {
                            name: item.name,
                            expandParam: true,
                            expandParamItem: item,
                            propsConfig: {
                                label: item.text,
                                prop: item.name,
                                rules: [].concat(item.rules).map(rule => {
                                    if (['amount', 'number'].includes(item.type)) {
                                        rule = { ...rule, type: 'number' };
                                    }
                                    return rule;
                                })
                            }
                        };
                    });
                }
            } catch (e) {
                result = false;
            }
            return result;
        },
        /** 选中 按钮触发的 */
        getDictionariesConfig(data, haveDic, dicType) {
            if (!Array.isArray(data)) return [];
            let dicConfig = [];
            /** 使用 Set 去重 let dic = new Set() */

            let mixs = data
                .filter(item => {
                    return haveDic.includes(item.type) && dicType.indexOf(item.dataType) > -1;
                })
                .reduce((total, mix) => {
                    if (total.has(mix.dataType)) {
                        total.set(mix.dataType, total.get(mix.dataType).add(mix.data));
                    } else {
                        total.set(mix.dataType, new Set([mix.data]));
                    }
                    return total;
                }, new Map());

            for (let [key, value] of mixs) {
                dicConfig.push({ dictType: key, dictKey: Array.from(value) });
            }
            return dicConfig;
        },
        /**
         * 获取后台已经保存的顺序值
         * 1.如果请求成功这里将按请求返回回的顺序值进行渲染
         * 2.请求失败或者没有保存过顺序数据是按formItemDefault的顺序进行渲染
         */
        getFormItemsOrder() {
            // 先移除掉所有校验
            this.$refs.queryForm && this.$refs.queryForm.clearValidate();
            // 这里向后台请求formItems的顺序值
            this.$http
                .post(this.formItemOrderUrl, {
                    code: this.code
                })
                .then(res => {
                    if (res.code == 0) {
                        let hiddenParams = [];
                        let hiddenParamsVal = {};
                        let customRequired = {}
                        if (res.data && res.data.hiddenParams) {
                            res.data.hiddenParams.forEach(item => {
                                if (!item.showOrHide) {
                                    hiddenParams.push(item.prop);
                                }
                                if (item.defaultVal) {
                                    hiddenParamsVal[item.prop] = item.defaultVal;
                                    let defaultVal = JSON.parse(item.defaultVal);
                                    for (let key in defaultVal) {
                                        if(defaultVal[key] === "$now"){
                                            if(this.model[key] instanceof Array){
                                                this.model[key] = [utils.formatDateOnly(new Date()),utils.formatDateOnly(new Date())];
                                            }else{
                                                this.model[key] = utils.formatDateOnly(new Date());
                                            }
                                        }else{
                                            this.model[key] = defaultVal[key];
                                        }
                                    }
                                }

                                if (item.required !== undefined && item.required !== 1) {
                                   customRequired[item.prop] = item.required;
                                }
                            });
                        }
                        this.customRequired = customRequired;
                        this.hiddenParams = hiddenParams;
                        let paramArr = this.formItemsDefault.map(item => {
                            let prop = item.propsConfig.prop;
                            return {
                                label: item.propsConfig.label,
                                prop: prop,
                                showOrHide: this.hiddenParams.includes(prop) ? false : true,
                                defaultVal: hiddenParamsVal[prop]
                                    ? hiddenParamsVal[prop]
                                    : '',
                                required: customRequired[prop] !== undefined ? customRequired[prop] : 1
                            };
                        });
                        this.paramArr = paramArr;
                    }
                    // 更新dom的顺序并重新渲染
                    if (this.custom && res.data && res.data.customOrder) {
                        if (res.code == 0 && this.validFormItemsData(res.data.customOrder)) {
                            this.formItemsOrder = res.data.customOrder;
                        }
                    }
                    this.setFormItemsOrder(this.formItemsOrder);
                    this.dataReady.formItemsOrderReady = true;
                })
                .catch(() => {
                    this.setFormItemsOrder(this.formItemsOrder);
                    this.dataReady.formItemsOrderReady = true;
                });
        },
        /**
         * 验证返回的自定义配置数据与组件代码中定义的组件数据是否完全匹配
         * 完全匹配则返回true
         * 有1个不匹配则返回false
         */
        validFormItemsData(data) {
            if (!data || data.length !== this.formItemsDefault.length) {
                return false;
            }
            let flag = false;
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (!this.formItemsDefaultObj[item.name]) {
                    break;
                } else if (i === data.length - 1) {
                    flag = true;
                }
            }
            return flag;
        },
        /**
         * 获取已保存的查询条件数据
         */
        getQueryFormList() {
            // 获取查询条件数据
            this.$http
                .post(this.queryFormListUrl, {
                    code: this.code
                })
                .then(res => {
                    if (res.data) {
                        // 当后台返回了data时
                        this.queryFormList = res.data.map(item => {
                            if (item.defaultFlag == undefined) {
                                item.isDefault = false;
                            } else {
                                item.isDefault = item.defaultFlag == '1' ? true : false;
                            }
                            return item;
                        });
                    }
                    this.dataReady.queryFormListReady = true;
                })
                .catch(() => {
                    this.dataReady.queryFormListReady = true;
                });
        },
        /**
         * 表单查询按钮操作
         * 这里会向外暴露query事件
         * @type 'click': 描述click事件触发的查询  'init': 描述的是js调用触发的查询
         */
        query(type = 'click') {
            // 先做表单验证, 验证成功在进行查询操作
            let queryForm = this.$refs['queryForm'];
            let children = (queryForm || {}).$children;
            let isEmpty = Array.isArray(children) ? children.length : 0;
            // 先做表单验证, 验证成功在进行查询操作
            // 防止页面未加载成功就进行校验
            isEmpty &&
                queryForm.validate(valid => {
                    if (valid) {
                        this.$emit('query', type);
                    }
                });
        },
        /**
         * 表单重置按钮操作
         * 这里会向外暴露reset事件
         */
        reset() {
            this.$refs['queryForm'].resetFields();
            this.$emit('reset');
        },
        /**
         * 表单展开/收起按钮操作
         */
        toggleOpen() {
            this.currentIsOpen = !this.currentIsOpen;
            let queryTable = document.querySelector('.query-table-wrap');
            queryTable.style.position = 'static';
        },
        /**
         * 表单保存按钮操作
         * 1.验证已经保存的条件是否已经有2条
         * 2.验证表单内数据, 全为空时提示用户输入值, 非全空时才能保存
         * 3.发送保存请求, 请求完成后重新获取表单顺序的数据
         */
        queryFormSave() {
            if (!this.queryFormSaveUrl) {
                console.log('查询条件保存地址不存在!');
                return;
            }
            let flag = false;
            this.$refs['queryForm'].validate(valid => {
                if (valid) {
                    flag = true;
                }
            });
            if (!flag) {
                return;
            }
            // 1.验证已经保存的条件是否已经达到最大值
            if (this.queryFormMax && this.queryFormList.length >= this.queryFormMax) {
                this.$error(this.$t('i18n.common.components.form.maxQuerySave'));
                return;
            }
            // 2.验证表单内数据是否全为空, 全为空时提示用户输入值
            flag = false;
            let items = this.formItemsDefault.concat(this.expandParamlist || [])
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                let value = '';
                if (item.propsConfig.prop) {
                    value = this.model[item.propsConfig.prop];
                }
                // 通过toString方法来判断各种类型的值时候不为空
                if (value !== null && value !== undefined && value.toString && value.toString()) {
                    flag = true;
                    break;
                }
            }
            if (flag) {
                this.saveQueryFormDialog = true;
            } else {
                this.$error(this.$t('i18n.common.components.form.placeholderSaveQueryWhere'));
            }
        },
        /**
         * 执行查询条件保存
         */
        doQueryFormSave() {
            this.$refs.addQueryForm.validate(valid => {
                if (valid) {
                    this.$http
                        .post(
                            this.queryFormSaveUrl,
                            JSON.stringify({
                                // 页面编号
                                code: this.code,
                                // 查询条件名称
                                ...this.addQueryForm,
                                // 查询条件数据
                                formData: this.model,
                                operationType: 1
                            })
                        )
                        .then(res => {
                            if (res.code == 0) {
                                this.$success(this.$t('i18n.common.success'));
                                // 重新请求已保存的查询条件的数据
                                this.getQueryFormList();
                            } else {
                                this.$error(res.msg);
                            }
                            this.saveQueryFormDialog = false;
                        });
                }
            });
        },
        /**
         * 查询条件保存弹窗关闭的时候清空数据
         */
        queryFormDialogClose() {
            this.$refs.addQueryForm.resetFields();
        },
        /**
         * 已经保存的查询条件的删除按钮操作
         */
        queryFormDel(item) {
            if (!this.queryFormDelUrl) {
                console.log('查询条件删除地址不存在!');
                return;
            }
            // 删除查询条件
            this.$http
                .post(this.queryFormDelUrl, {
                    // 页面编码
                    code: this.code,
                    // 查询条件id
                    id: item.id
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$success(this.$t('i18n.common.success'));
                        // 重新请求已保存的查询条件的数据
                        this.getQueryFormList();
                    } else {
                        this.$error(res.msg);
                    }
                });
        },
        /**
         * 已经保存的查询条件的点击事件
         * 1.将该查询条件的值赋值到model上
         * 2.并查询
         */
        setFormAndQuery(item) {
            this.setQueryForm(item);
            this.query();
        },
        /**
         * 已经保存的查询条件的点击事件
         * 1.将该查询条件的值赋值到model上
         */
        setQueryForm(item) {
            // 点击已保存的查询条件时, 将查询条件值赋值到查询表单中
            for (let key in this.model) {
                if (Array.isArray(this.model[key])) {
                    this.model[key] = [];
                } else if (typeof this.model[key] == 'object' && this.model[key] !== null) {
                    this.model[key] = {};
                } else if (typeof this.model[key] == 'boolean') {
                    this.model[key] = false;
                } else if (this.model[key] == null) {
                    // 如果值是null 或者 undefined 时 不做任何事;
                } else {
                    this.model[key] = '';
                }
            }
            for (let key in item.formData) {
                // model上存在这个key才赋值, 不存在的不处理
                if (this.model.hasOwnProperty(key)) {
                    this.model[key] = item.formData[key];
                }
            }
        },
        setFormItemsOrder(formItemsOrder) {
            // 过滤不需要展示的字段
            let showFormItemsOrder = formItemsOrder.filter(item => {
                return !this.hiddenParams.includes(item.name);
            });
            // 设置当前展示的formItems, 这里是为了将当前的和默认的配置区分开
            this.currentFormItems = showFormItemsOrder.map(item => {
                if (item.name) {
                    return Object.assign({}, this.formItemsDefaultObj[item.name] || {}, item);
                } else {
                    return item;
                }
            });
        },
        /**
         * 表单更改顺序--保存按钮操作
         * 1.保存当前更改后的顺序数据
         * 2.重新像后台获取表单顺序数据
         * 3.保存完成后关闭pop层
         */
        saveFormItemsOrder() {
            if (!this.formItemOrderSaveUrl) {
                console.log('表单元素顺序保存地址不存在!');
                return;
            }
            this.$http
                .post(
                    this.formItemOrderSaveUrl,
                    JSON.stringify({
                        code: this.code,
                        formItemsOrder: this.formItemsOrder
                    })
                )
                .then(res => {
                    if (res.code == 0) {
                        this.$success(this.$t('i18n.common.success'));
                        // 重新请求已保存的查询条件的数据
                        this.getFormItemsOrder();
                    } else {
                        this.$error(res.msg);
                    }
                    this.isSetting = false;
                });
        },
        /**
         * 表单更改顺序--重置按钮按钮操作
         * 1.将formItemsTagsList重置到formItemsDefault的顺序
         */
        orderSettingResetClick() {
            this.setFormItemsTagsList(this.formItemsDefault);
        },
        /**
         * 表单更改顺序--取消按钮操作
         * 1.将formItemsTagsList重置到当前的顺序
         * 2.关闭pop层
         */
        orderSettingCancelClick() {
            this.isSetting = false;
            // 将formItemstagsList重置到当前的表单顺序状态
            this.setFormItemsTagsList(this.currentFormItems);
        },
        /**
         * 设置formItemsTagsList数据
         */
        setFormItemsTagsList(formItems) {
            this.formItemsTagsList = formItems.map(item => {
                return {
                    name: item.name,
                    label: item.propsConfig.label
                };
            });
        },
        /**
         * 更改顺序拖动事件
         */
        handleOrderDragStart(event, item, index) {
            this.draggingItem = {
                el: event.target,
                data: item,
                index: index
            };
        },
        handleOrderDragEnd() {
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragEnter(event, item, index) {
            let target = event.target;
            let draggingItemIndex = this.draggingItem.index;
            let droppinItemIndex = index;
            if (droppinItemIndex == draggingItemIndex) {
                // 当放置区域就是本身拖动的对象时不做处理
                return;
            } else if (droppinItemIndex > draggingItemIndex) {
                // 向后拖动
                this.dragType = 'after';
            } else {
                // 向前拖动
                this.dragType = 'before';
            }
            let dropIndicator = this.$refs.dropIndicator;
            let top = target.offsetTop;
            let left = this.dragType == 'before' ? target.offsetLeft - 6 : target.offsetLeft + 6 + target.offsetWidth;
            setStyle(dropIndicator, {
                top: top + 'px',
                left: left + 'px'
            });
            // 显示drop位置线条
            this.showDropIndicator = true;
        },
        handleOrderDragLeave() {
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        handleOrderDragOver(event) {
            // 这里需要阻止默认的事件,才会执行drop事件
            event.preventDefault();
        },
        handleOrderDrop(event, item, index) {
            event.preventDefault();
            let draggingItemIndex = this.draggingItem.index;
            let droppingItemIndex = index;
            let newArr = [];
            let oldArr = this.formItemsTagsList;
            if (droppingItemIndex == draggingItemIndex) {
                return;
            }
            oldArr.forEach((item, i) => {
                if (i == droppingItemIndex && this.dragType == 'after') {
                    // 向后时需要再droppingItemIndex的后面插入draggingitem的数据
                    newArr.push(item);
                    newArr.push(this.draggingItem.data);
                } else if (i == droppingItemIndex && this.dragType == 'before') {
                    // 向后时需要再droppingItemIndex的前面插入draggingitem的数据
                    newArr.push(this.draggingItem.data);
                    newArr.push(item);
                } else if (i !== draggingItemIndex) {
                    // 当i不在draggingItemIndex及droppingItemIndex时直接添加item数据
                    newArr.push(item);
                }
            });
            this.formItemsTagsList = newArr;
            this.dragType = '';
            this.draggingItem = null;
            // 隐藏drop位置线条
            this.showDropIndicator = false;
        },
        /**
         * 打开隐藏字段设置弹窗
         */
        openHiddenParam() {
            this.$refs.hiddenParam.open();
        },
        /**
         * 设置默认查询条件
         */
        setDefaultQueryForm(item) {
            this.$http
                .post(
                    this.queryFormSaveUrl,
                    JSON.stringify({
                        // 页面编号
                        code: this.code,
                        ...item,
                        defaultFlag: item.isDefault ? 2 : 1,
                        operationType: !item.isDefault ? 2 : 3
                    })
                )
                .then(res => {
                    if (res.code == 0) {
                        if (item.isDefault) {
                            item.isDefault = false;
                        } else {
                            this.queryFormList.map(curr => {
                                curr.isDefault = false;
                            });
                            item.isDefault = true;
                        }
                    } else {
                        this.$error(res.msg);
                    }
                });
        },
        /**
         * 打开操作指引弹窗
         */
        openOperationGuide() {
            this.$bus.$emit('operationGuideOpen', this.code + 'list-page');
        }
    },
    watch: {
        currentFormItems(val) {
            // formItems更新时, 需要更新tags的顺序
            this.setFormItemsTagsList(val);
        },
        formItemsTagsList(val) {
            // 当tags的顺序改变时, 需要更新formItemsOrder的顺序
            // 保存时是保存formItemsOrder中的值
            this.formItemsOrder = val.map(item => {
                return {
                    name: item.name
                };
            });
        },
        dataReady: {
            handler(newVal) {
                if (
                    newVal.queryFormListReady &&
                    newVal.formItemsOrderReady &&
                    newVal.expandParamReady &&
                    newVal.componentMounted
                ) {
                    // 所有数据准备就绪的时候
                    let defaultModel = this.queryFormList.filter(item => {
                        return item.isDefault;
                    });
                    if (defaultModel.length > 0) {
                        // 这里添加$nextTick是为了保证子组件的mounted都已执行完, 保证表单重置时能重置到正确的初始值
                        this.$nextTick(() => {
                            this.setQueryForm(defaultModel[0]);
                        });
                    }
                    // 等待相关变更数据的dom更新全部完成后再进行表单校验, 表单校验通过才能进行查询
                    setTimeout(() => {
                        this.query('init');
                    }, 0);
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
.query-form-wrap {
    position: relative;
    height: 76px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    background: #fff;
    border-bottom: 1px dashed #d6dfeb;
    margin-bottom: 2px;
    padding: 4px 0;

    // 查询表单--表单区域样式--start
    .query-form-form {
        margin-right: 130px;
    }
    .el-form.f-form {
        margin: 0px;
    }
    /deep/ .el-form-item {
        text-align: left;
        margin: 4px 0;
        font-size: 12px;
        .el-form-item__label {
            font-size: 12px;
        }
    }
    /deep/ .el-input {
        border-radius: 2px;
    }
    /deep/ .el-input--mini .el-input__inner {
        height: 30px;
        line-height: 30px;
    }
    // 查询表单--表单区域样式--end

    // 查询表单--表单顺序设置按钮--start
    .form-order-set-btns {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 16px;
        cursor: pointer;
    }
    // 查询表单--表单顺序设置按钮--end

    // 查询表单--按钮区域样式+历史检索区域样式--start
    .query-form-btn {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 130px;
        .btns {
            margin: 4px 0;
            float: left;
            text-align: left;
            .el-button {
                width: 56px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        .toggle-btn,
        .save-btn {
            border-color: #f6f6f6;
            font-size: 16px;
            padding: 5px 0px;
            i {
                color: #888b95;
            }
            &:hover {
                border-color: #4577ff;
                i {
                    color: #4577ff;
                }
            }
            &:focus {
                border-color: #1d50db;
                i {
                    color: #1d50db;
                }
            }
        }
        .icon-toggle {
            width: 24px;
        }
        .el-icon-document {
            width: 24px;
        }
        .el-button {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .el-button {
            margin-left: 2px;
            margin-right: 2px;
        }
        .el-tag {
            margin: 2px;
            cursor: pointer;
        }
    }
    // 历史检索区域
    .query-form-list {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0px 6px 3px 6px;
        width: 110px;
        overflow-y: auto;
        overflow-x: hidden;
        text-align: left;
        border-left: 2px dashed #dddddd;
        .info {
            display: block;
            font-size: 12px;
            color: #999;
            height: 18px;
            line-height: 18px;
        }
        .el-tag {
            width: 110px;
            vertical-align: top;
            border-radius: 12px;
            position: relative;
            overflow: hidden;
            /deep/ .el-icon-close {
                position: absolute;
                display: block;
                width: 18px;
                height: 22px;
                top: 0;
                right: 0;
                background: #ecf5ff;
                transform: scale(1);
                line-height: 22px;
                text-align: center;
                border-radius: 0;
                &::before {
                    display: block;
                    margin-top: 2px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    transform: scale(0.8);
                }
                &:hover::before {
                    background: #409eff;
                    border-radius: 50%;
                }
            }
            &:hover {
                .icon-data-fill {
                    visibility: visible;
                }
            }
            &.is-default {
                /deep/ .el-icon-close {
                    background: #ecfff8;
                }
            }
        }
        .icon-data-fill {
            visibility: hidden;
            position: absolute;
            right: 16px;
            top: 0px;
            color: #3361de;
            background: #ecf5ff;
        }
        .is-default {
            color: #37c088;
            background: #ecfff8;
            border-color: #7dc5a2;
            .icon-data-fill {
                visibility: visible;
                color: #37c088;
                background: #ecfff8;
            }
        }
    }
    // 查询表单--按钮区域样式+历史检索区域样式--end

    // 动态交互的样式--start
    // 只有1行的情况
    &.only-one-row {
        height: 38px;
    }
    &.custom {
        height: 76px;
    }
    &.is-open {
        height: auto;
    }
    // 超过2行, 但是没有历史检索时
    &.over-two-row {
        .query-form-form {
            margin-right: 190px;
        }
        .query-form-btn {
            width: 180px;
        }
        .btns {
            width: 180px;
        }
    }
    // 有历史检索时
    &.custom {
        .query-form-form {
            margin-right: 250px;
        }
        .query-form-btn {
            width: 250px;
        }
        .btns {
            width: 120px;
        }
    }
    // 超过2行且有历史检索时
    &.over-two-row.custom {
        .query-form-form {
            margin-right: 250px;
        }
        .query-form-btn {
            width: 250px;
        }
        .btns {
            width: 120px;
        }
    }
    // 动态交互的样式--end
}

// 表单顺序设置弹出层样式--start
.custom-pop {
    padding: 8px;
}
.query-form-custom {
    width: 330px;
    background: #ffffff;
}
.form-order-set-wrap {
    padding: 4px 6px;
    border: 2px dashed #b8bbc4;
    position: relative;
    .el-tag {
        margin: 4px 6px;
    }
    .drop-indicator {
        position: absolute;
        height: 24px;
        width: 1px;
        background: blue;
    }
}
.custom-btns-wrap {
    text-align: center;
    margin-top: 8px;
}
// 表单顺序设置弹出层样式--end
</style>
