<template>
    <!-- 预算项目放大镜--多选 -->
    <f-magnifier-multi
        ref="magnifier"
        :title="$t('i18n.common.components.budgetMagnifierMulti.title')"
        :placeholder="placeholder || $t('i18n.common.components.budgetMagnifierMulti.placeholder')"
        valueKey="budgetCode"
        rowKey="budgetId"
        labelKey="budgetName"
        editKey="budgetAmount"
        editValueFromKey="payAmount"
        filterKey="budgetName"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        size="mini"
        :tableData="tableData"
        v-bind="$attrs"
        @open="handleOpen"
        @change="handlerChange"
        @query="handleQuery"
        @pageChange="handlePageChange"
        @clear="handleClear"
        @reset="handleReset"
        v-on="listeners"
        type="primary"
        :autoSelect="autoSelect"
        :leftSpan="12"
        :rightSpan="12"
        :showRightHeader="true"
        :class="['budget-magnifier']"
    >
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.budgetMagnifierMulti.budgetCode')" prop="budgetCode">
                <f-input v-model="searchForm.budgetCode"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.budgetMagnifierMulti.budgetName')" prop="budgetName">
                <f-input v-model="searchForm.budgetName"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 编码 -->
            <f-magnifier-column
                prop="budgetCode"
                :label="$t('i18n.common.components.budgetMagnifierMulti.budgetCode')"
            ></f-magnifier-column>
            <!-- 名称 -->
            <f-magnifier-column
                prop="budgetName"
                :label="$t('i18n.common.components.budgetMagnifierMulti.budgetName')"
            ></f-magnifier-column>
            <!-- 可用额度 -->
            <f-magnifier-column prop="budgetCurrencyName" :label="$t('i18n.common.components.budgetMagnifierMulti.budgetCurrencyName')">
            </f-magnifier-column>
            <!-- 可用额度 -->
            <f-magnifier-column prop="usedAmount" :label="$t('i18n.common.components.budgetMagnifierMulti.amount')">
                <template slot-scope="$scope">
                    {{ $scope.row.usedAmount | formatAmount(2, '') }}
                </template>
            </f-magnifier-column>
        </template>
        <template v-slot:rightColumns="{selectionEdit}">
            <f-magnifier-column width="100px" prop="budgetCode" show-overflow-tooltip :label="$t('i18n.common.components.budgetMagnifierMulti.budgetCodeAndBudgetName')">
                <template v-slot:default="{ row }"> {{ row['budgetName'] }} / {{ row['budgetCode'] }} </template>
            </f-magnifier-column>
            <f-magnifier-column width="120px" class-name="f-selected-table-edit-td" :label="$t('i18n.common.components.budgetMagnifierMulti.budgetAmount')">
                <template v-slot:default="{ row, $index }">
                    <f-amount
                        :precision="2"
                        :tooltip="true"
                        size="mini"
                        v-model="selectionEdit[$index]['budgetAmount']"
                    >
                    </f-amount>
                </template>
            </f-magnifier-column>
            <f-magnifier-column width="95px" prop="currencyName" :label="$t('i18n.common.components.budgetMagnifierMulti.budgetAmountCurrencyName')">
                <template v-slot:default="{ row }"> {{ row['currencyName'] }} </template>
            </f-magnifier-column>
            <f-magnifier-column width="60px" prop="exchangeRate" :label="$t('i18n.common.components.budgetMagnifierMulti.rate')">
                <template v-slot:default="{ row }"> {{ row['exchangeRate'] }} </template>
            </f-magnifier-column>
            <f-magnifier-column width="95px" :label="$t('i18n.common.components.budgetMagnifierMulti.budgetAmountConvert')">
                <template v-slot:default="{ row , $index}"> {{ selectionEdit[$index]['budgetAmount'] * row['exchangeRate'] | formatAmount(2, '') }} </template>
            </f-magnifier-column>
            <f-magnifier-column width="70px" prop="budgetCurrencyName" :label="$t('i18n.common.components.budgetMagnifierMulti.convertCurrencyName')">
                <template v-slot:default="{ row }"> {{ row['budgetCurrencyName'] }} </template>
            </f-magnifier-column>
            <f-magnifier-column prop="systemName" width="95px" :label="$t('i18n.common.components.budgetMagnifierMulti.systemName')"></f-magnifier-column>
            <f-magnifier-column prop="periodSystemName"  width="125px" :label="$t('i18n.common.components.budgetMagnifierMulti.periodSystemName')"></f-magnifier-column>
            <f-magnifier-column prop="periodTypeName"  width="125px" :label="$t('i18n.common.components.budgetMagnifierMulti.periodTypeName')"></f-magnifier-column>
            <f-magnifier-column prop="reportColumn"  width="125px" :label="$t('i18n.common.components.budgetMagnifierMulti.reportColumn')"></f-magnifier-column>

            <f-magnifier-column prop="budgetCode" width="50px">
                <template v-slot:default="item">
                    <i class="el-icon-circle-close" @click="handleRemoveSelection(item)"></i>
                </template>
            </f-magnifier-column>

        </template>
    </f-magnifier-multi>
</template>

<script>
import magnifierMultiMix from '@/common/comMixin/magnifierMultiMix.js';
/**
 * 单位放大镜-单选
 */
export default {
    name: 'BudgetMagnifierMulti',
    mixins: [magnifierMultiMix],
    data() {
        return {
            /**
             * 单位放大镜查询条件
             */
            searchForm: {
                budgetCode: '', // 单位编号
                budgetName: '' // 单位名称
            }
        };
    },
    props: {
        /**
         * 关联值配置
         */
        matchKey: {
            type: Object,
            default: () => {
                return {
                    orgCode: 'orgCode'
                };
            }
        },
        /**
         * 数据字典URL地址
         **/
        dictionariesSource: {
            type: String
        }
    },
    created() {},
    methods: {
        handleRemoveSelection(item) {
            this.$refs.magnifier.handleRemoveSelection(item);
        }
    }
};
</script>

<style lang="scss" scoped>
.el-form.f-form {
    margin: 0px;
}
.el-form-item--mini.el-form-item {
    margin-bottom: 0px;
}
.budget-magnifier {
    /deep/ .el-table .cell {
        padding-right: 8px;
    }
}

</style>
