// 数据操作工具类方法
import CryptoJS from 'crypto-js';
import storageUtils from '@/common/utils/storageUtils';

export const getDecryptKey = (temp) => {
    let encryptKey = storageUtils.getItem(document.location.origin+ (temp || '')) || '';
    if(encryptKey === null || encryptKey==="") {
        storageUtils.clear();
    }
    return encryptKey;
};
export const setDecryptKey = (value,temp) => {
    if(!temp){
        storageUtils.setItem('ENCRYPT_KEY', value);
    }
    storageUtils.setItem(document.location.origin + (temp || ''),value);
};

// AES ECB加密方法
export function encryptByAESModeECB(obj,temp) {
    let message;
    // 判断是否是json字符串; 如果是字符串就不进行json化
    if (Object.prototype.toString.call(obj) !== '[object String]') {
        message = JSON.stringify(obj);
    } else {
        message = obj;
    }
    let keyHex = CryptoJS.enc.Utf8.parse(getDecryptKey(temp));
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
// AES ECB解密方法
export function decryptByAESModeECB(str) {
    let keyHex = CryptoJS.enc.Utf8.parse(getDecryptKey());
    var decrypted = CryptoJS.AES.decrypt(str, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted).toString());
}
// 生成消息摘要
export function generateSHA (obj,temp) {
    return CryptoJS.SHA256(encryptByAESModeECB(obj,temp)).toString(CryptoJS.enc.Hex);
}

export function createEncryptRequest(obj,temp) {
    return {
        version: '1.0',
        timestamp: new Date().getTime(),
        data: encryptByAESModeECB(obj,temp)
    };
};

export function isUseEncrypt() {
    return (SYS_CONFIG['USEENCRYPT'] || {})['isUseEncrypt'];
};
