import http from "@/common/http";
import {setAllLangAsync} from "@/common/i18n";

async function commonHandle(api,router,store,i18n,model) {
    await initI18n(model,store);
}

async function initI18n(model,store){
    if(!model)return;
    let isSaas = process.env.VUE_APP_TYPE;
    let queryLangUrl = '/api/fsmc/login/query/lang-info-list';//查询国际化语言
    if (isSaas == 'saas') {
        queryLangUrl = '/api/portal/login/query/lang-info-list';//查询国际化语言
    }
    let langList = 'zh-CN';

    let sessionLangList = sessionStorage.getItem('langList');

    if(sessionLangList != '' && sessionLangList != null){
        langList =  JSON.parse(sessionLangList);
        console.log('加载缓存中国际化语言种类:',langList)
        await setAllLangAsync(langList,model,store);
    }else{
        await http.post(queryLangUrl, {}, { headers: { __expires__: 'true', notUseEncrypt: true},loading:false  })
            .then(res => {
                if (res.code == 0) {

                    let httplang = [];
                    res.data.forEach(item => {
                        httplang.push(item.langSymbol)
                    })
                    if(httplang.length >0){
                        langList = httplang;
                    }
                    sessionStorage.setItem('langList',JSON.stringify(langList));
                    console.log('加载国际化语言种类:',langList)
                } else {
                    this.$error(res.msg);
                }
            });
        await setAllLangAsync(langList,model,store);
    }


}

export { commonHandle };
