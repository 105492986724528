<template>
    <!-- 金融机构业务组件（结算中心） -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="cooperativeFinancNetName"
        filterKey="cooperativeFinancNetName"
        :title="$t('i18n.common.components.statement.title')"
        :placeholder="placeholder || $t('i18n.common.components.statement.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        v-on="listeners"
        :autoSelect="autoSelect"
        :isSearchOpen="true">
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.statement.cooperativeInstitutionType')" prop="cooperativeInstitutionType">
                <com-select-scroll v-model="searchForm.cooperativeInstitutionType"
                                   :placeholder="$t('i18n.common.components.statement.cooperativeInstitutionType')"
                                   :infiniteScroll="cooperativeData.infiniteScroll"
                                   filterable
                                   remote
                                   remoteFilterKey="cooperativeInstitutionType"
                                   :props="cooperativeData.props"
                                   :dataSource="cooperativeData.dataSource"
                                   :disabled="disabledCooperativeInstitutionType">
                </com-select-scroll>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.statement.cooperativeInstitutionName')" prop="cooperativeInstitutionCode">
                <office-select-single
                    v-model="searchForm.cooperativeInstitutionName"
                    :dataSource="cooperativeUrl"
                    :extraData="{cooperativeInstitutionType: searchForm.cooperativeInstitutionType}"
                    :props="{label: 'cooperativeInstitutionName', value: 'cooperativeInstitutionCode'}"
                    :formModel.sync="searchForm"
                    :matchKey="{ cooperativeInstitutionCode: 'cooperativeInstitutionCode',cooperativeInstitutionType: 'cooperativeInstitutionType',
                                 cooperativeInstitutionName: 'cooperativeInstitutionName',cooperativeFinancNetCode: 'cooperativeFinancNetCode',
                                 cooperativeFinancNetName: 'cooperativeFinancNetName',acctProvinceCode: 'acctProvinceCode',
                                 acctProvinceName: 'acctProvinceName',acctCityCode: 'acctCityCode',acctCityName: 'acctCityName'}"
                    :remoteFilterKey="['cooperativeInstitutionName']"
                    :disabled="disabledCooperativeInstitutionCode"
                ></office-select-single>

            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.statement.cooperativeFinancNetName')" prop="cooperativeFinancNetName">
                <f-input v-model="searchForm.cooperativeFinancNetName"></f-input>
            </f-form-item>
            <!-- 所在国家 -->
            <f-form-item :label="$t('i18n.common.components.statement.countryName')" prop="countryName">
                <country-select-single
                    v-model="searchForm.countryName"
                    :dataSource="countryUrl"
                    :formModel.sync="searchForm"
                    :matchKey="{
                        countryCode: 'countryCode',
                        countryName: 'countryName',
                    }"
                    :placeholder="$t('i18n.common.components.statement.countryName')"
                />
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.statement.provinceAndCity')">
                <province-and-city
                    id="statement-province-city"
                    :formModel.sync="searchForm"
                    :provinceUrl="provinceUrl"
                    :cityUrl="cityUrl"
                >
                </province-and-city>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.statement.cooperativeFinancNetCode')" prop="cooperativeFinancNetCode">
                <f-input v-model="searchForm.cooperativeFinancNetCode"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 机构类型 -->
            <f-magnifier-column prop="cooperativeInstitutionTypeName" :label="$t('i18n.common.components.statement.cooperativeInstitutionType')">
            </f-magnifier-column>
            <!-- 合作金融机构名称 -->
            <f-magnifier-column prop="cooperativeInstitutionName" :label="$t('i18n.common.components.statement.cooperativeInstitutionName')"></f-magnifier-column>
            <!-- 合作金融网点名称 -->
            <f-magnifier-column prop="cooperativeFinancNetName" :label="$t('i18n.common.components.statement.cooperativeFinancNetName')"></f-magnifier-column>
            <!-- 联行号 -->
            <f-magnifier-column prop="cooperativeFinancNetCode" :label="$t('i18n.common.components.statement.cooperativeFinancNetCode')"></f-magnifier-column>
            <f-magnifier-column prop="countryName" :label="$t('i18n.common.components.statement.countryName')"></f-magnifier-column>
            <f-magnifier-column prop="acctProvinceName" :label="$t('i18n.common.components.statement.province')"></f-magnifier-column>
            <f-magnifier-column prop="acctCityName" :label="$t('i18n.common.components.statement.city')"></f-magnifier-column>
        </template>
    </f-magnifier-single >
</template>

<script>
    import { dictionaryMix, constantMix,comMix } from '@/common/comMixin';
    import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
/**
 * 单位放大镜-单选
 */
    export default {
        name: 'StatementMagnifierSingle',
        mixins: [magnifierSingleMix, dictionaryMix, constantMix, comMix],
        data() {
            return {
                searchForm: {
                    cooperativeInstitutionType: this.orgType, // 机构类型
                    cooperativeInstitutionCode: this.orgCode,// 合作金融机构编号
                    cooperativeInstitutionName: this.orgName,// 合作金融机构名称
                    cooperativeFinancNetName: '',// 合作金融网点名称
                    cooperativeFinancNetCode: '', // 联行号
                    countryCode: '', //国家/地区
                    countryName: '',//国家/地区
                    provinceCode: '', // 省
                    provinceName: '', // 省名
                    cityCode: '', // 市
                    cityName: '' // 市名
                },
                /**
                 * 合作金融机构类型
                 */
                dictionariesConfig: [
                    {
                        "dictType": "dictionary",
                        "dictKey": ["CooperativeInstitutionType"]
                    }
                ],
                provinceUrl: this.$api.common.province,
                cityUrl: this.$api.common.city,
                countryUrl:this.$api.common.country,
                cooperativeData:{
                    infiniteScroll:false,
                    props:{label: 'name',
                        value: 'code'}
                }
            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        cooperativeInstitutionType: 'cooperativeInstitutionType',
                        cooperativeInstitutionCode: 'cooperativeInstitutionCode',
                        cooperativeInstitutionName: 'cooperativeInstitutionName',
                        cooperativeFinancNetCode: 'cooperativeFinancNetCode',
                        directCode:'directCode',
                        acctProvinceCode: 'acctProvinceCode',
                        acctProvinceName: 'acctProvinceName',
                        acctCityCode: 'acctCityCode',
                        acctCityName: 'acctCityName',
                        swiftCode:'swiftCode',
                        openAreaCode:'countryCode',
                        openAreaName:'countryName',
                        abroadAddressDetail:'abroadAddressDetail'
                    }
                }
            },

            cooperativeUrl: {
                type: String
            },
            orgType:{
                type: [String,Number]
            },
            disabledCooperativeInstitutionType:{
                type: Boolean,
                default:()=>{
                    return false;
                }
            },
            disabledCooperativeInstitutionCode:{
                 type: Boolean,
                default:()=>{
                    return false;
                }
            },
            orgCode:{
                type: String,
                default:()=>{
                    return "";
                }
            },
            orgName:{
                type: String,
                default:()=>{
                    return "";
                }
            },
            /**
             * 数据字典URL地址
             **/
            dictionariesSource:{
                type: String
            },
            cooperative:{
                type:Object,
                default:()=>{
                    return {
                        dataSource:'',
                        infiniteScroll:false,
                        props:{label: 'name',
                            value: 'code'}
                    }
                }
            }
        },

        async created() {
            // 获取数据字典
            await this.mixGetDictionaries(this.dictionariesSource, this.dictionariesConfig);
            console.info(this.mixDictionaries, this.searchForm,this.cooperative)
            Object.assign(this.cooperativeData,this.cooperative)
        },
    watch:{
        orgType:function(v){
            this.searchForm.cooperativeInstitutionType = v;
        },
        orgCode:function(v){
            this.searchForm.cooperativeInstitutionCode = v;
        },
        orgName:function(v){
            this.searchForm.cooperativeInstitutionName = v;
        }
    }
    }
</script>

<style lang="scss" scoped>
#statement-province-city {
    /deep/ .el-input {
        width: 86px;
    }
}
</style>
