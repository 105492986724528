export default {

    // 客商信息
    queryCounterPartyListUrl: '/api/fsmc/counter-party/query-contacts-org-by-page', // 客商信息列表查询
    deleteCounterPartyUrl: '/api/fsmc/counter-party/contacts-org-delete', // 客商信息删除
    queryOftenAccountTableListUrl: '/api/fsmc/counter-party/query-often-used-accounts', // 查询常用账户列表信息
    queryCounterPartyByIdUrl: '/api/fsmc/counter-party/query-contacts-org/-by-/', // 根据id查询,
    queryBankInfoList: '/api/fsmc/counter-party/query-bank-info-list-by-page', // 分页查询支行信息
    queryCurrencyList: '/api/fsmc/data/currency/query-currency-by-page', // 分页查询币种信息
    editCounterPartyInfoUrl: '/api/fsmc/counter-party/contacts-org-update', // 更新客商信息
    insertCounterPartyInfoUrl: '/api/fsmc/counter-party/contacts-org-add', // 新增客商信息
    queryOrgInfoPageListUrl: '/api/fsmc/counter-party/query-orginofo-by-page', // 组织放大镜
    queryOrgInfoWithAuthPageListUrl: '/api/fsmc/counter-party/query-orginofo-with-auth-by-page', // 组织放大镜
    queryCounterPartyUrl: '/api/fsmc/counter-party/query-counter-party-code', // 客商信息编码

    // 敏感信息设置
    sensitiveInformSetUrl:'/api/fsmc/settset/sensitive-set/query-sensitive-set-by-page', // 敏感信息设置
    querySensitiveSetByIdUrl:'/api/fsmc/settset/sensitive-set/query-sensitive-set/-by-/', // 敏感信息设置 - 根据id查询单个信息
    addSensitiveSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-add', // 敏感信息设置 - 新增
    updateSensitiveSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-update', // 敏感信息设置 - 更新
    deleteSensitiveSetUrl:'/api/fsmc/settset/sensitive-set/sensitive-set-delete', // 敏感信息设置 - 删除
    queryBusinessTypeUrl:'/api/fsmc/api/fsmcBusinessType/businessTypeSet/query-business-type-set-by-page',//敏感信息设置-业务类型查询
    queryPaymentTypeListUrl:'/api/fsmc/settset/sensitive-set/query-payment-type-list/-by-/', //敏感信息设置-收付款类型设置
    //queryPaymentTypeListUrl:'/api/fsmc/settset/sensitive-set/query-payment-type-list/-by-/{businessTypeId}', //敏感信息设置-收付款类型设置
}
