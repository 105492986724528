import i18n from "@/common/i18n";
import certJ from './InfosecNetSignCNGAgent.min';

// eslint-disable-next-line no-unused-vars
let certsAll = [],certDataList = [],certSelectedCertSN = [], myCert=null;

export default {
    setCertsAll(certsAllParam){
        certsAll = certsAllParam;
    },
    getCertsAll(){
        return certsAll;
    },
    setCertDataList(certDataListParam){
        certDataList = certDataListParam;
    },
    getCertDataList(){
        return certDataList;
    },
    setCertSelectedCertSN(certSelectedCertSNParam){
        certSelectedCertSN = certSelectedCertSNParam;
    },
    getCertSelectedCertSN(){
        return certSelectedCertSN;
    },
    setMyCert(mycertParam){
        myCert = mycertParam;
    },
    getMyCert(){
        return myCert;
    },
    //刷新证书列表
    refreshCertList() {
        try {
            var certs = this.getCertsAll();
            if (certs == undefined || certs == 'undefined') {
                throw('没有找到符合License的证书');
            }
            this.initCertList(certs);
            if (certs.length == 0) {
                throw('没有找到符合License的证书');
            }
        } catch (e) {
            throw e;
        }
    },
    //过滤证书
    filterCert(val) {
        try {
            var certs = this.getCertsAll();
            if (val != undefined || val != null) {
                if (val == 1) {
                    certs = certs.forSign();
                    return certs;
                } else if (val == 2) {
                    certs = certs.forEncrypt();
                    return certs;
                }
            } else {
                var r = certs;
                var datetime = new Date();
                if (datetime != null && datetime != ""  ) {
                    // 这里可以选择使用无参的重载
                    r = r.byValidity(datetime);
                }
                return r;
            }
        } catch (e) {
            throw e;
        }
    },
    //传入CertSet, 将certs中的证书显示在CertList中
    initCertList(certs) {
        try {
            var count = certs.length;
            if (count <= 0) {
                let dataValue = {};
                dataValue.text = '无';
                dataValue.value = '';
                this.getCertDataList().push(dataValue);
                return;
            }
            for (var i = 0; i < count; i++) {
                let cert = certs[i];
                //cert.certDN; 证书信息
                //cert.issuerDN; 签发者信息
                //cert.certSN; 证书序列号
                //cert.validBegin; 证书有效日起始日
                //cert.validEnd; 证书有效日结束日
                //cert.KeyUsage; 密钥用法
                //cert.CertType; 证书类型

                let certInfo = cert.certDN;
                let certInfoList = certInfo.split(",");
                if(certInfoList != null && certInfoList.length > 0){
                    var m = new Map();
                    certInfoList.forEach(info =>{
                        if(info.indexOf("=") > -1){
                            let arr = info.split("=");
                            m.set(arr[0].trim(),arr[1].trim());
                        }
                    }) ;


                    let code_name = m.get("CN");
                    if(code_name != null && code_name != ""){
                        let display_code = cert.certSN + '-' + code_name.split("-")[1];
                        let display_name = code_name.split("-")[0] + '('+ cert.validBegin + '   -   ' + cert.validEnd +')';
                        this.addOption(display_code, display_name);
                    }
                }
            }
        } catch (e) {
            throw e;
        }
    },
    addOption(oValue, oName) {
        let this_certDataList = this.getCertDataList();
        let dataValue = {};
        dataValue.text = oName;
        dataValue.value = this.replaceEnter(oValue);
        this_certDataList.push(dataValue);
        this.setCertDataList(this_certDataList);
    },
    getCNFromSubject(cert) {
        try {
            var t = cert.subject().match(/(S(?!N)|L|O(?!U)|OU|SN|CN|E)=([^=]+)(?=, |$)/g);
            for (var i = 0; i < t.length; i++) {
                if (t[i].indexOf('CN=') === 0) return t[i].substr(3, t[i].length);
            }
            return null;
        } catch (e) {
            throw e;
        }
    },
    // 返回Certificate对象
    // getSelectedCert(serialNumber) {
    //     try {
    //         var certs = this.getCertsAll();
    //         var r = certs.bySerialnumber(serialNumber);
    //         return r.get(0);
    //     } catch (e) {
    //         throw e;
    //     }
    // },
    sign(errorCode, signedData) {
        if (errorCode != 0) {
            throw ("加密失败");
        }
        return signedData;
    },
    //加密
    // encrypt() {
    //     try {
    //         var toEnc = Math.random() + "";
    //         var cert = this.getSelectedCert();
    //         var P7 = cert.encryptMessage(toEnc);
    //         return P7;
    //     } catch (e) {
    //         throw e;
    //     }
    // },
    // //解密
    // decrypt() {
    //     try {
    //         var b64 = this.encrypt();
    //         var P7 = new certJ.Pkcs7(b64);
    //         var result = P7.decryptMessage();
    //         return result;
    //     } catch (e) {
    //         throw e;
    //     }
    // },
    refreshCertListOnClick(licenes,flushFlag){
        try {
            if(flushFlag){
                this.setCertDataList([]);
                certJ.IWSAgent.IWSASetAsyncMode(false);
                certJ.IWSAgent.IWSASendAvailable();
                //获取证书数据
                certJ.IWSAgent.IWSAGetAllCertsListInfo("", "Sign", 0, this.setCertsAll);
                this.refreshCertList();
            }
        } catch (e) {
            this.setCertDataList([]);
        }
    },
    certChange(serialNumber) {
        try {
            let cert = {};
            for(let i=0;i<this.getCertDataList().length;i++){
                if(serialNumber == this.getCertDataList()[i].value){
                    cert = this.getCertDataList()[i];
                    break;
                }
            }
            this.setMyCert(cert);
            this.setCertSelectedCertSN(serialNumber.split("-")[0]);
        } catch (e) {
            throw e;
        }
    },
    sendAuto(){
        try {
            this.setCertDataList([]);
            certJ.IWSAgent.IWSASetAsyncMode(false);
            certJ.IWSAgent.IWSASendAvailable();
            //获取证书数据
            certJ.IWSAgent.IWSAGetAllCertsListInfo("", "Sign", 0, this.setCertsAll);
            this.refreshCertList();
            var serialNumber = this.getCertDataList()[0].value;
            this.setCertSelectedCertSN(serialNumber.split("-")[0]);
            this.setMyCert(this.getCertDataList()[0]);
        } catch (e) {
            throw e;
        }
    },
    initAuto(){
        try {
            this.setCertDataList([]);
            certJ.IWSAgent.IWSASetAsyncMode(false);
            certJ.IWSAgent.IWSASendAvailable();
            //获取证书数据
            certJ.IWSAgent.IWSAGetAllCertsListInfo("", "Sign", 0, this.setCertsAll);
            this.refreshCertList();
        } catch (e) {
            this.setCertDataList([]);
        }
    },
    getSingData(){
        let m_SignInterFaceType = 1; //接口类型   1 普通接口 一代签名。  2 高级接口 二代签名。
        let DigestArithmetic = "SHA1"; //设置摘要算法，默认值设置为：SHA1
        let certInfo_CN = this.getMyCert().value.split('-');
        let cert_index = -1;
        for(let i=0;i<this.getCertDataList().length;i++){
            if(this.getMyCert().value == this.getCertDataList()[i].value){
                cert_index = i;
                break;
            }
        }
        if(cert_index == -1){
            throw ("尚未选择证书");
        }
        certJ.IWSAgent.IWSASetAsyncMode(false);
        var signedData = certJ.IWSAgent.IWSADetachedSign(m_SignInterFaceType, certInfo_CN[1], cert_index, DigestArithmetic);
        return signedData[1];
    },
    replaceEnter(str){
        return str.replace(/[ ]/g,""); //去除字符算中的空格
    }
};

