<template>
    <div class="com-form-flow">
        <f-steps 
            v-if="flowData.length > 0"
            :active="active" align-center>
            <f-step v-for="(item, index) in flowData" :key="item.id || index">
                <template v-slot:icon>
                    <div v-if="item.isCurrent">
                        <f-popover
                            popper-class="com-form-flow-pop"
                            v-model="popoverVisible"
                            ref="popover"
                            placement="bottom"
                            width="400"
                            trigger="click"
                        >
                            <div v-if="flowInfo.length > 0">
                                <f-row :gutter="8" v-for="(item, index) in flowInfo" :key="item.label || index">
                                    <f-col class="flow-info-label" :span="4">{{ item.label }}</f-col>
                                    <f-col class="flow-info-value" :span="20">{{ item.value }}</f-col>
                                </f-row>
                            </div>
                            <div v-else>
                                {{$t('i18n.common.components.form.dataEmpty')}}
                            </div>
                            <div slot="reference">{{ index + 1 }}</div>
                        </f-popover>
                    </div>
                    <div v-else>{{ index + 1 }}</div>
                </template>
                <template v-slot:title>
                    <div>{{ item.flowName }}</div>
                </template>
            </f-step>
        </f-steps>
        <div v-else style="text-align: left;">{{$t('i18n.common.components.comFormFlow.dataEmpty')}}</div>
    </div>
</template>

<script>
/**
 * 表单中的审批流组件
 */
export default {
    name: 'ComFormFlow',
    data() {
        return {
            popoverVisible: false,
            active: 0,
            flowData: [],
            flowInfo: []
        };
    },
    props: {
        /**
         * 请求数据的地址, 或者直接的源数据
         */
        dataSource: {
            type: [Array, String]
        },
        /**
         * 请求携带的参数
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getFlowData();
        },
        /**
         * 获取流程跟踪信息
         */
        getFlowData() {
            if (typeof this.dataSource == 'string') {
                // 当dataSource配置为字符串时,发送请求
                this.$http.post(this.dataSource, this.extraData).then(res => {
                    if(res.code == 0) {
                        this.flowData = res.data;
                    }
                });
            } else {
                this.flowData = this.dataSource;
            }
        },
        reload() {
            this.getFlowData();
        }
    },
    watch: {
        flowData: {
            handler(newVal) {
                let active;
                for (let index = 0; index < newVal.length; index++) {
                    const item = newVal[index];
                    if (item.isComplete) {
                        active = index + 1;
                    }
                    if (item.isCurrent) {
                        this.flowInfo = item.flowInfo;
                    }
                }
                if (active) {
                    this.active = active;
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
.com-form-flow {
    width: 100%;
    margin: 32px 8px;
    /deep/ .el-step__line {
        background-color: #f2f2f2;
    }
    /deep/ .el-step__title {
        font-size: 12px;
        line-height: 36px;
        color: #676a74;
        font-weight: normal;
        &.is-process {
            color: #f45f49;
        }
    }
    /deep/ .el-step__icon.is-text {
        width: 24px;
        height: 24px;
        background: #e0e0e0;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #676a74;
        border: 0;
    }
    /deep/ .el-step__head.is-process {
        .el-step__icon.is-text {
            background: #f45f49;
            color: white;
            cursor: pointer;
        }
    }
}
.flow-info-label {
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    color: #b8bbc4;
}
.flow-info-value {
    font-size: 12px;
    line-height: 24px;
    color: #606266;
}
.com-form-flow-pop {
    padding: 8px;
}
</style>
