<template>
    <com-select-scroll
        v-model="select"
        :formModel.sync="exportData"
        v-bind="$attrs"
        v-on="listeners"
        :dataSource="dataSource"
        :extraData="extraData"
        :placeholder="placeholder || $t('i18n.common.components.orgSelectSingle.placeholder')"
        :infiniteComplete="$t('i18n.common.infiniteComplete')"
        :infiniteLoading="$t('i18n.common.infiniteLoading')"
        :props="props"
        :matchKey="matchKey"
        :remoteFilterKey="remoteFilterKey"
        filterable
        remote
        infiniteScroll
        :keyNames="keyNames"
        :scroll="scroll"
        @select="selectHandle"
    >
        <template v-slot:default="{ item }">
            <f-row v-if="item" type="flex" justify="space-between">
                <span class="f-ellipsis">{{ item.orgName }}</span>
                <span style="color: #8492a6; font-size: 13px">{{ item.orgCode }}</span>
            </f-row>
        </template>
    </com-select-scroll>
</template>
<script>
import selectSingleMix from '@/common/comMixin/selectSingleMix.js';
export default {
    name: 'OrgSelectSingle',
    mixins: [selectSingleMix],
    props: {
        placeholder: {
            type: String
        }
    }
};
</script>
