/**
 * 导航要用的方法
 */
function direction(config = {}) {
    let direction = 'left';
    switch (config.direction) {
        case 'rtl':
            direction = 'left';
            break;
        case 'ltr':
            direction = 'right';
            break;
        case 'ttb':
            direction = 'bottom';
            break;
        case 'btt':
            direction = 'top';
            break;
    }
    return direction;
}
function widthDirection(config) {
    let { size } = config || {};
    // eslint-disable-next-line no-useless-escape
    return `${direction(config)}:${size && size.replace(/([\d|\.]+)%$/, (a, b) => 100 - b * 1 + '%')}`;
}

function closeDirection(config) {
    let direct = direction(config);
    let styles = '';
    switch (direct) {
        case 'left':
        case 'right':
            styles += `top:0;`;
            break;
        case 'bottom':
            styles += `left:0;`;
            break;
        case 'top':
            styles += `right:0;`;
            break;
    }
    styles += `${direct}:0`;
    return styles;
}
function contentDirection(config) {
    let direct = direction(config);
    let height = '';
    if(config && config.height){
        height = `height:${config.height}`;
    }
    return `margin-${direct}: 18px;${height}`;
}
function getRouterView(matched, route) {
    let result = [];
    let components = matched.find(item => item.path === route.path && item.name === route.name).components || [];
    for (let key in components) {
        if (key !== 'default') {
            result.push(key);
        }
    }
    return result;
}

export const rootNav = {
    widthDirection,
    closeDirection,
    contentDirection,
    getRouterView
};
