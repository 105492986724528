<template>
    <div>
        <f-form>
        <div v-if="validType(fileType,'img')" :style="{maxHeight: h,height:h,width:w,maxWidth:w,overflow: 'auto'}">
            <img :src="pdfUrl" >
        </div>
        <div class="wrap" v-if="validType(fileType,'pdf')" :style="{maxHeight: h,height:h,width:w,maxWidth:w}">
            <div class="pdf-container" ref="pdfContainer" id="pdfContainer" :style="{maxHeight: h,height:h,width:w,maxWidth:w}">
                <canvas ref="watermark" class="watermark"></canvas>
                <canvas ref="myCanvas" ></canvas>
            </div>
            <div class="pdf-control">
                <div class="pdf-control-zoom">
                    <el-button type="text" icon="el-icon-zoom-out zoom-out" @click="minus"></el-button>
                    <el-button type="text" icon="el-icon-zoom-in" @click="addscale"></el-button>
                </div>
                <div class="pdf-control-page">

                    <el-button type="text" icon="el-icon-arrow-left" @click="prev" ></el-button>
                    <span class="page-number-container">
                        <input
                            type="number"
                            v-model="pageNum"
                            class="page-number-input"
                            @blur="queueRenderPage(pageNum)"
                        />
                        / {{ pageCount }}页
                    </span>
                    <el-button type="text" icon="el-icon-arrow-right"  @click="next"></el-button>
                </div>
                <div class="pdf-control-zoom">
                   <!-- <el-button type="text" icon="el-icon-printer zoom-out" @click="print" v-print="'#imgPrint'"></el-button>-->
                    <el-button type="text" icon="el-icon-download" @click="download"></el-button>
                </div>
            </div>
        </div>
        <div v-if="fileType == 'txt' || fileType == '.txt' || fileType.indexOf('txt') != -1" :style="{maxHeight: h,height:h,width:w,maxWidth:w,overflow: 'auto','white-space': 'pre','text-align': 'left'}">
            {{txtView}}
        </div>
        </f-form>
    </div>
</template>

<script>
import pdfJs from "pdfjs-dist";
export default {
    name: "com-pdf",
    data() {
        return {
            isLoading: false,
            pdfUrl: "", // PDF的base64
            pdfName:'',
            pdfDoc: null, // pdfjs 生成的对象
            pageNum: 0, // 当前页数
            pageRendering: false,
            pageNumPending: null,
            scale: 1, // 放大倍数
            page_num: 0, // 当前页数
            pageCount: 0, // 总页数
            maxscale: 5, // 最大放大倍数
            minscale: 0.3, // 最小放大倍数
            waterInfo: {},
            w:'',
            h:'',
            txtView:''
        };
    },
    props:{
        src: {
            type: String,
            default: () => {
                return ''
            }
        },
        options:{
            type: Object,
            default: () => {
                return {}
            }
        },
        width:{
            type:String,
            default:''
        },
        fileType:{
            type:String,
            default:'pdf'
        },
        height:{
            type:String,
            default:''
        }
    },

    async created() {

        this.initWidth(this.width);
        this.initHeight(this.height)

        this.isLoading = true;
        await this.getPreviewossUrl();


        //this.waterInfo = await this.getWaterInfo()
        /*if (this.waterInfo.enable) {
            this.createWatermarkTemplate();
        }*/

    },
    methods: {
        validType(param,type){
            let img = /jpeg|jpg|jpe|bmp|png|gif|ico|apng|webp|svg|tif|pcx|tga|exif|fpx|psd|cdr|pcd/;
            let pdf = /pdf|doc|docx|xlsx|xls|ppt|pptx/;
            if(type === 'img'){
                return img.test(param);
            }
            else if(type === 'pdf'){
                return pdf.test(param)
            }
        },
        initWidth(w){
            this.w = w;
        },
        initHeight(h){
            this.h = h;
        },
        // 获取水印信息
        async getWaterInfo() {
            return this.$api.cloud.getWaterInfo()
        },
        // 获取文件
        getCloudFilePreviewoss() {
            let office = /doc|docx|xlsx|xls|ppt|pptx/;
            if(office.test(this.fileType)){
                return this.$http.blob(this.src, this.options,'get');
            }else{
                return this.$http.blob(this.src, this.options);
            }

        },
        async getPreviewossUrl() {
            const {blob,fileName,fileType} = await this.getCloudFilePreviewoss();
            this.pdfName = fileName;
            this.getBlob(blob);
        },
        getBlob(result) {
            if(this.fileType.indexOf('txt') != -1){
                let reader = new FileReader();
                reader.readAsText(result);
                reader.onload =  (e)=>{
                    let content = e.target.result;
                    let sampleStr = content?.slice(4, 4 + content?.length / 2);
                    if (sampleStr.indexOf("�") !== -1) {
                        let reader2 = new FileReader();
                        reader2.readAsText(result,'GBK');
                        reader2.onload =  (e)=>{
                            this.txtView =  e.target.result
                        }
                    }else{
                        this.txtView = reader.result
                    }
                }
            }else{
                /*if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(result);
                    link.download = this.pdfName+'.pdf';
                    link.click();
                    //释放内存
                    window.URL.revokeObjectURL(link.href);
                }*/

                let fileURL= URL.createObjectURL(result)
                this.pdfUrl = fileURL
                result.type.indexOf('pdf') == -1 || this.init();
            }
        },
        // 水印模板
        createWatermarkTemplate() {
            const width = 250 * this.scale,
                height = 250 * this.scale;
            const canvas = this.$refs.watermark;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d"); //返回一个用于在画布上绘图的环境
            ctx.scale(this.scale, this.scale);
            ctx.clearRect(0, 0, width, height); //绘制之前画布清除

            ctx.rotate((-30 * Math.PI) / 180);
            ctx.fillStyle = "rgba(100,100,100,0.1)";
            ctx.font = "24px 黑体";
            ctx.fillText(this.waterInfo.content, -10, 130);
            ctx.font = "18px 黑体";
            ctx.fillText(this.waterInfo.name + this.waterInfo.userAccount, -10, 160);
            ctx.fillText(this.waterInfo.time, -10, 190);
            ctx.rotate("30*Math.PI/180"); //坐标系还原
        },
        // 初始化pdf
        init() {

            pdfJs.getDocument({
                url:this.pdfUrl,
                cMapUrl: "./vendor/pdfjs-dist/cmaps/",
                cMapPacked: true
            }).promise.then((pdfDoc_)=> {
                this.isLoading = false;
                this.pdfDoc = pdfDoc_;
                this.pageCount = this.pdfDoc.numPages;
                this.pageNum = 1;
                this.renderPage(this.pageNum);
            });
        },
        // 渲染pdf
        renderPage(num) {
            this.pageRendering = true;
            let canvas = this.$refs.myCanvas;
            // Using promise to fetch the page
            this.pdfDoc.getPage(num).then( (page)=> {
                var viewport = page.getViewport(this.scale);
                if(!this.w){
                    this.initWidth((viewport.width +20)+'px');
                }
                if(!this.h){
                    this.initHeight((viewport.height +10)+'px')
                }

                canvas.height = viewport.height;
                canvas.width = viewport.width;
                let ctx = canvas.getContext("2d")
                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport,
                };
                var renderTask = page.render(renderContext);
                renderTask.promise.then( ()=> {
                    ctx.fillStyle = ctx.createPattern(
                        this.$refs.watermark,
                        "repeat"
                    );
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    this.pageRendering = false;
                    if (this.pageNumPending !== null) {
                        this.renderPage(this.pageNumPending);
                        this.pageNumPending = null;
                    }
                });
            });
        },
        // 放大
        addscale() {
            if (this.scale >= this.maxscale) {
                return;
            }
            this.scale += 0.1;
            this.queueRenderPage(this.pageNum);
        },
        // 缩小
        minus() {
            if (this.scale <= this.minscale) {
                return;
            }
            this.scale -= 0.1;
            this.queueRenderPage(this.pageNum);
        },
        // 上一页
        prev() {
            if (this.pageNum <= 1) {
                return;
            }
            this.pageNum--;
            this.queueRenderPage(this.pageNum);
        },
        // 下一页
        next() {
            if (this.pageNum >= this.pageCount) {
                return;
            }
            this.pageNum++;
            this.queueRenderPage(this.pageNum);
        },
        queueRenderPage(num) {
            var number = Number(num);
            if (this.pageRendering) {
                this.pageNumPending = number;
            } else {
                this.renderPage(number);
            }
        },
        print(){

        },
        download() {
            const a = document.createElement("a");
            if (!a.click) {
                throw new Error('DownloadManager: "a.click()" is not supported.');
            }

            a.href = this.pdfUrl;
            a.target = "_parent";

            if ("download" in a) {
                a.download = this.pdfName;
            }

            (document.body || document.documentElement).appendChild(a);
            a.click();
            a.remove();
        }
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    background-color: #fff !important;
    display: flex;
    flex-direction: column;
    .pdf-container {
        flex: 1;
        height: 300px;
        overflow: auto;
        text-align: center;
        background-color: rgba(0,0,0,0.2);
        .watermark {
            display: none;
        }
    }
    .pdf-control {
        padding: 0px 16px;
        height: 50px;
        line-height: 50px;
        background: rgba(103, 103, 103, 1);
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #fff;
        img {
            vertical-align: middle;
        }
        .pdf-control-page {
            button {
                width: 24px;
                font-size: 18px;
                color: #fff;
            }
            .page-number-container {
                margin: 0px 7px;
                .page-number-input {
                    width: 50px;
                    background-color: #363636;
                    border: none;
                    border-radius: 2px;
                    padding: 2px 4px;
                    box-sizing: border-box;
                    color: #fff;
                }
            }
        }
        .pdf-control-zoom {
            button {
                width: 24px;
                font-size: 18px;
                color: #fff;
            }
            .zoom-out {
                margin-right: 12px;
            }
        }
    }
}
</style>
