<template>
    <!-- 所属金融机构（大行）放大镜 -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="name"
        filterKey="code"
        :title="$t('i18n.common.components.office.title')"
        :placeholder="placeholder || $t('i18n.common.components.office.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        @reset="handleReset"
        v-on="listeners"
        :autoSelect="autoSelect">
        <template v-slot:search>
            <f-form-item :label="$t('i18n.common.components.office.name')" prop="name">
                <f-input v-model="searchForm.name"></f-input>
            </f-form-item>
            <f-form-item :label="$t('i18n.common.components.office.code')" prop="code">
                <f-input v-model="searchForm.code"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 名称 -->
            <f-magnifier-column prop="name" :label="$t('i18n.common.components.office.name')"></f-magnifier-column>
            <!-- 编码 -->
            <f-magnifier-column prop="code" :label="$t('i18n.common.components.office.code')"></f-magnifier-column>
        </template>
    </f-magnifier-single >
</template>

<script>
import { dictionaryMix } from '@/common/comMixin';
import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';
/**
 * 单位放大镜-单选
 */
    export default {
        name: 'OfficeMagnifierSingle',
        mixins: [magnifierSingleMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    name: '', // 银行名称
                    code: '' // 银行编号
                },
            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        code: 'code',
                        financialType :'financialType'
                    }
                }
            },
            /**
             * 数据字典URL地址
             **/
            dictionariesSource:{
                type: String
            },
        },
        async created() {

        }
    }
</script>

<style lang="scss" scoped>

</style>
