/**
 * 模块请求地址统一管理
 * 注意: 导出对象中的key对应的value值只能是字符串, 不能再嵌套对象
 */
export default {
    list1: 'api/demo/list1.json', // 单行列表表格数据
    delInfo: 'api/demo/delInfo.json', // 删除列表行数据
    list2: 'api/demo/list2.json', // 多选列表数据
    list5: 'api/demo/list2.json', // 多行列表数据
    list8: 'api/demo/list8.json', // 表格空数据
    list81: 'api/demo/list81.json', // 表格空数据
    submit: 'api/demo/submit.json', // 对行数据进行提交
    form1: 'api/demo/form1.json', // 输入表单数据
    select1: 'api/demo/demoPart2/select1.json', // 模拟下拉框数据
    select2: 'api/demo/demoPart2/select2.json', // 模拟选项禁用的下拉框数据
    select12: 'api/demo/demoPart2/select12.json', // 模拟分组下拉框数据
    addForm1: 'api/demo/demoPart2/addForm.json', // 模拟新增保存
    editForm1: 'api/demo/demoPart2/editForm.json', // 模拟编辑保存
    submitForm1: 'api/demo/demoPart2/submitForm.json', // 模拟提交
    formInfo1: 'api/demo/demoPart2/submitForm.json', // 模拟提交
    singleList: 'single/api/list.json',
    queryItemUrl: 'api/demo/queryItemUrl.json', // 公共的编辑页面--折叠面板顺序查询
    fieldConfigUrl:'/api/wkfl/get-task-match-config',
    singledelete: 'single/api/delete.json',
    singlemaniquerytest: 'single/api/singlemaniquerytest.json',
    singleAdd: 'single/api/singleAdd.json',
    singleCurrency: 'single/api/queryCurrency.json',
    // reconciliationtest: '/api/demo/reconciliationtest.json', //余额调节表
    //reconciliationTablelistct: '/api/demo/reconciliationtablelistct.json', //余额调节表2*/
    reconciliationTable: '/api/demo/reconciliationTable.json', //银行已收，单位未对账单据
    reconciliationTablecont: '/api/demo/reconciliationTablecont.json', //单位已对账，银行未对账单据
    singleInfo: 'single/api/get.json',
    cascader: 'api/demo/demoPart1/cascader', // 单列联动
    magnifier: 'api/demo/demoPart1/magnifier', // 放大镜
    linkage: 'api/demo/demoPart1/linkage', // 多列联动
    province: '/api/sett/common/zoom/query-province-list', // 省查询
    city: '/api/sett/common/zoom/query-city-list', // 市查询
    formTemplate: '/api/demo/form/template', // 模板表单
    uploadTemplate: '/api/demo/upload/template', // 上传模板 表单
    fileList: 'api/demo/upload/filelist', // 上传模板 文件列表
    reconciliationtest: '/api/demo/reconciliationtest.json', //余额调节表
    reconciliationconttable: '/api/demo/reconciliationconttable.json', //余额调节表2

    //示例布局使用
    common: {
        //表单公共部分
        formItemOrder: 'api/formItemOrder.json', // 公共的查询表单--查询表单项顺序
        formItemOrderSave: 'api/formItemOrderSave.json', // 公共的查询表单--表单项顺序设置保存
        queryFormList: 'api/common/queryFormList.json', // 公共的查询表单--查询已保存查询条件列表
        queryFormSave: 'api/common/queryFormSave.json', // 公共的查询表单--查询条件保存
        queryFormDel: 'api/common/queryFormDel.json', // 公共的查询表单--查询条件删除
        getToken: '/api/fsmc/common/token/getToken', // 公共获取token
        //当前位置公共请求
        breadCrumb: 'api/common/breadCrumb', //当前位置公共请求
        //表格公共请求
        tableColumnsSave: 'api/common/tableColumnsSave.json', // 公共的查询列表--列表列配置保存
        tableColumns: 'api/common/tableColumns.json' // 公共的查询列表--列表列配置查询
    },
    comUpload: {
        initFile: '/api/fsmc/attach-type-set/query-attach-type-set-info',//初始化附件上传配置
    },
    queryTable: {
        tableListUrl: 'api/demo/list1.json',
        saveUrl: '/api/demo/acct-open-apply' //新增账户开户申请
    },

    demoPart1: {
        linkageTable: '/api/demo/demoPart1/table/linkage'
    }
};
