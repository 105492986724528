<template>
    <div :class="[`${tableType}-table-wrap`, bigData ? 'is-bigdata' : '', tableFixed ? 'is-tablefixed' : '']">
        <f-popover popper-class="custom-pop" ref="custom" placement="bottom" v-model="customVisible">
            <com-table-custom
                ref="tableCustom"
                v-if="custom"
                :tableColumns="tableColumns"
                @confirm="customConfirm"
                @cancel="customCancel"
                @reset="customReset"
                :isQueryTable="true"
            >
            </com-table-custom>
        </f-popover>
        <edit-dialog v-if="dialogConfig" :type="dialogType" :visible.sync="dialogVisible" :dialog-config="dialogConfig">
            <template v-for="item in dialogConfig.formItems">
                <slot
                    :name="'dialog' + item.name"
                    :slot="'dialog' + item.name"
                    v-bind:item="item"
                    v-if="item.propsConfig && item.propsConfig.visible"
                ></slot>
            </template>
            <slot v-if="!dialogConfig.formItems.length" name="dialog" slot="dialog"></slot>
        </edit-dialog>
        <f-form :disabled="disabled">
        <div v-if="toolbars" style="height: 46px;">
            <vxe-toolbar
                v-bind="toolbars"
                :custom="false"
                ref="tableToolbar"
                :class="{ 'is-toolbar-fixed': fixedToolbar }"
            >
                <template v-slot:buttons>
                    <slot name="buttons">
                        <f-button
                            class="toolbars-btn"
                            v-for="(btn, index) in buttons"
                            v-hide-btn="btn.vHideBtn || ''"
                            :key="'toolbarButton' + index"
                            size="mini"
                            v-bind="btn"
                            @click="toolbarClick(btn, $event)"
                        >
                            {{ btn.name }}
                        </f-button>
                    </slot>
                </template>
                <template v-slot:tools>
                    <div class="toolbars-tools">
                        <slot name="tools"></slot>
                    </div>
                    <f-tooltip
                        effect="dark"
                        :content="$t('i18n.common.components.comQueryTable.exportTipText1')"
                        placement="bottom"
                    >
                        <!-- localExport 优先级比exportConfig高 -->
                        <f-form v-if="!localExport && exportConfig" style="padding: 0;margin:0;">
                            <f-button
                                class="toolbars-icon-btn toolbars-export-btn"
                                icon="proicon icon-export toolbars-export-icon"
                                size="mini"
                                circle
                                @click="openExport"
                            >
                            </f-button>
                        </f-form>
                        <f-form v-if="localExport" style="padding: 0;margin:0;">
                            <f-button
                                class="toolbars-icon-btn toolbars-export-btn"
                                icon="proicon icon-export toolbars-export-icon"
                                size="mini"
                                circle
                                @click="exportTable"
                            >
                            </f-button>
                        </f-form>
                    </f-tooltip>
                    <f-tooltip
                        effect="dark"
                        :content="$t('i18n.common.components.comQueryTable.tableSettingTipText1')"
                        placement="bottom"
                    >
                        <f-button
                            v-if="custom"
                            class="toolbars-icon-btn toolbars-custom-btn"
                            icon="el-icon-setting toolbars-custom-icon"
                            size="mini"
                            circle
                            v-popover:custom
                        ></f-button>
                    </f-tooltip>
                    <com-table-export
                        v-if="exportConfig"
                        ref="tableExport"
                        v-bind="exportConfig"
                        :exportUrl="exportConfig.exportUrl"
                        :tableColums="exportTableColumns"
                        :tablePage="tablePage"
                        :pagination="pagination"
                        :keyNames="keyNames"
                        :tableUrl="exportTableUrl"
                    >
                    </com-table-export>
                </template>
            </vxe-toolbar>
        </div>
        <div ref="tableListWrap" @mouseenter="handleTableMouseenter" @mouseleave="handleTableMouseout">
            <vxe-table
                ref="tableList"
                :keep-source="tableType === 'edit'"
                border
                :show-overflow="showOverflow"
                highlight-hover-row
                resizable
                :data="tableData"
                v-bind="$attrs"
                :height="curHeight"
                :size="size"
                :scroll-y="scrollY"
                :stripe="stripe"
                :checkbox-config="checkboxConfigDef"
                :seq-config="{ startIndex: this.seqStartIndex() }"
                :radio-config="tableRadioConfig"
                :sort-config="{ sortMethod: initSort }"
                :edit-config="tableType === 'edit' ? tableEditConfig : null"
                v-on="listeners"
                :class="{ 'is-header-fixed': fixedHeader }"
                @resizable-change="resizableChange"
            >
                <slot></slot>
            </vxe-table>
            <div
                ref="tableScrollCopyWrap"
                v-show="fixedFooter && tableHover"
                class="table-wrap-width-copy"
                @scroll="handleTableScrollCopyScroll"
            >
                <div ref="tableScrollCopy" class="table-full-width-copy"></div>
            </div>
        </div>
        <div style="height: 36px;" v-if="pagination || isTableInfo">
            <div ref="tableFooter" :class="['table-footer', fixedFooter ? 'is-footer-fixed' : '']">
                <div v-if="isTableInfo" class="table-info">
                    <slot name="tableInfo"></slot>
                </div>
                <vxe-pager
                    v-if="pagination"
                    ref="tablePager"
                    border
                    :current-page="tablePage.currentPage"
                    :page-size="tablePage.pageSize"
                    :page-sizes="tablePage.pageSizes"
                    :total="tablePage.total"
                    align="right"
                    :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                    @page-change="handlePageChange"
                ></vxe-pager>
            </div>
        </div>
        </f-form>
    </div>
</template>
<script>
import ComTableCustom from './com-table-custom';
import './com-table-style.scss';
export default {
    inheritAttrs: false,
    components: { ComTableCustom }
};
</script>
