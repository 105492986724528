export default {

    //基准利率新增
    benchmarkInterestUrl:'/api/fsmc/api/fsmcRate/rateAdd/query-rate-add-by-page', //基准利率新增-列表分页查询
    queryRateAddByIdUrl:'/api/fsmc/api/fsmcRate/rateAdd/query-rate-add/-by-/', //基准利率新增-通过id查询单个对象
    addRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-add', //基准利率新增-新增保存
    addSubmitRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-add-submit', //基准利率新增-新增提交
    updateRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-update', //基准利率新增-更新保存
    updateSubmitRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-update-submit', //基准利率新增-更新提交
    deleteRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-delete', //基准利率新增-删除按钮
    submitRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-submit', //基准利率新增-提交按钮
    approvalRateAddUrl:'/api/fsmc/api/fsmcRate/rateAdd/rate-add-approval', //基准利率新增-审批同意/拒绝
    benchmarkRuleUrl:'/api/fsmc/api/fsmcRate/rateAdd/get/rule-code', //基准利率新增-新增规则获取code值

    queryApprovalHistory:'/api/fsmc/common/wkfl/quey-approval-history',//审批历史信息

    //基准利率类型
    queryRateTypeByPageUrl:'/api/fsmc/data/rateType/query-rate-type-by-page', //基准利率类型-列表分页查询
    queryRateTypeByIdUrl:'/api/fsmc/data/rateType/query-rate-type/-by-/', //基准利率类型-通过id查询单个对象
    addRateTypeUrl:'/api/fsmc/data/rateType/rate-type-add', //基准利率类型-新增
    updateRateTypeUrl:'/api/fsmc/data/rateType/rate-type-update', //基准利率类型-更新
    deleteRateTypeUrl:'/api/fsmc/data/rateType/rate-type-delete', //基准利率类型-删除
    queryOrgStructUrl:'/api/fsmc/data/rateType/query-base-rate-list', //基准利率类型-查询基准利率类型下拉框
    queryOrgStructCurrencyUrl:'/api/fsmc/api/fsmcRate/rate/query-base-currency-list', //基准利率-查询币种下拉框
    benchmarkTypeRuleUrl:'/api/fsmc/data/rateType/get/rule-code', //基准利率类型-新增规则获取code值


    //基准利率变更申请
    queryRateChangeByPageUrl:'/api/fsmc/api/fsmcRate/rateChange/query-rate-change-by-page', //基准利率变更申请-列表分页查询
    queryRateChangeByIdUrl:'/api/fsmc/api/fsmcRate/rateChange/query-rate-change/-by-/', //基准利率变更申请-根据id查单个对象
    addRateChangeUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-add', //基准利率变更申请-新增保存
    addSubmitRateChangeUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-add-submit', //基准利率变更申请-新增提交
    updateRateChangeUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-update', //基准利率变更申请-更新
    updateSubmitRateChangeUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-update-submit', //基准利率变更申请-更新提交
    deleteRateChangeUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-delete', //基准利率变更申请-删除
    benchmarkChangeRuleUrl:'/api/fsmc/api/fsmcRate/rateChange/get/rule-code', //基准利率变更-新增规则获取code值
    rateChangeSubmitUrl:'/api/fsmc/api/fsmcRate/rateChange/rate-change-submit', //基准利率变更-列表按钮-提交

    //基准利率查询
    queryRateByPageUrl:'/api/fsmc/api/fsmcRate/rate/query-rate-by-page', //基准利率查询-列表分页查询
    queryRateByIdUrl:'/api/fsmc/api/fsmcRate/rate/query-rate/-by-/', //基准利率查询-根据id查单个对象
    queryRateRecordByPageUrl:'/api/fsmc/api/fsmcRate/rate/query-rate-record-by-page', //基准利率查询-查看页面列表查询
    queryCurrentRateByPageUrl:'/api/fsmc/api/fsmcRate/rate/query-current-rate-by-page', //基准利率查询-基准利率放大镜

    //利率计划新增
    queryRatePlanAddByPageUrl:'/api/fsmc/ratePlan/ratePlanAdd/query-rate-plan-add-by-page', //利率计划新增-列表分页查询
    queryRatePlanAddByIdUrl:'/api/fsmc/ratePlan/ratePlanAdd/query-rate-plan-add/-by-/', //利率计划新增-根据id查单个对象
    addRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-add', //利率计划新增-新增
    updateRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-update', //利率计划新增-更新
    deleteRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-delete', //利率计划新增-删除
    submitRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-submit', //利率计划新增-提交
    addSubmitRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-add-submit', //利率计划新增-新增提交
    updateSubmitRatePlanAddUrl:'/api/fsmc/ratePlan/ratePlanAdd/rate-plan-add-update-submit', //利率计划新增-更新提交
    queryRatePlanDetailListUrl:'/api/fsmc/ratePlan/ratePlanDetail/query-rate-plan-detail-list', //利率计划新增-分档利率设置列表查询
    benchmarkRatePlanRuleUrl:'/api/fsmc/ratePlan/ratePlanAdd/get/rule-code', //利率计划新增-新增规则获取code值

    //利率计划变更
    queryRatePlanChangeByPageUrl:'/api/fsmc/ratePlan/ratePlanChange/query-rate-plan-change-by-page', //利率计划变更-列表分页查询
    queryRatePlanChangeByIdUrl:'/api/fsmc/ratePlan/ratePlanChange/query-rate-plan-change/-by-/', //利率计划变更-根据id查单个对象
    addRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-add', //利率计划变更-新增
    addSubmitRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-add-submit', //利率计划变更-新增提交
    updateRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-update', //利率计划变更-更新
    updateSubmitRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-update-submit', //利率计划变更-更新提交
    deleteRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-delete', //利率计划变更-删除
    submitRatePlanChangeUrl:'/api/fsmc/ratePlan/ratePlanChange/rate-plan-change-submit', //利率计划变更-提交
    benchmarkRateChangeRuleUrl:'/api/fsmc/ratePlan/ratePlanChange/get/rule-code', //利率计划变更-新增规则获取code值

    //利率计划查询
    queryRatePlanByPageUrl:'/api/fsmc/ratePlan/ratePlan/query-rate-plan-by-page', //利率计划查询-列表分页查询
    queryRatePlanChooseByPageUrl:'/api/fsmc/ratePlan/ratePlan/query-rate-plan-choose-by-page', //利率计划查询-选择共享模板分页查询
    queryRatePlanByIdUrl:'/api/fsmc/ratePlan/ratePlan/query-rate-plan/-by-/', //利率计划查询-根据id查单个对象
    ratePlanShareUrl:'/api/fsmc/ratePlan/ratePlan/rate-plan-share', //利率计划查询-复制利率计划
    queryRatePlanRecordByPageUrl:'/api/fsmc/ratePlan/ratePlanRecord/query-rate-plan-record-by-page', //利率计划变更记录查询-列表分页查询
    queryRatePlanRecordByIdUrl:'/api/fsmc/ratePlan/ratePlanRecord/query-rate-plan-record/-by-/', //利率计划变更记录查询-根据id查单个对象

    //查询有权限的机构列表
    queryOrgStructWithDataAuthUrl:'/api/fsmc/ratePlan/ratePlan/query-orgstruct-with-dataauth-accord-page',
}
