import { childNavMix } from '@/common/comMixin';
import XEUtils from 'xe-utils';
import { dictionaryMix } from '@/common/comMixin';
export default {
    mixins: [childNavMix, dictionaryMix],
    inject: ['scrollApp'],
    data() {
        let { ...listeners } = this.$listeners;
        return {
            /**
             * 前后端交互的字段名配置,
             * 当后端字段名有变动时,改这里的配置即可
             */
            keyNames: {
                currentPage: 'start',
                total: 'total',
                pageSize: 'limit',
                rows: 'rows'
            },
            /**
             * table数据存放
             */
            tableData: [],
            /**
             * table分页默认配置
             */
            tablePage: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100 , 500]
            },
            /**
             * 复选框默认配置
             */
            checkboxConfigDef: {
                trigger: 'row',
                highlight: true
            },
            /**
             * 用户自定义表格配置
             */
            customVisible: false,
            /**
             * 列表默认的列配置
             */
            tableColumnsDefaultObj: {},
            /**
             * 表格最终渲染的列数据
             */
            tableColumns: [],
            // 表格的单选配置
            tableRadioConfig: {},
            listeners: listeners,
            // 表格分页是否为固定状态
            fixedPage: false,
            fixedFooter: false,
            // 表格工具栏是否为固定状态
            fixedToolbar: false,
            // 表格头部是否为固定状态
            fixedHeader: false,
            // 鼠标时候浮在表格上
            tableHover: false,
            scrollCopyShow: false,
            notifyVm: null, // 表格宽度保存提示框
            noNotify: false,
            // scrollPage:0,
            observer: null,
            curHeight: null, // 表格高度
            queryFormHeight: 0, //查询条件高度
            comQueryListPageHeight: 0, //页面高度
            tableExpandColumns: [], // 存放表格扩展字段的值
            currentTableColumnsDefault: [], // 加上表格扩展字段的默认的列配置
            tableExpandLoaded: false, // 扩展字段是否请求完成
            alreadyInitTable: false, // 表格是否已经初始化完成
            dictionariesConfig: [], //需要的数据字典的参数
        };
    },
    props: {
        dialogConfig: Object,
        noListPage: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        scrollY: {
            type: Object,
            default: () => {
                return {
                    gt: 0,
                    enabled: false
                };
            }
        },
        /**
         * 源数据, 可以配置为请求地址, 也可以配置成为具体的数据
         * pagination=false && dataSource为具体的数据时, dataSource的类别为Array
         * pagination=true && dataSource为具体的数据时, dataSource的类别为Object,其中必须包含{rows, total}这两个字段
         */
        dataSource: {
            type: [Array, String, Object],
            default: () => []
        },
        /**
         * 请求发送到后台的数据
         * 仅在dataSource配置为String的时候有效
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 表格工具栏按钮配置
         */
        buttons: Array,
        /**
         * 表格动态列默认配置
         */
        tableColumnsDefault: Array,
        /**
         * 是否显示分页
         * true显示分页
         * false不显示分页
         */
        pagination: {
            type: Boolean,
            default: true
        },
        pagerCount: {
            type: Number,
            default: 7
        },
        /**
         * 复选框配置
         */
        checkboxConfig: Object,
        height: {
            type: [Number, String],
            required: false,
            default: null
        },
        size: {
            type: String,
            required: false,
            default: 'mini'
        },
        stripe: {
            type: Boolean,
            required: false,
            default: true
        },
        // vxe-toolbar的自带功能，可以通过该属性进行暴露
        toolbars: {
            type: [Object, Boolean],
            default: () => {
                return {
                    export: false
                };
            }
        },
        custom: {
            type: [Boolean, Object],
            required: false,
            default: true
        },
        /**
         * 导出表格数据的配置
         * export: {
         *      exportUrl: '/api/export' // 导出的url地址
         * }
         */
        exportConfig: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        /**
         * 组件编号
         */
        customComponentsCode: {
            type: String
        },
        // table列配置保存地址
        tableColumnsSaveUrl: String,
        // table列配置查询地址
        tableColumnsUrl: String,
        /**
         * 重新封装单选的配置, 匹配data.rows[checkField]==true的值为选中状态
         * 继承了vxetable中radioConfig中除了checkRowKey的其他的所有配置项
         */
        radioConfig: Object,
        // table的表头和分页是否随滚动条一起浮动
        tableFixed: {
            type: Boolean,
            default: false
        },
        /**
         * 初始化不加载数据, 只有用户点击查询的时候才加载数据
         */
        lazy: Boolean,
        /**
         * 主要为了处理当一个页面有多个查询表单时, 标记table与表单一一对应的属性
         * 未设置时, 说明只有1个查询表单
         */
        withQueryFormName: String,
        /**
         * 是否有查询表单
         */
        isQueryForm: {
            type: Boolean,
            default: true
        },
        /**
         * 数据请求后处理方法
         */
        parseDataFn: Function,
        /**
         * 本地导出excel表格
         */
        localExport: Boolean,
        bigData: Boolean, // 是否为大数据表格--true时启用虚拟滚动
        isTableInfo: Boolean, // 是否显示表格汇总信息区域
        transferData: Function, // 对请求回的源数据进行转换的方法
        /**
         * 查询表格扩展字段要额外传入的参数
         */
        expandParam: {
            type: Object
        },
        tableExpandParamUrl: {
            // 表格扩展字段地址
            type: String,
            default: ''
        },
        dictionariesUrl: String, // // 字典url
    },
    computed: {
        exportTableUrl() {
            if (this.exportConfig && this.exportConfig.tableUrl) {
                return this.exportConfig.tableUrl;
            } else if (typeof this.dataSource == 'string') {
                return this.dataSource;
            } else {
                return '';
            }
        },
        exportTableColumns() {
            if (this.exportConfig && this.exportConfig.tableColumns) {
                return this.exportConfig.tableColumns;
            } else {
                return this.tableColumns;
            }
        }
    },
    watch: {
        tableColumnsDefault: {
            immediate: true,
            handler(val) {
                this.doTableColumnsInit();
            }
        },
        tableExpandLoaded: {
            handler(val) {
                this.doTableColumnsInit();
            }
        },
        tableColumns: {
            handler(value) {
                this.$nextTick(() => {
                    this.$refs.tableList.loadColumn(value.filter(item => !(item.exist === false))).then(() => {
                        // 这里表格列,表格数据均渲染完成, 调用一次scrollHandler
                        if (!this.bigData && this.tableFixed) {
                            this.scrollHandler();
                        }
                    });
                });
            }
        },
        radioConfig: {
            handler(val) {
                this.tableRadioConfig = Object.assign({}, val, {
                    checkRowKey: ''
                });
            },
            deep: true
        },
        /**
         * dataSource变化时对表格数据进行更新
         */
        dataSource: {
            handler(val)
            {
                if (!this.lazy) {
                    this.$nextTick(() => {
                        this.getTableData();
                    });
                }
            }
        },
        tableData: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    // 这里表格数据变化时, 调用一次scrollHandler
                    if (this.tableFixed && !this.bigData) {
                        this.scrollHandler();
                    }
                });
            }
        }
    },
    created() {
        this.getTableExpandData();
        if (!this.isQueryForm) {
            if (!this.lazy) {
                this.$nextTick(() => {
                    this.getTableData();
                });
            }
        }
        if (this.bigData) {
            //大数据下分页增加显示条数
            this.tablePage.pageSizes = [50, 100, 200, 300, 500, 1000];
            this.tablePage.pageSize = 50;
        }
        this.init();
    },
    mounted() {
        if (this.bigData && this.tableFixed) {
            this.showOverflow = true;
            this.initBigDataFixedTable();
        } else if (this.tableFixed) {
            this.initDefaultFixedTable();
        } else if (this.bigData) {
            this.showOverflow = true;
            this.initBigDataTable();
        } else {
            this.curHeight = this.height;
        }
    },
    beforeDestroy() {
        if (this.scrollHandler && this.tableFixed) {
            this.$bus.$off('appScroll', this.scrollHandler);
            document.body.removeEventListener('resize', this.scrollHandler);
            window.removeEventListener('resize', this.scrollHandler);
            // window.removeEventListener('mousewheel', this.handleScroll);
            // window.removeEventListener('DOMMouseScroll', this.handleScroll);
        }
        /***********新版滚动  观察器 start*********/
        //销毁观察器
        if (this.observer) {
            this.observer.disconnect();
            this.observer.takeRecords();
            this.observer = null;
        }
        /***********新版滚动  观察器 end*********/
    },
    methods: {
        /**
         * 普通表格处理
         */
        initDefaultFixedTable() {
            this.$nextTick(() => {
                if (this.tableFixed) {
                    this.$bus.$on('appScrollTable', this.scrollHandler);
                    document.body.addEventListener('resize', this.scrollHandler, false);
                    window.addEventListener('resize', this.scrollHandler, false);
                }
            });
        },
        /**
         * 大数据表格处理--不处理表格固定
         */
        initBigDataTable() {
            this.scrollY.enabled = true;
            this.curHeight = this.height !== null ? this.height : 260;
        },
        /**
         * 大数据表格处理--需要处理表格固定
         */
        initBigDataFixedTable() {
            this.fixedFooter = true;
            this.curHeight = this.height !== null ? this.height : document.body.clientHeight - 190;
            this.scrollY.enabled = true;
            /***********新版滚动  start*********/
            //外层滚动新控制器
            this.$bus.$on('appScrollTable', this.scrollTableSync);
            let table = this.$el;

            let _this = this;

            //表格控制表单滚动
            table.onmousewheel = function(e) {
                // console.log(e.clientY,e.deltaY,e.layerY,e.pageY,e.screenY,e.y);
                let scrollbarEl = _this.scrollApp.$refs.scrollbar.wrap;
                let scrollRestoration = document.querySelector('.vxe-body--y-space').clientHeight / 90;
                if (e.deltaY > 0) {
                    scrollbarEl.scrollTop = scrollbarEl.scrollTop + scrollRestoration;
                    //console.log('table-onmousewheel', 'down', scrollRestoration, scrollbarEl.scrollTop);
                }
                if (e.deltaY < 0) {
                    scrollbarEl.scrollTop = scrollbarEl.scrollTop - scrollRestoration;
                    //console.log('table-onmousewheel', 'up', scrollRestoration, scrollbarEl.scrollTop);
                }
                let beforScrollTop = scrollbarEl.scrollTop;
                _this.scrollTableSync(e);
            };
            /**
             * 观察器
             * 处理表格高度变化改变表单高度变化
             * *************/
            let MutationObserver =
                window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            let element = document.querySelector('.vxe-body--y-space');
            /* let queryFormHeight = document.querySelector('.query-form-wrap');
            this.queryFormHeight = queryFormHeight ? queryFormHeight.clientHeight : 230;
            console.log('queryFormHeight',this.queryFormHeight,element.height,element)
            ;*/
            this.observer = new MutationObserver(objList => {
                // +200高度是查询条件的高度
                document.querySelector('.com-query-list').style.cssText =
                    'height:' +
                    (element.clientHeight + 200) +
                    'px;' +
                    'min-height:' +
                    (document.body.clientHeight - 60) +
                    'px';
            });
            this.observer.observe(element, { attributes: true, childList: true, subtree: true });

            /******** end *****/
        },
        /***********新版滚动 表单同步表格滚动 start*********/
        scrollTableSync(e) {
            let comQueryList = document.querySelector('.com-query-list');
            let { top } = comQueryList.getBoundingClientRect();
            let _table = this.$refs.tableList;
            if (_table) {
                _table.scrollTo(_table.getScroll().scrollLeft,top * -1);
            }
            this.updateTableHeight();
        },
        updateTableHeight() {
            let comQueryList = document.querySelector('.com-query-list');
            let { top } = comQueryList.getBoundingClientRect();
            let queryForm = document.querySelector('.query-form-wrap');
            let _newTop = top * -1;
            let padding = 30;
            let queryFormHeight = queryForm.clientHeight + padding;
            //console.log(top * -1, queryFormHeight,this.queryFormHeight);
            let element = document.querySelector('.vxe-body--y-space');

            if (queryFormHeight != this.queryFormHeight || this.comQueryListPageHeight != element.scrollHeight) {
                this.queryFormHeight = queryFormHeight;
                this.comQueryListPageHeight = element.scrollHeight;
                comQueryList.style.cssText = `height:${this.queryFormHeight + element.scrollHeight + 100}px`;
                //console.log('element',element.scrollHeight,queryFormHeight);
            }

            let tbTop = top * -1 < queryFormHeight ? queryFormHeight - top * -1 : 0;
            this.$el.style.cssText = `position: fixed;left:${24}px;right:${24}px;top:${tbTop}px`;

            let max = document.body.clientHeight - 90,
                min = document.body.clientHeight - 190,
                srollH = document.body.clientHeight - 210 + (_newTop < 0 ? 0 : _newTop);
            if (srollH < min) {
                this.curHeight = min;
                //console.log('---------------------------------------------');
            } else if (srollH > max) {
                this.curHeight = max;
            } else {
                this.curHeight = srollH;
            }
            if (_newTop <= 0) {
                this.$el.style.cssText = 'position: static;';
            }
            /*console.log(
                `------------${srollH > min}---------${srollH < max}--------#${
                    _newTop < 0 ? 0 : _newTop
                }----------------`
            );
            console.log('heigt:', this.curHeight, min, max, srollH);
            */
        },
        /***********新版滚动  表单同步表格 end*********/
        init() {
            this.code = this.customComponentsCode ? this.customComponentsCode : '';
            if (!this.$slots.default || this.$slots.default.length == 0) {
                this.initTableColumns();
            }
            /**
             * 继承vxe-table的复选框配置属性
             */
            if (this.checkboxConfig) {
                this.checkboxConfigDef = Object.assign(this.checkboxConfigDef, this.checkboxConfig);
            }
        },
        /**
         * 初始化table列相关数据
         */
        initTableColumns() {
            let currentTableColumnsDefault = this.currentTableColumnsDefault;
            if (this.custom && this.tableColumnsUrl) {
                if (currentTableColumnsDefault && currentTableColumnsDefault.length) {
                    let tableColumnsDefaultObj = {};
                    currentTableColumnsDefault.forEach(item => {
                        if (item.field) {
                            tableColumnsDefaultObj[item.field] = item;
                        } else if (item.type) {
                            tableColumnsDefaultObj[item.type] = item;
                        }
                    });
                    this.tableColumnsDefaultObj = tableColumnsDefaultObj;
                    this.getTableColumns();
                }
            } else {
                this.tableColumns = [].concat(currentTableColumnsDefault);
            }
        },
        initSort(table) {
            table.data.sort((prev, next) => {
                let v1 = prev[table.property];
                let v2 = next[table.property];
                if (table.order == 'asc') return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
                else {
                    return v2 < v1 ? -1 : v2 > v1 ? 1 : 0;
                }
            });
            return table.data;
        },
        /**
         * 获取表格数据
         */
        getTableData() {
            if (typeof this.dataSource == 'string') {
                // 当dataSource配置为字符串时,发送请求
                let param = {};
                if (this.pagination) {
                    param = Object.assign({}, this.extraData, {
                        [this.keyNames.currentPage]: this.tablePage.currentPage,
                        [this.keyNames.pageSize]: this.tablePage.pageSize
                    });
                } else {
                    param = this.extraData;
                }
                if (this.dataSource) {
                    this.$http.post(this.dataSource, param).then(res => {
                        if (res.code == 0) {
                            if (this.transferData) {
                                this.tableData = this.transferData(
                                    this.pagination ? res.data[this.keyNames.rows] : res.data
                                );
                            } else {
                                this.tableData = this.pagination ? res.data[this.keyNames.rows] : res.data;
                            }
                            this.tableData = this.parseDataFn ? this.parseDataFn(this.tableData) : this.tableData;
                            // 设置radio的默认选中
                            this.setRadioDefault();
                            this.pagination && (this.tablePage.total = res.data.total);
                            this.$emit('afterCompletion', res.data);
                        } else {
                            this.$error(res.msg);
                        }
                    });
                }
            } else {
                // 当dataSource为对象时,直接赋值tableData
                if (this.pagination) {
                    this.tableData = this.dataSource[this.keyNames.rows];
                    // 设置radio的默认选中
                    this.setRadioDefault();
                    this.tablePage.total = this.dataSource[this.keyNames.total];
                } else {
                    // 当没有分页时, dataSource为数组
                    this.tableData = this.dataSource;
                }
            }
        },
        setRadioDefault() {
            if (this.radioConfig && this.radioConfig.checkField) {
                // 设置默认的选中项
                for (let i = 0; i < this.tableData.length; i++) {
                    const row = this.tableData[i];
                    if (row[this.radioConfig.checkField]) {
                        this.$refs.tableList.setRadioRow(row);
                        return;
                    }
                }
            }
        },
        /**
         * 获取后台已经保存的列数据
         * 1.如果请求成功这里将按请求返回的值进行渲染
         * 2.请求失败或者没有保存过顺序数据是按tableColumnsDefault的顺序进行渲染
         */
        getTableColumns() {
            this.$http
                .post(this.tableColumnsUrl, {
                    code: this.code
                })
                .then(res => {
                    // 重新渲染
                    if (res.code == 0 && this.validTableColumnsData(res.data.customOrder)) {
                        this.setTableColumns(res.data.customOrder);
                    } else {
                        this.setTableColumns(this.currentTableColumnsDefault);
                    }
                })
                .catch(() => {
                    this.setTableColumns(this.currentTableColumnsDefault);
                });
        },
        /**
         * 验证返回的自定义配置数据与组件代码中定义的组件数据是否完全匹配
         * 完全匹配则返回true
         * 有1个不匹配则返回false
         */
        validTableColumnsData(data) {
            if (!data || data.length !== this.currentTableColumnsDefault.length) {
                return false;
            }
            let flag = false;
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (!this.tableColumnsDefaultObj[item.field] && !this.tableColumnsDefaultObj[item.type]) {
                    break;
                } else if (i === data.length - 1) {
                    flag = true;
                }
            }
            return flag;
        },
        /**
         * 设置table列配置
         */
        setTableColumns(columns) {
            this.tableColumns = columns.map(item => {
                // 这里需要将item中的title剔除
                let obj = {
                    field: item.field,
                    fixed: item.fixed,
                    type: item.type,
                    visible: item.visible,
                    exist: item.exist
                };
                if (item.width) {
                    obj.width = item.width;
                }
                if (item.field) {
                    return Object.assign({}, this.tableColumnsDefaultObj[item.field] || {}, obj);
                } else if (item.type) {
                    return Object.assign({}, this.tableColumnsDefaultObj[item.type] || {}, obj);
                } else {
                    return obj;
                }
            });
        },
        /**
         * 表格列设置--确定按钮事件
         * 1.保存当前更改后的列数据
         * 2.重新向后台获取table列数据
         * 3.保存完成后关闭pop层
         * @param flag boolean 是否提示操作成功
         */
        customConfirm(columns, flag = true) {
            if (!this.tableColumnsSaveUrl) {
                console.log('表格列设置保存地址不存在!');
                return;
            }
            let saveTableColumns = columns.map(item => {
                return {
                    field: item.field,
                    fixed: item.fixed,
                    type: item.type,
                    visible: item.visible,
                    width: item.width || '',
                    exist: item.exist
                };
            });
            this.$http
                .post(
                    this.tableColumnsSaveUrl,
                    JSON.stringify({
                        code: this.code,
                        tableColumns: saveTableColumns
                    }),
                    {
                        loading: flag
                    }
                )
                .then(res => {
                    if (res.code == 0) {
                        if (flag) {
                            this.$success(this.$t('i18n.common.success'));
                            // 重新请求已经保存的列表列配置数据
                            this.getTableColumns();
                        }
                    } else {
                        this.$error(res.msg);
                    }

                    // 关闭pop成
                    this.customVisible = false;
                });
        },
        customCancel() {
            // 这里直接关闭pop
            this.customVisible = false;
        },
        /**
         * 表格列数据重置到默认状态
         */
        customReset() {
            // 这里重置列设置中的数据到默认的状态
            this.$refs.tableCustom.setCurrentColumns(this.currentTableColumnsDefault);
        },
        /**
         * 刷新表格
         * @type 'click': 描述click事件触发的查询  'init': 描述的是js调用触发的查询
         */
        reload(type = 'click') {
            if (this.lazy && type === 'init') {
                return;
            }
            // 分页的情况才需要重置当前页码数
            if (this.pagination) {
                this.tablePage.currentPage = 1;
            }
            this.getTableData();
        },
        /**
         * 分页事件
         */
        handlePageChange({ currentPage, pageSize, type, $event }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.getTableData();
        },
        /**
         * 重写工具栏按钮点击事件
         */
        toolbarClick(btn) {
            this.$emit('buttonClick', btn);
        },
        /**
         * 返回动态序号
         */
        seqStartIndex() {
            if (this.pagination) {
                return (this.tablePage.currentPage - 1) * this.tablePage.pageSize;
            } else {
                return 0;
            }
        },
        /**
         * 获取table组件
         */
        getTable() {
            return this.$refs.tableList;
        },
        /**
         * 获取table工具栏组件
         */
        getTableToolbar() {
            return this.$refs.tableToolbar;
        },
        /**
         * 获取table分页组件
         */
        getTablePager() {
            return this.$refs.tablePager;
        },
        /**
         * 此方法只提供一个接口，导出功能需要自己实现
         */
        exportXls(all = false) {
            if (typeof this.dataSource == 'string') {
                let param = {};
                if (!all && this.pagination) {
                    param = Object.assign({}, this.extraData, {
                        [this.keyNames.currentPage]: this.tablePage.currentPage,
                        [this.keyNames.pagesize]: this.tablePage.pageSize
                    });
                } else {
                    param = this.extraData;
                }
                this.$emit('exportXls', { url: this.dataSource, data: param });
            }
        },
        openExport() {
            this.$refs.tableExport.open();
        },
        exportTable() {
            const attrs = this.$attrs;
            let exColType = ['checkbox', 'radio'];
            // this.getExportGroup(this.tableColumns), // 此处可以自定义
            let columns = this.getExportGroup(this.tableColumns.filter(item => !exColType.includes(item.type)));
            this.getTable().exportData({
                filename: new Date().getTime(),
                sheetName: 'Sheet1',
                type: 'xlsx',
                columns,
                useStyle: true,
                sheetMethod({ options, worksheet }) {
                    let { data, columns, colgroups } = options;
                    let start = colgroups.length;
                    let mergeCells = [];
                    data.forEach((row, $rowIndex) => {
                        columns.forEach((column, $columnIndex) => {
                            let position =
                                attrs['span-method'] &&
                                attrs['span-method']({ row, $rowIndex, column, $columnIndex, data });
                            if (position && (position.rowspan || position.colspan)) {
                                mergeCells.push({
                                    s: { r: $rowIndex + start + 1, c: $columnIndex + 1 },
                                    e: {
                                        r: position.rowspan + $rowIndex + start,
                                        c: position.colspan + $columnIndex
                                    }
                                });
                            }
                        });
                    });
                    mergeCells.forEach(item => {
                        let { s, e } = item;
                        worksheet.mergeCells(s.r, s.c, e.r, e.c);
                    });
                }
            });
        },
        // 获取表格导出数据
        getExportGroup(customCols) {
            return XEUtils.searchTree(customCols, item => item, {
                children: 'children',
                mapChildren: 'childNodes',
                original: true
            });
        },
        scrollHandler(event) {
            this.footerFixedFun(event);
            this.toolbarFixedFun(event);
            this.headerFixedFun(event);
        },
        footerFixedFun(event) {
            if (!this.pagination) {
                return;
            }
            const { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            this.fixedFooter =
                bottom > document.documentElement.clientHeight && top + 36 <= document.documentElement.clientHeight;
            this.$refs.tableFooter.style.left = this.fixedFooter ? `${left}px` : '0';
            this.$refs.tableFooter.style.width = this.fixedFooter ? `${right - left}px` : 'auto'
            let mainBody = this.$refs.tableList.$el.querySelector('.vxe-table--body-wrapper.body--wrapper');
            if(this.fixedFooter){
                mainBody.style.overflow = 'hidden';
            }else{
                mainBody.style.overflow = 'auto';
            }
        },
        headerFixedFun(event) {
            //内容头部-----
            let tableMainWrap = this.$refs.tableList.$el.querySelector('.vxe-table--main-wrapper');
            let tableMainBody = this.$refs.tableList.$el.querySelector('.vxe-table--header-wrapper.body--wrapper');
            tableMainBody.style.width = tableMainWrap.clientWidth + 'px';

            let tableListWrapClientRect = this.$refs.tableListWrap.getBoundingClientRect();
            let { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            let headerClientRect = this.$el
                .querySelector('.vxe-table--main-wrapper .vxe-table--header-wrapper')
                .getBoundingClientRect();
            let vxeTableHeaderLeft = this.$refs.tableList.$el.querySelector(
                '.vxe-table--header-wrapper.fixed-left--wrapper'
            );
            let vxeTableHeaderLeftWrap = this.$el.querySelector('.vxe-table--fixed-left-wrapper');
            let vxeTableHeaderRight = this.$refs.tableList.$el.querySelector(
                '.vxe-table--header-wrapper.fixed-right--wrapper'
            );
            let vxeTableHeaderRightWrap = this.$el.querySelector('.vxe-table--fixed-right-wrapper');
            let scrollheader = this.$el.querySelector('.vxe-table--fixed-right-wrapper');

            this.fixedHeader = tableListWrapClientRect.top - 46 <= 0;
            this.$refs.tableListWrap.style.paddingTop = this.fixedHeader
                ? `${headerClientRect.bottom - headerClientRect.top}px`
                : '0px';
            if (vxeTableHeaderLeft) {
                // 计算左边固定列的left值
                vxeTableHeaderLeft.style.left = this.fixedHeader ? `${left}px` : '0';
                // 计算左边固定列的宽度值
                vxeTableHeaderLeft.style.width = this.fixedHeader
                    ? `${vxeTableHeaderLeftWrap.style.width || 0}`
                    : 'auto';
                // 计算左边固定列的高度值
                let leftBody = this.$el.querySelector('.vxe-table--fixed-left-wrapper .vxe-table--body-wrapper');
                this.$el.querySelector('.vxe-table--fixed-left-wrapper').style.height = this.fixedHeader
                    ? `${leftBody.clientHeight || 0}px`
                    : `${leftBody.clientHeight + vxeTableHeaderLeft.clientHeight}px`;
            }
            if (vxeTableHeaderRight) {
                // 计算右边固定列的right值
                vxeTableHeaderRight.style.right = this.fixedHeader
                    ? `${document.documentElement.clientWidth - right}px`
                    : '0';
                // 计算右边固定列的宽度
                vxeTableHeaderRight.style.width = this.fixedHeader
                    ? `${vxeTableHeaderRightWrap.style.width || 0}`
                    : 'auto';
                // 计算左边固定列的高度值
                let rightBody = this.$el.querySelector('.vxe-table--fixed-right-wrapper .vxe-table--body-wrapper');
                this.$el.querySelector('.vxe-table--fixed-right-wrapper').style.height = this.fixedHeader
                    ? `${rightBody.clientHeight || 0}px`
                    : `${rightBody.clientHeight + vxeTableHeaderRight.clientHeight}px`;
                //右侧固定头部显示-----
                let vxeTableHeaderRightWrapTable = vxeTableHeaderRight.firstElementChild;
                vxeTableHeaderRightWrapTable.style.position = this.fixedHeader ? 'relative' : 'static';
                vxeTableHeaderRightWrapTable.style.left = this.fixedHeader
                    ? '-' + (vxeTableHeaderRightWrapTable.clientWidth - scrollheader.clientWidth) + 'px'
                    : 'auto';
            }
        },
        toolbarFixedFun(event) {
            if (!this.toolbars) {
                return;
            }
            this.$nextTick(()=>{
                const { top, bottom, left, right } = this.$el.getBoundingClientRect();
                this.fixedToolbar = top <= 0;
                //this.$refs.tableToolbar.$el.style.left = this.fixedToolbar ? `${left}px` : '0';
                this.$refs.tableToolbar.$el.style.width = this.fixedToolbar ? `${right - left}px` : 'auto';
            })
        },
        handleTableMouseenter() {
            this.tableHover = true;
            this.scrollCopySize();
        },
        handleTableMouseout() {
            this.tableHover = false;
        },
        handleTableScrollCopyScroll($event) {
            this.$el.querySelector('.vxe-table--main-wrapper .vxe-table--body-wrapper').scrollLeft =
                $event.target.scrollLeft;
        },
        // 浮起的滚动条
        scrollCopySize() {
            let { top, bottom, left, right } = this.$refs.tableList.$el.getBoundingClientRect();
            if (this.$refs.tableScrollCopyWrap) {
                this.$refs.tableScrollCopyWrap.style.left = `${left}px`;
                this.$refs.tableScrollCopyWrap.style.width = `${right - left - 12}px`;
                if (this.tableType === 'edit' && !this.pagination && this.noListPage) {
                    this.fixedFooter =
                        bottom + 49 > document.documentElement.clientHeight &&
                        top <= document.documentElement.clientHeight;
                    this.$refs.tableScrollCopyWrap.style.bottom = '49px';
                }
            }
            if (this.$refs.tableScrollCopy) {
                this.$refs.tableScrollCopy.style.width = this.$el.querySelector(
                    '.vxe-table--main-wrapper .vxe-table--header-border-line'
                ).style.width;
            }
        },
        resizableChange(param) {
            if (!this.custom) {
                return;
            }
            let _this = this;
            if (this.notifyVm) {
                return;
            }
            let isNoNotify = this.$store.getters['common/getIsNoNotify']();
            if (isNoNotify) {
                this.customConfirm(this.getResizeColumns(param), false);
                return;
            }

            let notify = (
                <div class="resize-notify">
                    <p class="resize-notify-text">{this.$t('i18n.common.components.comQueryTable.cloumnChange')}</p>
                    <f-checkbox
                        size="mini"
                        border={true}
                        on-change={v => {
                            _this.noNotify = v;
                        }}
                    >
                        {this.$t('i18n.common.components.comQueryTable.saveTip')}
                    </f-checkbox>
                    <div class="resize-notify-btn">
                        <f-button
                            type="primary"
                            on-click={() => {
                                _this.saveTableWidth(param);
                            }}
                        >
                            {this.$t('i18n.common.confirmText')}
                        </f-button>
                    </div>
                </div>
            );
            this.notifyVm = this.$notify({
                title: this.$t('i18n.common.components.comQueryTable.tableCloumnSaveTip'),
                message: notify,
                duration: 0,
                dangerouslyUseHTMLString:true,
                onClose: () => {
                    this.notifyVm = null;
                }
            });
        },
        getResizeColumns({ columnIndex, column }) {
            let columns = new Array(...this.tableColumns);
            columns[columnIndex].width = `${column.resizeWidth}px`;
            return columns;
        },
        saveTableWidth(param) {
            // 保存数据到后台
            this.$store.commit('common/changeIsNoNotify', this.noNotify);
            this.notifyVm.close();
            this.customConfirm(this.getResizeColumns(param));
        },
        doTableColumnsInit() {
            if (
                !this.alreadyInitTable &&
                this.tableColumnsDefault &&
                this.tableColumnsDefault.length > 0 &&
                this.tableExpandLoaded
            ) {
                if (!this.tableExpandParamUrl) {
                    this.noTableExpandParamInit();
                } else {
                    this.hasTableExpandParamInit();
                }
            }
        },
        /**
         * 无扩展字段的初始化
         */
        noTableExpandParamInit() {
            this.currentTableColumnsDefault = this.tableColumnsDefault;
            this.initTableColumns();
        },
        /**
         * 有扩展字段的初始化
         */
        hasTableExpandParamInit() {
            this.currentTableColumnsDefault = this.tableColumnsDefault.concat(this.tableExpandColumns);
            this.initTableColumns();
            this.alreadyInitTable = true;
        },
        async getTableExpandData() {
            this.tableExpandParamUrl &&
                (await this.$http.post(this.tableExpandParamUrl, this.expandParam).then(async (res) => {
                    if (res && res.code == 0 && res.data) {
                        let data = JSON.parse(res.data);
                        await this.processingTableColData(data);
                    }
                }));
            this.tableExpandLoaded = true;
        },
        /**
         * 处理表格列数据
         */
        async processingTableColData(data) {
            /**
             * res: {
             *    code: 0,
             *    msg: '',
             *    data: [
             *          {
                *          field: 'currencyType',
                            title: this.$t('i18n.list1.tableTitle.currencyType'),
                            formatter: ['matchDictionary', 'dictionary' ,'currencyType.getList'],
                            formatter: ['matchDictionary', 'constant' ,'currencyType.getList'],
                            formatter: ['matchDictionary', 'custom' ,'currencyType.getList'],
                            width: '35',
                            fixed: 'left',
                            align: 'center',
                            visible: true,
                            cellRender: {
                            name: 'linkCell', // 必传
                            events: 'http://www.cms.com/demo.html#/demo/form1?pageType=view', // 代表可点击必传
                            evnetsIframeName: 'name, // iframe的唯一名称必传
                            eventsParam: [ //选填, 该数据会拼接到url上http://www.cms.com/demo.html#/demo/form1?pageType=view&id=[row.id]&code=[row.acctCode]
                                {
                                    keyName: 'id',
                                    formKeyName: 'id'
                                },
                                {
                                    keyName: 'code',
                                    formKeyName: 'acctCode'
                                }
                            ]
                        },
                *          }
                *
                *    ]
                * }
                */
            if (data.length > 0) {

                data.forEach(item => {
                    if (item.cellRender && item.cellRender.events) {
                        let url = item.cellRender.events;
                        item.cellRender.events = {
                            click: row => {
                                this.viewIframeInfo(
                                    row,
                                    url,
                                    item.cellRender.eventsParam,
                                    item.cellRender.evnetsIframeName
                                );
                            }
                        };
                    }
                    if (item.formatter && item.formatter.length && item.formatter[0] == 'matchDictionary') {
                        try {
                            let dictType = item.formatter[1];
                            let dictKey = item.formatter[2];
                            if (typeof dictKey === 'string') {
                                // 数据字典的情况
                                this.dictionariesConfig.push({
                                    dictType: dictType,
                                    dictKey: [dictKey]
                                });
                            }
                        } catch (error) {
                            item.formatter = null;
                        }
                    }
                });
                // 扩展字段中有数据字典的情况
                this.dictionariesUrl && await this.mixGetDictionaries(this.dictionariesUrl, this.dictionariesConfig);
                this.dictionariesUrl && data.forEach(item => {
                    if (item.formatter && item.formatter.length && item.formatter[0] == 'matchDictionary') {
                        try {
                            let dictType = item.formatter[1];
                            let dictKey = item.formatter[2];
                            if (typeof dictKey === 'string') {
                                // 数据字典的情况
                                item.formatter[1] = this.mixDictionaries[dictType][`${dictKey}.obj`];
                            } else if(typeof dictKey === 'object') {
                                // 静态数据的情况
                                item.formatter[1] = dictKey
                            }
                        } catch (error) {
                            item.formatter = null;
                        }
                    }
                });
                this.tableExpandColumns = data;
            }
            this.tableExpandLoaded = true;
        },
        /**
         * 扩展字段的单元格匹配数据字典
         */
        tableCellMatchDictionary(dictType, dictKey) {

        },
        /**
         * 使用iframe的方式打开查看页面
         * @param {Object} row 点击的行的行数据
         * @param {string} url 要打开的iframe的src地址
         * @param {Array} eventsParam 要传入iframe的参数keyname, 会从row中获取
         * @param {string} evnetsIframeName iframe的name值
         */
        viewIframeInfo(row, url, eventsParam, evnetsIframeName) {
            let param = '';
            if (eventsParam && eventsParam.length) {
                param = eventsParam.reduce((result, curr, index) => {
                    return (
                        result +
                        curr.keyName +
                        '=' +
                        row[curr.formKeyName] +
                        (index == eventsParam.length - 1 ? '' : '&')
                    );
                }, '');
            }
            if (url.indexOf('?') > -1 && param) {
                url = url + '&' + param;
            } else if (param) {
                url = url + '?' + param;
            }
            this.rootNav.setRootNav(
                `iframe:${evnetsIframeName}`,
                {
                    query: {
                        url: url
                    }
                },
                { size: '86.1%', direction: 'rtl' }
            );
        }
    }
};
