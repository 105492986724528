import storageUtils from '@/common/utils/storageUtils';
export default {
    extend(router) {
        router.beforeEach((to, from, next) => {
            console.log('路由跳转',to,from);
            if (to.name != 'login') {
                let systemTitle = storageUtils.getItem('systemTitle', systemTitle)
                let title = to.meta.title;
                document.title = systemTitle +  (title ? ('-' + title) : '');
            }
            //requireLogin为false时不进行登录校验
            if (typeof to.meta.requireLogin == 'undefined' || to.meta.requireLogin) {
                let token = storageUtils.getItem('JwtToken');
                if ((token == undefined || token =='undefined' || token == null || token == 'null') && to.path !== '/login' && process.env.NODE_ENV != "mock") {
                    const pathname = window.location.pathname;
                    next(false);
                    console.log('正常提示：登录页>',process.env.VUE_APP_LOGIN_ADDRESS)
                    top.location.href = process.env.VUE_APP_LOGIN_ADDRESS;
                } else {
                    next();
                }
            } else {
                next();
            }
        });
    }
};

