export default {
    processQueryInfo: '/api/fsmc/process-data/query-process-config-with-data/-by-/',//流程透明化数据查询
    processQueryDetail: '/api/fsmc/process/query/detail',//流程透明化详情


    //流程透明化配置
    processSettingList: '/api/fsmc/process-config/query-process-config-by-page',//流程透明化设置列表查询
    queryProcessSettingById: '/api/fsmc/process-config/query-process-config/-by-/',//根据id查询流程透明化信息
    insertProcessSetting: '/api/fsmc/process-config/process-config-add',//新增流程信息
    updateProcessSetting: '/api/fsmc/process-config/process-config-update',//修改流程信息
    deleteProcessSetting: '/api/fsmc/process-config/process-config-delete',//删除流程信息
    queryWkflBusinessType: '/api/fsmc/process-config/query-wkflBusinessType/-by-/',//查询业务类型
};
