<template>
    <f-select v-model="selectValue" :data="list" @change="handleChange" size="mini"> </f-select>
</template>

<script>
/**
 * 只是显示数据， 当change的时候; 会触发父级的lazyLoad方法; 让lazyLoad方法调用请求
 * 暂时不考虑无限下拉
 */
export default {
    inheritAttrs: false,
    inject: ['linkage'],
    name: 'LinkSelect',
    data() {
        return {
            selectValue: null
        };
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        options: {
            type: Array,
            default: null
        }
    },

    computed: {
        config() {
            return this.linkage.config;
        },
        list() {
            return this.options;
        }
    },
    methods: {
        handleChange(val) {
            let { config } = this;
            let node = this.options.find(item => (val || []).indexOf(item[config.value]) > -1);
            node.isLeaf ? this.handleCheckChange(node.getValueByOption(), node) : this.handleExpand(node);
        },
        handleExpand(node) {
            let { config, linkage } = this;

            if (node.loading) return false;

            if (config.lazy && !node.loaded) {
                linkage.lazyLoad(node, () => {
                    const { isLeaf } = node;
                    if (!isLeaf) this.handleExpand(node);
                });
            } else {
                linkage.handleExpand(node);
            }
        },
        handleCheckChange(value, node) {
            const { linkage } = this;
            linkage.handleCheckChange(value);
            linkage.handleLabelChange(node);
            linkage.handleExpand(node);
        }
    },

    watch: {
        options: {
            handler(val) {
                // 重置下一级
                let value = null,
                    _this = this;
                for (let i = 0, len = val.length; i < len; i++) {
                    let item = val[i];
                    if (item.value === _this.selectValue) {
                        value = item.value;
                        break;
                    }
                }
                this.selectValue = value;
            },
            deep: true
        }
    }
};
</script>
