// 本文件主要是封装axios相关内容，包括请求前和收到返回数据的相关数据处理等
import axios from 'axios';
import { Loading } from '@iss/frontend-ui';
import MessageBox from '@/common/components/messageBox.js';
import i18n from '../i18n';
import store from '@/common/store';
import ComCert from '../components/comCert/src/com-cert.js';
import storageUtils from '../utils/storageUtils';
import { generateSHA, createEncryptRequest, isUseEncrypt } from '@/common/utils';

// 优先查看env.xxx内容， 然后查看 sys-config.json 里面 baseUrl 内容， 如果都没有就返回"/"
const getBaseURL = () => {
    const { VUE_APP_API_SERVER } = process.env;
    return VUE_APP_API_SERVER || '/';
};

// 初始化一个axios实例
const instance = axios.create({
    baseURL: getBaseURL(),
    timeout: 100000000000,
    withCredentials: false, //跨域是否带Cookie
    headers: {
        'Content-Type': 'application/json;text/xml;charset=UTF-8'
    }
});

let loadingInstance = window;
let t = 0,msgIndex=0;
// request interceptor
instance.interceptors.request.use(
    function(config) {
        //前端无操作超时退出
        //console.log((config.headers.__expires__ == 'true'),(!storageUtils.uploadHttp()),'go',config.url,config.headers.__expires__);
        if(config.headers.__expires__ != 'true' && !storageUtils.uploadHttp()){
            loadingInstance.loading && loadingInstance.loading.close();
            sessionTimeout();
            return;
        }
        if (!(config.loading === false) && (!loadingInstance.loading || t == 0)) {
            loadingInstance.loading = Loading.service({
                fullscreen: true,
                lock: true,
                spinner: 'el-icon-loading',
                text: i18n.t('i18n.common.infiniteLoading'),
                background: 'rgba(242,242,242,0.5)'
            });
        }
        t++;
        //config.headers.common.pv = sessionStorage.getItem('_pv_');
        config.headers.JwtToken = storageUtils.getItem('JwtToken');
        config.headers.lang = storageUtils.getItem('lang');
        if (localStorage.getItem('ClusterNO') != null && localStorage.getItem('ClusterNO') !== ''){
            config.headers.ClusterNO = localStorage.getItem('ClusterNO');
        }
        // 请求方法不是get
        // 请求头配置了 notUseEncrypt
        if (config.method !== 'get' && !config.headers.notUseEncrypt && isUseEncrypt()) {
            process.env.NODE_ENV === 'development' && console.log(`%c${config.method}->${config.url}->request`, 'background:#000;color:#bada55', config.data);
            config.headers.useEncrypt = true;
            config.headers.signature = generateSHA(config.data,config.headers.encryptTemp);
            config.data = createEncryptRequest(config.data,config.headers.encryptTemp);
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// response interceptor
instance.interceptors.response.use(
    async function(response) {
        t--;
        if (t === 0 && loadingInstance && loadingInstance.loading) {
            loadingInstance.loading.close();
            t = 0;
        }
        // ComCert.open({ funcUrl: 'xxxx' });
        if (response.headers.requestToken || response.headers.requesttoken) {
            let url = store.getters['common/getTokenUrl'];
            let ClusterNO = localStorage.getItem('ClusterNO')
            await axios.get(url,{headers:{"ClusterNO":ClusterNO}}).then(res => {
                let { data } = res.data;
                removeAxiosHeader('requestToken');
                let requestToken = data.token;
                setAxiosHeaders({ requestToken });
            });
        }
        // 返回不解密
        // if (isUseEncrypt()) {
        //     response.data = decryptByAESModeECB(response.data);
        // }
        return response;
    },
    function(error) {
        t--;
        if (t === 0 && loadingInstance && loadingInstance.loading) {
          loadingInstance.loading.close();
            t = 0;
        }
        errorMessageHandler(error);
        if (error.response && error.response.status != 412) {
            return ;//Promise.reject('error'); // 返回接口返回的错误信息
        }else{
            return;
        }
    }
);

//这两个方法为axios的静态方法，由于外部api使用同一axios实例，所以将静态方法挂载到实例上
instance.all = axios.all;
instance.spread = axios.spread;

function setAxiosHeaders(config) {
    Object.keys(config).forEach(key => {
        instance.defaults.headers[key] = config[key];
    });
}

function removeAxiosHeader(key) {
    delete instance.defaults.headers[key];
}

let timeStamp = 0,indexTest =0;
function sessionTimeout(code){
    let currentWindow = window;
    while (currentWindow.top != currentWindow) {
        currentWindow = currentWindow.top;
    }
    storageUtils.clear();
    let text = i18n.t('i18n.common.loginExpried');
    if(code == -998){
        //超时退出
        text = i18n.t('i18n.common.loginExpried');
    }else if(code == -997){
        //挤出退出
        text = i18n.t('i18n.common.loginOtherExpried');
    }

    let wait = 500 , timer;
    let run = ()=>{
        timer = setTimeout(()=>{
            if(indexTest <= 0){
                indexTest ++;
                self.parent.$bus.$emit('iframe:messageBox', text, i18n.t('i18n.common.prompt'),{
                    callback: action => {
                        clean();
                        currentWindow.location = process.env.VUE_APP_LOGIN_ADDRESS;
                    }
                });
            }
            console.log(indexTest);
        },wait);
    }

    let clean = ()=>{
        timer && clearTimeout(timer);
    }
    let now = (new Date()).getTime();
    if((now - timeStamp) > wait){
        console.log(now,timeStamp,wait);
        clean();
        run();
        timeStamp = now;
    }
}

export { setAxiosHeaders, removeAxiosHeader };

export default instance;

function errorMessageHandler(error) {
    let errorMsg;
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                errorMsg = i18n.t('i18n.common.requestBad');
                break;
            case 401:
                // eslint-disable-next-line no-case-declarations

                sessionTimeout(error.response.data.code);

                return;
            case 403:
                errorMsg = 'requestDenied';
                break;
            case 404:
                errorMsg = 'requestNotfound';
                break;
            case 405:
                errorMsg = 'requestMethodNoAllowed';
                break;
            case 408:
                errorMsg = 'requestTimeOut';
                break;
            case 412:
                // 当某种情况下使用证书验证
                var param_data = error.response.data.data;
                var businessRequestUrl = error.response.headers.businessrequesturl;
                var businessrequestmethod = error.response.headers.businessrequestmethod;
                var certDirectUrl = error.response.headers.certdirecturl;
                ComCert.open({
                    funcUrl: businessRequestUrl,
                    businessRequestMethod: businessrequestmethod,
                    directParam: param_data,
                    funcType: 'json',
                    funcParam: param_data,
                    showType: 'dialog',
                    directUrl: certDirectUrl
                });
                break;
            case 500:
                errorMsg = 'serverError';
                break;
            case 501:
                errorMsg = 'networkUnfulfilled';
                break;
            case 502:
                errorMsg = 'networkError';
                break;
            case 503:
                errorMsg = 'serverUnavailable';
                break;
            case 504:
                errorMsg = 'networkTimeout';
                break;
            case 505:
                errorMsg = 'httpNotSupported';
                break;
            default:
                errorMsg = 'connectionError';
                break;
        }
    } else {
        errorMsg = 'connectServerError';
    }
    console.log('error',error,errorMsg);
    if (errorMsg && error.response && error.response.status != 412 && !error.response.config.headers.errorNoTip) {
        MessageBox.error(i18n.t('i18n.common.' + errorMsg));
    }

    /*if (error.response) {
    let url = "/error.html#/error-";
    switch (error.response.status) {
      case 400: //Bad Request
      case 401: //未授权，请重新登录
      case 403: //拒绝访问
      case 404: //找不到资源
      case 405: //请求方法未允许
      case 408: //请求超时
      case 500: //服务器内部错误
      case 501: //网络未实现
      case 502: //网络错误
      case 503: //服务不可用
      case 504: //网络超时
      case 505: //http版本不支持该请求
        url += error.response.status;
        if (error.response.data) {
          url += "?data=" + JSON.stringify(error.response.data);
        }
        window.location.href = url;
        break;
      default:
        Message.error(error.response.data.errorMsg);
    }
  }*/
}
