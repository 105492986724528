//加法
function add(a, b) {
    var c, d, e;
    try {
        c = a.toString().split('.')[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split('.')[1].length;
    } catch (f) {
        d = 0;
    }
    return (e = Math.pow(10, Math.max(c, d))), (mul(a, e) + mul(b, e)) / e;
}

function sub(a, b) {
    return add(a, -b);
}

//乘法
function mul(a, b) {
    var c = 0,
        d = a.toString(),
        e = b.toString();
    try {
        c += d.split('.')[1].length;
        // eslint-disable-next-line no-empty
    } catch (f) {}
    try {
        c += e.split('.')[1].length;
        // eslint-disable-next-line no-empty
    } catch (f) {}
    return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / Math.pow(10, c);
}

//除法
function div(a, b) {
    var c,
        d,
        e = 0,
        f = 0;
    try {
        e = a.toString().split('.')[1].length;
        // eslint-disable-next-line no-empty
    } catch (g) {}
    try {
        f = b.toString().split('.')[1].length;
        // eslint-disable-next-line no-empty
    } catch (g) {}
    return (
        (c = Number(a.toString().replace('.', ''))),
        (d = Number(b.toString().replace('.', ''))),
        mul(c / d, Math.pow(10, f - e))
    );
}

const math = {
    add,
    sub,
    mul,
    div
};

/**
 * 导航要用的方法
 */
function direction(config = {}) {
    let direction = 'left';
    switch (config.direction) {
        case 'rtl':
            direction = 'left';
            break;
        case 'ltr':
            direction = 'right';
            break;
        case 'ttb':
            direction = 'bottom';
            break;
        case 'btt':
            direction = 'top';
            break;
    }
    return direction;
}
function widthDirection(config) {
    let { size } = config || {};
    return `${direction(config)}:${size && size.replace(/(\d+)%$/, (a, b) => 100 - b * 1 + '%')}`;
}
function closeDirection(config) {
    let direct = direction(config);
    let styles = '';
    switch (direct) {
        case 'left':
        case 'right':
            styles += `top:0;`;
            break;
        case 'bottom':
            styles += `left:0;`;
            break;
        case 'top':
            styles += `right:0;`;
            break;
    }
    styles += `${direct}:0`;
    return styles;
}
function contentDirection(config) {
    let direct = direction(config);
    return `margin-${direct}: 30px;`;
}

const rootNav = {
    widthDirection,
    closeDirection,
    contentDirection
};

export { math, rootNav };
