<template>
    <div
        class="com-table-process"
        v-loading="loading"
        :element-loading-text="$t('i18n.common.infiniteLoading')"
        element-loading-spinner="el-icon-loading">
        <f-timeline>
            <f-timeline-item
                v-for="(item, index) in processData"
                :key="item.id"
                :color="index == 0 ? '#359BE6' : '#E0E0E0'"
            >
                <div>
                    <div class="current-approve-state">
                        {{ item.currentState }}
                        <span v-if="item.approveInfo">
                            ， {{ item.approveInfo }}
                        </span>
                    </div>
                    <div class="current-approve-person">{{ item.approveLabel }} : {{ item.approvePerson }}</div>
                    <div class="next-approve-person">
                        {{ item.nextApproveLabel }}  {{item.nextApproveLabel ? ':':'' }}
                        {{
                            Array.isArray(item.nextApprovePerson)
                                ? item.nextApprovePerson.join('，')
                                : item.nextApprovePerson
                        }}
                    </div>
                    <div class="date">
                        <span class="day" v-if="item.dateTime">
                            {{ item.dateTime | formatDateOnly }}
                        </span>
                        <span class="time" v-if="item.dateTime">
                            {{ item.dateTime | formatTimeOnly }}
                        </span>
                    </div>
                </div>
            </f-timeline-item>
            <div v-if="processData.length === 0" class="no-data">{{this.$t('i18n.common.components.form.dataEmpty')}}</div>
        </f-timeline>
    </div>
</template>

<script>
/**
 * 表格中的审批流浮层
 */
export default {
    name: 'ComTableProcess',
    data() {
        return {
            loading: false,
            processData: []
        };
    },
    props: {
        /**
         * 请求数据的地址, 或者直接的源数据
         */
        dataSource: {
            type: [Array, String]
        },
        /**
         * 请求携带的参数
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 初始化是否加载数据, 默认初始化不加载数据
         */
        initGetData: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.initGetData) {
                this.getProcessData();
            }
        },
        /**
         * 获取审批流信息
         */
        getProcessData() {
            if (typeof this.dataSource == 'string') {
                // 当dataSource配置为字符串时,发送请求
                this.$nextTick(() => {
                    this.processData = [];
                    this.loading = true;
                    this.$http.post(this.dataSource, this.extraData, {
                        loading: false
                    }).then(res => {
                        if (res.code === 0) {
                            this.processData = res.data || [];
                        } else {
                            this.$error(res.msg);
                        }
                        this.loading = false;
                    }).catch((err) => {
                        this.$error(this.$t('i18n.common.serverError'));
                        this.loading = false;
                    });
                });
            } else {
                this.processData = this.dataSource;
            }
        },
        reload() {
            this.getProcessData();
        }
    }
};
</script>

<style lang="scss" scoped>
.com-table-process {
    min-height: 200px;
    max-height: 300px;
    min-width: 250px;
    max-width: 400px;
    overflow: auto;
    padding: 16px 16px 0 16px;
    /deep/ .el-timeline-item__content {
        text-align: left;
    }
    /deep/ .el-timeline {
        padding-left: 0px;
    }
    /deep/ .el-timeline-item__wrapper {
        padding-left: 100px;
    }
    /deep/ .el-timeline-item__node--large {
        left: 100px;
    }
    /deep/ .el-timeline-item__node--normal {
        width: 9px;
        height: 9px;
        left: 80px;
    }
    /deep/ .el-timeline-item__tail {
        left: 84px;
        border-left: 1px solid #f7f7f7;
    }
    .current-approve-state {
        font-size: 13px;
        color: #2c2c2c;
        line-height: 18px;
        margin-bottom: 5px;
        text-align: left;
    }
    .current-approve-person {
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
    }
    .next-approve-person {
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
    }
    .approve-info {
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
    }
    .date {
        position: absolute;
        top: 0px;
        left: 0px;
        text-align: right;
        line-height: 18px;
    }
    .day {
        display: block;
        font-size: 13px;
        color: #aaaaaa;
    }
    .time {
        display: block;
        font-size: 11px;
        color: #aaaaaa;
    }
    .no-data {
        width: 220px;
        line-height: 200px;
        text-align: center;
    }
}
</style>
