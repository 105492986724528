<template>
    <!-- 公共按钮放大镜 -->
    <f-dialog
        :visible.sync="visible"
        :title="title"
        :class="['com-magnifier-dialog']"
        width="1100px"
        height="auto"
        :append-to-body="true"
        @close="handleClose"
    >
        <f-magnifier-search ref="searchForm" :formSize="mixSize" :model="searchModel" :rules="searchRules"  @query="handleQuery" :column="3">
            <slot name="search"></slot>
        </f-magnifier-search>
        <div>
            <f-row>
                <f-col :span="16">
                    <div class="f-magnifier-table-header">
                        <span>{{$t('i18n.common.components.magnifier.toBeSelect')}}</span>
                    </div>
                    <f-magnifier-table
                        ref="table"
                        :tableData="tableData"
                        :row-key="valueKey"
                        :pageSizes="pageSizes"
                        :page="page"
                        :tableSize="mixSize"
                        :pagination="pagination"
                        @pageChange="handlePageChange"
                        @row-click="handleRowClick"
                        @row-dblclick="handleRowDblclick"
                        @select="handleSelect"
                        @select-all="handleSelectAll"
                        @currentRowEnter="currentRowEnter"
                        @selection-change="handleSelectionChange"
                    >
                        <f-magnifier-column type="selection" :reserve-selection="true" :selectable="selectable" ></f-magnifier-column>
                        <slot></slot>
                    </f-magnifier-table>
                </f-col>
                <f-col :span="8">
                    <div style="margin-left: 5px">
                        <div class="f-magnifier-table-header">
                            <span>{{$t('i18n.common.components.magnifier.selected')}}</span>
                            <f-button :size="mixSize" @click="handleClearSelection">{{$t('i18n.common.cleanUp')}}</f-button>
                        </div>
                        <div style="border: 1px solid #ebeef5;">
                            <f-table
                                ref="selectedTable"
                                :data="selection"
                                style="width: 100%"
                                :height="selectedTableHeight"
                                :size="mixSize"
                                :show-header="false"
                            >
                                <slot name="rightColumns">
                                    <f-magnifier-column :prop="valueKey" show-overflow-tooltip>
                                        <template v-slot:default="{ row }">
                                            {{ row[valueKey] }} / {{ row[labelKey] }}
                                        </template>
                                    </f-magnifier-column>
                                </slot>
                                <f-magnifier-column :prop="valueKey" width="30px">
                                    <template v-slot:default="item">
                                        <i class="el-icon-circle-close" @click="handleRemoveSelection(item)"></i>
                                    </template>
                                </f-magnifier-column>
                            </f-table>
                        </div>
                    </div>
                </f-col>
            </f-row>
        </div>

        <template v-slot:footer>
            <f-button :size="mixSize" type="primary" @click="confirm">{{$t('i18n.common.confirmText')}}</f-button>
            <f-button :size="mixSize" @click="cancel">{{$t('i18n.common.cancelText')}}</f-button>
        </template>
    </f-dialog>

</template>

<script>
import btnMagnifierMutilMix from '@/common/comMixin/btnMagnifierMultiMix.js';
/**
 * 付款账号-单选
 */
export default {
    name: 'ComBtnmagnifierMutil',
    mixins: [btnMagnifierMutilMix],
    data() {
        return {};
    },
    props: {
        searchModel: {
            type: Object,
            default: () => {
                return {};
            }
        },
        selectable:{
            type:Function
        }
    },
    created() {
        this.searchForm = this.searchModel;
    }
};
</script>

<style lang="scss" scoped></style>
