<template>
    <a :class="[isEmpty ? 'link-url is-empty' : 'link-url', visited ? 'is-visited' : '']" href="javascript: void(0);" @click="handleClick($event)">
        {{ getValue() }}
    </a>
</template>

<script>
import { VXETable, emptyText } from '@/common/plugins/table-formaters';
import XEUtils from 'xe-utils';
export default {
    name: 'LinkCell',
    data() {
        return {
            isEmpty: true,
            visited: false,
        };
    },
    props: {
        rowData: {
            type: Object,
            defualt: () => {
                return {};
            }
        },
        /**
         * @param linkConfig 的数据说明如下
         * @param {Function(rowData, e)} events.click value上的事件配置, 统一做了e.stopPropagation阻止事件冒泡处理
         * @param {String} emptyText 空数据时显示的文本值
         */
        linkConfig: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * 当前render中的filed的值
         */
        field: {
            type: String
        }
    },
    methods: {
        getValue() {
            let cellValue = this.rowData[this.field];
            let cellLabel = cellValue;
            // 数据formatter处理
            let formatter = this.linkConfig.formatter;
            if (formatter) {
                if (XEUtils.isString(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter] || XEUtils[formatter];
                    cellLabel = globalFunc ? globalFunc(cellValue) : cellValue;
                } else if (XEUtils.isArray(formatter)) {
                    const globalFunc = VXETable.formats.store[formatter[0]] || XEUtils[formatter[0]];
                    cellLabel = globalFunc ? globalFunc(...[cellValue].concat(formatter.slice(1))) : cellValue;
                } else {
                    cellLabel = formatter(Object.assign({ cellValue }, this.rowData)) || cellValue;
                }
            }

            // 空数据处理
            let empty = emptyText(cellLabel, this.linkConfig.emptyText);
            this.isEmpty = empty.isEmpty;
            if (empty.isEmpty) {
                // 如果cellValue为空, 直接返回emptyText
                return (cellLabel = empty.text);
            }

            return cellLabel;
        },
        handleClick(e) {
            this.visited = true;
            if (this.linkConfig.events && this.linkConfig.events.click && !this.isEmpty) {
                e.stopPropagation();
                this.linkConfig.events.click(this.rowData, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.link-url {
    color: #1d50db;
    text-decoration: underline;
    cursor: pointer;
    &.is-empty {
        text-decoration: none;
        cursor: default;
    }
    &.is-visited {
        color: #7626a8;
    }
}
</style>
