<template>
    <!-- 客商信息放大镜 -->
    <f-magnifier-single
        ref="magnifier"
        v-model="selectValue"
        :searchModel="searchForm"
        :isCloseResetSearchForm="isCloseResetSearchForm"
        :tableData="tableData"
        size="mini"
        valueKey="accountNo"
        filterKey="accountNo"
        :title="$t('i18n.common.components.customer.title')"
        :placeholder="placeholder || $t('i18n.common.components.customer.placeholder')"
        v-bind="$attrs"
        @open="handleOpen"
        @pageChange="handlePageChange"
        @query="handleQuery"
        @change="handlerChange"
        @confirm="handlerConifrm"
        @clear="handleClear"
        v-on="listeners"
        :autoSelect="autoSelect">
        <template slot="search">
            <f-form-item :label="$t('i18n.common.components.customer.accountNo')" prop="accountNo">
                <f-input v-model="searchForm.accountNo"></f-input>
            </f-form-item>
        </template>
        <template>
            <!-- 账户号 -->
            <f-magnifier-column prop="accountNo"
                                :label="$t('i18n.common.components.customer.accountNo')"></f-magnifier-column>
            <!-- 账户名称 -->
            <f-magnifier-column prop="accountName"
                                :label="$t('i18n.common.components.customer.accountName')"></f-magnifier-column>
            <!-- 单位名称 -->
            <f-magnifier-column prop="contactsOrgName"
                                :label="$t('i18n.common.components.customer.contactsOrgName')"></f-magnifier-column>
            <!-- 开户行名称 -->
            <f-magnifier-column prop="bankName"
                                :label="$t('i18n.common.components.customer.bankName')"></f-magnifier-column>
            <!-- 开户行省 -->
            <!--<f-magnifier-column prop="province" :label="$t('i18n.common.components.customer.province')"></f-magnifier-column>-->
            <!-- 开户行市 -->
            <!--<f-magnifier-column prop="city" :label="$t('i18n.common.components.customer.city')"></f-magnifier-column>-->
            <!-- 单位类型 -->
            <f-magnifier-column prop="contactsOrgType" :label="$t('i18n.common.components.customer.contactsOrgType')">
                <template v-slot="$scope">
                    {{mixMatchDictionary($scope.row.contactsOrgType,
                    mixDictionaries.constant['OppNature.getList.obj'])}}
                </template>
            </f-magnifier-column>
            <!-- 单位性质 -->
            <f-magnifier-column
                prop="contactsOrgProperty"
                :label="$t('i18n.common.components.customer.contactsOrgProperty')">
                <template v-slot="$scope">
                    {{mixMatchDictionary($scope.row.contactsOrgProperty, mixDictionaries.constant['YesOrNo.getList.obj'])}}
                </template>
            </f-magnifier-column>
            <!-- 人行号 -->
            <!--<f-magnifier-column prop="cnapsCode" :label="$t('i18n.common.components.customer.cnapsCode')"></f-magnifier-column>-->
            <!-- 直联号 -->
            <!--<f-magnifier-column prop="directCode" :label="$t('i18n.common.components.customer.directCode')"></f-magnifier-column>-->
        </template>
    </f-magnifier-single>
</template>

<script>
    import {dictionaryMix} from '@/common/comMixin';
    import magnifierSingleMix from '@/common/comMixin/magnifierSingleMix.js';

    /**
     * 单位放大镜-单选
     */
    export default {
        name: 'CustomerMagnifierSingle', //客商信息放大镜
        mixins: [magnifierSingleMix, dictionaryMix],
        data() {
            return {
                /**
                 * 单位放大镜查询条件
                 */
                searchForm: {
                    accountNo: ''// 账户
                },
                /**
                 * 单位类型/单位性质/是否结算中心
                 */
                dictionariesConfig: [{
                    "dictType": "dictionary",
                    "dictKey": ["orgProperty"]
                }, {
                    "dictType": "constant",
                    "dictKey": ["OppNature.getList", "YesOrNo.getList"]
                }]
            }
        },
        props: {
            /**
             * 关联值配置
             */
            matchKey: {
                type: Object,
                default: () => {
                    return {
                        accountNo: 'accountNo',
                        contactsId: 'contactsId',
                        useOrgId: 'useOrgId'
                    }
                }
            },
            /**
             * 数据字典URL地址
             **/
            dictionariesSource: {
                type: String
            },
        },
        async created() {
            // 获取数据字典
            await this.mixGetDictionaries(this.dictionariesSource, this.dictionariesConfig);
        }
    }
</script>

<style lang="scss" scoped>

</style>
