<script>
import tableMix from '@/common/comMixin/tableMix.js';
import TableTemplateMixin from './table-template-mixin';
export default {
    name: 'ComEditTable',
    mixins: [TableTemplateMixin, tableMix],
    data() {
        return {
            tableType: 'edit',
            dialogVisible: false,
            dialogType: {},
            showOverflow:true
        };
    },
    props: {
        editConfig: Object,
        overflowShow:{
            type: Boolean,
            default: true
        },
        isQueryForm: {
            type: Boolean,
            default: false
        }
    },
    watch:{
        overflowShow:{
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.showOverflow = newValue
            }
        }
    },
    computed: {
        tableEditConfig() {
            return {
                trigger: 'manual',
                mode: 'row',
                autoClear: false,
                ...this.editConfig
            };
        }
    },
    methods: {
        /**
         * 往表格插入临时数据，从指定位置插入一行或多行；
         * 第二个参数：row 指定位置（不支持树表格）、
         * null从第一行插入、-1 从最后插入
         * 返回: records: Array<object>, row?: Row
         * 用法:
         */
        insertAt(records, row) {
            let table = this.$refs.tableList;
            let hasActive = table.getActiveRecord();
            if (hasActive) {
                return Promise.reject(hasActive);
            }
            let result = { row_status: 'add', ...records, row_add: true };
            return table.insertAt(result, row);
        },
        // 获取新增内容
        getInsertRecords() {
            return Promise.resolve(this.$refs.tableList.getInsertRecords());
        },
        // 获取修改内容
        getUpdateRecords() {
            return Promise.resolve(this.$refs.tableList.getUpdateRecords());
        },
        // 获取删除内容
        getRemoveRecords() {
            return Promise.resolve(this.$refs.tableList.getRemoveRecords());
        },
        getAllRecords() {
            return Promise.resolve(this.$refs.tableList.getTableData());
        },
        // 还原操作
        revertAt() {
            return this.$refs.tableList.revertData();
        },
        // 打开编辑操作
        setActiveRow(row) {
            return this.$refs.tableList.setActiveRow(row);
        },
        clearActived() {
            return this.$refs.tableList.clearActived();
        },

        batchLines(row) {
            let rows = this.batchSave(row);
            return new Promise(resolve => {
                this.$refs.tableList.reloadData(rows).then(() => {
                    resolve({ rows, row });
                });
            });
        },
        batchSave(row) {
            /**
             * 此处待优化
             * 1. vxe-table 里面有getCheckboxRecords()方法
             */
            let isCheckboxConfig = !!Object.keys(this.checkboxConfig || {}).length,
                checkboxKey = isCheckboxConfig ? this.checkboxConfig.checkField : '';
            return this.tableData.map(tr => {
                if (isCheckboxConfig && tr[checkboxKey]) {
                    for (let key in row) {
                        let td = row[key];
                        if (!(td == null || td.length === 0)) {
                            tr[key] = td;
                        }
                    }
                }
                return tr;
            });
        },
        // 打开弹窗编辑行
        openDialog(type) {
            this.dialogType = type;
            this.dialogVisible = true;
        },
        dialogConfirm(row) {
            this.$emit('dialog:confirm', row, this.dialogType);
        },
        dialogCancel() {
            this.$emit('dialog:cancel', this.dialogType);
        },
        removeRow(row) {
            return this.$refs.tableList.remove(row);
        },
        // 表格调度中心 = 代理 + 适配 模式
        tableDispatch(methodName, ...args) {
            return this.getTable()[methodName](...args);
        }
    }
};
</script>
