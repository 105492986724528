import ComEditForm from './src/com-edit-form';
import FlowChart from './src/flow-chart';

/* istanbul ignore next */
ComEditForm.install = function(Vue) {
    Vue.component(ComEditForm.name, ComEditForm);
    Vue.component(FlowChart.name, FlowChart);
};

export default ComEditForm;
