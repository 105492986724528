export default {

    // 菜单国际化
    queryMenuI18nByPageUrl:'/api/fsmc/auth/menu-i18n/query-menu-i18n-by-page', // 菜单国际化 - 查询分页列表
    queryMenuI18nByLangUrl:'/api/fsmc/auth/menu-i18n/query-menu-i18n-by-lang', // 菜单国际化 - 查询已维护的菜单国际化列表
    saveAndUpdateMenuI18nUrl:'/api/fsmc/auth/menu-i18n/save-and-update-menu-i18n', // 菜单国际化 - 新增或修改菜单国际化信息

    // 用户维护
    queryUserInfoByPageUrl:'/api/fsmc/auth/userinfo/query-auth-userinfo-by-page', // 用户维护
    queryUserByIdUrl:'/api/fsmc/auth/userinfo/query-auth-userinfo/-by-/',// 用户维护修改-根据id查用户信息
    queryUserInfoAndStationByIdUrl:'/api/fsmc/auth/userinfo/query-user-station/-by-/',// 用户维护-查询单个对象, 包含职责信息
    organiMagnifyUrl:'/api/fsmc/auth/userinfo/queryOrgStructTreelList',// 用户维护修改-所属组织放大镜
    addUserInfoUrl:'/api/fsmc/auth/userinfo/auth-userinfo-add',// 用户维护-新增
    submitUserInfoUrl:'/api/fsmc/auth/userinfo/auth-userinfo-submit',// 用户维护-用户新增提交
    updateUserInfoUrl:'/api/fsmc/auth/userinfo/auth-userinfo-update',// 用户维护-更新
    userCancelUrl:'/api/fsmc/auth/userinfo/auth-userinfo-cancel',// 用户维护-作废按钮
    updateSubmitUserInfoUrl:'/api/fsmc/auth/userinfo/auth-userinfo-submit-update',// 用户维护-提交更新
    deleteUserInfoUrl:'/api/fsmc/auth/userinfo/auth-userinfo-delete',// 用户维护-删除按钮
    queryDutyListUrl:'/api/fsmc/auth/userinfo/query-duty-list',// 用户维护-获取所有已生效的职责
    queryDutyListInViewUrl:'/api/fsmc/auth/userinfo/query-duty-list-in-view',// 用户维护-获取所有已生效的职责
    validateUserCodeUrl:'/api/fsmc/auth/userinfo/validate-user-code-with-admin/-by-/',// 用户维护-验证用户名是否唯一
    changeLockedTimeUrl:'/api/fsmc/auth/userinfo/changeLockedTime',// 用户维护-用户解锁
    resetPassowrdUrl:'/api/fsmc/auth/userinfo/reset-password',// 用户维护-重置密码按钮
    editPasswordUrl:'/api/fsmc/auth/userinfo/edit-password',// 用户维护-登录修改密码
    editUserInfoUrl:'/api/fsmc/auth/userinfo/edit-userinfo',// 用户维护-登录用户修改用户信息
    queryOrgStructTreelListUrl:'/api/fsmc/auth/userinfo/queryOrgStructTreelList',// 用户维护-查询组织机构列表
    queryOnlineUserInfoByPage:'/api/fsmc/auth/userinfo/query-auth-userinfo-online-by-page', // 在线用户查询
    validateUserEmailUrl:'/api/fsmc/auth/userinfo/validate-user-mail',// 用户维护-验证用户邮箱是否唯一
    validateCertExists:'/api/fsmc/auth/userinfo/validate-user-cert',// 用户维护-验证用户证书是否唯一

    // 用户复核
    userReviewUrl:'/api/fsmc/auth/userinfo/query-userinfo-review-by-page', // 用户复核
    queryUserReviewByIdUrl:'/api/fsmc/auth/userinfo/query-auth-userinfo/-by-/', // 用户复核-根据id查用户信息
    queryUseReviewByIdUrl:'/api/fsmc/auth/userinfo/query-duty-list', // 用户复核-获取已生效的职责
    reviewUserStationInfoUrl:'/api/fsmc/auth/userinfo/review-user-station', //用户复核-岗位复核
    backUserStationInfoUrl:'/api/fsmc/auth/userinfo/back-user-station', //用户复核-复核打回
    batchReviewUserStationInfoUrl:'/api/fsmc/auth/userinfo/review-user-station', // 用户复核-批量复核

    // 职责维护
    responsMaintainUrl:'/api/fsmc/auth/duty/query-duty-by-page', // 职责维护 - 查询分页列表
    queryDutyByIdUrl:'/api/fsmc/auth/duty/query-duty/-by-/', // 职责维护--查询职责单个对象
    addDutyUrl:'/api/fsmc/auth/duty/add-duty', // 职责维护--新增保存
    addSubmitDutyUrl:'/api/fsmc/auth/duty/add-submit-duty', // 职责维护--新增提交
    updateDutyUrl:'/api/fsmc/auth/duty/update-duty', // 职责维护--更新
    updateSubmitDutyUrl:'/api/fsmc/auth/duty/update-submit-duty', // 职责维护--修改提交职责
    deleteDutyUrl:'/api/fsmc/auth/duty/delete-duty', // 职责维护--删除职责参数
    cancelDutyUrl:'/api/fsmc/auth/duty/cancel-duty', // 职责维护--复核之后的职责废弃
    queryResponsByIdUrl:'/api/fsmc/auth/duty/query-duty-menu-list/-by-/', // 职责维护--根据职责查询菜单信息
    queryStationUrl:'/api/fsmc/auth/duty/query-duty-code', // 职责维护--根据职责查询菜单信息
    queryValidateMenuUrl:'/api/fsmc/auth/duty/queryValidateMenu/-by-/', // 职责维护--获取已生效的菜单
    queryFunctionCheckedListByDutyUrl:'/api/fsmc/auth/duty/query-function-checkedlist-by-duty', // 职责维护--职责获取关联的功能树
    validateDutyCancelUrl: '/api/fsmc/auth/duty/query-duty-used/-by-/',

    // 职责维护
    responsReviewUrl:'/api/fsmc/auth/duty/query-duty-review-by-page', // 职责复核-查询分页列表
    reviewDutyUrl:'/api/fsmc/auth/duty/review-duty', // 职责复核-复核职责
    reviewBackDutyUrl:'/api/fsmc/auth/duty/review-back-duty', // 职责复核-复核职责打回


    passwordRulesUrl:'/api/fsmc/auth/password-rule/query-password-rule', // 密码规则设置
    updatePasswordRuleUrl:'/api/fsmc/auth/password-rule/update-password-rule', // 密码规则设置-更新密码规则


    querySysPageIframeUrl: '/api/fsmc/sys-page-iframe/query-sys-page-iframe-by-page', // 系统嵌入页面-分页查询
    addSysPageIframeUrl:'/api/fsmc/sys-page-iframe/sys-page-iframe-add', // 系统嵌入页面-新增保存
    querySysPageIframeListUrl:'/api/fsmc/sys-page-iframe/query-sys-page-iframe-list', // 系统嵌入页面-列表查询
    updateSysPageIframeUrl:'/api/fsmc/sys-page-iframe/sys-page-iframe-update', // 系统嵌入页面-编辑保存
    deleteSysPageIfrmeUrl:'/api/fsmc/sys-page-iframe/sys-page-iframe-delete', // 系统嵌入页面-列表删除


    //操作日志查询
    queryOperateLogByPageUrl: '/api/fsmc/system/operate/query-operate-by-page',


}
