export default {
    contactorgtype:{//往来机构类型维护
        queryContactOrgTypeByPageUrl: '/api/fsmc/contactOrg/contactOrgType/query/contact-org-type/page',//列表分页查询
        queryContactOrgTypeByIdUrl: '/api/fsmc/contactOrg/contactOrgType/query/contact-org-type/-by-/',//查询单个对象
        addContactOrgTypeUrl: '/api/fsmc/contactOrg/contactOrgType/insert/contact-org-type-add',//新增
        updateContactOrgTypeUrl: '/api/fsmc/contactOrg/contactOrgType/update/contact-org-type-update',//更新
        deleteContactOrgTypeUrl: '/api/fsmc/contactOrg/contactOrgType/delete/contact-org-type-delete',//删除
        startOrStopUrl: '/api/fsmc/contactOrg/contactOrgType/update/contact-org-type-start-or-stop',//启用/停用
        queryOrgStructUrl: '/api/fsmc/contactOrg/contactOrgType/query/contact-org-type-list',//查询往来机构类型下拉框
        getCodeUrl: '/api/fsmc/contactOrg/contactOrgType/get/code',//生成编码
    },
    contactorg:{//往来机构维护
        queryContactOrgByPageUrl: '/api/fsmc/contactOrg/contactOrg/query/contact-org/page',//列表分页查询
        queryContactOrgByIdUrl: '/api/fsmc/contactOrg/contactOrg/query/contact-org/-by-/',//查询单个对象
        addContactOrgUrl: '/api/fsmc/contactOrg/contactOrg/insert/contact-org-add',//新增
        updateContactOrgUrl: '/api/fsmc/contactOrg/contactOrg/update/contact-org-update',//更新
        deleteContactOrgUrl: '/api/fsmc/contactOrg/contactOrg/delete/contact-org-delete',//删除
        queryBankByPageUrl: '/api/fsmc/contactOrg/contactOrg/query/bank/page',//支行信息列表分页查询
    },
    contactorgacc:{//往来机构账户维护
        queryContactOrgAccByPageUrl: '/api/fsmc/contactOrg/contactOrgAcc/query/contact-org-acc/page',//列表分页查询
        queryContactOrgAccByIdUrl: '/api/fsmc/contactOrg/contactOrgAcc/query/contact-org-acc/-by-/',//查询单个对象
        addContactOrgAccUrl: '/api/fsmc/contactOrg/contactOrgAcc/insert/contact-org-acc-add',//新增
        updateContactOrgAccUrl: '/api/fsmc/contactOrg/contactOrgAcc/update/contact-org-acc-update',//更新
        deleteContactOrgAccUrl: '/api/fsmc/contactOrg/contactOrgAcc/delete/contact-org-acc-delete',//删除
        queryCurrencyList: '/api/fsmc/data/currency/query-currency-combo', // 查询币种下拉框
    },

    financeorg:{//金融机构
        queryApprovalHistory:'/api/fsmc/common/wkfl/quey-approval-history',//审批历史信息
        queryBackFinanceOrgUrl:'/api/fsmc/finance-org/query-back-finance-org-by-page', // 查询退出的金融机构

        // 金融机构准入
        queryFinanceOrgEnterBusinessNo: '/api/fsmc/finance-org-enter-apply/get/rule-code', // 金融机构准入申请单编号获取
        addFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/finance-org-add', // 金融机构准入申请单-新增保存
        addSubmitFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/finance-org-add-submit', // 金融机构准入申请单-新增提交
        updateFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/finance-org-update', // 金融机构准入申请单-更新保存
        updateSubmitFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/finance-org-update-submit', // 金融机构准入申请单-更新提交
        delFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/finance-org-delete', // 金融机构准入申请单-删除
        queryFinanceOrgEnterApplyByIdUrl: '/api/fsmc/finance-org-enter-apply/query-finance-org/-by-/', // 金融机构准入申请单根据id查询
        queryFinanceOrgEnterApplyUrl: '/api/fsmc/finance-org-enter-apply/query-finance-org-by-page', // 金融机构准入申请单查询

        // 金融机构变更
        queryFinanceOrgModifyBusinessNo: '/api/fsmc/finance-org-modify-apply/get/rule-code', // 金融机构变更申请单编号获取
        addFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/finance-org-add', // 金融机构变更申请单-新增保存
        addSubmitFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/finance-org-add-submit', // 金融机构变更申请单-新增提交
        updateFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/finance-org-update', // 金融机构变更申请单-更新保存
        updateSubmitFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/finance-org-update-submit', // 金融机构变更申请单-更新提交
        delFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/finance-org-delete', // 金融机构变更申请单-删除
        queryFinanceOrgModifyApplyByIdUrl: '/api/fsmc/finance-org-modify-apply/query-finance-org/-by-/', // 金融机构变更申请单根据id查询
        queryFinanceOrgModifyApplyUrl: '/api/fsmc/finance-org-modify-apply/query-finance-org-by-page', // 金融机构变更申请单查询

        // 金融机构退出
        queryFinanceOrgBackBusinessNo: '/api/fsmc/finance-org-back-apply/get/rule-code', // 金融机构退出申请单编号获取
        addFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/finance-org-add', // 金融机构退出申请单-新增保存
        addSubmitFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/finance-org-add-submit', // 金融机构退出申请单-新增提交
        updateFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/finance-org-update', // 金融机构退出申请单-更新保存
        updateSubmitFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/finance-org-update-submit', // 金融机构退出申请单-更新提交
        delFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/finance-org-delete', // 金融机构退出申请单-删除
        queryFinanceOrgBackApplyByIdUrl: '/api/fsmc/finance-org-back-apply/query-finance-org/-by-/', // 金融机构退出申请单根据id查询
        queryFinanceOrgBackApplyUrl: '/api/fsmc/finance-org-back-apply/query-finance-org-by-page', // 金融机构退出申请单查询

        // 金融机构查询
        queryFinanceOrgByPage: '/api/fsmc/finance-org/query-finance-org-by-page', // 金融机构分页查询
        queryFinanceOrgByIdUrl: '/api/fsmc/finance-org/query-finance-org/-by-/', // 金融机构单笔查询
        queryAllApplyInfoForFinanceOrgUrl: '/api/fsmc/finance-org/query-all-apply-info', // 查询该金融机构全部的申请单信息

        getFinanceCodeUrl:'/api/fsmc/finance-org/get/finance-code',// 合作金融机构-生成机构编码
        queryOrgTypeByPageUrl:'/api/fsmc/finance-org/query-org-type-by-page',// 合作金融机构-机构大类查询
        queryBackOrgByPageUrl:'/api/fsmc/finance-org/query-back-org-by-page',// 合作金融机构-机构大类查询
        queryOrgByPageUrl:'/api/fsmc/finance-org/query-org-by-page',// 合作金融机构-机构大类查询
    }
}
