<template>
    <div class="com-form-process">
        <f-timeline
            v-if="processData.length > 0">
            <f-timeline-item
                v-for="(item, index) in processData"
                :key="item.id"
                :color="index == 0 ? '#359BE6' : '#E0E0E0'"
            >
                <div>
                    <div class="current-approve-state">
                        <span>{{ item.currentState }}</span>
                        <span
                            v-for="file in item.fileList || []"
                            :key="file.id"
                            class="current-approve-file"
                            @click="download(file)">
                            {{ file.fileName }}
                            <i class="el-icon-download"></i>
                        </span>
                        <f-form style="margin: 0; padding: 0; display:inline-block;">
                            <f-button class="down-btn" v-if="item.fileList && item.fileList.length > 0" type="primary" size="mini" @click="downloadAll(item.fileList)">{{$t('i18n.common.components.uploadTemplate.btns.downloadAll')}}</f-button>
                        </f-form>
                    </div>
                    <div class="current-approve-person">{{ item.approveLabel }} : {{ item.approvePerson }}</div>
                    <div class="current-approve-info">{{ item.approveInfoLabel }} : {{ item.approveInfo }}</div>
                    <div class="next-approve-person">
                        {{ item.nextApproveLabel }}  {{item.nextApproveLabel ? ':':'' }}
                        {{
                            Array.isArray(item.nextApprovePerson)
                                ? item.nextApprovePerson.join(',')
                                : item.nextApprovePerson
                        }}
                    </div>
                    <div class="date">
                        <span class="day">
                            {{ item.dateTime | formatDateOnly }}
                        </span>
                        <span class="time">
                            {{ item.dateTime | formatTimeOnly }}
                        </span>
                    </div>
                </div>
            </f-timeline-item>
        </f-timeline>
        <div v-else>{{$t('i18n.common.components.comFormProcess.dataEmpty')}}</div>
    </div>
</template>

<script>
/**
 * 表单中的审批流组件
 */
export default {
    name: 'ComFormProcess',
    data() {
        return {
            processData: [],
        };
    },
    props: {
        /**
         * 请求数据的地址, 或者直接的源数据
         */
        dataSource: {
            type: [Array, String],
            default: '/api/wkfl/workdesk/task-form/query/task-history/list'
        },
        /**
         * 请求携带的参数
         */
        extraData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getProcessData();
        },
        /**
         * 获取审批流信息
         */
        getProcessData() {
            if (typeof this.dataSource == 'string') {
                // 当dataSource配置为字符串时,发送请求
                this.$http.post('/api/wkfl/workdesk/task-form/query/task-history/list', this.extraData).then(res => {
                    if (res.code == 0) {
                        this.processData = [];
                        this.processData.push(...res.data);
                    }
                });
            } else {
                this.processData = this.dataSource;
            }
        },
        reload() {
            this.getProcessData();
        },
        download(file) {
            this.$http.export('/api/fsmc/components/upload/download-file', {
                id: file.id
            });
        },
        downloadAll(fileList) {
            this.$http.export('api/fsmc/components/upload/download-file-zip', {
                idList: fileList.map(item => item.id)
            });
        }
    },
    watch: {
        dataSource: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getProcessData();
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.com-form-process {
    margin: 8px;
    /deep/ .el-timeline-item__content {
        text-align: left;
    }
    /deep/ .el-timeline {
        padding-left: 0px;
    }
    /deep/ .el-timeline-item__wrapper {
        padding-left: 100px;
    }
    /deep/ .el-timeline-item__node--large {
        left: 100px;
    }
    /deep/ .el-timeline-item__node--normal {
        width: 9px;
        height: 9px;
        left: 80px;
    }
    /deep/ .el-timeline-item__tail {
        left: 84px;
        border-left: 1px solid #f7f7f7;
    }
    .current-approve-state {
        font-size: 13px;
        color: #2c2c2c;
        line-height: 22px;
        margin-bottom: 5px;
        text-align: left;
    }
    .current-approve-file{
        margin: 0 10px;
        cursor: pointer;
        &:hover{
            color: blue;
        }
        .el-icon-download{
            color: blue;
        }
    }
    .down-btn{
        padding: 3px 6px;
        font-size: 10px;
    }
    .current-approve-person {
        display: inline-block;
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
        margin-right: 32px;
    }
    .current-approve-info {
        display: inline-block;
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
        margin-right: 32px;
    }
    .next-approve-person {
        display: inline-block;
        line-height: 18px;
        font-size: 11px;
        color: #7d7d7d;
        text-align: left;
    }
    .date {
        position: absolute;
        top: 0px;
        left: 0px;
        text-align: right;
        line-height: 18px;
    }
    .day {
        display: block;
        font-size: 13px;
        color: #aaaaaa;
    }
    .time {
        display: block;
        font-size: 11px;
        color: #aaaaaa;
    }
}
</style>
