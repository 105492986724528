export default {

    //省信息维护
    queryProvinceByPageUrl:'/api/fsmc/data/province/query-province-by-page', // 省信息维护
    addProvinceUrl:'/api/fsmc/data/province/add-province', // 省信息维护 - 新增
    updateProvinceUrl:'/api/fsmc/data/province/update-province', // 省信息维护 - 更新
    deleteProvinceUrl:'/api/fsmc/data/province/delete-province', // 省信息维护 - 删除
    queryProvinceByIdUrl:'/api/fsmc/data/province/query-province/-by-/', // 省信息维护 - 根据id查询单个对象
    queryProvinceCodeListUrl:'/api/fsmc/data/province/combo/query-province-all-list', // 省信息维护 - 省信息下拉框常量列表

    //市信息维护
    queryCityByPageUrl:'/api/fsmc/data/city/query-city-by-page', // 市信息维护
    addCityUrl:'/api/fsmc/data/city/add-city', // 市信息维护 - 新增
    updateCityUrl:'/api/fsmc/data/city/update-city', // 市信息维护 - 更新
    deleteCityUrl:'/api/fsmc/data/city/delete-city', // 市信息维护 - 删除
    queryCityByIdUrl:'/api/fsmc/data/city/query-city/-by-/', // 市信息维护 - 根据id查询市单个对象
    queryCityCodeListUrl:'/api/fsmc/org-info/combo/city/query-city-code-list', // 市信息维护 - 市信息下拉框常量列表

    //国家维护
    countryStateUrl:'/api/fsmc/data/country/query-country-by-page',// 国家维护
    addCountryUrl:'/api/fsmc/data/country/country-add',// 国家维护 - 新增
    deleteCountryUrl:'/api/fsmc/data/country/country-delete',// 国家维护 - 删除
    deleteBatchCountryUrl:'/api/fsmc/data/country/country-batch-delete',// 国家维护 - 批量删除
    updateCountryUrl:'/api/fsmc/data/country/country-update',// 国家维护 - 更新
    queryCountryByIdUrl:'/api/fsmc/data/country/query-country/-by-/',// 国家维护 - 根据id查询单个对象
    queryCountryListUrl:'/api/fsmc/data/country/combo/country/query-country-code-list',// 国家维护 - 国家信息下拉框常量列表（下拉框不是变量）

    //合作金融机构
    queryLineNumbersByPageUrl:'/api/fsmc/data/line-numbers/query-line-numbers-by-page',// 合作金融机构
    queryNonLineNumbersByPageUrl:'/api/fsmc/finance-org/query-finance-org-by-page',// 合作金融机构(非银行类)
    addLineNumbersUrl:'/api/fsmc/data/line-numbers/add-line-numbers',// 合作金融机构 - 新增
    updateLineNumbersUrl:'/api/fsmc/data/line-numbers/update-line-numbers',// 合作金融机构 - 更新
    deleteLineNumbersUrl:'/api/fsmc/data/line-numbers/delete-line-numbers',// 合作金融机构-删除
    queryCoopByIdUrl:'/api/fsmc/data/line-numbers/query-line-numbers/-by-/',// 合作金融机构-根据id查用户信息
    queryLineNumbersListUrl:'/api/fsmc/data/line-numbers/combo/query-line-numbers-list',// 合作金融机构-合作金融机构下拉框

    //币种维护
    queryCurrencyByPageUrl:'/api/fsmc/data/currency/query-currency-by-page', // 币种维护 - 分页列表
    queryCurrencyByIdUrl:'/api/fsmc/data/currency/query-currency/-by-/', // 币种维护-根据id查询单个对象
    addCurrencyUrl:'/api/fsmc/data/currency/currency-add', // 币种维护-新增币种信息
    updateCurrencyUrl:'/api/fsmc/data/currency/currency-update', // 币种维护-更新
    deleteCurrencyUrl:'/api/fsmc/data/currency/currency-delete', // 币种维护-删除
    queryCurrencyUrl:'/api/fsmc/data/currency/query-currency-combo', // 币种维护-查询币种下拉框

    // 支行信息维护
    queryBankByPageUrl:'/api/fsmc/data/bank/query-bank-by-page',// 支行信息维护-列表分页查询
    queryBankByIdUrl:'/api/fsmc/data/bank/query-bank/-by-/',// 支行信息维护-通过id查询单个对象
    addBankUrl:'/api/fsmc/data/bank/bank-add',// 支行信息维护-新增
    updateBankUrl:'/api/fsmc/data/bank/bank-update',// 支行信息维护-更新
    deleteBankUrl:'/api/fsmc/data/bank/bank-delete',// 支行信息维护-删除
    queryOrgStructBankUrl:'/api/fsmc/data/bank/query-line-numbers-list',// 支行信息维护-查询大行信息下拉框  （下拉框不是变量）
    queryProvinceUrl:'/api/fsmc/data/province/combo/query-province-all-list', // 支行信息维护-省下拉框查询
    queryCityUrl:'/api/fsmc/data/city/combo/query-city-all-list', // 支行信息维护-市下拉框查询

    // 汇率维护设置
    queryTemplateTypeList:'/api/fsmc/fsmc-api/template/query-template-list',//导入
    queryExchangeRateByPageUrl:'/api/fsmc/data/exchangeRate/query-exchange-rate-by-page',// 汇率维护设置-列表分页查询
    importExchangeRateUrl:'/api/fsmc/data/exchangeRate/exchange-rate-import',// 汇率维护设置-导入
    downloadFileUrl:'/api/fsmc/data/exchangeRate/download-template',// 汇率维护设置-下载模板
    deleteExchangeRateUrl:'/api/fsmc/data/exchangeRate/exchange-rate-delete',// 汇率维护设置-删除
    queryCurrentExchangeRateByPageUrl:'/api/fsmc/data/exchangeRate/query-current-exchange-rate-by-page',// 汇率维护设置-列表分页查询

    // 导入模板设置
    queryTemplateByPageUrl:'/api/fsmc/data/template/query-template-by-page',// 导入模板设置-列表分页查询
    queryTemplateByIdUrl:'/api/fsmc/data/template/query-template/-by-/',// 导入模板设置-通过id查询单个对象
    addTemplateUrl:'/api/fsmc/data/template/template-add',// 导入模板设置-新增
    updateTemplateUrl:'/api/fsmc/data/template/template-update',// 导入模板设置-更新
    deleteTemplateUrl:'/api/fsmc/data/template/template-delete',// 导入模板设置-删除
    queryTemplateList:'/api/fsmc/data/template/query-template-list',//查询模板列表


    //岗位关系映射
    queryPositionRelationByPageUrl:'/api/fsmc/position-relation/query-position-relation-by-page', // 岗位关系映射 - 分页列表
    queryPositionRelationByIdUrl:'/api/fsmc/position-relation//query-position-relation/-by-/', // 岗位关系映射 - 查询单个对象
    addPositionRelationUrl:'/api/fsmc/position-relation/position-relation-add', // 岗位关系映射 - 新增
    updatePositionRelationUrl:'/api/fsmc/position-relation/position-relation-update', // 岗位关系映射 - 更新
    deletePositionRelationUrl:'/api/fsmc/position-relation/position-relation-delete', // 岗位关系映射 - 删除
    queryPositionRelationListUrl:'/api/fsmc/position-relation/query-position-relation-list/', // 岗位关系映射 - 列表查询
    queryDutyByPageUrl:'/api/fsmc/position-relation/query-duty-combo-by-page', // 岗位关系映射 - 列表分页查询职责

    //外部岗位
    queryExternalPositionListUrl:'/api/fsmc/external-position/query-external-position-list', // 外部岗位 - 列表分页查询

}
