<script>
import utils from '@/common/utils';
function isFunction(fn) {
    return Object.prototype.toString.call(fn) === '[object Function]';
}
// 深，浅合并
// extend(true, target, source) 深合并
// extend(false, target, source) 浅合并
function extend() {
    var extended = {};
    var deep = false;
    var i = 0;

    // 判断是否为深拷贝
    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
        deep = arguments[0];
        i++; //如果为深拷贝则初始的i为1或者为0
    }

    // 将对象属性合并到已存在的对象中
    var merge = function(obj) {
        for (var prop in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(prop)) {
                // 如果属性为对象并且需要深拷贝时则使用函数递归、反之则将当前的属性替换现有的属性
                if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                    extended[prop] = extend(extended[prop], obj[prop]);
                } else {
                    extended[prop] = obj[prop];
                }
            }
        }
    };

    // 遍历所有对象属性
    for (; i < arguments.length; i++) {
        merge(arguments[i]);
    }

    return extended;
}

export default {
    name: 'ComRange',
    data() {
        return {
            showClose: false
        };
    },
    props: {
        value: Array, // v-model 绑定值
        disabled: Boolean, // 是否禁用
        readonly: Boolean, // 是否只读
        prefixIcon: String, // 自定义前置图标
        clearIcon: {
            type: String,
            default: 'el-icon-circle-close'
        }, // 清除图标
        dataSource: Array, // 元数据
        // 是否显示清除按钮
        clearable: {
            type: Boolean,
            default: true
        }, // 是否可清空
        rangeSeparator: {
            type: [String, Function],
            default: '-'
        }, // 中间分隔
        tip: {
            type: Boolean,
            default: true
        },
        tipFormat: {
            type: Array,
            default: () => [{ tag: 'f-amount', foramt: utils.formatAmount }]
        }
    },
    computed: {
        model() {
            return this.value || [];
        },
        // 组件是否全部disabled
        rangeDisabled() {
            return this.disabled || (this.elForm || {}).disabled;
        },
        // 前置图标
        triggerClass() {
            return this.prefixIcon;
        },
        // 判断值是否是空， 如果是空就不显示清空小图标
        valueIsEmpty() {
            return !this.model.some(item => item != null);
        },
        // 使用计算属性，方便form 重置时不使用广播，进行重置
        form: {
            get() {
                // 将 ['a', 'b', 'c'] =>  {index_1: 'a', index_2: 'b', index_3: 'c'}
                return this.dataSource.reduce((t, item, index) => {
                    t[`index_${index}`] = this.model[index];
                    return t;
                }, {});
            },
            set(val) {
                this.update(val);
            }
        }
    },
    methods: {
        // 更新组件数据
        update(form) {
            let isClear = Object.keys(form).every(key => form[key] == null);
            let value = isClear ? [] : Object.values(form).map(item => item);
            this.$emit('input', value);
        },
        // 清空按钮清空数据
        handleClickIcon() {
            if (this.readonly || this.rangeDisabled) return;
            this.$emit('input', []);
        },
        // 如果填入鼠标移入组件会出现清空图标
        handleMouseEnter() {
            if (this.readonly || this.rangeDisabled) return;
            if (!this.valueIsEmpty && this.clearable) {
                this.showClose = true;
            }
        },
        // 渲染子组件
        renderChildren(h) {
            let { renderSeparator, renderComponent, dataSource, rangeDisabled, readonly } = this;
            let content = dataSource.reduce((t, item, index) => {
                return t.concat(
                    renderSeparator(h),
                    renderComponent(h, item, {
                        props: { value: this.form[`index_${index}`], disabled: rangeDisabled, readonly },
                        on: {
                            input: val => {
                                if (val !== this.form[`index_${index}`]) {
                                    this.form = { ...this.form, [`index_${index}`]: val };
                                }
                            }
                        }
                    })
                );
            }, []);
            content.shift();
            return content;
        },
        // 渲染tooltip
        // 处理空字符串， 0， false
        renderTooltip(h, children) {
            let { tipFormat } = this;
            let format = this.dataSource.reduce((total, item, index) => {
                let tag = '',
                    tagFormat;
                if (isFunction(item)) {
                    tag = (item(h)['componentOptions'] || {}).tag;
                } else {
                    tag = item.tag;
                }
                tagFormat = tipFormat.find(item => item.tag === tag);
                if (tagFormat) {
                    total.push(this.model[index] != null ? tagFormat.foramt(this.model[index]) : '');
                } else {
                    total.push(this.model[index] != null ? this.model[index] : '');
                }
                return total;
            }, []);
            // tip 是否显示tooltip true 是, false 否
            // !(this.tip && !this.valueIsEmpty)  为 true 表示禁用状态 false 表示非禁用状态
            return h(
                'f-tooltip',
                {
                    props: {
                        effect: 'dark',
                        content: format.join(` ${this.rangeSeparator} `),
                        disabled: !(this.tip && !this.valueIsEmpty)
                    }
                },
                [children]
            );
        },
        // 渲染配置的组件， 支持 () => <div></div>  和 {tag: 'f-input'}
        renderComponent(h, cfg, extra) {
            let isFn = isFunction(cfg);
            let { tag, ...props } = extend(true, cfg, extra);
            let vnode = isFn && cfg(h);
            if (vnode) {
                vnode.componentOptions = extend(true, vnode.componentOptions, {
                    propsData: props.props,
                    listeners: props.on
                });
            }
            return vnode ? vnode : h(tag, props, []);
        },
        // 渲染分隔, 支持 () => <div></div>  和 '至'
        renderSeparator(h) {
            let _this = this;
            return typeof _this.rangeSeparator === 'string'
                ? h('span', { class: 'el-range-separator' }, [_this.rangeSeparator])
                : _this.rangeSeparator(h);
        }
    },
    render(h) {
        let _this = this;
        return this.renderTooltip(
            h,
            h(
                'div',
                {
                    class: ['com-range', 'xxxx', 'el-input__inner', [_this.rangeDisabled ? 'is-disabled' : '']],
                    on: {
                        mouseenter() {
                            _this.handleMouseEnter();
                        },
                        mouseleave() {
                            _this.showClose = false;
                        }
                    }
                },
                [
                    ..._this.renderChildren(h),
                    _this.clearable &&
                        h(
                            'i',
                            {
                                class: [
                                    'el-input__icon',
                                    'el-range__close-icon',
                                    this.showClose ? '' + this.clearIcon : ''
                                ],
                                on: {
                                    click() {
                                        _this.handleClickIcon();
                                    }
                                }
                            },
                            []
                        )
                ]
            )
        );
    }
};
</script>

<style lang="scss" scoped>
.com-range {
    &.el-input__inner {
        height: 32px;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
        & /deep/ .el-input__inner {
            border-color: transparent;
            padding: 0;
        }
        & .el-input__icon {
            min-width: 20px;
        }
    }
    & .el-range-separator {
        display: inline-block;
        height: 100%;
        padding: 0 5px;
        margin: 0;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        width: 5%;
        color: #303133;
    }
    & .el-range__close-icon {
        font-size: 14px;
        color: #c0c4cc;
        width: 25px;
        display: inline-block;
        line-height: 32px;
    }
}
</style>
