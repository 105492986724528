<template>
    <com-magnifier-single :searchModel="searchForm" v-bind="$attrs" v-on="$listeners">
        <template v-if="queryData.length" v-slot:search>
            <f-form-item v-for="(query, index) in queryData" :key="index" :label="query.text" :prop="query.name">
                <f-input v-model="searchForm[query.name]"></f-input>
            </f-form-item>
        </template>
        <template>
            <f-magnifier-column
                v-for="(show, index) in showData"
                :key="index"
                :prop="show.name"
                :label="show.text"
            ></f-magnifier-column>
        </template>
    </com-magnifier-single>
</template>

<script>
export default {
    name: 'FormMagnifierSingle',
    data() {
        return {
            searchForm: {}
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        queryData: Array,
        showData: Array
    }
};
</script>

<style lang="scss" scoped></style>
