import { addClass, removeClass } from '@iss/vmui-vue/lib/utils/dom';
import storageUtils from '@/common/utils/storageUtils';
export default {
    /**
     * 按钮隐藏指令
     * 指令的value: 为按钮的唯一标识,根据这个标识的值判断是否对按钮进行隐藏操作
     * 注意: 当开发并非使用f-button标签时, 而是使用的props传参的方式进行组件渲染按钮时, 组件按钮需要统一实现对vHideBtn的支持
     * 1. <f-botton v-hide-btn="10001"></f-button>
     * 1. btnProps: {
     *     vHideBtn: '10001',
     *     type: 'primary',
     *     size: 'mini'
     * }
     */
    'hide-btn': {
        bind: function(el, binding) {
            try {
                let hideBtn = JSON.parse(storageUtils.getItem('hideBtn'));
                let key = binding.value;
                if (hideBtn && key && hideBtn[key] && hideBtn[key].functionDisplay === false) {
                    addClass(el, 'com-directive-hide-btn');
                }
            } catch (err) {
                console.log(err);
            }
        },
        update: function(el, binding) {
            try {
                let hideBtn = JSON.parse(storageUtils.getItem('hideBtn'));
                let key = binding.value;
                if (hideBtn && key && hideBtn[key] && hideBtn[key].functionDisplay === false) {
                    addClass(el, 'com-directive-hide-btn');
                }
            } catch (err) {
                console.log(err);
            }
        }
    },
    'hide-param': {
        bind: function(el, binding) {
            let prop = binding.arg;
            let hiddenParams = binding.value;
            if (prop && hiddenParams && hiddenParams.includes(prop)) {
                addClass(el, 'com-directive-hide-param');
            }
        },
        update: function(el, binding) {
            let prop = binding.arg;
            let hiddenParams = binding.value;
            if (prop && hiddenParams && hiddenParams.includes(prop)) {
                addClass(el, 'com-directive-hide-param');
            } else {
                removeClass(el, 'com-directive-hide-param');
            }
        }
    }
};
