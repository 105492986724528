import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhLocale from '@iss/vmui-vue/lib/locale/lang/zh-CN';
import enLocale from '@iss/vmui-vue/lib/locale/lang/en';
import zhFrontend from '@iss/frontend-ui/src/locale/lang/zh-CN';
import enFrontend from '@iss/frontend-ui/src/locale/lang/en';
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import storageUtils from '@/common/utils/storageUtils';

import http from '@/common/http';
let path = require('path');

Vue.use(VueI18n);
//单元测试的时候不识别sessionStorage，所以要在前面加window
const messages = {
    'zh-CN': {
        ...zhLocale,
        ...zhCN,
        ...zhFrontend
    },
    'en-US': {
        ...enLocale,
        ...enUS,
        ...enFrontend
    }
};

const i18n = new VueI18n({
    locale: storageUtils.getItem('lang') || 'zh-CN',
    messages: messages
});
/**
 * 引入demo模块下的国际化文件
 * @param {Funcion} files 引入功能模块下国际化文件的函数
 * @param {String} partName  功能模块文件夹名称
 * @param {String} langType 引入的国际化的语言类别
 * @param {langObj} 国际化数据对象
 */
function setMoudelLang(files, partName, langType, langObj) {
    let partLang = {};
    files.keys().forEach(key => {
        let exportDefault = files(key).default;
        if (exportDefault) {
            // 以国际化文件的名称做为key值
            let keyName = key.replace('./', '').replace('.js', '');
            partLang[keyName] = exportDefault;
        }
    });
    if (!langObj[langType]) {
        langObj[langType] = {};
    }
    if (!langObj[langType][partName]) {
        langObj[langType][partName] = {};
    }
    langObj[langType][partName] = partLang;
}

/**
 * 模块下全局设置
 * @param files  模块路径
 * @param langType  语言
 * @param langObj  国际化数据对象
 */
function setAllLang(files, langType, langObj) {
    /*let path = require('path');
    let partLang = {};
    let partName = 'i18n';
    files.keys().forEach(item => {
        let name = path.basename(item, '.js');
        if (!/^((.*)\/(i18n)\/)/.test(item)) {
            if (item.indexOf(langType) != -1) {
                let exportDefault = files(item).default || files(item);
                if (exportDefault) {
                    // 以国际化文件的名称做为key值
                    partLang[name] = exportDefault;
                }
            }
        } else {
            //console.log("这不是------->"+name + " == " +item);
        }
    });
    if (!langObj[langType]) {
        langObj[langType] = {};
    }
    if (!langObj[langType][partName]) {
        langObj[langType][partName] = {};
    }
    Object.keys(partLang).forEach(item => {
        langObj[langType][partName][item] = partLang[item];
    });*/
}

async function setAllLangAsync(param,nowModel,store) {

    let hasLang = store.getters['common/getInitLang'];
    console.log('是否装载国际化:',hasLang);
    if(hasLang){
        return Promise.resolve();
    }
    let langTypeList = [];
    if(typeof param === 'string'){
        langTypeList[0] = param;
    }else if(param instanceof Array){
        langTypeList = param;
    }else {
        return;
    }
    let partLang = {};
    for (const langType of langTypeList) {
        let partName = 'i18n';

        partLang[langType] ={};
        partLang[langType][partName] ={};
        let common = {},index = {} ,zIndex={};
        try{
            await http.get(`./locale/${nowModel}/${langType}/index.json`,{}, { __expires__: 'true' ,errorNoTip:true},false).then(res =>{
                try{
                    if(res.status == 200){
                    }else{
                        index = res
                        Object.assign(common,res.common);

                    }
                }catch (e) {
                    console.log(e,res,item);
                }
            })
        }catch (e){

        }
        try{
            await http.get(`./locale/${nowModel}/${langType}/z-index.json`,{}, { __expires__: 'true' ,errorNoTip:true},false).then(res =>{
                try{
                    if(res.status == 200){
                    }else{
                        zIndex = res
                        Object.assign(common,res.common);
                    }
                }catch (e) {
                    console.log(e,res,item);
                }

            })
        }catch (e) {

        }

        try{
            await http.get(`./locale/global/${langType}/index.json`,{}, { __expires__: 'true' ,errorNoTip:true},false).then(res =>{
                try{
                    if(res.status === 200){
                        //partLang[langType][partName][model]={}
                    }else{
                        Object.assign(common,res.common);
                    }
                }catch (e) {
                    console.log(e,res,item);
                }

            })
        }catch (e) {

        }
        try{
            await http.get(`./locale/global/${langType}/z-index.json`,{}, { __expires__: 'true' ,errorNoTip:true},false).then(res =>{
                try{
                    if(res.status === 200){
                        //partLang[langType][partName][model]={}
                    }else{
                        Object.assign(common,res.common);
                    }
                }catch (e) {
                    console.log(e,res,global);
                }

            })
        }catch (e) {

        }
        delete zIndex.common;
        delete index.common;

        Object.keys(index).forEach(item =>{
            Object.assign(index[item],zIndex[item]);
        })

        partLang[langType] = {...messages[langType]};
        partLang[langType][partName]={...index,common:common||{}};
        console.log('装载国际化语言',partLang,index,common);
        i18n.setLocaleMessage(langType,partLang[langType]);
        store.commit('common/setInitLang',true);

        console.log('装载完毕，跳转页面中....');
    }

    i18n.locale = storageUtils.getItem('lang');
    return Promise.resolve();

}


export { i18n, setMoudelLang, setAllLang,setAllLangAsync };

export default i18n;
